import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { MeState, ISetCompanyTour } from './types'
import { ls } from 'utils/my-localstorage'

const getUserDataFromLS = () => {
  const result = ls.getObject('wwl-user') ?? {}
  const company = ls.getObject('wwl-selected_company')
  result.companies = typeof company === 'object' ? [company] : []
  return result
}

const setUserDataToLS = payload => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companies, ...data } = payload
  ls.setObject('wwl-user', data)
}

const setSelectedCompanyDataToLS = payload => {
  ls.setObject('wwl-selected_company', payload ?? '')
}

const selectedCompanyId = ls.getItem('wwl-selected_company_id') || '0'

const initialState: MeState = {
  data: getUserDataFromLS(),
  selectedCompanyId,
}

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      setUserDataToLS(payload)
      state.data = payload
    },
    setSelectedCompanyId: (state, { payload }: PayloadAction<string>) => {
      const company = state?.data?.companies?.find(v => v.id === payload)
      setSelectedCompanyDataToLS(company)
      state.selectedCompanyId = payload
    },
    clearState: () => {
      return {
        ...initialState,
        selectedCompanyId: '0',
      }
    },
    setCompanyTour: (state, { payload }: PayloadAction<ISetCompanyTour>) => {
      const { companyId, tour } = payload
      const companies = (state?.data?.companies ?? []).map(company => {
        if (company.id === companyId) return { ...company, tour }
        return company
      })
      state.data = {
        ...state.data,
        // @ts-ignore
        companies,
      }
    },
  },
})

export const { actions, reducer: me } = meSlice
export const { setData, setSelectedCompanyId, clearState, setCompanyTour } =
  actions
