import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import {
  ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  ADMINISTRATION_TRADE_OFFERS,
  RETAIL_WINES,
  REVIEWS_MY_REVIEWS,
} from 'utils/consts/path'
import { useCamelCase } from 'utils/hooks/useCamelCase.js'

export const routeExclusions = {
  '/acc/buy-wine/offers/all': '/acc/buy-wine/offers',
  '/acc/buy-wine/offers/shortlist': '/acc/buy-wine/offers',
  '/acc/buy-wine/offers/negotiations': '/acc/buy-wine/offers',
  '/acc/buy-wine/offers/my-deals': '/acc/buy-wine/offers',
  '/acc/buy-wine/offers/archived': '/acc/buy-wine/offers',
  '/acc/sell-wine/my-offers/all': '/acc/sell-wine/my-offers',
  '/acc/sell-wine/my-offers/negotiations': '/acc/sell-wine/my-offers',
  '/acc/sell-wine/my-offers/accepted': '/acc/sell-wine/my-offers',
  '/acc/sell-wine/my-offers/archived': '/acc/sell-wine/my-offers',
  '/acc/my-wines/wines/all': '/acc/my-wines/wines',
  '/acc/my-wines/wines/shortlist': '/acc/my-wines/wines',
  [REVIEWS_MY_REVIEWS + '/all']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/public']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/private']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/for-sale']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/sold']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/checking']: REVIEWS_MY_REVIEWS,
  [REVIEWS_MY_REVIEWS + '/drafts']: REVIEWS_MY_REVIEWS,
  '/acc/database/producers/all': '/acc/database/producers',
  '/acc/my-wines/e-labels/all': '/acc/my-wines/e-labels',
  '/acc/my-wines/e-labels/published': '/acc/my-wines/e-labels',
  '/acc/my-wines/e-labels/draft': '/acc/my-wines/e-labels',
  '/acc/my-wines/e-labels/published-by-me': '/acc/my-wines/e-labels',
  '/acc/administration/companies/verified': '/acc/administration/companies',
  '/acc/administration/companies/unverified': '/acc/administration/companies',
  '/acc/administration/companies/rejected': '/acc/administration/companies',
  '/acc/administration/companies/prohibited': '/acc/administration/companies',
  '/acc/administration/e-shops/all': '/acc/administration/e-shops',
  '/acc/administration/e-shops/active': '/acc/administration/e-shops',
  '/acc/administration/e-shops/deleted': '/acc/administration/e-shops',
  '/acc/administration/users/current': '/acc/administration/users',
  '/acc/administration/users/archive': '/acc/administration/users',
  [`${ADMINISTRATION_TRADE_OFFERS}/all`]: ADMINISTRATION_TRADE_OFFERS,
  [`${ADMINISTRATION_TRADE_OFFERS}/active`]: ADMINISTRATION_TRADE_OFFERS,
  [`${ADMINISTRATION_TRADE_OFFERS}/hidden`]: ADMINISTRATION_TRADE_OFFERS,
  [`${ADMINISTRATION_TRADE_OFFERS}/deleted`]: ADMINISTRATION_TRADE_OFFERS,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/all`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/active`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/hidden`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/deleted`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/undefined`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [`${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/unavailable`]:
    ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  [RETAIL_WINES + '/all']: RETAIL_WINES,
  [RETAIL_WINES + '/my-range']: RETAIL_WINES,
  [RETAIL_WINES + '/compare']: RETAIL_WINES,
}
export const withParamsExclusions = [
  [
    '/acc/buy-wine/analysis/:slug/:geoId/:offerId',
    '/acc/buy-wine/analysis/rating',
  ],
]

export const getFiltersStorageName = (pathname: string) =>
  `filters${pathname.replace(/\//g, '-')}`

const getPartOfActionName = (path: string) => {
  const partOfActionName = useCamelCase(path.slice(1))
  // Ex.: 'AccDatabaseBrands'
  return partOfActionName[0].toUpperCase() + partOfActionName.slice(1)
}

export const useFilterStoreData = () => {
  const location = useLocation()

  const pathname =
    withParamsExclusions.find(([path]) =>
      matchPath(location.pathname, { path })
    )?.[1] ||
    routeExclusions?.[location.pathname] ||
    location.pathname

  const partOfActionName = getPartOfActionName(pathname)

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // filtersStorageName = 'filters-acc-buy-wine-offers'
  const filtersStorageName = useMemo(
    () => getFiltersStorageName(pathname),
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // filtersStorageName = 'search-buy-wine-offers'
  const searchStorageName = useMemo(
    () => `search${pathname.replace(/\//g, '-')}`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setTableFilterActionType = 'tableFilters/setBuyWineOffersFilters'
  const setTableFilterActionType = useMemo(
    () => `tableFilters/set${partOfActionName}Filters`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setTableSpecificFilterActionType = 'tableFilters/setBuyWineOffersFilter'
  const setTableSpecificFilterActionType = useMemo(
    () => `tableFilters/set${partOfActionName}Filter`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setTableFiltersAppliedActionType = 'tableFilters/setBuyWineOffersFiltersApplied'
  const setTableFiltersAppliedActionType = useMemo(
    () => `tableFilters/set${partOfActionName}FiltersApplied`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // removeTableFilterActionType = 'tableFilters/setBuyWineOffersRemoveFilter'
  const removeTableFilterActionType = useMemo(
    () => `tableFilters/set${partOfActionName}RemoveFilter`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setLastUpdatedFilterActionType = 'tableFilters/setBuyWineOffersLastUpdatedFilter'
  const setLastUpdatedFilterActionType = useMemo(
    () => `tableFilters/set${partOfActionName}LastUpdatedFilter`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setFilterErrorsActionType = 'tableFilters/setBuyWineOffersSetFilterErrors'
  const setFilterErrorsActionType = useMemo(
    () => `tableFilters/set${partOfActionName}SetFilterErrors`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers',
  // setAlcoholLevelsActionType = 'tableFilters/setBuyWineOffersAlcoholLevels'
  const setAlcoholLevelsActionType = useMemo(
    () => `tableFilters/set${partOfActionName}AlcoholLevels`,
    [pathname]
  )

  const setPriceIntervalActionType = useMemo(
    () => `tableFilters/set${partOfActionName}PriceInterval`,
    [pathname]
  )
  const setDrinkingWindowActionType = useMemo(
    () => `tableFilters/set${partOfActionName}DrinkingWindow`,
    [pathname]
  )
  const setParticipantsActionType = useMemo(
    () => `tableFilters/set${partOfActionName}Participants`,
    [pathname]
  )
  const setFilterByName = useMemo(
    () => `tableFilters/set${partOfActionName}ByName`,
    [pathname]
  )

  const setCurrencyDefaultActionType = useMemo(
    () => `tableFilters/set${partOfActionName}CurencyDefault`,
    [pathname]
  )
  const setPresetActionType = useMemo(
    () => `tableFilters/set${partOfActionName}Preset`,
    [pathname]
  )
  const setAppliedPresetActionType = useMemo(
    () => `tableFilters/set${partOfActionName}AppliedPreset`,
    [pathname]
  )

  // Ex.: if pathname = '/acc/buy-wine/offers', storeName = 'accBuyWineOffers'
  const storeName = useCamelCase(pathname.slice(1))

  return {
    storeName,
    filtersStorageName,
    searchStorageName,
    setTableFilterActionType,
    setTableSpecificFilterActionType,
    setTableFiltersAppliedActionType,
    removeTableFilterActionType,
    setLastUpdatedFilterActionType,
    setAlcoholLevelsActionType,
    setPriceIntervalActionType,
    setCurrencyDefaultActionType,
    setFilterErrorsActionType,
    setPresetActionType,
    setAppliedPresetActionType,
    setFilterByName,
    setDrinkingWindowActionType,
    setParticipantsActionType,
  }
}
