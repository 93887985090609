import { Radio } from '../../../Common'
import { Description, Group } from '../_styles'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'

const AnotherBlock = ({ disabled }) => {
  const active = useAppSelector(state => {
    return state?.verify?.method === 'another'
  })
  const { t } = useTranslation()

  return (
    <Group>
      <Radio
        name='method'
        value='another'
        defaultChecked={active}
        disabled={disabled}
      >
        {t('myCompany.verifyDialog.another.title')}
      </Radio>
      <Description>{t('myCompany.verifyDialog.another.contact')}</Description>
    </Group>
  )
}

export default AnotherBlock
