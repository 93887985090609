import { addELabelModal } from 'store/add-elabel-modal'
import { administration } from 'store/administration'
import { award } from 'store/award'
import { brands } from 'store/brands'
import { companyWines } from 'store/company-wines'
import { company } from 'store/company'
import { tableFilters } from 'store/table-filters'
import { location } from 'store/location'
import { offers } from 'store/offers'
import { database } from 'store/database'
import { dictionary } from 'store/dictionary'
import { elabelEdit } from 'store/elabel-edit'
import { exhibition } from 'store/exhibition'
import { geoIndication } from 'store/geo-indication'
import { me } from 'store/me'
import { myReviews } from 'store/my-reviews'
import { myWines } from 'store/my-wines'
import { negotiations } from 'store/negotiations/reducer'
import { offerCreate } from 'store/offer-create'
import { offer } from 'store/offer'
import { producer } from 'store/producer'
import { requests } from 'store/requests'
import { reward } from 'store/reward'
import { userNotification } from 'store/user-notification'
import { verify } from 'store/verify'
import { warehouses } from 'store/warehouses'
import { wineCreate } from 'store/wine-create'
import { wineEdit } from 'store/wine-edit'
import { wineRating } from 'store/wine-rating'
import { merchant } from 'store/merchant'
import { tradeOffer } from 'store/trade-offer'
import { wines } from 'store/wines'
import { retailEShops } from 'store/retail-e-shops'
import { retailWines } from 'store/retail-wines'
import { retailGeoIndications } from 'store/retail-geo-indications'
import { retailBuyersWine } from 'store/retail-buyers-wine'
import { requestWines } from 'store/request-wines'
import { potentialProducers } from 'store/potential-producers'
import { mentionEdit } from 'store/mention-edit'

import { configureStore } from '@reduxjs/toolkit'

const store = configureStore({
  reducer: {
    me,
    offers,
    offer,
    company,
    negotiations,
    requests,
    requestWines,
    warehouses,
    dictionary,
    verify,
    wineCreate,
    wineEdit,
    wineRating,
    companyWines,
    location,
    tableFilters,
    brands,
    producer,
    administration,
    database,
    myWines,
    myReviews,
    exhibition,
    geoIndication,
    award,
    reward,
    userNotification,
    offerCreate,
    elabelEdit,
    addELabelModal,
    merchant,
    tradeOffer,
    wines,
    retailEShops,
    retailWines,
    retailGeoIndications,
    retailBuyersWine,
    potentialProducers,
    mentionEdit,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware()
  },
})
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
