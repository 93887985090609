import type { MerchantValues, Validators } from './types'
import { isValidUrl } from 'utils/validators'

const name = (values: MerchantValues) => {
  return !values.name ? 'error.fieldRequired' : null
}

const website = (values: MerchantValues) => {
  if (!values.website) return 'error.fieldRequired'
  if (!isValidUrl(values.website)) return 'error.websiteExample'
  return null
}

const country = (values: MerchantValues) => {
  return !values.country?.id ? 'error.fieldRequired' : null
}

const deliveryTime = () => null

const deliveryTerms = () => null

const validators: Validators = {
  name,
  website,
  country,
  deliveryTime,
  deliveryTerms,
}

export default validators
