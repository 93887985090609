import { createSlice } from '@reduxjs/toolkit'
import type { ExhibitionState } from './types'

const initialState: ExhibitionState = {
  values: {},
  errors: {},
}

const exhibitionSlice = createSlice({
  name: 'exhibition',
  initialState,
  reducers: {
    setValue: (state, { payload }) => {
      state.values = {
        ...state.values,
        [payload.field]: payload.value,
      }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.value,
      }
    },
    init: (state, { payload }) => {
      state.values = {
        ...initialState.values,
        ...payload,
        initialName: payload?.name,
      }
      state.errors = { ...initialState.errors }
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: exhibition } = exhibitionSlice
export const { setValue, setError, init, reset } = actions
