import { createSlice } from '@reduxjs/toolkit'
import { PUBLIC_TYPE } from 'utils/consts'
import type { OfferCreateState } from './types'

const initialState: OfferCreateState = {
  pricePer: '',
  buyerCategory: null,
  buyerCompany: null,
  sellerCompany: null,
  validityPeriod: null,
  incoterms: null,
  warehouse: null,
  products: {},
  offerType: PUBLIC_TYPE,
  errors: {},
  contactEmail: null,
  contactPhone: null,
  copyMode: false,
  availableRoutes: ['/acc/offer/create/wine'],
  loading: false,
  note: null,
}

const offerCreateSlice = createSlice({
  name: 'offerCreate',
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      return { ...state, ...payload }
    },
    setProducts: (state, { payload }) => {
      state.products = payload
    },
    appendProducts: (state, { payload }) => {
      state.products = { ...state.products, ...payload }
    },
    addProduct: (state, { payload }) => {
      state.products = { ...state.products, [payload.id]: payload.data }
    },
    removeProduct: (state, { payload }) => {
      const value = { ...state.products }
      delete value[payload]
      state.products = value
    },
    setProductField: (state, { payload }) => {
      state.products = {
        ...state.products,
        [payload.id]: {
          ...state.products[payload.id],
          [payload.field]: payload.data,
        },
      }
    },
    setField: (state, { payload }) => {
      state[payload.field] = payload.data
    },
    setFieldError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.id]: {
          ...state.errors[payload.id],
          [payload.field]: payload.data,
        },
      }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.data,
      }
    },
    setPricePer: (state, { payload }) => {
      state.pricePer = payload
    },
    seCopyMode: state => {
      state.copyMode = true
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: offerCreate } = offerCreateSlice
export const {
  setData,
  appendProducts,
  removeProduct,
  setProductField,
  setField,
  setFieldError,
  setError,
  setPricePer,
  seCopyMode,
  reset,
} = actions
