import { Placement } from '@popperjs/core'
import type { CSSProperties, SetStateAction, Dispatch } from 'react'
import { FC, ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { usePopperTooltip } from 'react-popper-tooltip'
import { PropsGetterArgs } from 'react-popper-tooltip/dist/types'
import { getOffsetModifier } from './_methods'
import { Arrow, Wrapper } from './Tooltip/_styles'
import questionUrl from './_assets/question.svg'
import { DELAY_SHOW } from 'utils/tooltipModifier'

interface BubbleProps {
  children: ReactNode
  width?: number

  getArrowProps: (args?: PropsGetterArgs) => {
    style: CSSProperties
    'data-popper-arrow': boolean
  }
  getTooltipProps: (args?: PropsGetterArgs) => {
    'data-popper-interactive': boolean | undefined
    style: CSSProperties
  }
  setTooltipRef: Dispatch<SetStateAction<HTMLElement | null>>
}

const Bubble: FC<BubbleProps> = props => {
  const { setTooltipRef, getTooltipProps, getArrowProps, children, width } =
    props

  const maxWidth = typeof width === 'number' ? `${width}px` : width

  return (
    <Wrapper ref={setTooltipRef} {...getTooltipProps()} $maxWidth={maxWidth}>
      {children}
      <Arrow {...getArrowProps()} />
    </Wrapper>
  )
}

const trigger = ['hover', 'click']

interface HelpTipProps {
  children: ReactNode
  offset?: number
  placement?: Placement
  width?: number
  hideTooltip?: boolean
}

export const HelpTip: FC<HelpTipProps> = props => {
  const {
    children,
    offset = 16,
    placement = 'right-start',
    width,
    hideTooltip = false,
  } = props

  const modifiers = useMemo(
    () => [
      getOffsetModifier(offset),
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          padding: 8,
          boundary: document.querySelector('.popover-zone') || document.body,
        },
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          padding: 8,
          fallbackPlacements:
            placement.includes('right') || placement.includes('left')
              ? ['right', 'left', 'bottom', 'top']
              : ['top', 'bottom', 'right', 'left'],
          boundary: document.querySelector('.popover-zone') || document.body,
        },
      },
    ],
    [props]
  )

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    // @ts-ignore
    { trigger, delayShow: DELAY_SHOW },
    { placement, modifiers }
  )

  return (
    <>
      <img src={questionUrl} alt='help tip' ref={setTriggerRef} />
      {!hideTooltip &&
        visible &&
        createPortal(
          <Bubble
            setTooltipRef={setTooltipRef}
            getTooltipProps={getTooltipProps}
            width={width}
            getArrowProps={getArrowProps}
          >
            {children}
          </Bubble>,
          document.getElementById('tooltips')!
        )}
    </>
  )
}

export { Tooltip } from './Tooltip'
export * from './_methods'

export default HelpTip
