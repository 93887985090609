import { useEffect } from 'react'
import { Radio, Error, Select } from '../../../Common'
import { Description, Group } from '../_styles'
import { useTranslation } from 'react-i18next'
import { setUserError, setTouched, setUserValue } from 'store/verify'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const UserBlock = props => {
  const { userLists } = props
  const { t } = useTranslation()

  const active = useAppSelector(state => {
    return state.verify.method === 'user'
  })

  const value = useAppSelector(state => {
    return state.verify.user
  })

  const error = useAppSelector(state => {
    return state.verify.userError
  })

  const touched = useAppSelector(state => {
    return state.verify.touched
  })

  const dispatch = useAppDispatch()

  const verify = value => {
    let error
    if (!active) {
      error = ''
    } else {
      error = !value ? t('myCompany.verifyDialog.user.error.choose') : ''
    }
    dispatch(setUserError(error))
  }

  useEffect(() => {
    verify(value)
  }, [active])

  const handleChange = value => {
    dispatch(setTouched(false))
    dispatch(setUserValue(value))
    verify(value)
  }

  const handleBlur = () => {
    dispatch(setTouched(true))
    verify(value)
  }

  const hasError = active && touched && error

  return (
    <Group>
      <Radio
        name='method'
        value='user'
        defaultChecked={active}
        disabled={!userLists || userLists.length === 0}
      >
        {t('myCompany.verifyDialog.user.request')}
      </Radio>

      <Description>{t('myCompany.verifyDialog.user.description')}</Description>

      <Select
        label={t('myCompany.verifyDialog.user.label')}
        id='user'
        name='user'
        value={value}
        list={userLists}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!active}
        hasError={hasError}
      />
      <Error>{hasError ? error : ''}</Error>
    </Group>
  )
}

export default UserBlock
