import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  RequestsStore,
  Sorting,
  Filters,
  PageInfo,
  Request,
} from './types'

const initialState: RequestsStore = {
  items: [],
  item: {},
  pageInfo: null,
  allCheckboxChecked: false,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
  notCompletedCount: 0,
  selectedItems: [],
}

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<Request[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSelectedItems: (state, { payload }: PayloadAction<Request>) => {
      state.selectedItems = payload
    },
    selAllCheckboxesChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.allCheckboxChecked = payload
    },
    setSort: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    setRequest: (state, { payload }: PayloadAction<Partial<Request>>) => {
      state.item = { ...state.item, ...payload }
    },
    clear: () => {
      return initialState
    },
    resetItem: state => {
      state.item = initialState.item
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    updateRow: (state, { payload }: PayloadAction<any>) => {
      state.items = (state.items ?? []).map(row => {
        if (row.id !== payload.id) return row
        return { ...row, ...payload }
      })
    },
    updateNotCompleted: (state, { payload }: PayloadAction<number>) => {
      state.notCompletedCount = payload
    },
  },
})

export const { actions, reducer: requests } = requestsSlice
export const {
  setItems,
  setPageInfo,
  setSelectedItems,
  selAllCheckboxesChecked,
  setSort,
  setRequest,
  clear,
  resetItem,
  setLoading,
  setQuery,
  setFilters,
  updateRow,
  updateNotCompleted,
  setError,
} = actions
