import { setData, getUserData } from 'store/me'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const useBecomeRepresentative = () => {
  const dispatch = useAppDispatch()
  const meData = useAppSelector(getUserData)

  const handleCreateCompany = () => {
    // show dialog BecomeRepresentative
    dispatch(setData({ ...meData, hideBecomeRepresentative: false }))
  }

  return { handleCreateCompany }
}
