export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  GTIN: { input: string; output: string; }
  GeoJSONCoordinates: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Latitude: { input: number; output: number; }
  Longitude: { input: number; output: number; }
  NonNegativeFloat: { input: number; output: number; }
  NonNegativeInt: { input: number; output: number; }
  PhoneNumber: { input: string; output: string; }
  PositiveFloat: { input: number; output: number; }
  PositiveInt: { input: number; output: number; }
  URL: { input: string; output: string; }
};

/** Изменение допустимости */
export type AcceptabilityDiff = {
  __typename?: 'AcceptabilityDiff';
  canDelete: Scalars['Boolean']['output'];
  current: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Старый признак допустимости (если он был) */
  lhs?: Maybe<Scalars['Boolean']['output']>;
  /** Метаданные изменения */
  metadata: EntityDiffMetadata;
  /** Новый признак допустимости */
  rhs: Scalars['Boolean']['output'];
};

export type AdminAssignStripeCustomerPayload = {
  __typename?: 'AdminAssignStripeCustomerPayload';
  company: Company;
  query?: Maybe<Query>;
};

export type AdminCompanyFilter = {
  /** Выбранные значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminCompanyQueries = {
  __typename?: 'AdminCompanyQueries';
  /** Список фильтров для компаний */
  companiesFilters: FacetFilterPayload;
  /** Список компаний */
  companiesPagination: CompanyPagination;
  /** Поиск компании по идентификатору */
  company?: Maybe<Company>;
  /** Поиск компании по Stripe Customer ID */
  companyByStripeCustomerId?: Maybe<Company>;
  /** Поиск компаний по названию, стране и категории */
  companySearch: Array<Company>;
};


export type AdminCompanyQueriesCompaniesFiltersArgs = {
  filter?: InputMaybe<AdminCompanyFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminCompanyQueriesCompaniesPaginationArgs = {
  filter?: InputMaybe<AdminCompanyFilter>;
  localeId?: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: CompanySort;
};


export type AdminCompanyQueriesCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type AdminCompanyQueriesCompanyByStripeCustomerIdArgs = {
  stripeCustomerId: Scalars['String']['input'];
};


export type AdminCompanyQueriesCompanySearchArgs = {
  filter: AdminCompanySearchFilter;
  limit?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCompanySearchFilter = {
  companyCategoryId: Scalars['Int']['input'];
  postCountryId: Scalars['Int']['input'];
};

export type AdminLegalEntity = {
  __typename?: 'AdminLegalEntity';
  activityCode?: Maybe<Array<Scalars['String']['output']>>;
  branchNumber?: Maybe<Scalars['String']['output']>;
  closingDate?: Maybe<Scalars['DateTime']['output']>;
  country?: Maybe<Country>;
  identityNumber?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  legalAddress?: Maybe<Scalars['String']['output']>;
  legalEntityForm?: Maybe<LegalEntityForm>;
  legalName?: Maybe<Scalars['String']['output']>;
  postCountry?: Maybe<Country>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type AdminLegalEntityCreateInput = {
  activityCode?: InputMaybe<Array<Scalars['String']['input']>>;
  branchNumber?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  identityNumber?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  legalEntityFormId: Scalars['Int']['input'];
  legalName: Scalars['String']['input'];
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type AdminLegalEntityCreatePayload = {
  __typename?: 'AdminLegalEntityCreatePayload';
  query?: Maybe<Query>;
  record: LegalEntity;
  recordId: Scalars['ID']['output'];
};

export type AdminLegalEntityMutations = {
  __typename?: 'AdminLegalEntityMutations';
  create: AdminLegalEntityCreatePayload;
};


export type AdminLegalEntityMutationsCreateArgs = {
  input: AdminLegalEntityCreateInput;
};

export type AdminLegalEntityQueries = {
  __typename?: 'AdminLegalEntityQueries';
  /** Поиск юрлица в официальном источнике */
  legalEntities: Array<AdminLegalEntity>;
  /** Список юридических лиц */
  legalEntitiesPagination: LegalEntityPagination;
};


export type AdminLegalEntityQueriesLegalEntitiesArgs = {
  discriminatorValue: Scalars['String']['input'];
  source: Scalars['String']['input'];
};


export type AdminLegalEntityQueriesLegalEntitiesPaginationArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminMentionCompleteImageManagementInput = {
  mentionId: Scalars['ID']['input'];
};

export type AdminMentionCompleteImageManagementPayload = {
  __typename?: 'AdminMentionCompleteImageManagementPayload';
  query?: Maybe<Query>;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionCreateFromImagesInput = {
  mentionId: Scalars['ID']['input'];
  productImageId: Array<Scalars['ID']['input']>;
};

export type AdminMentionCreateFromImagesPayload = {
  __typename?: 'AdminMentionCreateFromImagesPayload';
  newRecord: Mention;
  query?: Maybe<Query>;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionCreateInput = {
  images?: InputMaybe<Array<Scalars['URL']['input']>>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type AdminMentionCreatePayload = {
  __typename?: 'AdminMentionCreatePayload';
  query?: Maybe<Query>;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminMentionMutations = {
  __typename?: 'AdminMentionMutations';
  completeImageManagement: AdminMentionCompleteImageManagementPayload;
  create: AdminMentionCreatePayload;
  createFromImages: AdminMentionCreateFromImagesPayload;
  updateGeneralInfo: AdminMentionUpdateGeneralInfoPayload;
  updateProduct: AdminMentionUpdateProductPayload;
  updateSourceInfo: AdminMentionUpdateSourceInfoPayload;
  updateTradeName: AdminMentionUpdateTradeNamePayload;
};


export type AdminMentionMutationsCompleteImageManagementArgs = {
  input: AdminMentionCompleteImageManagementInput;
};


export type AdminMentionMutationsCreateArgs = {
  input: AdminMentionCreateInput;
};


export type AdminMentionMutationsCreateFromImagesArgs = {
  input: AdminMentionCreateFromImagesInput;
};


export type AdminMentionMutationsUpdateGeneralInfoArgs = {
  input: AdminMentionUpdateGeneralInfoInput;
};


export type AdminMentionMutationsUpdateProductArgs = {
  input: AdminMentionUpdateProductInput;
};


export type AdminMentionMutationsUpdateSourceInfoArgs = {
  input: AdminMentionUpdateSourceInfoInput;
};


export type AdminMentionMutationsUpdateTradeNameArgs = {
  input: AdminMentionUpdateTradeNameInput;
};

export type AdminMentionQueries = {
  __typename?: 'AdminMentionQueries';
  /** Поиск упоминания по id */
  mention?: Maybe<Mention>;
  mentionsFilters: FacetFilterPayload;
  mentionsPagination: MentionPagination;
};


export type AdminMentionQueriesMentionArgs = {
  id: Scalars['ID']['input'];
};


export type AdminMentionQueriesMentionsFiltersArgs = {
  filter?: InputMaybe<AdminMentionFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminMentionQueriesMentionsPaginationArgs = {
  filter?: InputMaybe<AdminMentionFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: AdminMentionSort;
};

export enum AdminMentionSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModelConfidenceAsc = 'MODEL_CONFIDENCE_ASC',
  ModelConfidenceDesc = 'MODEL_CONFIDENCE_DESC',
  ModelProductWineCompositeNameAsc = 'MODEL_PRODUCT_WINE_COMPOSITE_NAME_ASC',
  ModelProductWineCompositeNameDesc = 'MODEL_PRODUCT_WINE_COMPOSITE_NAME_DESC',
  ProductMentionCountAsc = 'PRODUCT_MENTION_COUNT_ASC',
  ProductMentionCountDesc = 'PRODUCT_MENTION_COUNT_DESC',
  ProductWineCompositeNameAsc = 'PRODUCT_WINE_COMPOSITE_NAME_ASC',
  ProductWineCompositeNameDesc = 'PRODUCT_WINE_COMPOSITE_NAME_DESC'
}

export type AdminMentionUpdateGeneralInfoInput = {
  alcoholOnLabelMax?: InputMaybe<Scalars['Float']['input']>;
  alcoholOnLabelMin?: InputMaybe<Scalars['Float']['input']>;
  certificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  grapeVarieties?: InputMaybe<Array<WineGrapeVarietyInput>>;
  mentionId: Scalars['ID']['input'];
};

export type AdminMentionUpdateGeneralInfoPayload = {
  __typename?: 'AdminMentionUpdateGeneralInfoPayload';
  query: Query;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionUpdateProductInput = {
  geoIndicationConditionId?: InputMaybe<Scalars['Int']['input']>;
  mentionId: Scalars['ID']['input'];
  product?: InputMaybe<ProductExpandedCompositeIdInput>;
  state?: InputMaybe<MentionProductState>;
};

export type AdminMentionUpdateProductPayload = {
  __typename?: 'AdminMentionUpdateProductPayload';
  query: Query;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionUpdateSourceInfoInput = {
  addressTradeName?: InputMaybe<Scalars['String']['input']>;
  alcoholOnLabelMax?: InputMaybe<Scalars['Float']['input']>;
  alcoholOnLabelMin?: InputMaybe<Scalars['Float']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  communeTradeName?: InputMaybe<Scalars['String']['input']>;
  elaborateTradeName?: InputMaybe<Scalars['String']['input']>;
  emailTradeName?: InputMaybe<Scalars['String']['input']>;
  isNotWine?: Scalars['Boolean']['input'];
  keywords?: InputMaybe<Scalars['String']['input']>;
  mentionId: Scalars['ID']['input'];
  numberTradeName?: InputMaybe<Scalars['String']['input']>;
  parTradeName?: InputMaybe<Scalars['String']['input']>;
  postcodeTradeName?: InputMaybe<Scalars['String']['input']>;
  pourTradeName?: InputMaybe<Scalars['String']['input']>;
  proprietaiereTradeName?: InputMaybe<Scalars['String']['input']>;
  vigneronTradeName?: InputMaybe<Scalars['String']['input']>;
  websiteTradeName?: InputMaybe<Scalars['String']['input']>;
  withoutTradeName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminMentionUpdateSourceInfoPayload = {
  __typename?: 'AdminMentionUpdateSourceInfoPayload';
  query: Query;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMentionUpdateTradeNameInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  mentionId: Scalars['ID']['input'];
  notEnoughInfo?: Scalars['Boolean']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  postAddress?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  tradeNameId?: InputMaybe<Scalars['ID']['input']>;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type AdminMentionUpdateTradeNamePayload = {
  __typename?: 'AdminMentionUpdateTradeNamePayload';
  query: Query;
  record: Mention;
  recordId: Scalars['ID']['output'];
};

export type AdminMerchantActivatePayload = {
  __typename?: 'AdminMerchantActivatePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<Merchant>;
};

export type AdminMerchantCreateInput = {
  countryId: Scalars['Int']['input'];
  deliveryTerms?: InputMaybe<Scalars['String']['input']>;
  deliveryTimeId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  website: Scalars['URL']['input'];
};

export type AdminMerchantCreatePayload = {
  __typename?: 'AdminMerchantCreatePayload';
  query?: Maybe<Query>;
  record: Merchant;
  recordId: Scalars['ID']['output'];
};

export type AdminMerchantDeletePayload = {
  __typename?: 'AdminMerchantDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<Merchant>;
};

export type AdminMerchantFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<Array<MerchantStatus>>;
};

export type AdminMerchantMutations = {
  __typename?: 'AdminMerchantMutations';
  /** Активация удаленного мерчантов */
  activate: AdminMerchantActivatePayload;
  /** Создание мерчанта */
  create: AdminMerchantCreatePayload;
  /** Удаление мерчантов */
  delete: AdminMerchantDeletePayload;
  /** Редактирование мерчанта */
  update: AdminMerchantUpdatePayload;
};


export type AdminMerchantMutationsActivateArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdminMerchantMutationsCreateArgs = {
  input: AdminMerchantCreateInput;
};


export type AdminMerchantMutationsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminMerchantMutationsUpdateArgs = {
  input: AdminMerchantUpdateInput;
};

export type AdminMerchantQueries = {
  __typename?: 'AdminMerchantQueries';
  /** Поиск мерчанта по идентификатору */
  merchant?: Maybe<Merchant>;
  /** Поиск мерчанта по сайту */
  merchantByWebsite?: Maybe<Merchant>;
  /** Поиск мерчанта по идентификатору, сайту, названию */
  merchantSearch: Array<Merchant>;
  merchantsFilters: FacetFilterPayload;
  /** Список мерчантов в системе */
  merchantsPagination: MerchantPagination;
};


export type AdminMerchantQueriesMerchantArgs = {
  id: Scalars['ID']['input'];
};


export type AdminMerchantQueriesMerchantByWebsiteArgs = {
  website: Scalars['URL']['input'];
};


export type AdminMerchantQueriesMerchantSearchArgs = {
  limit?: Scalars['PositiveInt']['input'];
  query: Scalars['String']['input'];
};


export type AdminMerchantQueriesMerchantsFiltersArgs = {
  filter?: InputMaybe<AdminMerchantFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminMerchantQueriesMerchantsPaginationArgs = {
  filter?: InputMaybe<AdminMerchantFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: MerchantSort;
};

export type AdminMerchantUpdateInput = {
  countryId: Scalars['Int']['input'];
  deliveryTerms?: InputMaybe<Scalars['String']['input']>;
  deliveryTimeId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  website: Scalars['URL']['input'];
};

export type AdminMerchantUpdatePayload = {
  __typename?: 'AdminMerchantUpdatePayload';
  query?: Maybe<Query>;
  record: Merchant;
  recordId: Scalars['ID']['output'];
};

/** Мутации для администратора */
export type AdminMutations = {
  __typename?: 'AdminMutations';
  advanceUserClocks: Query;
  /** Очистка кэша */
  clearDatabaseCache: Scalars['Boolean']['output'];
  generateReviews: Query;
  legalEntities: AdminLegalEntityMutations;
  /** Мутации связанные с упоминаниями */
  mentions: AdminMentionMutations;
  /** Мутации связанные с мерчантами */
  merchants: AdminMerchantMutations;
  migrateProductImages: Scalars['Boolean']['output'];
  migrateUserVisits: Query;
  /** Мутации связанные с источниками цен */
  priceSources: AdminPriceSourceMutations;
  /** Мутации связанные с изображениями продуктов */
  productImages: AdminProductImageMutations;
  refreshAppellationRanks: Scalars['Boolean']['output'];
  refreshBuyerPrices: Scalars['Boolean']['output'];
  /** Перезапуск поиска дубликатов производителей */
  regroupAllTradeNameDuplicates: Scalars['Boolean']['output'];
  /** Перезапуск объединения производителей */
  remergeTradeNames: Query;
  /** Мутации связанные с поиском в Elasticsearch */
  search: AdminSearchMutations;
  stripeCustomers: AdminStripeCustomerMutations;
  /** Мутации связанные с торговыми наименованиями */
  tradeNames: AdminTradeNameMutations;
  /** Мутации связанные с торговыми предложениями */
  tradeOffers: AdminTradeOfferMutations;
  /** Мутации связанные с пользователями */
  users: AdminUserMutations;
  /** Мутации связанные с запросами */
  wineOfferRequests: AdminWineOfferRequestMutations;
  /** Мутации связанные с коммерческими предложениями */
  wineOffers: AdminWineOfferMutations;
  /** Мутации связанные с отзывами */
  wineReviews: AdminWineReviewMutations;
};


/** Мутации для администратора */
export type AdminMutationsAdvanceUserClocksArgs = {
  companyId: Scalars['Int']['input'];
  timestamp: Scalars['DateTime']['input'];
};


/** Мутации для администратора */
export type AdminMutationsGenerateReviewsArgs = {
  authorUserId: Scalars['Int']['input'];
  count: Scalars['Int']['input'];
  vintage: Scalars['PositiveInt']['input'];
};

export type AdminPendingMigration = {
  __typename?: 'AdminPendingMigration';
  /** Количество записей со статусом миграции */
  chunkCount: Scalars['Int']['output'];
  /** Дата создания миграции */
  createdDate: Scalars['DateTime']['output'];
  /** Название ORM модели */
  modelName: Scalars['String']['output'];
  /** Статус миграции */
  status: AdminPendingMigrationStatus;
  /** Генерируемое название миграции */
  suffix: Scalars['String']['output'];
};


export type AdminPendingMigrationChunkCountArgs = {
  status: AdminPendingMigrationStatus;
};

export enum AdminPendingMigrationStatus {
  /** Миграция завершилась */
  Completed = 'COMPLETED',
  /** Миграция не произведена */
  Idle = 'IDLE'
}

export type AdminPriceSourceDeletePayload = {
  __typename?: 'AdminPriceSourceDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type AdminPriceSourceFileValidationError = {
  __typename?: 'AdminPriceSourceFileValidationError';
  field: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  meta?: Maybe<Scalars['String']['output']>;
};

export type AdminPriceSourceFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Фильтр по статусам */
  status?: InputMaybe<Array<PriceSourceStatus>>;
};

export type AdminPriceSourceImportFileInput = {
  hideOther?: Scalars['Boolean']['input'];
  merchantId: Scalars['ID']['input'];
  modificationDate?: InputMaybe<Scalars['Date']['input']>;
  sourceURL: Scalars['URL']['input'];
};

export type AdminPriceSourceImportFilePayload = {
  __typename?: 'AdminPriceSourceImportFilePayload';
  query?: Maybe<Query>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type AdminPriceSourceMutations = {
  __typename?: 'AdminPriceSourceMutations';
  /** Удаление источников цен */
  delete: AdminPriceSourceDeletePayload;
  importFile: AdminPriceSourceImportFilePayload;
  /** Валидация файла с ценами */
  validateFile: AdminPriceSourceValidateFilePayload;
};


export type AdminPriceSourceMutationsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminPriceSourceMutationsImportFileArgs = {
  input: AdminPriceSourceImportFileInput;
};


export type AdminPriceSourceMutationsValidateFileArgs = {
  input: AdminPriceSourceValidateFileInput;
};

export type AdminPriceSourceQueries = {
  __typename?: 'AdminPriceSourceQueries';
  /** Список фильтров для источников цен */
  priceSourcesFilters: FacetFilterPayload;
  /** Список источников цен */
  priceSourcesPagination: PriceSourcePagination;
};


export type AdminPriceSourceQueriesPriceSourcesFiltersArgs = {
  filter?: InputMaybe<AdminPriceSourceFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminPriceSourceQueriesPriceSourcesPaginationArgs = {
  filter?: InputMaybe<AdminPriceSourceFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: PriceSourceSort;
};

export type AdminPriceSourceValidateFileFormatPayload = {
  __typename?: 'AdminPriceSourceValidateFileFormatPayload';
  error?: Maybe<Scalars['String']['output']>;
};

export type AdminPriceSourceValidateFileInput = {
  sourceURL: Scalars['URL']['input'];
};

export type AdminPriceSourceValidateFilePayload = AdminPriceSourceValidateFileFormatPayload | AdminPriceSourceValidateFileValidationPayload;

export type AdminPriceSourceValidateFileValidationPayload = {
  __typename?: 'AdminPriceSourceValidateFileValidationPayload';
  errors: Array<AdminPriceSourceFileValidationError>;
  invalidRowCount: Scalars['Int']['output'];
  totalRowCount: Scalars['Int']['output'];
  validRowCount: Scalars['Int']['output'];
};

export type AdminProductELabelQueries = {
  __typename?: 'AdminProductELabelQueries';
  productELabelsFilters: FacetFilterPayload;
  productELabelsPagination: ProductELabelsPagination;
};


export type AdminProductELabelQueriesProductELabelsFiltersArgs = {
  filter?: InputMaybe<AdminProductELabelsFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminProductELabelQueriesProductELabelsPaginationArgs = {
  filter?: InputMaybe<AdminProductELabelsFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductELabelSort;
};

export type AdminProductELabelsFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminProductImageCreateInput = {
  isReference?: InputMaybe<Scalars['Boolean']['input']>;
  isVintageUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ProductImageType>;
  url: Array<Scalars['URL']['input']>;
};

export type AdminProductImageCreatePayload = {
  __typename?: 'AdminProductImageCreatePayload';
  query?: Maybe<Query>;
  records: Array<ProductImage>;
};

export type AdminProductImageDeletePayload = {
  __typename?: 'AdminProductImageDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type AdminProductImageFilter = {
  /** Username автора изображений */
  authorUsername?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Идентификатор вина в качестве основы для фильтрации. Используется для фильтрации по характеристикам вина без учета винтажа */
  baseWineId?: InputMaybe<Scalars['ID']['input']>;
  /** Известен ли винтаж для вина на изображении */
  isVintageUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  linkAuthorUsername?: InputMaybe<Array<Scalars['String']['input']>>;
  linkStatus?: InputMaybe<Array<ProductImageLinkStatus>>;
  /** Переводы названий типов упаковок */
  packageType?: InputMaybe<Array<Scalars['String']['input']>>;
  referenceStatus?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  sourceType?: InputMaybe<Array<ProductImageSourceType>>;
  type?: InputMaybe<Array<ProductImageType>>;
  /** Username пользователя который внес последнее изменение в изображение */
  updateUsername?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Список годов винтажей */
  vintage?: InputMaybe<Array<InputMaybe<Scalars['PositiveInt']['input']>>>;
  /** Размеры бутылок в мл. */
  volumeML?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type AdminProductImageGroupPayload = {
  __typename?: 'AdminProductImageGroupPayload';
  query?: Maybe<Query>;
  records: Array<ProductImage>;
};

export type AdminProductImageMarkAsNotReferencePayload = {
  __typename?: 'AdminProductImageMarkAsNotReferencePayload';
  query?: Maybe<Query>;
  records: Array<ProductImage>;
};

export type AdminProductImageMarkAsReferencePayload = {
  __typename?: 'AdminProductImageMarkAsReferencePayload';
  query?: Maybe<Query>;
  records: Array<ProductImage>;
};

export type AdminProductImageMutations = {
  __typename?: 'AdminProductImageMutations';
  clearExternal: Scalars['Boolean']['output'];
  create: AdminProductImageCreatePayload;
  createImagesFromProducts: Scalars['Boolean']['output'];
  delete: AdminProductImageDeletePayload;
  group: AdminProductImageGroupPayload;
  markAsNotReference: AdminProductImageMarkAsNotReferencePayload;
  markAsReference: AdminProductImageMarkAsReferencePayload;
  repeatSearch: AdminProductImageRepeatSearchPayload;
  resetReference: AdminProductImageResetReferencePayload;
  update: AdminProductImageUpdatePayload;
};


export type AdminProductImageMutationsCreateArgs = {
  input: AdminProductImageCreateInput;
};


export type AdminProductImageMutationsCreateImagesFromProductsArgs = {
  isReference?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AdminProductImageMutationsDeleteArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdminProductImageMutationsGroupArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminProductImageMutationsMarkAsNotReferenceArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdminProductImageMutationsMarkAsReferenceArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdminProductImageMutationsRepeatSearchArgs = {
  id: Scalars['ID']['input'];
};


export type AdminProductImageMutationsResetReferenceArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdminProductImageMutationsUpdateArgs = {
  input: AdminProductImageUpdateInput;
};

export type AdminProductImageQueries = {
  __typename?: 'AdminProductImageQueries';
  /** Время когда было индексирование изображений в Google Vision */
  googleLastIndexTime: Scalars['DateTime']['output'];
  /** Количество референсных изображений согласно Google Vision */
  googleReferenceImageCount: Scalars['Int']['output'];
  /** Список изображений продуктов */
  productImagesPagination: ProductImagePagination;
  /** Количество референсных изображений согласно Tineye */
  tineyeReferenceImageCount: Scalars['Int']['output'];
};


export type AdminProductImageQueriesProductImagesPaginationArgs = {
  filter?: InputMaybe<AdminProductImageFilter>;
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
};

export type AdminProductImageRepeatSearchPayload = {
  __typename?: 'AdminProductImageRepeatSearchPayload';
  query?: Maybe<Query>;
  record: ProductImage;
};

export type AdminProductImageResetReferencePayload = {
  __typename?: 'AdminProductImageResetReferencePayload';
  query?: Maybe<Query>;
  records: Array<ProductImage>;
};

export type AdminProductImageUpdateInput = {
  hasProduct?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isReference?: InputMaybe<Scalars['Boolean']['input']>;
  isVintageUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ProductImageType>;
};

export type AdminProductImageUpdatePayload = {
  __typename?: 'AdminProductImageUpdatePayload';
  query?: Maybe<Query>;
  record: ProductImage;
};

export type AdminQueries = {
  __typename?: 'AdminQueries';
  /** Запросы связанные с компаниями */
  companies: AdminCompanyQueries;
  /** Запрос связанные с юридическими лицами */
  legalEntities: AdminLegalEntityQueries;
  /** Запросы связанные с упоминаниями */
  mentions: AdminMentionQueries;
  /** Запросы связанные с мерчантами */
  merchants: AdminMerchantQueries;
  /** Список текущих миграций для Elasticsearch */
  pendingMigrations: Array<Maybe<AdminPendingMigration>>;
  /** Запросы связанные с источникам розничными ценами */
  priceSources: AdminPriceSourceQueries;
  /** Запросы связанные с этикетками */
  productELabels: AdminProductELabelQueries;
  /** Запросы связанные с изображениями продуктов */
  productImages: AdminProductImageQueries;
  stripeCustomers: AdminStripeCustomerQueries;
  /** Запросы связанные с розничными ценами */
  tradeOffers: AdminTradeOfferQueries;
  /** Запросы связанные с пользователями */
  users: AdminUserQueries;
  /** Запросы связанные с запросами от пользователей */
  wineOfferRequests: AdminWineOfferRequestQueries;
  /** Запросы связанные с коммерческими предложениями */
  wineOffers: AdminWineOfferQueries;
  /** Запросы связанные с отзывами */
  wineReviews: AdminWineReviewQueries;
};

/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutations = {
  __typename?: 'AdminSearchMutations';
  /** Миграция индексов для всех сущностей */
  migrateAllIndices?: Maybe<Query>;
  /** Миграция индекса для брендов */
  migrateBrandIndex?: Maybe<Query>;
  /** Миграция индекса для компаний */
  migrateCompanyIndex?: Maybe<Query>;
  /** Миграция индекса для выставок */
  migrateExhibitionIndex?: Maybe<Query>;
  /** Миграция индекса для геоиндикаций */
  migrateGeoIndicationIndex?: Maybe<Query>;
  migrateGtin?: Maybe<Query>;
  /** Миграция индекса для упоминаний */
  migrateMentionIndex?: Maybe<Query>;
  /** Миграция индекса для мерчантов */
  migrateMerchantIndex?: Maybe<Query>;
  /** Миграция индекса для источников цен */
  migratePriceSourceIndex?: Maybe<Query>;
  /** Миграция индекса для электронных этикеток продук��ов */
  migrateProductELabelIndex?: Maybe<Query>;
  /** Миграция индекса для продуктов */
  migrateProductIndex?: Maybe<Query>;
  /** Миграция индекса для торговых наименований */
  migrateTradeNameIndex?: Maybe<Query>;
  /** Миграция индекса для торговых предложений */
  migrateTradeOfferIndex?: Maybe<Query>;
  /** Миграция индекса для лотов торговых предложений */
  migrateTradeOfferLotIndex?: Maybe<Query>;
  /** Миграция индекса для пользователей */
  migrateUserIndex?: Maybe<Query>;
  /** Миграция индекса для вин */
  migrateWineIndex?: Maybe<Query>;
  /** Миграция индекса для коммерческих предложений */
  migrateWineOfferIndex?: Maybe<Query>;
  /** Миграция индекса для запросов */
  migrateWineOfferRequestIndex?: Maybe<Query>;
  /** Миграция индекса для отзывов */
  migrateWineReviewIndex?: Maybe<Query>;
  /** Создание индексов для всех сущностей (без постепенной миграции) */
  rebuildAllIndices?: Maybe<Query>;
  /** Создание индекса для брендов (без постепенной миграции) */
  rebuildBrandIndex?: Maybe<Query>;
  /** Создание индекса для компаний (без постепенной миграции) */
  rebuildCompanyIndex?: Maybe<Query>;
  /** Создание индекса для выставок (без постепенной миграции) */
  rebuildExhibitionIndex?: Maybe<Query>;
  /** Создание индекса для геоиндикаций (без постепенной миграции) */
  rebuildGeoIndicationIndex?: Maybe<Query>;
  /** Создание индекса для электронных этикеток продуктов (без постепенной миграции) */
  rebuildProductELabelIndex?: Maybe<Query>;
  /** Создание индекса для продуктов (без постепенной миграции) */
  rebuildProductIndex?: Maybe<Query>;
  /** Создание индекса для торговых наименований (без постепенной миграции) */
  rebuildTradeNameIndex?: Maybe<Query>;
  /** Создание индекса для наград (без постепенной миграции) */
  rebuildWineAwardIndex?: Maybe<Query>;
  /** Создание индекса для вин (без постепенной миграции) */
  rebuildWineIndex?: Maybe<Query>;
  /** Создание индекса для коммерческих предложений (без постепенной миграции) */
  rebuildWineOfferIndex?: Maybe<Query>;
  /** Создание индекса для запросов (без постепенной миграции) */
  rebuildWineOfferRequestIndex?: Maybe<Query>;
  /** Создание индекса для отзывов (без постепенной миграции) */
  rebuildWineReviewIndex?: Maybe<Query>;
  /** Индексация сущностей зависящих от гео-индикации */
  reindexEntitiesByGeoIndication?: Maybe<Query>;
  /** Индексация сущностей зависящих от ТН */
  reindexEntitiesByTradeName?: Maybe<Query>;
  reindexProductsById?: Maybe<Query>;
  reindexTradeNamesById?: Maybe<Query>;
  reindexWinesById?: Maybe<Query>;
  remergeTradeNamesById?: Maybe<Query>;
  removeDuplicateJobs?: Maybe<Query>;
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsReindexEntitiesByGeoIndicationArgs = {
  geoIndicationId: Scalars['Int']['input'];
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsReindexEntitiesByTradeNameArgs = {
  tradeNameId: Array<Scalars['Int']['input']>;
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsReindexProductsByIdArgs = {
  productId?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsReindexTradeNamesByIdArgs = {
  tradeNameId?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsReindexWinesByIdArgs = {
  wineId?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Мутации администратора, связанные с полнотекстовым поиском */
export type AdminSearchMutationsRemergeTradeNamesByIdArgs = {
  tradeNameId: Array<Scalars['ID']['input']>;
};

export type AdminStripeCustomer = {
  __typename?: 'AdminStripeCustomer';
  email: Scalars['EmailAddress']['output'];
  name: Scalars['String']['output'];
  subscription?: Maybe<ProductELabelSubscription>;
};

export type AdminStripeCustomerMutations = {
  __typename?: 'AdminStripeCustomerMutations';
  assign: AdminAssignStripeCustomerPayload;
};


export type AdminStripeCustomerMutationsAssignArgs = {
  companyId: Scalars['ID']['input'];
  stripeCustomerId: Scalars['String']['input'];
};

export type AdminStripeCustomerQueries = {
  __typename?: 'AdminStripeCustomerQueries';
  customer?: Maybe<AdminStripeCustomer>;
};


export type AdminStripeCustomerQueriesCustomerArgs = {
  id: Scalars['String']['input'];
};

export type AdminTradeNameMutations = {
  __typename?: 'AdminTradeNameMutations';
  export: AdminTradeNamesExportPayload;
};


export type AdminTradeNameMutationsExportArgs = {
  input: AdminTradeNamesExportInput;
};

export type AdminTradeNamesExportFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminTradeNamesExportInput = {
  columns: Array<TradeNameExportColumn>;
  /** Формат файла */
  fileFormat: TableFileFormat;
  filter?: InputMaybe<AdminTradeNamesExportFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminTradeNamesExportPayload = {
  __typename?: 'AdminTradeNamesExportPayload';
  /** Ссылка на файл */
  link: Scalars['URL']['output'];
  query?: Maybe<Query>;
  /** Размер файла в байтах */
  sizeInBytes: Scalars['Int']['output'];
};

export type AdminTradeOfferActivateLotsPayload = {
  __typename?: 'AdminTradeOfferActivateLotsPayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOfferLot>;
};

export type AdminTradeOfferActivatePayload = {
  __typename?: 'AdminTradeOfferActivatePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOffer>;
};

export type AdminTradeOfferCompositeId = {
  additionalId?: InputMaybe<Scalars['ID']['input']>;
  productURL: Scalars['URL']['input'];
};

export type AdminTradeOfferCreateInput = {
  additionalId?: InputMaybe<Scalars['ID']['input']>;
  bottleSizeId?: InputMaybe<Scalars['Int']['input']>;
  isProductURLUnique: Scalars['Boolean']['input'];
  isVintageUnknown?: Scalars['Boolean']['input'];
  lots?: InputMaybe<Array<AdminTradeOfferCreateLotInput>>;
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  packageTypeId?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  productURL: Scalars['URL']['input'];
  status?: TradeOfferStatus;
  wineDescription?: InputMaybe<Scalars['String']['input']>;
  wineName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminTradeOfferCreateLotInput = {
  currencyId: Scalars['Int']['input'];
  deliveryCostId?: InputMaybe<Scalars['Int']['input']>;
  deliveryTimeId?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Float']['input'];
  purchaseConditionId?: InputMaybe<Scalars['Int']['input']>;
  vatBehaviorId?: InputMaybe<Scalars['Int']['input']>;
  wineLotId: Scalars['Int']['input'];
};

export type AdminTradeOfferCreateLotsInput = {
  /** Идентификатор торгового предложения */
  id: Scalars['ID']['input'];
  lots: Array<AdminTradeOfferCreateLotInput>;
};

export type AdminTradeOfferCreateLotsPayload = {
  __typename?: 'AdminTradeOfferCreateLotsPayload';
  query?: Maybe<Query>;
  /** Идентификаторы созданных лотов */
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOfferLot>;
};

export type AdminTradeOfferCreatePayload = {
  __typename?: 'AdminTradeOfferCreatePayload';
  query?: Maybe<Query>;
  record: TradeOffer;
  recordId: Scalars['ID']['output'];
};

export type AdminTradeOfferDeleteCurrentPricePayload = {
  __typename?: 'AdminTradeOfferDeleteCurrentPricePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOfferLot>;
};

export type AdminTradeOfferDeleteHistoryEntriesPayload = {
  __typename?: 'AdminTradeOfferDeleteHistoryEntriesPayload';
  query?: Maybe<Query>;
};

export type AdminTradeOfferDeleteLotsPayload = {
  __typename?: 'AdminTradeOfferDeleteLotsPayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type AdminTradeOfferDeletePayload = {
  __typename?: 'AdminTradeOfferDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOffer>;
};

export type AdminTradeOfferFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<Array<TradeOfferVirtualStatus>>;
};

export type AdminTradeOfferHidePayload = {
  __typename?: 'AdminTradeOfferHidePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOffer>;
};

export type AdminTradeOfferLotExportFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Выгружать только лоты, без офферов */
  onlyLots?: Scalars['Boolean']['input'];
  status?: InputMaybe<Array<TradeOfferLotStatus>>;
};

export type AdminTradeOfferLotExportInput = {
  /** Столбцы для выгрузки (с учетом порядка столбцов) */
  columns: Array<TradeOfferLotExportColumn>;
  /** Формат файла */
  fileFormat: TableFileFormat;
  filter?: InputMaybe<AdminTradeOfferLotExportFilter>;
  localeId: Scalars['Int']['input'];
};

export type AdminTradeOfferLotExportPayload = {
  __typename?: 'AdminTradeOfferLotExportPayload';
  /** Ссылка на файл */
  link: Scalars['URL']['output'];
  query?: Maybe<Query>;
  /** Размер файла в байтах */
  sizeInBytes: Scalars['Int']['output'];
};

export type AdminTradeOfferLotFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<Array<TradeOfferLotStatus>>;
};

export type AdminTradeOfferLotHidePayload = {
  __typename?: 'AdminTradeOfferLotHidePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOfferLot>;
};

export type AdminTradeOfferMutations = {
  __typename?: 'AdminTradeOfferMutations';
  /** Активация торговых предложений */
  activate: AdminTradeOfferActivatePayload;
  /** Активация лотов торговых предложений */
  activateLots: AdminTradeOfferActivateLotsPayload;
  /** Создание торгового предложения */
  create: AdminTradeOfferCreatePayload;
  /** Перезапись лотов торгового предложения */
  createLots: AdminTradeOfferCreateLotsPayload;
  /** Удаление торговых предложений */
  delete: AdminTradeOfferDeletePayload;
  /** Удаление актуальной розничной цены предложения */
  deleteCurrentPrice: AdminTradeOfferDeleteCurrentPricePayload;
  /** Удаление записи из истории цен */
  deleteHistoryEntries: AdminTradeOfferDeleteHistoryEntriesPayload;
  /** Удаление лотов торгового предложения */
  deleteLots: AdminTradeOfferDeleteLotsPayload;
  /** Экспорт файла с лотами */
  exportLots: AdminTradeOfferLotExportPayload;
  /** Скрытие торгового предложений */
  hide: AdminTradeOfferHidePayload;
  /** Скрытие лотов торгового предложения */
  hideLots: AdminTradeOfferLotHidePayload;
  /** Пометка сайта как недоступного */
  unavailable: AdminTradeOfferUnavailablePayload;
  /** Редактирование торгового предложения */
  update: AdminTradeOfferUpdatePayload;
  updateLotPrice: AdminTradeOfferUpdateLotPricePayload;
  /** Обновление лотов торгового предложения */
  updateLots: AdminTradeOfferUpdateLotsPayload;
};


export type AdminTradeOfferMutationsActivateArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsActivateLotsArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsCreateArgs = {
  input: AdminTradeOfferCreateInput;
};


export type AdminTradeOfferMutationsCreateLotsArgs = {
  input: AdminTradeOfferCreateLotsInput;
};


export type AdminTradeOfferMutationsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type AdminTradeOfferMutationsDeleteCurrentPriceArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsDeleteHistoryEntriesArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsDeleteLotsArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsExportLotsArgs = {
  input: AdminTradeOfferLotExportInput;
};


export type AdminTradeOfferMutationsHideArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsHideLotsArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsUnavailableArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminTradeOfferMutationsUpdateArgs = {
  input: AdminTradeOfferUpdateInput;
};


export type AdminTradeOfferMutationsUpdateLotPriceArgs = {
  input: AdminTradeOfferUpdateLotPriceInput;
};


export type AdminTradeOfferMutationsUpdateLotsArgs = {
  input: AdminTradeOfferUpdateLotsInput;
};

export type AdminTradeOfferOrLotsFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<Array<TradeOfferStatus>>;
};

export type AdminTradeOfferProductUrlUsedPayload = {
  __typename?: 'AdminTradeOfferProductURLUsedPayload';
  isUnique: Scalars['Boolean']['output'];
  isUsed: Scalars['Boolean']['output'];
  /** Торговое предложение если ссылка уникальная */
  tradeOffer?: Maybe<TradeOffer>;
};

export type AdminTradeOfferQueries = {
  __typename?: 'AdminTradeOfferQueries';
  /** Поиск торгового предложения по id */
  tradeOffer?: Maybe<TradeOffer>;
  /** Поиск торгового предложения по составному идентификатору */
  tradeOfferByCompositeId?: Maybe<TradeOffer>;
  tradeOfferLot?: Maybe<TradeOfferLot>;
  /** Список фильтров для торговых предложений */
  tradeOfferLotsFilters: FacetFilterPayload;
  tradeOfferLotsPagination: TradeOfferLotPagination;
  tradeOfferOrLotsFilters: FacetFilterPayload;
  tradeOfferOrLotsPagination: TradeOfferOrLotPagination;
  /** Запрос информации по внешней ссылке на продукт */
  tradeOfferProductURLUsed: AdminTradeOfferProductUrlUsedPayload;
  /** Список фильтров для торговых предложений */
  tradeOffersFilters: FacetFilterPayload;
  /** Список торговых предложенний */
  tradeOffersPagination: TradeOfferPagination;
};


export type AdminTradeOfferQueriesTradeOfferArgs = {
  id: Scalars['ID']['input'];
};


export type AdminTradeOfferQueriesTradeOfferByCompositeIdArgs = {
  compositeId: AdminTradeOfferCompositeId;
};


export type AdminTradeOfferQueriesTradeOfferLotArgs = {
  id: Scalars['ID']['input'];
};


export type AdminTradeOfferQueriesTradeOfferLotsFiltersArgs = {
  filter?: InputMaybe<AdminTradeOfferLotFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminTradeOfferQueriesTradeOfferLotsPaginationArgs = {
  filter?: InputMaybe<AdminTradeOfferLotFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeOfferLotSort;
};


export type AdminTradeOfferQueriesTradeOfferOrLotsFiltersArgs = {
  filter?: InputMaybe<AdminTradeOfferOrLotsFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminTradeOfferQueriesTradeOfferOrLotsPaginationArgs = {
  filter?: InputMaybe<AdminTradeOfferOrLotsFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeOfferSort;
};


export type AdminTradeOfferQueriesTradeOfferProductUrlUsedArgs = {
  productURL: Scalars['URL']['input'];
};


export type AdminTradeOfferQueriesTradeOffersFiltersArgs = {
  filter?: InputMaybe<AdminTradeOfferFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminTradeOfferQueriesTradeOffersPaginationArgs = {
  filter?: InputMaybe<AdminTradeOfferFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeOfferSort;
};

export type AdminTradeOfferUnavailablePayload = {
  __typename?: 'AdminTradeOfferUnavailablePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOffer>;
};

export type AdminTradeOfferUpdateInput = {
  additionalId?: InputMaybe<Scalars['ID']['input']>;
  bottleSizeId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор торгового предложения */
  id: Scalars['ID']['input'];
  isProductURLUnique: Scalars['Boolean']['input'];
  isVintageUnknown?: Scalars['Boolean']['input'];
  lots?: InputMaybe<Array<AdminTradeOfferUpdateLotInput>>;
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  packageTypeId?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  productURL: Scalars['URL']['input'];
  status?: InputMaybe<TradeOfferStatus>;
  wineDescription?: InputMaybe<Scalars['String']['input']>;
  wineName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminTradeOfferUpdateLotInput = {
  currencyId: Scalars['Int']['input'];
  deliveryCostId?: InputMaybe<Scalars['Int']['input']>;
  deliveryTimeId?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Float']['input'];
  purchaseConditionId?: InputMaybe<Scalars['Int']['input']>;
  status?: TradeOfferLotStatus;
  vatBehaviorId?: InputMaybe<Scalars['Int']['input']>;
  wineLotId: Scalars['Int']['input'];
};

export type AdminTradeOfferUpdateLotPriceInput = {
  currencyId: Scalars['Int']['input'];
  lotId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
};

export type AdminTradeOfferUpdateLotPricePayload = {
  __typename?: 'AdminTradeOfferUpdateLotPricePayload';
  query?: Maybe<Query>;
  record: TradeOfferLot;
  recordId: Scalars['ID']['output'];
};

export type AdminTradeOfferUpdateLotsInput = {
  /** Идентификатор торгового предложения */
  id: Scalars['ID']['input'];
  lots: Array<AdminTradeOfferUpdateLotInput>;
};

export type AdminTradeOfferUpdateLotsPayload = {
  __typename?: 'AdminTradeOfferUpdateLotsPayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<TradeOfferLot>;
};

export type AdminTradeOfferUpdatePayload = {
  __typename?: 'AdminTradeOfferUpdatePayload';
  query?: Maybe<Query>;
  record: TradeOffer;
  recordId: Scalars['ID']['output'];
};

export type AdminUserChangePermissionsInput = {
  reviewsConsidered: Scalars['Boolean']['input'];
  reviewsDisplayed: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminUserMutations = {
  __typename?: 'AdminUserMutations';
  /** Меняет статус пользователя на ACTIVE */
  activate: UserActivatePayload;
  /**
   * Удаление пользователя и всех его компаний и КП
   * Пользователь получает статус ARCHIVED
   */
  archive: UserArchivePayload;
  /**
   * Блокировка пользователя с указанием причины.
   * При этом блокируются все компании пользователя, удаляются архивируются КП
   */
  ban: UserBanPayload;
  /** Выдача разрешений пользователю */
  changePermissions: UserChangePermissionsPayload;
  /** Смена роли пользователя */
  changeRole?: Maybe<UserChangeRolePayload>;
  /** Восстановление удаленного пользователя (без восстановления компаний) */
  recover: UserRecoverPayload;
  /** Снимает блокировку пользователя и всех его компаний */
  removeBan: UserRemoveBanPayload;
  /**
   * Верификация пользователя как квалифицированного дегустатора
   * @deprecated Use verifyExpertQualification
   */
  verifyExpert: UserVerifyExpertPayload;
  /** Верификация квалификации дегустатора */
  verifyExpertQualification: UserVerifyExpertPayload;
};


export type AdminUserMutationsActivateArgs = {
  userId: Array<Scalars['ID']['input']>;
};


export type AdminUserMutationsArchiveArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  userId: Array<Scalars['ID']['input']>;
};


export type AdminUserMutationsBanArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  userId: Array<Scalars['ID']['input']>;
};


export type AdminUserMutationsChangePermissionsArgs = {
  input: AdminUserChangePermissionsInput;
};


export type AdminUserMutationsChangeRoleArgs = {
  userId: Scalars['ID']['input'];
  userRoleId: Scalars['Int']['input'];
};


export type AdminUserMutationsRecoverArgs = {
  userId: Array<Scalars['ID']['input']>;
};


export type AdminUserMutationsRemoveBanArgs = {
  userId: Array<Scalars['ID']['input']>;
};


export type AdminUserMutationsVerifyExpertArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  expertVerificationStatusId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type AdminUserMutationsVerifyExpertQualificationArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  expertQualificationId: Scalars['Int']['input'];
  expertVerificationRejectReasonId?: InputMaybe<Scalars['Int']['input']>;
  expertVerificationStatusId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminUserQueries = {
  __typename?: 'AdminUserQueries';
  /** Список фильтров для пользователей */
  usersFilters: FacetFilterPayload;
  /** Список пользователей */
  usersPagination: UserPagination;
};


export type AdminUserQueriesUsersFiltersArgs = {
  filter?: InputMaybe<UserFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminUserQueriesUsersPaginationArgs = {
  filter?: InputMaybe<UserFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: UserSort;
};

export type AdminWineOfferCompanyUpdateInput = {
  /** Идентификатор компании для которой создается предложение */
  buyerCompanyId: Scalars['ID']['input'];
  /** Идентификатор компании продавца */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId: Scalars['Int']['input'];
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['input'];
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  /** Минимальный размер поставки */
  minimumDeliverySize: Scalars['PositiveInt']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Цена за лот */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта для продажи */
  productId: Scalars['ID']['input'];
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения для обновления */
  wineOfferId: Scalars['Int']['input'];
};

export type AdminWineOfferCompanyUpdatePayload = {
  __typename?: 'AdminWineOfferCompanyUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferCompany>;
};

export type AdminWineOfferFilter = {
  /** Выбранные значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminWineOfferMutations = {
  __typename?: 'AdminWineOfferMutations';
  wineOfferCompanyUpdate: AdminWineOfferCompanyUpdatePayload;
  wineOfferPublicUpdate: AdminWineOfferPublicUpdatePayload;
  wineOffersCompanyCreate: AdminWineOffersCompanyCreatePayload;
  wineOffersPublicCreate: AdminWineOffersPublicCreatePayload;
};


export type AdminWineOfferMutationsWineOfferCompanyUpdateArgs = {
  input: AdminWineOfferCompanyUpdateInput;
};


export type AdminWineOfferMutationsWineOfferPublicUpdateArgs = {
  input: AdminWineOfferPublicUpdateInput;
};


export type AdminWineOfferMutationsWineOffersCompanyCreateArgs = {
  input: AdminWineOffersCompanyCreateInput;
};


export type AdminWineOfferMutationsWineOffersPublicCreateArgs = {
  input: AdminWineOffersPublicCreateInput;
};

export type AdminWineOfferPublicUpdateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId: Scalars['Int']['input'];
  /** Идентификатор компании продавца */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId: Scalars['Int']['input'];
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['input'];
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  /** Минимальный размер поставки */
  minimumDeliverySize: Scalars['PositiveInt']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Цена за лот */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта для продажи */
  productId: Scalars['ID']['input'];
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type AdminWineOfferPublicUpdatePayload = {
  __typename?: 'AdminWineOfferPublicUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferPublic>;
};

export type AdminWineOfferQueries = {
  __typename?: 'AdminWineOfferQueries';
  /** Список фильтров для коммерческих предложений */
  wineOffersFilters: FacetFilterPayload;
  /** Список коммерческих предложений (без переговоров и черновиков) */
  wineOffersPagination: WineOfferPagination;
};


export type AdminWineOfferQueriesWineOffersFiltersArgs = {
  filter?: InputMaybe<AdminWineOfferFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminWineOfferQueriesWineOffersPaginationArgs = {
  filter?: InputMaybe<AdminWineOfferFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineOfferSort;
};

export type AdminWineOfferRequestChangeStageInput = {
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
  /** Статус обработки запроса */
  stage: WineOfferRequestStage;
};

export type AdminWineOfferRequestChangeStagePayload = {
  __typename?: 'AdminWineOfferRequestChangeStagePayload';
  query?: Maybe<Query>;
  record: WineOfferRequest;
};

export type AdminWineOfferRequestDeletePayload = {
  __typename?: 'AdminWineOfferRequestDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type AdminWineOfferRequestEditNoteInput = {
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
  /** Комментарий к запросу */
  note: Scalars['String']['input'];
};

export type AdminWineOfferRequestEditNotePayload = {
  __typename?: 'AdminWineOfferRequestEditNotePayload';
  query?: Maybe<Query>;
  record: WineOfferRequest;
};

export type AdminWineOfferRequestExportPotentialProducersFilter = {
  countryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysSinceLastNewsletter?: InputMaybe<Scalars['Int']['input']>;
  emailStatuses?: InputMaybe<Array<WineOfferRequestTradeNameEmailStatus>>;
  onlyWithEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminWineOfferRequestExportPotentialProducersInput = {
  /** Столбцы для выгрузки (с учетом порядка столбцов) */
  columns: Array<WineOfferRequestExportPotentialProducerColumn>;
  /** Формат файла */
  fileFormat: TableFileFormat;
  filter?: InputMaybe<AdminWineOfferRequestExportPotentialProducersFilter>;
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
};

export type AdminWineOfferRequestExportPotentialProducersPayload = {
  __typename?: 'AdminWineOfferRequestExportPotentialProducersPayload';
  /** Ссылка на файл */
  link: Scalars['URL']['output'];
  query?: Maybe<Query>;
  /** Размер файла в байтах */
  sizeInBytes: Scalars['Int']['output'];
};

export type AdminWineOfferRequestFilter = {
  /** Выбранные значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminWineOfferRequestMutations = {
  __typename?: 'AdminWineOfferRequestMutations';
  /** Изменение статуса обработки запроса */
  changeStage: AdminWineOfferRequestChangeStagePayload;
  /**
   * Завершения работы запроса
   * @deprecated Use wineOfferRequestsComplete mutation
   */
  complete: AdminWineOfferRequestsCompletePayload;
  /** Удаление запросов */
  delete: AdminWineOfferRequestDeletePayload;
  /** Установка комментария менеджером */
  editNote: AdminWineOfferRequestEditNotePayload;
  /** Продление даты валидности */
  updatePeriodOfValidity: AdminWineOfferRequestUpdatePeriodOfValidityPayload;
  /** Выгрузка потенциальных производителей попадающих под запрос */
  wineOfferRequestExportPotentialProducers?: Maybe<AdminWineOfferRequestExportPotentialProducersPayload>;
  /** Рассылка писем потенциальным производителям */
  wineOfferRequestNewsletterSendToPotentialProducers?: Maybe<AdminWineOfferRequestNewsletterSendToPotentialProducersPayload>;
};


export type AdminWineOfferRequestMutationsChangeStageArgs = {
  input: AdminWineOfferRequestChangeStageInput;
};


export type AdminWineOfferRequestMutationsCompleteArgs = {
  input: AdminWineOfferRequestsCompleteInput;
};


export type AdminWineOfferRequestMutationsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type AdminWineOfferRequestMutationsEditNoteArgs = {
  input: AdminWineOfferRequestEditNoteInput;
};


export type AdminWineOfferRequestMutationsUpdatePeriodOfValidityArgs = {
  input: AdminWineOfferRequestUpdatePeriodOfValidityInput;
};


export type AdminWineOfferRequestMutationsWineOfferRequestExportPotentialProducersArgs = {
  input: AdminWineOfferRequestExportPotentialProducersInput;
};


export type AdminWineOfferRequestMutationsWineOfferRequestNewsletterSendToPotentialProducersArgs = {
  input: AdminWineOfferRequestNewsletterSendToPotentialProducersInput;
};

export type AdminWineOfferRequestNewsletterPotentialProducersCountPayload = {
  __typename?: 'AdminWineOfferRequestNewsletterPotentialProducersCountPayload';
  /** Общее количество */
  count: Scalars['Int']['output'];
  query?: Maybe<Query>;
};

export type AdminWineOfferRequestNewsletterSendToPotentialProducersInput = {
  filter?: InputMaybe<WineOfferRequestTradeNamesMatchFilter>;
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
};

export type AdminWineOfferRequestNewsletterSendToPotentialProducersPayload = {
  __typename?: 'AdminWineOfferRequestNewsletterSendToPotentialProducersPayload';
  query?: Maybe<Query>;
};

export type AdminWineOfferRequestQueries = {
  __typename?: 'AdminWineOfferRequestQueries';
  /** Количество потенциальных производителей в рассылке */
  wineOfferRequestNewsletterPotentialProducersCount: AdminWineOfferRequestNewsletterPotentialProducersCountPayload;
  /** Список фильтров для запросов */
  wineOfferRequestsFilters: FacetFilterPayload;
  /** Список запросов пользователей */
  wineOfferRequestsPagination?: Maybe<WineOfferRequestPagination>;
};


export type AdminWineOfferRequestQueriesWineOfferRequestNewsletterPotentialProducersCountArgs = {
  filter?: InputMaybe<WineOfferRequestTradeNamesMatchFilter>;
  id: Scalars['ID']['input'];
};


export type AdminWineOfferRequestQueriesWineOfferRequestsFiltersArgs = {
  filter?: InputMaybe<AdminWineOfferRequestFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminWineOfferRequestQueriesWineOfferRequestsPaginationArgs = {
  filter?: InputMaybe<AdminWineOfferRequestFilter>;
  localeId?: InputMaybe<Scalars['Int']['input']>;
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineOfferRequestSort;
};

export type AdminWineOfferRequestUpdatePeriodOfValidityInput = {
  /** Идентификаторы запросов (в случае установления одной и той же даты окончания действия запроса) */
  id: Array<Scalars['ID']['input']>;
  /** Дата окончания действия запроса (для нескольких запросов) */
  periodOfValidity: Scalars['Date']['input'];
};

export type AdminWineOfferRequestUpdatePeriodOfValidityPayload = {
  __typename?: 'AdminWineOfferRequestUpdatePeriodOfValidityPayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<WineOfferRequest>;
};

export type AdminWineOfferRequestsCompleteInput = {
  id: Array<Scalars['ID']['input']>;
};

export type AdminWineOfferRequestsCompletePayload = {
  __typename?: 'AdminWineOfferRequestsCompletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
  records: Array<WineOfferRequest>;
};

export type AdminWineOffersCompanyCreateInput = {
  /** Идентификатор компании для которой создается предложение */
  buyerCompanyId: Scalars['ID']['input'];
  /** Идентификатор компании продавца */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type AdminWineOffersCompanyCreatePayload = {
  __typename?: 'AdminWineOffersCompanyCreatePayload';
  query?: Maybe<Query>;
  records: Array<WineOfferCompany>;
};

export type AdminWineOffersPublicCreateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId: Scalars['Int']['input'];
  /** Идентификатор компании-продавца */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type AdminWineOffersPublicCreatePayload = {
  __typename?: 'AdminWineOffersPublicCreatePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferPublic>>;
};

export type AdminWineReviewBlockPayload = {
  __typename?: 'AdminWineReviewBlockPayload';
  query?: Maybe<Query>;
  /** Заблокированные отзывы */
  records?: Maybe<Array<Maybe<WineReview>>>;
};

export type AdminWineReviewFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type AdminWineReviewMutations = {
  __typename?: 'AdminWineReviewMutations';
  /** Блокировка отзывов на вино */
  block: AdminWineReviewBlockPayload;
  /** Принять право владения отзывом */
  takeOwnership: AdminWineReviewTakeOwnershipPayload;
  /** Передать право владения */
  transferOwnership: AdminWineReviewTransferOwnershipPayload;
  /** Разблокировка отзывов на вино */
  unblock: AdminWineReviewUnblockPayload;
};


export type AdminWineReviewMutationsBlockArgs = {
  wineReviewId: Array<Scalars['ID']['input']>;
};


export type AdminWineReviewMutationsTakeOwnershipArgs = {
  wineReviewId: Array<Scalars['ID']['input']>;
};


export type AdminWineReviewMutationsTransferOwnershipArgs = {
  companyId: Scalars['ID']['input'];
  type: WineReviewType;
  wineReviewId: Scalars['ID']['input'];
};


export type AdminWineReviewMutationsUnblockArgs = {
  wineReviewId: Array<Scalars['ID']['input']>;
};

export type AdminWineReviewQueries = {
  __typename?: 'AdminWineReviewQueries';
  /** Значения фильтров для списка отзывов */
  reviewsFilters: FacetFilterPayload;
  /** Список отзывов пользователей */
  reviewsPagination: WineReviewPagination;
};


export type AdminWineReviewQueriesReviewsFiltersArgs = {
  filter?: InputMaybe<AdminWineReviewFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AdminWineReviewQueriesReviewsPaginationArgs = {
  filter?: InputMaybe<AdminWineReviewFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort: WineReviewSort;
};

export type AdminWineReviewTakeOwnershipPayload = {
  __typename?: 'AdminWineReviewTakeOwnershipPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<WineReview>>>;
};

export type AdminWineReviewTransferOwnershipPayload = {
  __typename?: 'AdminWineReviewTransferOwnershipPayload';
  query?: Maybe<Query>;
  record: WineReview;
};

export type AdminWineReviewUnblockPayload = {
  __typename?: 'AdminWineReviewUnblockPayload';
  query?: Maybe<Query>;
  /** Разблокированные отзывы */
  records?: Maybe<Array<Maybe<WineReview>>>;
};

/** Аллерген */
export type Allergen = {
  __typename?: 'Allergen';
  /** Идентификатор аллергена */
  id: Scalars['Int']['output'];
  /** Перевод названия аллергена */
  localizedName: Scalars['String']['output'];
  /** Название аллергена на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Аллерген */
export type AllergenLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Конкурс вин */
export type Award = {
  __typename?: 'Award';
  authorUser?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  /** Идентификатор конкурса */
  id: Scalars['Int']['output'];
  /** Признак наличия ссылок на этот конкурс */
  isReferenced: Scalars['Boolean']['output'];
  /** Уровень конкурса (для оценки). От 1 до 5 */
  level?: Maybe<Scalars['Int']['output']>;
  /** Количество баллов по 100 бальной шкале, соответствующие минимальному количеству баллов в конкурсе */
  lowerLimit?: Maybe<Scalars['Float']['output']>;
  /** Название конкурса на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** Сокращенное название конкурса на языке по умолчанию */
  masterNameShort: Scalars['String']['output'];
  /** Максимальное количество баллов присуждаемое на конкурсе */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Минимальное количество баллов присуждаемое на конкурсе */
  minScore?: Maybe<Scalars['Float']['output']>;
  /** Поиск награды по полному названию */
  rewardByName?: Maybe<AwardReward>;
  /** Типы наград присуждаемые в конкурсе */
  rewardTypes: Array<AwardRewardType>;
  /** Присуждаемые награды в конкурсе */
  rewards: Array<AwardReward>;
  /** Список наград с постраничным доступом */
  rewardsPagination: AwardRewardPagination;
  /** Количество знаков после запятой используемых в баллах конкурса */
  roundScore?: Maybe<Scalars['Float']['output']>;
  /** Шаг в баллах конкурса */
  stepScore?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


/** Конкурс вин */
export type AwardRewardByNameArgs = {
  name: Scalars['String']['input'];
};


/** Конкурс вин */
export type AwardRewardsPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: InputMaybe<AwardRewardSort>;
};

export type AwardCreateInput = {
  level?: InputMaybe<Scalars['Int']['input']>;
  lowerLimit?: InputMaybe<Scalars['Float']['input']>;
  masterName: Scalars['String']['input'];
  masterNameShort: Scalars['String']['input'];
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  roundScore?: InputMaybe<Scalars['Float']['input']>;
  stepScore?: InputMaybe<Scalars['Float']['input']>;
};

export type AwardCreatePayload = {
  __typename?: 'AwardCreatePayload';
  query?: Maybe<Query>;
  record: Award;
  recordId: Scalars['Int']['output'];
};

export type AwardPagination = {
  __typename?: 'AwardPagination';
  items: Array<Award>;
  pageInfo: PageInfo;
};

/** Награда в конкурсе */
export type AwardReward = {
  __typename?: 'AwardReward';
  authorUser?: Maybe<User>;
  /** Конкурс */
  award: Award;
  createdAt: Scalars['DateTime']['output'];
  /** Идентификатор награды в конкурсе */
  id: Scalars['Int']['output'];
  /** Признак наличия ссылок на эту награду */
  isReferenced: Scalars['Boolean']['output'];
  /** Максимальное количество баллов для присуждения награды */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Год до которого присуждалась награда */
  maxYear?: Maybe<Scalars['PositiveInt']['output']>;
  /** Минимальное количество баллов для присуждения награды */
  minScore?: Maybe<Scalars['Float']['output']>;
  /** Год, с которого присуждается награда */
  minYear?: Maybe<Scalars['PositiveInt']['output']>;
  /** Название награды */
  name: Scalars['String']['output'];
  /** Тип награды */
  rewardType: AwardRewardType;
  scoreLevel?: Maybe<AwardRewardScoreLevel>;
  /** Короткое название награды */
  shortName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AwardRewardCreateInput = {
  awardId: Scalars['Int']['input'];
  awardRewardTypeId: Scalars['Int']['input'];
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  maxYear?: InputMaybe<Scalars['PositiveInt']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  minYear?: InputMaybe<Scalars['PositiveInt']['input']>;
  name: Scalars['String']['input'];
  scoreLevelId?: InputMaybe<Scalars['Int']['input']>;
  shortName: Scalars['String']['input'];
};

export type AwardRewardCreatePayload = {
  __typename?: 'AwardRewardCreatePayload';
  query?: Maybe<Query>;
  record: AwardReward;
  recordId: Scalars['Int']['output'];
};

export type AwardRewardDiff = {
  __typename?: 'AwardRewardDiff';
  current: Scalars['Boolean']['output'];
  /** Старая награда */
  lhs?: Maybe<AwardReward>;
  /** Новая награда */
  rhs?: Maybe<AwardReward>;
};

export type AwardRewardPagination = {
  __typename?: 'AwardRewardPagination';
  items: Array<AwardReward>;
  pageInfo: PageInfo;
};

export type AwardRewardScoreLevel = {
  __typename?: 'AwardRewardScoreLevel';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export enum AwardRewardSort {
  /** Сортировка по возрастанию короткого названия награды */
  ShortNameAsc = 'SHORT_NAME_ASC',
  /** Сортировка по убыванию короткого названия награды */
  ShortNameDesc = 'SHORT_NAME_DESC',
  /** Сортировка по возрастанию названия типа награды */
  TypeNameAsc = 'TYPE_NAME_ASC',
  /** Сортировка по убыванию названия типа награды */
  TypeNameDesc = 'TYPE_NAME_DESC',
  /** Сортировка по возрастанию даты обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванию даты обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Тип награды в конкурсе */
export type AwardRewardType = {
  __typename?: 'AwardRewardType';
  /** Идентификатор типа награды в конкурсе */
  id: Scalars['Int']['output'];
  /** Перевод названия типа награды в конкурсе */
  localizedName: Scalars['String']['output'];
  /** Название типа награды в конкурсе */
  masterName: Scalars['String']['output'];
};


/** Тип награды в конкурсе */
export type AwardRewardTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type AwardRewardUpdateInput = {
  awardId: Scalars['Int']['input'];
  awardRewardTypeId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  maxYear?: InputMaybe<Scalars['PositiveInt']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  minYear?: InputMaybe<Scalars['PositiveInt']['input']>;
  name: Scalars['String']['input'];
  scoreLevelId?: InputMaybe<Scalars['Int']['input']>;
  shortName: Scalars['String']['input'];
};

export type AwardRewardUpdatePayload = {
  __typename?: 'AwardRewardUpdatePayload';
  query?: Maybe<Query>;
  record: AwardReward;
  recordId: Scalars['Int']['output'];
};

export type AwardRewardsDeleteInput = {
  /** Массив ID наград, которые нужно удалить */
  awardRewardIds: Array<Scalars['Int']['input']>;
};

export type AwardRewardsDeletePayload = {
  __typename?: 'AwardRewardsDeletePayload';
  query?: Maybe<Query>;
  /** ID наград, которые были удалены */
  recordIds: Array<Scalars['Int']['output']>;
};

export enum AwardSort {
  /** Сортировка по возрастанию идентификатора конкурса */
  IdAsc = 'ID_ASC',
  /** Сортировка по убыванию идентификатора конкурса */
  IdDesc = 'ID_DESC',
  /** Сортировка по возрастанию уровня конкурса */
  LevelAsc = 'LEVEL_ASC',
  /** Сортировка по убыванию уровня конкурса */
  LevelDesc = 'LEVEL_DESC',
  /** Сортировка по возрастанию короткого наименования */
  ShortNameAsc = 'SHORT_NAME_ASC',
  /** Сортировка по убыванию короткого наименования */
  ShortNameDesc = 'SHORT_NAME_DESC',
  /** Сортировка по возрастанию даты обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванюи даты обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type AwardUpdateInput = {
  id: Scalars['Int']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  lowerLimit?: InputMaybe<Scalars['Float']['input']>;
  masterName: Scalars['String']['input'];
  masterNameShort: Scalars['String']['input'];
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  roundScore?: InputMaybe<Scalars['Float']['input']>;
  stepScore?: InputMaybe<Scalars['Float']['input']>;
};

export type AwardUpdatePayload = {
  __typename?: 'AwardUpdatePayload';
  query?: Maybe<Query>;
  record: Award;
  recordId: Scalars['Int']['output'];
};

export type AwardsDeleteInput = {
  /** Массив ID конкурсов, которые нужно удалить */
  awardIds: Array<Scalars['Int']['input']>;
};

export type AwardsDeletePayload = {
  __typename?: 'AwardsDeletePayload';
  query?: Maybe<Query>;
  /** ID конкурсов, которые были удалены */
  recordIds: Array<Scalars['Int']['output']>;
};

export type BooleanWrapper = {
  __typename?: 'BooleanWrapper';
  value: Scalars['Boolean']['output'];
};

/** Размер бутылки */
export type BottleSize = {
  __typename?: 'BottleSize';
  /** Идентификатор размера бутылки */
  id: Scalars['Int']['output'];
  /** @deprecated Do not used for clients */
  order: Scalars['Int']['output'];
  /** Размер бутылки в миллилитрах */
  volumeML: Scalars['Float']['output'];
};

export type Brand = {
  __typename?: 'Brand';
  /** Признак допустимости */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /** Автор последнего изменения */
  authorUser?: Maybe<User>;
  /** Может ли текущий пользователь изменить признак допустимости бренда */
  canOverrideAcceptability: Scalars['Boolean']['output'];
  /** Может ли текущий пользователь редактировать бренд */
  canOverrideFacts: Scalars['Boolean']['output'];
  /** История изменений бренда */
  changelog: Array<BrandChangelogItem>;
  /** @deprecated delete in next release */
  company?: Maybe<Company>;
  /** Идентификатор бренда */
  id: Scalars['ID']['output'];
  /** Локализованное название бренда */
  localizedName: Scalars['String']['output'];
  /** Основной синоним для бренда (при наличии) */
  mainSynonym?: Maybe<Brand>;
  /** Название бренда */
  name: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Int']['output']>;
  /**
   * Приоритет текущей записи о допустимости
   * @deprecated Use canOverrideAcceptability
   */
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /**
   * Приоритет текущего факта
   * @deprecated Use canOverrideFacts
   */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  /** Больше ли приоритет текущего пользователя текущей записи о допустимости */
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  /** Источник данной записи */
  source?: Maybe<Source>;
  /** Автор добавивший бренд */
  sourceAuthorUser?: Maybe<User>;
  /** Внешние источники в которых встречается бренд */
  sources: Array<Source>;
  /** @deprecated delete in next release */
  status?: Maybe<BrandStatus>;
  /** Список торговых марок с совпадающим названием */
  tradeMarks: Array<TradeMark>;
  tradeName: TradeName;
  /** Существующие переводы бренда */
  translations: Array<BrandTranslation>;
  /** Дата последнего изменения */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Является ли запись проверенной */
  verified: Scalars['Boolean']['output'];
  /** Список вин с текущим брендом */
  wines: Array<Wine>;
};


export type BrandLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


export type BrandTranslationsArgs = {
  sort?: InputMaybe<BrandTranslationSort>;
};


export type BrandWinesArgs = {
  includeSynonyms?: InputMaybe<Scalars['Boolean']['input']>;
  withoutVintageDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandAcceptInput = {
  /** Идентификатор бренда */
  brandId: Scalars['ID']['input'];
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
};

export type BrandAcceptPayload = {
  __typename?: 'BrandAcceptPayload';
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type BrandChangelogItem = AcceptabilityDiff | BrandDiff;

export type BrandContributedSeriesPayloadItem = {
  __typename?: 'BrandContributedSeriesPayloadItem';
  /** Количество измененных брендов за дату */
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type BrandCreateInput = {
  /** Название бренда */
  brand: Scalars['String']['input'];
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор торгового наименования */
  tradeNameId: Scalars['ID']['input'];
};

export type BrandCreatePayload = {
  __typename?: 'BrandCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type BrandDiff = {
  __typename?: 'BrandDiff';
  /** Может ли текущий пользователь удалить данное изменение */
  canDelete: Scalars['Boolean']['output'];
  /** Может ли текущий пользователь переменести данное изменение */
  canMove: Scalars['Boolean']['output'];
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  /** Метаданные изменения */
  metadata: EntityDiffMetadata;
  /** Изменения в переводах */
  translations?: Maybe<Array<BrandTranslationDiff>>;
};

export type BrandELabel = {
  __typename?: 'BrandELabel';
  brand: Scalars['String']['output'];
};

export type BrandFactDeleteInput = {
  /** ID факта */
  id: Scalars['ID']['input'];
};

export type BrandFactDeletePayload = {
  __typename?: 'BrandFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
};

export type BrandFactMoveInput = {
  /** ID бренда */
  brandId: Scalars['ID']['input'];
  /** ID факта */
  factId: Scalars['ID']['input'];
};

export type BrandFactMovePayload = {
  __typename?: 'BrandFactMovePayload';
  originRecord?: Maybe<Brand>;
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
};

export type BrandFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Включаеются ли только допустимые бренды */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type BrandFiltersFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type BrandPagination = {
  __typename?: 'BrandPagination';
  items: Array<Brand>;
  pageInfo: PageInfo;
};

export type BrandRefuseInput = {
  /** Идентификатор бренда */
  brandId: Scalars['ID']['input'];
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
};

export type BrandRefusePayload = {
  __typename?: 'BrandRefusePayload';
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export enum BrandSort {
  /** Сортировка по возрастанию названия */
  BrandNameAsc = 'BRAND_NAME_ASC',
  /** Сортировка по убыванию названия */
  BrandNameDesc = 'BRAND_NAME_DESC',
  /** Сортировка по релевантности полнотекстового поиска */
  Score = 'SCORE',
  /** Сортировка по возрастанию торгового наименования */
  TradeNameAsc = 'TRADE_NAME_ASC',
  /** Сортировка по убыванию торгового наименования */
  TradeNameDesc = 'TRADE_NAME_DESC',
  /** Сортировка по возрастанию даты последнего изменения */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванию даты последнего изменения */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum BrandStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type BrandTranslation = {
  __typename?: 'BrandTranslation';
  /** Локаль перевода */
  locale: Locale;
  /** Перевод названия */
  name: Scalars['String']['output'];
  /** Список торговых марок с совпадающим названием */
  tradeMarks: Array<TradeMark>;
};

export type BrandTranslationDiff = {
  __typename?: 'BrandTranslationDiff';
  current: Scalars['Boolean']['output'];
  /** Старое значение перевода */
  lhs?: Maybe<BrandTranslation>;
  /** Новое значение перевода */
  rhs?: Maybe<BrandTranslation>;
};

export type BrandTranslationInput = {
  /** Перевод бренда */
  brand: Scalars['String']['input'];
  /** Идентификатор локали */
  localeId: Scalars['Int']['input'];
};

export enum BrandTranslationSort {
  /** Сортировка по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type BrandUpdateInput = {
  /** Название бренда */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор бренда */
  brandId: Scalars['ID']['input'];
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Переводы бренда */
  translations?: Array<BrandTranslationInput>;
};

export type BrandUpdatePayload = {
  __typename?: 'BrandUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Brand>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type BuyProductsPaginationFilter = {
  companyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type BuyProductsPaginationPayload = {
  __typename?: 'BuyProductsPaginationPayload';
  items: Array<Product>;
  pageInfo: PageInfo;
  tradeNameCount: Scalars['Int']['output'];
};

export type CmsMutations = {
  __typename?: 'CMSMutations';
  tradeNameBulkLegalEntityUpdate?: Maybe<CmsTradeNameBulkLegalEntityUpdatePayload>;
  /** Массовое переименование торговых наименований */
  tradeNameBulkRename?: Maybe<CmsTradeNameBulkRenamePayload>;
};


export type CmsMutationsTradeNameBulkLegalEntityUpdateArgs = {
  input: CmsTradeNameBulkLegalEntityUpdateInput;
};


export type CmsMutationsTradeNameBulkRenameArgs = {
  input: CmsTradeNameBulkRenameInput;
};

export type CmsProductByTextAttributesInput = {
  /** Размер бутылки в миллилитрах */
  bottleSizeML: Scalars['Int']['input'];
  /** Название бренда вина */
  brand: Scalars['String']['input'];
  /** Код страны согласно стандарту ISO 3166-1 */
  countryISOCode: Scalars['String']['input'];
  /** Названия геоиндикаций */
  geoIndication?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Ключевые слова вина */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** Сокращенное название типа национальнной геоиндикации */
  nationalGITypeShortName?: InputMaybe<Scalars['String']['input']>;
  /** Полное название типа упаковки на английском */
  packageTypeName?: InputMaybe<Scalars['String']['input']>;
  /** Название первоисточника */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Названия специальных классификаций */
  specialClassification?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Название сладости вина */
  sweetnessLevel: Scalars['String']['input'];
  /** Торговое наименование производителя вина */
  tradeName: Scalars['String']['input'];
  /** Код страны производителя вина */
  tradeNameCountryISOCode: Scalars['String']['input'];
  /** Винтаж вина */
  vintage?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Название цвета вина */
  wineColor: Scalars['String']['input'];
  /** Название типа вина */
  wineType: Scalars['String']['input'];
};

export type CmsQueries = {
  __typename?: 'CMSQueries';
  productByTextAttributes?: Maybe<Product>;
};


export type CmsQueriesProductByTextAttributesArgs = {
  input: CmsProductByTextAttributesInput;
};

/** Тип источника (часть типов приоритетов) */
export enum CmsSourceType {
  Ai = 'AI',
  OfficialRegistry = 'OFFICIAL_REGISTRY',
  TopSource = 'TOP_SOURCE',
  UsualSource = 'USUAL_SOURCE'
}

export type CmsTradeNameBulkLegalEntityUpdateInput = {
  authorUserEmail: Scalars['EmailAddress']['input'];
  rows: Array<CmsTradeNameLegalEntityUpdateInput>;
  sourceType: CmsSourceType;
};

export type CmsTradeNameBulkLegalEntityUpdatePayload = {
  __typename?: 'CMSTradeNameBulkLegalEntityUpdatePayload';
  affectedCount: Scalars['Int']['output'];
  query?: Maybe<Query>;
};

export type CmsTradeNameBulkRenameInput = {
  rows: Array<CmsTradeNameRenameInput>;
};

export type CmsTradeNameBulkRenamePayload = {
  __typename?: 'CMSTradeNameBulkRenamePayload';
  query?: Maybe<Query>;
};

export type CmsTradeNameLegalEntityUpdateInput = {
  countryISOCode: Scalars['String']['input'];
  discriminatorValue: Scalars['String']['input'];
  tradeName: Scalars['String']['input'];
};

export type CmsTradeNameRenameInput = {
  /** Код страны согласно стандарту ISO 3166-1 */
  countryISOCode: Scalars['String']['input'];
  /** Новое торговое наименование */
  newName: Scalars['String']['input'];
  /** Старое торговое наименование */
  oldName: Scalars['String']['input'];
};

export type CmsWineChemicalAnalysisInput = {
  acidityTotalGPerL?: InputMaybe<Scalars['Float']['input']>;
  alcoholVolume?: InputMaybe<Scalars['Float']['input']>;
  pH?: InputMaybe<Scalars['Float']['input']>;
  residualSugar?: InputMaybe<Scalars['Float']['input']>;
};

export type CmsWineGrapeVarietyInput = {
  grapeVarietySynonym: Scalars['String']['input'];
  isInOFN?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type CrmOrganization = {
  __typename?: 'CRMOrganization';
  id: Scalars['ID']['output'];
  link: Scalars['URL']['output'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** Сертификат */
export type Certification = {
  __typename?: 'Certification';
  /** Типы сертификатов */
  certificationTypes: Array<CertificationType>;
  /** Идентификатор сертификата */
  id: Scalars['ID']['output'];
  /** Перевод названия сертификата */
  localizedName: Scalars['String']['output'];
  /** Перевод сокращенного названия сертификата */
  localizedShortName: Scalars['String']['output'];
  /** Ссылка на логотип */
  logo?: Maybe<Scalars['URL']['output']>;
  /** Название сертификата по умолчанию */
  masterName: Scalars['String']['output'];
  /** Сокращенное название сертификата по умолчанию */
  masterNameShort?: Maybe<Scalars['String']['output']>;
  /** Сайт с информацией о сертификате */
  website?: Maybe<Scalars['URL']['output']>;
};


/** Сертификат */
export type CertificationLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Сертификат */
export type CertificationLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Тип сертификата */
export type CertificationType = {
  __typename?: 'CertificationType';
  /** Идентификатор типа сертификата */
  id: Scalars['Int']['output'];
  /** Перевод названия типа сертификата */
  localizedName: Scalars['String']['output'];
  /** Перевод сокращенного названия типа сертификата */
  localizedShortName?: Maybe<Scalars['String']['output']>;
  /** Название типа сертификата по умолчанию */
  masterName: Scalars['String']['output'];
  /** @deprecated Not used by clients */
  order?: Maybe<Scalars['Int']['output']>;
};


/** Тип сертификата */
export type CertificationTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Тип сертификата */
export type CertificationTypeLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Вид пробки */
export type ClosureType = {
  __typename?: 'ClosureType';
  /** Идентификатор вида пробки */
  id: Scalars['Int']['output'];
  /** Перевод названия вида пробки */
  localizedName: Scalars['String']['output'];
  /** Название вида пробки на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Вид пробки */
export type ClosureTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type ClosureTypeDiff = {
  __typename?: 'ClosureTypeDiff';
  current: Scalars['Boolean']['output'];
  /** Старый вид пробки */
  lhs?: Maybe<ClosureType>;
  /** Новый вид пробки */
  rhs?: Maybe<ClosureType>;
};

/** Представитель компании */
export type Company = {
  __typename?: 'Company';
  avatar?: Maybe<Image>;
  /**
   * Валюта в которой покупатель смотрит цены
   * @deprecated Use currency with page param
   */
  buyCurrency?: Maybe<Currency>;
  /** @deprecated use buyProductsFilters in Query */
  buyProductsFilters: FacetFilterPayload;
  /** @deprecated Use buyProductsPagination in Quer */
  buyProductsPagination: BuyProductsPaginationPayload;
  /** Может ли компания использовать триальную версию подписки */
  canTrialELabelSubscription: Scalars['Boolean']['output'];
  /** Категория компании */
  companyCategory: CompanyCategory;
  companyLink?: Maybe<Scalars['String']['output']>;
  /** Страна компании */
  country: Country;
  /** Дата создания записи о компании */
  createdAt: Scalars['DateTime']['output'];
  crmOrganization?: Maybe<CrmOrganization>;
  /** Основной электронный почтовый адрес компании */
  email?: Maybe<Scalars['EmailAddress']['output']>;
  /** Имя работника представителя компании */
  employeeName?: Maybe<Scalars['String']['output']>;
  /** Идентификатор компании */
  id: Scalars['ID']['output'];
  /** Признак верификации пользователя в качестве представителя компании */
  isVerifiedUser: Scalars['Boolean']['output'];
  /** Широта местонахождения компании */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Юрлицо привязанное к компании */
  legalEntity?: Maybe<LegalEntity>;
  /** Долгота местонахождения компании */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Связанный магазин с компанией */
  merchant?: Maybe<Merchant>;
  /** Настройки уведомлений по почте для компании */
  notificationSettings: CompanyNotificationSettings;
  pageSettings: CompanyPageSettings;
  /** Основной номер телефона компании */
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Почтовый адрес компании */
  postAddress?: Maybe<Scalars['String']['output']>;
  /** Почтовый код компании */
  postCode?: Maybe<Scalars['String']['output']>;
  postCountry?: Maybe<Country>;
  postTerritory?: Maybe<Territory>;
  /** Торговое название производителя (в случае если компания представляет производителя) */
  producerTradeName?: Maybe<TradeName>;
  /** Текущая информация о покупателе тарифа электронных этикеток */
  productELabelCustomer?: Maybe<ProductELabelCustomer>;
  /** Текущая подписка компании на тариф электронных этикеток */
  productELabelSubscription?: Maybe<ProductELabelSubscription>;
  /** Фильтры для электронных этикеток */
  productELabelsFilters: FacetFilterPayload;
  /** Список электронных этикеток компании */
  productELabelsPagination: ProductELabelsPagination;
  /** Список продуктов и вин которые компания может выставить на продажу */
  productsOrWinesToOffer: ProductsOrWinesToOfferPayload;
  /** Значения для фильтров продуктов из шортлиста */
  productsShortlistFilters: FacetFilterPayload;
  /** Список продуктов которые компания добавила в шортлист */
  productsShortlistPagination: ProductsPagination;
  /** Группа представителей */
  representativeGroup?: Maybe<CompanyRepresentativeGroup>;
  /** Пользователь представитель данной компании */
  representativeUser?: Maybe<User>;
  tour: CompanyTour;
  /** Название компании */
  tradeName?: Maybe<Scalars['String']['output']>;
  /** Комментарий к верификации */
  verificationComment?: Maybe<Scalars['String']['output']>;
  /** Статус верификации компании */
  verificationStatus: CompanyVerificationStatus;
  /** Дата и время последнего обновления компании */
  verificationStatusUpdatedAt: Scalars['DateTime']['output'];
  /** Пользователь который изменял верификацию */
  verificationUpdateUser?: Maybe<User>;
  /**
   * Поиск склада по ID
   * Возвращает найденный склад или null, если склад не был найден
   */
  warehouse?: Maybe<Warehouse>;
  /**
   * Поиск склада по названию
   * Возвращает найденный склад или null, если склад не был найден
   */
  warehouseByName?: Maybe<Warehouse>;
  /** Поиск складов компании */
  warehousesPagination?: Maybe<WarehousesPagination>;
  /** Основной сайт компании */
  website?: Maybe<Scalars['URL']['output']>;
  /**
   * История переговоров по коммерческому предложению
   * @deprecated Use WineOfferPersonal.history
   */
  wineOfferPersonalHistory?: Maybe<Array<WineOffer>>;
  /** Поиск запросов созданных компанией или подходящих под компанию */
  wineOfferRequestsPagination?: Maybe<WineOfferRequestPagination>;
  /** Список истекающих запросов по дням */
  wineOfferRequestsPeriodOfValiditySeries: Array<WineOfferRequestsPeriodOfValiditySeriesPayloadItem>;
  /** Список фильтров для коммерческих предложений */
  wineOffersFilters: FacetFilterPayload;
  /** Список коммерческих предложений для компании */
  wineOffersPagination?: Maybe<WineOfferPagination>;
  /** Список отзывов которыми владеет компания */
  wineReviewsPagination: WineReviewPagination;
  /** Список вин компании для размещения на доске предложений */
  winesToOffer: WinesToOfferPayload;
};


/** Представитель компании */
export type CompanyBuyProductsFiltersArgs = {
  filter?: InputMaybe<BuyProductsPaginationFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Представитель компании */
export type CompanyBuyProductsPaginationArgs = {
  filter?: InputMaybe<BuyProductsPaginationFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};


/** Представитель компании */
export type CompanyPageSettingsArgs = {
  page: Scalars['String']['input'];
};


/** Представитель компании */
export type CompanyProductELabelsFiltersArgs = {
  filter?: InputMaybe<ProductELabelsFilterFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Представитель компании */
export type CompanyProductELabelsPaginationArgs = {
  filter?: InputMaybe<ProductELabelsFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductELabelSort;
};


/** Представитель компании */
export type CompanyProductsOrWinesToOfferArgs = {
  limit?: Scalars['Int']['input'];
  localeId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};


/** Представитель компании */
export type CompanyProductsShortlistFiltersArgs = {
  filter?: InputMaybe<ProductsShortlistFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Представитель компании */
export type CompanyProductsShortlistPaginationArgs = {
  filter?: InputMaybe<ProductsShortlistFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};


/** Представитель компании */
export type CompanyWarehouseArgs = {
  id: Scalars['ID']['input'];
};


/** Представитель компании */
export type CompanyWarehouseByNameArgs = {
  name: Scalars['String']['input'];
};


/** Представитель компании */
export type CompanyWarehousesPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort: WarehouseSort;
};


/** Представитель компании */
export type CompanyWineOfferPersonalHistoryArgs = {
  id: Scalars['Int']['input'];
};


/** Представитель компании */
export type CompanyWineOfferRequestsPaginationArgs = {
  isBuyer?: Scalars['Boolean']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: WineOfferRequestSort;
};


/** Представитель компании */
export type CompanyWineOfferRequestsPeriodOfValiditySeriesArgs = {
  isBuyer?: Scalars['Boolean']['input'];
  limit?: Scalars['Int']['input'];
};


/** Представитель компании */
export type CompanyWineOffersFiltersArgs = {
  filter?: InputMaybe<WineOfferFiltersFilter>;
  isBuyer?: Scalars['Boolean']['input'];
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Представитель компании */
export type CompanyWineOffersPaginationArgs = {
  filter?: InputMaybe<WineOfferFilter>;
  isBuyer?: Scalars['Boolean']['input'];
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineOfferSort;
};


/** Представитель компании */
export type CompanyWineReviewsPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: WineReviewSort;
};


/** Представитель компании */
export type CompanyWinesToOfferArgs = {
  limit?: Scalars['Int']['input'];
  localeId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

/** Категория компании */
export type CompanyCategory = {
  __typename?: 'CompanyCategory';
  /** Идентификатор категории компании */
  id: Scalars['Int']['output'];
  /** Может ли компания выступать в роли покупателя */
  isBuyer: Scalars['Boolean']['output'];
  /** Может ли компания выступать в роли продавца */
  isSeller: Scalars['Boolean']['output'];
  /** Перевод названия категории компании */
  localizedName: Scalars['String']['output'];
  /** Перевод сокращенного названия категории компании */
  localizedShortName: Scalars['String']['output'];
  /** Название категории компании на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Категория компании */
export type CompanyCategoryLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Категория компании */
export type CompanyCategoryLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type CompanyChangeBuyCurrencyInput = {
  buyCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['ID']['input'];
};

export type CompanyChangeBuyCurrencyPayload = {
  __typename?: 'CompanyChangeBuyCurrencyPayload';
  company: Company;
  query: Query;
};

export type CompanyChangePageCurrencyInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Название страницы */
  page: Scalars['String']['input'];
};

export type CompanyChangePageCurrencyPayload = {
  __typename?: 'CompanyChangePageCurrencyPayload';
  company: Company;
  query: Query;
};

export type CompanyChangePageMerchantInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  merchantId: Scalars['ID']['input'];
  page: Scalars['String']['input'];
};

export type CompanyChangePageMerchantPayload = {
  __typename?: 'CompanyChangePageMerchantPayload';
  company: Company;
  query: Query;
};

export type CompanyContributionUpdateInput = {
  /** ИНН */
  TIN?: InputMaybe<Scalars['String']['input']>;
  /** Код экономической деятельности компании */
  activityCode?: InputMaybe<Array<Scalars['String']['input']>>;
  avatar?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор категории компании (CompanyCategory.id) */
  companyCategoryId: Scalars['Int']['input'];
  /** Идентификатор компании */
  companyId: Scalars['ID']['input'];
  /** Уникальная ссылка на компанию */
  companyLink?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор страны (Country.id) */
  countryId: Scalars['Int']['input'];
  /** Электронная почта компании */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Имя известного сотрудника компании */
  employeeName?: InputMaybe<Scalars['String']['input']>;
  /** Широта из координат компании */
  latitude: Scalars['Float']['input'];
  legalEntityFormId?: InputMaybe<Scalars['Int']['input']>;
  /** Наименование ЮЛ */
  legalName: Scalars['String']['input'];
  /** Долгота из координат компании */
  longitude: Scalars['Float']['input'];
  /** Телефон компании (юрлица) */
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Почтовый адрес */
  postAddress: Scalars['String']['input'];
  /** Почтовый код почтового адреса */
  postCode: Scalars['String']['input'];
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  postTerritoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Торговое наименование */
  tradeName?: InputMaybe<Scalars['String']['input']>;
  /** Номер НДС компании (юрлица) */
  vat?: InputMaybe<Scalars['String']['input']>;
  /** Сайт компании */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type CompanyContributionUpdatePayload = {
  __typename?: 'CompanyContributionUpdatePayload';
  company: Company;
};

export type CompanyCreateFromExternalInput = {
  externalCompanyId: Array<Scalars['ID']['input']>;
};

export type CompanyCreateFromExternalPayload = {
  __typename?: 'CompanyCreateFromExternalPayload';
  companies: Array<Company>;
  query?: Maybe<Query>;
};

export type CompanyCreateInput = {
  /** Регистрационный номер юрлица */
  TIN?: InputMaybe<Scalars['String']['input']>;
  /** Код экономической деятельности компании */
  activityCode?: InputMaybe<Array<Scalars['String']['input']>>;
  avatar?: InputMaybe<Scalars['URL']['input']>;
  /** Регистрационный номер дополнительного юрлица */
  branchNumber?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор категории компании (CompanyCategory.id) */
  companyCategoryId: Scalars['Int']['input'];
  /** Уникальная ссылка на компанию */
  companyLink?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор страны (Country.id) */
  countryId: Scalars['Int']['input'];
  /** Электронная почта компании */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Имя известного сотрудника компании */
  employeeName?: InputMaybe<Scalars['String']['input']>;
  /** Широта из координат компании */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** Адрес ЮЛ */
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  legalEntityFormId?: InputMaybe<Scalars['Int']['input']>;
  /** Наименование ЮЛ */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Долгота из координат компании */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** Телефон компании (юрлица) */
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Почтовый адрес */
  postAddress?: InputMaybe<Scalars['String']['input']>;
  /** Почтовый код почтового адреса */
  postCode?: InputMaybe<Scalars['String']['input']>;
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  postTerritoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Торговое наименование */
  tradeName: Scalars['String']['input'];
  /** Номер НДС компании (юрлица) */
  vat?: InputMaybe<Scalars['String']['input']>;
  /** Сайт компании */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type CompanyCreatePayload = {
  __typename?: 'CompanyCreatePayload';
  company: Company;
};

export type CompanyFilter = {
  /** List of category names */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of country IDs */
  countries?: InputMaybe<Array<Scalars['Int']['input']>>;
  hasVerifiedUser?: Scalars['Boolean']['input'];
};

export type CompanyGrantInfoInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификатор компании получателя доступа к данным */
  recipientCompanyId: Scalars['ID']['input'];
};

export type CompanyGrantInfoPayload = {
  __typename?: 'CompanyGrantInfoPayload';
  query?: Maybe<Query>;
};

export type CompanyInfo = Company | HiddenCompany;

export type CompanyInfoPagination = {
  __typename?: 'CompanyInfoPagination';
  items: Array<CompanyInfo>;
  pageInfo: PageInfo;
};

export type CompanyNotificationSettings = {
  __typename?: 'CompanyNotificationSettings';
  /** Маркетинговые письма */
  marketing: Scalars['Boolean']['output'];
  /** Принятие сделки */
  offerAccepted: Scalars['Boolean']['output'];
  /** Изменение условий сделки */
  offerConditionChanged: Scalars['Boolean']['output'];
  /** Отклонение сделки */
  offerDeclined: Scalars['Boolean']['output'];
  /** Появление запроса покупателя (для производителей) */
  wineOfferRequestCreated: Scalars['Boolean']['output'];
  /** Публикация отзыва на вино */
  wineReviewPublished: Scalars['Boolean']['output'];
};

export type CompanyNotificationSettingsInput = {
  /** Идентификатор компании */
  companyId: Scalars['ID']['input'];
  /** Маркетинговые письма */
  marketing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Принятие сделки */
  offerAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Изменение условий сделки */
  offerConditionChanged?: InputMaybe<Scalars['Boolean']['input']>;
  /** Отклонение сделки */
  offerDeclined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Появление запроса покупателя (для производителей) */
  wineOfferRequestCreated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Публикация отзыва на вино */
  wineReviewPublished?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyPageSettings = {
  __typename?: 'CompanyPageSettings';
  /** Валюта сохраненная для страницы */
  currency?: Maybe<Currency>;
  /** Магазин сохраненный для страницы */
  merchant?: Maybe<Merchant>;
};

export type CompanyPagination = {
  __typename?: 'CompanyPagination';
  items: Array<Company>;
  pageInfo: PageInfo;
};

export type CompanyRemoveInput = {
  companyId: Scalars['ID']['input'];
};

export type CompanyRemovePayload = {
  __typename?: 'CompanyRemovePayload';
  query?: Maybe<Query>;
};

export type CompanyRepresentativeGroup = {
  __typename?: 'CompanyRepresentativeGroup';
  /** Компании в группе представителей */
  companies: Array<Company>;
};


export type CompanyRepresentativeGroupCompaniesArgs = {
  verifiedOnly?: Scalars['Boolean']['input'];
};

export type CompanyRevokeInfoInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификатор компании получателя доступа к данным */
  recipientCompanyId: Scalars['ID']['input'];
};

export type CompanyRevokeInfoPayload = {
  __typename?: 'CompanyRevokeInfoPayload';
  query?: Maybe<Query>;
};

export type CompanySendVerificationEmailInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['EmailAddress']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySendVerificationEmailToRepresenativeInput = {
  /** ID текущей компании */
  companyId: Scalars['ID']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
  /** ID представителя, которому нужно отправить письмо */
  representativeUserId: Scalars['Int']['input'];
};

export enum CompanySort {
  /** Сортировка компаний по возрастанию названия страны */
  CountryNameAsc = 'COUNTRY_NAME_ASC',
  /** Сортировка компаний по убыванию названия страны */
  CountryNameDesc = 'COUNTRY_NAME_DESC',
  /** Сортировка компаний по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка компаний по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка компаний по возрастанию торгового наименования */
  TradeNameAsc = 'TRADE_NAME_ASC',
  /** Сортировка компаний по убыванию торгового наименования */
  TradeNameDesc = 'TRADE_NAME_DESC',
  /** Сортировка компаний по возрастанию email пользователя представителя */
  UserEmailAsc = 'USER_EMAIL_ASC',
  /** Сортировка компаний по убыванию email пользователя представителя */
  UserEmailDesc = 'USER_EMAIL_DESC',
  /** Сортировка компаний по возрастанию ФИО пользователя представителя */
  UserFullNameAsc = 'USER_FULL_NAME_ASC',
  /** Сортировка компаний по убыванию ФИО пользователя представителя */
  UserFullNameDesc = 'USER_FULL_NAME_DESC',
  /** Сортировка компаний по возрастанию username пользователя представителя */
  UserUsernameAsc = 'USER_USERNAME_ASC',
  /** Сортировка компаний по убыванию username пользователя представителя */
  UserUsernameDesc = 'USER_USERNAME_DESC',
  /** Сортировка компаний по возрастанию названия статуса верификации */
  VerificationStatusNameAsc = 'VERIFICATION_STATUS_NAME_ASC',
  /** Сортировка компаний по убыванию названия статуса верификации */
  VerificationStatusNameDesc = 'VERIFICATION_STATUS_NAME_DESC',
  /** Сортировка компаний по возрастанию даты обновления статуса верификации */
  VerificationStatusUpdatedAtAsc = 'VERIFICATION_STATUS_UPDATED_AT_ASC',
  /** Сортировка компаний по убыванию даты обновления статуса верификации */
  VerificationStatusUpdatedAtDesc = 'VERIFICATION_STATUS_UPDATED_AT_DESC',
  /** Сортировка компаний по возрастанию сайта компании */
  WebsiteAsc = 'WEBSITE_ASC',
  /** Сортировка компаний по убыванию сайта компании */
  WebsiteDesc = 'WEBSITE_DESC'
}

export type CompanyTour = {
  __typename?: 'CompanyTour';
  completed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  skipped: Scalars['Boolean']['output'];
  tasks: Array<CompanyTourTask>;
};

export type CompanyTourCompleteInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
};

export type CompanyTourCompletePayload = {
  __typename?: 'CompanyTourCompletePayload';
  company?: Maybe<Company>;
  query?: Maybe<Query>;
};

export type CompanyTourSkipInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
};

export type CompanyTourSkipPayload = {
  __typename?: 'CompanyTourSkipPayload';
  company?: Maybe<Company>;
  query?: Maybe<Query>;
};

export type CompanyTourTask = {
  __typename?: 'CompanyTourTask';
  /** Завершена ли задача */
  completed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Сколько раз было выполнено условие */
  step?: Maybe<Scalars['Int']['output']>;
  /** Код задачи */
  task: Scalars['String']['output'];
  /** Сколько нужно выполнить условий для завершения задачи */
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyUpdateInput = {
  /** Регистрационный номер юрлица */
  TIN?: InputMaybe<Scalars['String']['input']>;
  /** Код экономической деятельности компании */
  activityCode?: InputMaybe<Array<Scalars['String']['input']>>;
  avatar?: InputMaybe<Scalars['URL']['input']>;
  /** Регистрационный номер дополнительного юрлица */
  branchNumber?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор компании */
  companyId: Scalars['ID']['input'];
  /** Уникальная ссылка на компанию */
  companyLink?: InputMaybe<Scalars['String']['input']>;
  /** Электронная почта компании */
  email: Scalars['EmailAddress']['input'];
  /** Имя известного сотрудника компании */
  employeeName?: InputMaybe<Scalars['String']['input']>;
  /** Широта из координат компании */
  latitude: Scalars['Float']['input'];
  /** Адрес ЮЛ */
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  legalEntityFormId?: InputMaybe<Scalars['Int']['input']>;
  /** Наименование ЮЛ */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Долгота из координат компании */
  longitude: Scalars['Float']['input'];
  /** Телефон компании (юрлица) */
  phone: Scalars['PhoneNumber']['input'];
  /** Почтовый адрес */
  postAddress: Scalars['String']['input'];
  /** Почтовый код почтового адреса */
  postCode: Scalars['String']['input'];
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  postTerritoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Торговое наименование */
  tradeName: Scalars['String']['input'];
  /** Номер НДС компании (юрлица) */
  vat?: InputMaybe<Scalars['String']['input']>;
  /** Сайт компании */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type CompanyUpdatePayload = {
  __typename?: 'CompanyUpdatePayload';
  company: Company;
};

export type CompanyVerificationAcceptInput = {
  companyId: Array<Scalars['ID']['input']>;
  localeId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyVerificationAcceptPayload = {
  __typename?: 'CompanyVerificationAcceptPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Company>>;
};

export type CompanyVerificationAllowInput = {
  companyId: Array<Scalars['ID']['input']>;
};

export type CompanyVerificationAllowPayload = {
  __typename?: 'CompanyVerificationAllowPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Company>>;
};

export type CompanyVerificationProhibitInput = {
  companyId: Array<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyVerificationProhibitPayload = {
  __typename?: 'CompanyVerificationProhibitPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Company>>;
};

export type CompanyVerificationRejectInput = {
  companyId: Array<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyVerificationRejectPayload = {
  __typename?: 'CompanyVerificationRejectPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Company>>;
};

export type CompanyVerificationRequestInput = {
  companyId: Scalars['ID']['input'];
};

export type CompanyVerificationRequestPayload = {
  __typename?: 'CompanyVerificationRequestPayload';
  query?: Maybe<Query>;
  record?: Maybe<Company>;
};

/** Статус верификации компании */
export type CompanyVerificationStatus = {
  __typename?: 'CompanyVerificationStatus';
  /** Код статуса верификации компании */
  code: Scalars['String']['output'];
  /** Идентификатор статуса верификации компании */
  id: Scalars['ID']['output'];
  /** Название статуса верификации компании на языке по умолчанию */
  masterName: Scalars['String']['output'];
};

/** Страна */
export type Country = {
  __typename?: 'Country';
  /** Используется ли в стране несколько геоиндикаций */
  allowManyGeoIndication: Scalars['Boolean']['output'];
  /** Граница страны в формате GeoJSON */
  border?: Maybe<GeoJsonInterface>;
  /** Формат регистрацинного номера филиала юрлица */
  branchNumberFormat?: Maybe<Scalars['String']['output']>;
  /** Название регистрацинного номера филиала юрлица */
  branchNumberName?: Maybe<Scalars['String']['output']>;
  /** Список типов сертификатов */
  certificationTypes: Array<CertificationType>;
  /** Код страны согласно стандарту ISO 3166-1 */
  codeISO: Scalars['String']['output'];
  /** Валюты используемые в стране */
  currencies: Array<Currency>;
  /** Количество географических индикаций в стране */
  geoIndicationCount: Scalars['Int']['output'];
  /** Идентификатор страны */
  id: Scalars['Int']['output'];
  /** Формат регистрационного номера юрлица */
  identityNumberFormat?: Maybe<Scalars['String']['output']>;
  /** Название регистрационного номера юрлица */
  identityNumberName?: Maybe<Scalars['String']['output']>;
  /** Является ли страна членом ЕС */
  isEUMember?: Maybe<Scalars['Boolean']['output']>;
  /** Используется ли страна для выбора на интерфейсе */
  isUsed: Scalars['Boolean']['output'];
  /** Поле которое используется для идентификации юрлиц */
  legalEntityDiscriminator?: Maybe<LegalEntityDiscriminator>;
  /** Текст ошибки при несовпадении формата регистрационного номера филиала юрлица */
  localizedBranchNumberFormatError: Scalars['String']['output'];
  /** Текст подсказки для регистрационного номера филиала юрлица */
  localizedBranchNumberTooltip: Scalars['String']['output'];
  /** Текст ошибки при несовпадении формата регистрационного номера юрлица */
  localizedIdentityNumberFormatError: Scalars['String']['output'];
  /** Текст подсказки для формата регистрационного номера юрлица */
  localizedIdentityNumberTooltip: Scalars['String']['output'];
  /** Перевод названия страны */
  localizedName: Scalars['String']['output'];
  /** Текст ошибки при несовпадении формата почтового индекса */
  localizedPostCodeFormatError: Scalars['String']['output'];
  /** Текст подсказки для почтового индекса */
  localizedPostCodeTooltip: Scalars['String']['output'];
  /** Текст ошибки формата номера налогоплательщка */
  localizedVATFormatError: Scalars['String']['output'];
  /** Текст подсказки номера налогоплательщка */
  localizedVATTooltip: Scalars['String']['output'];
  /** Название страны на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** Список национальных типов геоиндикаций */
  nationalGITypes: Array<NationalGiType>;
  /** Формат почтового индекса */
  postCodeFormat?: Maybe<Scalars['String']['output']>;
  /** Маска ввода почтового индекса */
  postCodeMask?: Maybe<Scalars['String']['output']>;
  /** Название почтового индекса */
  postCodeName?: Maybe<Scalars['String']['output']>;
  /** Специальные классификации в стране */
  specialClassifications: Array<SpecialClassification>;
  /** Типы сладости используемые в стране */
  sweetnessLevels: Array<SweetnessLevel>;
  /** Список территорий */
  territories?: Maybe<TerritoryPayload>;
  /** Типы территорий */
  territoryTypes: Array<TerritoryType>;
  tinMask?: Maybe<Scalars['String']['output']>;
  /** Формат номера налогоплательщика */
  vatFormat?: Maybe<Scalars['String']['output']>;
  /** Маска номера налогоплательщика */
  vatMask?: Maybe<Scalars['String']['output']>;
  /** Название номера налогоплательщика */
  vatName?: Maybe<Scalars['String']['output']>;
  /** Годовое производство вина */
  wineProductionVolume?: Maybe<Scalars['Int']['output']>;
  /** Винные регионы ЕС в стране */
  wineRegionsEU: Array<WineRegionEu>;
  /** Типы винных территорий */
  wineTerritoryTypes: Array<WineTerritoryType>;
};


/** Страна */
export type CountryLocalizedBranchNumberFormatErrorArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedBranchNumberTooltipArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedIdentityNumberFormatErrorArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedIdentityNumberTooltipArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedPostCodeFormatErrorArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedPostCodeTooltipArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedVatFormatErrorArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountryLocalizedVatTooltipArgs = {
  localeId: Scalars['Int']['input'];
};


/** Страна */
export type CountrySweetnessLevelsArgs = {
  wineTypeId?: InputMaybe<Scalars['Int']['input']>;
};


/** Страна */
export type CountryTerritoriesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CropInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<PositionType>;
  width?: InputMaybe<Scalars['Int']['input']>;
  xOffset?: InputMaybe<Scalars['Int']['input']>;
  yOffset?: InputMaybe<Scalars['Int']['input']>;
};

/** Валюта */
export type Currency = {
  __typename?: 'Currency';
  /** Код валюты */
  code: Scalars['String']['output'];
  codeNumber: Scalars['String']['output'];
  /** Идентификатор валюты */
  id: Scalars['Int']['output'];
  isGlobal: Scalars['Boolean']['output'];
  /** Название валюты на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** Курс валюты к USD */
  rate: Scalars['Float']['output'];
  symbol: Scalars['String']['output'];
};

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  currency: Currency;
  rate: Scalars['Float']['output'];
};

export type DateDiff = {
  __typename?: 'DateDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<Scalars['Date']['output']>;
  rhs?: Maybe<Scalars['Date']['output']>;
};

/** Учет доставки в цене */
export type DeliveryCost = {
  __typename?: 'DeliveryCost';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  localizedName: Scalars['String']['output'];
};


/** Учет доставки в цене */
export type DeliveryCostLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Время доставки */
export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  /** Идентификатор времени доставки */
  id: Scalars['Int']['output'];
  /** Перевод названия времени доставки */
  localizedName: Scalars['String']['output'];
};


/** Время доставки */
export type DeliveryTimeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type EsWasteContainer = {
  __typename?: 'ESWasteContainer';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  localizedDescription: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  logo: Scalars['URL']['output'];
};


export type EsWasteContainerLocalizedDescriptionArgs = {
  localeId: Scalars['Int']['input'];
};


export type EsWasteContainerLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Европейский тип геоиндикации */
export type EugiType = {
  __typename?: 'EUGIType';
  /** Идентификатор европейского типа геоиндикации */
  id: Scalars['Int']['output'];
  /** Перевод полного названия европеского типа геоиндикации */
  localizedFullName: Scalars['String']['output'];
  /** Перевод сокращенного названия европейского типа геоиндикации */
  localizedShortName: Scalars['String']['output'];
  /** Название типа европеской геоиндикации */
  masterName: Scalars['String']['output'];
};


/** Европейский тип геоиндикации */
export type EugiTypeLocalizedFullNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Европейский тип геоиндикации */
export type EugiTypeLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Европейский материал упаковки */
export type EuPackageMaterial = {
  __typename?: 'EUPackageMaterial';
  /** Код материала согласно стандарту */
  code: Scalars['String']['output'];
  /** Идентификатор европейского материала упаковки */
  id: Scalars['Int']['output'];
  /** Способ утилизации материала */
  localizedDisposal: Scalars['String']['output'];
  /** Название материала */
  localizedName: Scalars['String']['output'];
  /** Ссылка на логотип материала */
  logo: Scalars['URL']['output'];
};


/** Европейский материал упаковки */
export type EuPackageMaterialLocalizedDisposalArgs = {
  localeId: Scalars['Int']['input'];
};


/** Европейский материал упаковки */
export type EuPackageMaterialLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Изменение электронной почты */
export type EmailAddressDiff = {
  __typename?: 'EmailAddressDiff';
  /** Совпадает ли новое значение с текущим */
  current: Scalars['Boolean']['output'];
  /** Старое значение почты */
  lhs?: Maybe<Scalars['EmailAddress']['output']>;
  /** Новое значение почты */
  rhs?: Maybe<Scalars['EmailAddress']['output']>;
};

export type EntityDiffMetadata = {
  __typename?: 'EntityDiffMetadata';
  /** Автор изменений */
  authorUser?: Maybe<User>;
  batchLoad?: Maybe<Scalars['String']['output']>;
  priorityType?: Maybe<PriorityType>;
  timestamp: Scalars['DateTime']['output'];
};

/** Выставка */
export type Exhibition = {
  __typename?: 'Exhibition';
  /** Автор последнего изменения */
  authorUser?: Maybe<User>;
  /** Страна проведения выставки */
  country?: Maybe<Country>;
  /** Дата окончания выставки */
  dateEnd?: Maybe<Scalars['Date']['output']>;
  /** Дата начала выставки */
  dateStart?: Maybe<Scalars['Date']['output']>;
  /** Тип выставки */
  exhibitionType?: Maybe<ExhibitionType>;
  /** Идентификатор выставки */
  id: Scalars['Int']['output'];
  /** Признак наличия ссылок на эту выставку */
  isReferenced: Scalars['Boolean']['output'];
  /** Название выставки */
  name: Scalars['String']['output'];
  /**
   * Количество участников выставки
   * @deprecated Use participantsMin & participantsMax
   */
  participants?: Maybe<Scalars['PositiveInt']['output']>;
  /** Максимальное количество участников выставки */
  participantsMax?: Maybe<Scalars['Int']['output']>;
  /** Минимальное количество участников выставки */
  participantsMin?: Maybe<Scalars['Int']['output']>;
  /** Место проведения выставки (адрес) */
  place?: Maybe<Scalars['String']['output']>;
  /** Дата и время последнего обновления */
  updatedAt: Scalars['DateTime']['output'];
  /** Сайт выставки */
  website?: Maybe<Scalars['URL']['output']>;
};

export type ExhibitionCreateInput = {
  /** Идентификатор страны проведения выставки */
  countryId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата окончания выставки */
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  /** Дата начала выставки */
  dateStart?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор типа выставки */
  exhibitionTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** Название выставки */
  name: Scalars['String']['input'];
  /** Максимальное количество участников выставки */
  participantsMax?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальное количество участников выставки */
  participantsMin?: InputMaybe<Scalars['Int']['input']>;
  /** Место проведения выставки (адрес) */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Сайт выставки */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type ExhibitionCreatePayload = {
  __typename?: 'ExhibitionCreatePayload';
  query?: Maybe<Query>;
  record: Exhibition;
  recordId: Scalars['Int']['output'];
};

export type ExhibitionFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type ExhibitionFiltersFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type ExhibitionPagination = {
  __typename?: 'ExhibitionPagination';
  items: Array<Exhibition>;
  pageInfo: PageInfo;
};

export enum ExhibitionSort {
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Тип выставки */
export type ExhibitionType = {
  __typename?: 'ExhibitionType';
  /** Идентификатор типа выставки */
  id: Scalars['Int']['output'];
  /** Перевод названия типа выставки */
  localizedName: Scalars['String']['output'];
};


/** Тип выставки */
export type ExhibitionTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type ExhibitionUpdateInput = {
  /** Идентификатор страны проведения выставки */
  countryId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата окончания выставки */
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  /** Дата начала выставки */
  dateStart?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор типа выставки */
  exhibitionTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор выставки */
  id: Scalars['Int']['input'];
  /** Название выставки */
  name: Scalars['String']['input'];
  /** Максимальное количество участников выставки */
  participantsMax?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальное количество участников выставки */
  participantsMin?: InputMaybe<Scalars['Int']['input']>;
  /** Место проведения выставки (адрес) */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Сайт выставки */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type ExhibitionUpdatePayload = {
  __typename?: 'ExhibitionUpdatePayload';
  query?: Maybe<Query>;
  record: Exhibition;
  recordId: Scalars['Int']['output'];
};

export type ExhibitionsDeleteInput = {
  /** Массив ID выставок, которые нужно удалить */
  exhibitionIds: Array<Scalars['Int']['input']>;
};

export type ExhibitionsDeletePayload = {
  __typename?: 'ExhibitionsDeletePayload';
  query?: Maybe<Query>;
  /** ID выставок, которые были удалены */
  recordIds: Array<Scalars['Int']['output']>;
};

/** Квалификация дегустатора */
export type ExpertQualification = {
  __typename?: 'ExpertQualification';
  /** Идентификатор квалификации дегустатора */
  id: Scalars['Int']['output'];
  /** Перевод названия квалификации */
  localizedName: Scalars['String']['output'];
  /** Перевод сокращенного названия квалификации */
  localizedShortName: Scalars['String']['output'];
};


/** Квалификация дегустатора */
export type ExpertQualificationLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Квалификация дегустатора */
export type ExpertQualificationLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Причина отказа в верификации дегустатора */
export type ExpertVerificationRejectReason = {
  __typename?: 'ExpertVerificationRejectReason';
  /** Код причины в отказе верификации компании */
  code: Scalars['String']['output'];
  /** Идентификатор причины */
  id: Scalars['Int']['output'];
  localizedName: Scalars['String']['output'];
};


/** Причина отказа в верификации дегустатора */
export type ExpertVerificationRejectReasonLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Статус верификации дегустатора */
export type ExpertVerificationStatus = {
  __typename?: 'ExpertVerificationStatus';
  /** Код статуса верификации компании */
  code: Scalars['String']['output'];
  /** Идентификатор статуса верификации дегустатора */
  id: Scalars['Int']['output'];
  localizedName: Scalars['String']['output'];
};


/** Статус верификации дегустатора */
export type ExpertVerificationStatusLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type ExternalCompany = {
  __typename?: 'ExternalCompany';
  companyCategory: CompanyCategory;
  country: Country;
  id: Scalars['ID']['output'];
  legalEntityDiscriminator: Scalars['String']['output'];
  tradeName: Scalars['String']['output'];
};

/** Фильтр для переключателей */
export type FacetBooleanFilter = {
  __typename?: 'FacetBooleanFilter';
  facetType: FacetType;
  key: Scalars['String']['output'];
};

export type FacetBooleanFilterSelection = {
  __typename?: 'FacetBooleanFilterSelection';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type FacetBooleanInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Составной фильтр */
export type FacetComplexFilter = {
  __typename?: 'FacetComplexFilter';
  children: Array<FacetFilter>;
  facetType: FacetType;
  key: Scalars['String']['output'];
};

export type FacetComplexFilterSelection = {
  __typename?: 'FacetComplexFilterSelection';
  childrenInputs: Array<FacetFilterSelection>;
  key: Scalars['String']['output'];
};

export type FacetComplexInput = {
  childrenInputs: Array<FacetInput>;
  key: Scalars['String']['input'];
};

/** Фильтр для выбора диапозона дат */
export type FacetDateRangeFilter = {
  __typename?: 'FacetDateRangeFilter';
  facetType: FacetType;
  key: Scalars['String']['output'];
};

export type FacetDateRangeFilterSelection = {
  __typename?: 'FacetDateRangeFilterSelection';
  fromDate?: Maybe<Scalars['Date']['output']>;
  key: Scalars['String']['output'];
  toDate?: Maybe<Scalars['Date']['output']>;
};

export type FacetDateRangeInput = {
  /** Дата начала временного интервала. Включая выбранную дату */
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  key: Scalars['String']['input'];
  /** Дата окончания временного интервала. Включая выбранную дату */
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type FacetFilter = FacetBooleanFilter | FacetComplexFilter | FacetDateRangeFilter | FacetFreeListFilter | FacetListFilter | FacetRangeFilter;

export type FacetFilterPayload = {
  __typename?: 'FacetFilterPayload';
  /** Общее количество записей */
  count: Scalars['Int']['output'];
  /** Фильтр фасетного поиска */
  filterByKey?: Maybe<FacetFilter>;
  /** Список фильтров фасетного поиска */
  filters: Array<FacetFilter>;
};


export type FacetFilterPayloadFilterByKeyArgs = {
  key: Scalars['String']['input'];
};

export type FacetFilterSelection = FacetBooleanFilterSelection | FacetComplexFilterSelection | FacetDateRangeFilterSelection | FacetFreeListFilterSelection | FacetListFilterSelection | FacetRangeFilterSelection;

export type FacetFreeListFilter = {
  __typename?: 'FacetFreeListFilter';
  facetType: FacetType;
  key: Scalars['String']['output'];
  values: FacetFreeListValuesPayload;
};

export type FacetFreeListFilterSelection = {
  __typename?: 'FacetFreeListFilterSelection';
  byName?: Maybe<Array<Scalars['String']['output']>>;
  key: Scalars['String']['output'];
};

export type FacetFreeListInput = {
  byName?: InputMaybe<Array<Scalars['String']['input']>>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type FacetFreeListValuePayload = {
  __typename?: 'FacetFreeListValuePayload';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type FacetFreeListValuesPayload = {
  __typename?: 'FacetFreeListValuesPayload';
  items?: Maybe<Array<FacetFreeListValuePayload>>;
};

export type FacetInput = {
  booleanInput?: InputMaybe<FacetBooleanInput>;
  complexInput?: InputMaybe<FacetComplexInput>;
  dateRangeInput?: InputMaybe<FacetDateRangeInput>;
  freeListInput?: InputMaybe<FacetFreeListInput>;
  listInput?: InputMaybe<FacetListInput>;
  rangeInput?: InputMaybe<FacetRangeInput>;
};

/** Фильтр с выбором из списка */
export type FacetListFilter = {
  __typename?: 'FacetListFilter';
  canSelectMany: Scalars['Boolean']['output'];
  /** Общее количество записей */
  count: Scalars['Int']['output'];
  facetType: FacetType;
  key: Scalars['String']['output'];
  /** Список допустимых значений фасета */
  valuesPagination: FacetListValuePagination;
};


/** Фильтр с выбором из списка */
export type FacetListFilterValuesPaginationArgs = {
  includeUnusedLabels?: Scalars['Boolean']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<FacetListValuesSort>;
};

export type FacetListFilterSelection = {
  __typename?: 'FacetListFilterSelection';
  byName?: Maybe<Array<Scalars['String']['output']>>;
  key: Scalars['String']['output'];
};


export type FacetListFilterSelectionByNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type FacetListInput = {
  /** Выбор значений по строковому представлению */
  byName?: InputMaybe<Array<Scalars['String']['input']>>;
  key: Scalars['String']['input'];
};

export type FacetListPageInfo = {
  __typename?: 'FacetListPageInfo';
  /** Есть ли данные на следующей странице */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли данные на предыдущей странице */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер текущей страницы (начиная с 1) */
  page: Scalars['Int']['output'];
  /** Количество элементво на странице */
  perPage: Scalars['Int']['output'];
};

export type FacetListValuePagination = {
  __typename?: 'FacetListValuePagination';
  items?: Maybe<Array<FacetListValuePayload>>;
  pageInfo: FacetListPageInfo;
};

export type FacetListValuePayload = {
  __typename?: 'FacetListValuePayload';
  /** Количество записей с данным значением */
  count: Scalars['Int']['output'];
  record: FacetValuesResponseItem;
};

export enum FacetListValuesSort {
  /** Сортировка значений в списке по убыванию количества найденных документов */
  CountDesc = 'COUNT_DESC',
  /** Встроенная сортировка */
  Custom = 'CUSTOM',
  /** Сортировка значений в списке по возрастанию названий */
  NameAsc = 'NAME_ASC'
}

/** Фильтр для диапозона значений */
export type FacetRangeFilter = {
  __typename?: 'FacetRangeFilter';
  facetType: FacetType;
  key: Scalars['String']['output'];
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
};

export type FacetRangeFilterSelection = {
  __typename?: 'FacetRangeFilterSelection';
  key: Scalars['String']['output'];
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
};

export type FacetRangeInput = {
  key: Scalars['String']['input'];
  maxValue?: InputMaybe<Scalars['Float']['input']>;
  minValue?: InputMaybe<Scalars['Float']['input']>;
};

export enum FacetType {
  Boolean = 'BOOLEAN',
  Complex = 'COMPLEX',
  Daterange = 'DATERANGE',
  Freelist = 'FREELIST',
  List = 'LIST',
  Range = 'RANGE'
}

export type FacetValuesResponseItem = BooleanWrapper | StringWrapper;

/** Пресет фильтров */
export type FilterPreset = {
  __typename?: 'FilterPreset';
  /** Сущность для которой сохраняются фильтры */
  entity: FilterPresetEntity;
  /** Значения фильтров */
  filters: Array<FacetFilterSelection>;
  /** Идентификатор пресета фильтров */
  id: Scalars['ID']['output'];
  /** Является ли пресет избранным */
  isFavorite: Scalars['Boolean']['output'];
  /** Название пресета фильтров */
  name: Scalars['String']['output'];
  /** Название страницы на клиенте */
  page: Scalars['String']['output'];
};

export type FilterPresetDeletePayload = {
  __typename?: 'FilterPresetDeletePayload';
  query?: Maybe<Query>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export enum FilterPresetEntity {
  Brand = 'BRAND',
  Company = 'COMPANY',
  Exhibition = 'EXHIBITION',
  GeoIndication = 'GEO_INDICATION',
  Mention = 'MENTION',
  Merchant = 'MERCHANT',
  PriceSource = 'PRICE_SOURCE',
  Product = 'PRODUCT',
  ProductELabel = 'PRODUCT_E_LABEL',
  TradeName = 'TRADE_NAME',
  TradeOffer = 'TRADE_OFFER',
  TradeOfferLot = 'TRADE_OFFER_LOT',
  User = 'USER',
  Wine = 'WINE',
  WineOffer = 'WINE_OFFER',
  WineOfferRequest = 'WINE_OFFER_REQUEST',
  WineReview = 'WINE_REVIEW'
}

export type FilterPresetFavoritePayload = {
  __typename?: 'FilterPresetFavoritePayload';
  query?: Maybe<Query>;
  record?: Maybe<FilterPreset>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type FilterPresetSaveInput = {
  /** Сущность для которой сохраняются фильтры */
  entity: FilterPresetEntity;
  /** Значения фильтров */
  filters: Array<FacetInput>;
  /** Идентификатор пресета фильтров (в случае редактирования) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Является ли пресет избранным */
  isFavorite: Scalars['Boolean']['input'];
  /** Текущая локаль пользователя */
  localeId: Scalars['Int']['input'];
  /** Название пресета фильтров (до 50 символов) */
  name: Scalars['String']['input'];
  /** Название страницы на клиенте */
  page: Scalars['String']['input'];
};

export type FilterPresetSavePayload = {
  __typename?: 'FilterPresetSavePayload';
  query?: Maybe<Query>;
  record?: Maybe<FilterPreset>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

/** Измение вещественного поля */
export type FloatDiff = {
  __typename?: 'FloatDiff';
  /** Совпадает ли новое значение с текущим */
  current: Scalars['Boolean']['output'];
  /** Старое вещественное значение */
  lhs?: Maybe<Scalars['Float']['output']>;
  /** Новое вещественное значение */
  rhs?: Maybe<Scalars['Float']['output']>;
};

export type FloatForecast = {
  __typename?: 'FloatForecast';
  isForecast: Scalars['Boolean']['output'];
  value: Scalars['Float']['output'];
};

export type FloatRange = {
  __typename?: 'FloatRange';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type FloatRangeDiff = {
  __typename?: 'FloatRangeDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<FloatRange>;
  rhs?: Maybe<FloatRange>;
};

/** Блюдо которое может сочетаться с напитками */
export type FoodPair = {
  __typename?: 'FoodPair';
  /** Идентификатор блюда */
  id: Scalars['Int']['output'];
  /** Перевод названия блюда */
  localizedName: Scalars['String']['output'];
  /** Название блюда на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Блюдо которое может сочетаться с напитками */
export type FoodPairLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type FoodPairsDiff = {
  __typename?: 'FoodPairsDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<FoodPair>;
  rhs?: Maybe<FoodPair>;
};

export enum FormatType {
  Avif = 'avif',
  Gif = 'gif',
  Heic = 'heic',
  Heif = 'heif',
  Ico = 'ico',
  Jpeg = 'jpeg',
  Jpg = 'jpg',
  Png = 'png',
  Webp = 'webp'
}

/** Геоиндикация */
export type GeoIndication = {
  __typename?: 'GeoIndication';
  /** Список дочерних геоиндикаций */
  children?: Maybe<Array<GeoIndication>>;
  /** Код геоиндикации */
  code?: Maybe<Scalars['String']['output']>;
  /** Идентификатор геоиндикации */
  id: Scalars['Int']['output'];
  /** Признак наличия ссылок на эту геоиндикацию */
  isReferenced: Scalars['Boolean']['output'];
  isUsed: Scalars['Boolean']['output'];
  /** Перевод названия геоиндикации */
  localizedName: Scalars['String']['output'];
  /** Название геоиндикации на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** Типы национальных геоиндкаций */
  nationalGITypes: Array<NationalGiType>;
  /** Родительская геоиндикация */
  parent?: Maybe<GeoIndication>;
  retailCoefficient: Scalars['Float']['output'];
  /** Переводы названия геоиндикации */
  translations: Array<GeoIndicationTranslation>;
  /** Европейский винный регион соответствующий геоиндикации */
  wineRegionEU?: Maybe<WineRegionEu>;
  /**
   * Европейские винные регоины представленные в геоиндикации
   * @deprecated Use wineRegionEU
   */
  wineRegionsEU: Array<WineRegionEu>;
  /** Тип винной территории */
  wineTerritoryType: WineTerritoryType;
  winesAnalyzedCount: Scalars['Int']['output'];
  winesWithoutRRPCount: Scalars['Int']['output'];
};


/** Геоиндикация */
export type GeoIndicationLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type GeoIndicationCondition = {
  __typename?: 'GeoIndicationCondition';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GeoIndicationCreateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  masterName: Scalars['String']['input'];
  nationalGITypeIds: Array<Scalars['Int']['input']>;
  parentGeoIndicationId?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<GeoIndicationTranslationInput>>;
  wineRegionEUIds: Array<Scalars['Int']['input']>;
  wineTerritoryTypeId: Scalars['Int']['input'];
};

export type GeoIndicationCreatePayload = {
  __typename?: 'GeoIndicationCreatePayload';
  query?: Maybe<Query>;
  record: GeoIndication;
  recordId: Scalars['Int']['output'];
};

export type GeoIndicationFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type GeoIndicationFiltersFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type GeoIndicationInstantSearchFilter = {
  nationalGITypeId?: InputMaybe<Scalars['Int']['input']>;
  wineRegionEUId?: InputMaybe<Scalars['Int']['input']>;
};

export type GeoIndicationPagination = {
  __typename?: 'GeoIndicationPagination';
  items: Array<GeoIndication>;
  pageInfo: PageInfo;
};

export enum GeoIndicationSort {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  RetailCoefAsc = 'RETAIL_COEF_ASC',
  RetailCoefDesc = 'RETAIL_COEF_DESC',
  WinesAnalyzedCountAsc = 'WINES_ANALYZED_COUNT_ASC',
  WinesAnalyzedCountDesc = 'WINES_ANALYZED_COUNT_DESC',
  WinesWithoutRrpCountAsc = 'WINES_WITHOUT_RRP_COUNT_ASC',
  WinesWithoutRrpCountDesc = 'WINES_WITHOUT_RRP_COUNT_DESC'
}

export type GeoIndicationTranslation = {
  __typename?: 'GeoIndicationTranslation';
  /** Локаль перевода */
  locale: Locale;
  /** Перевод названия */
  name: Scalars['String']['output'];
};

export type GeoIndicationTranslationInput = {
  localeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type GeoIndicationUpdateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  masterName: Scalars['String']['input'];
  nationalGITypeIds: Array<Scalars['Int']['input']>;
  parentGeoIndicationId?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<GeoIndicationTranslationInput>>;
  wineRegionEUIds: Array<Scalars['Int']['input']>;
  wineTerritoryTypeId: Scalars['Int']['input'];
};

export type GeoIndicationUpdatePayload = {
  __typename?: 'GeoIndicationUpdatePayload';
  query?: Maybe<Query>;
  record: GeoIndication;
  recordId: Scalars['Int']['output'];
};

export type GeoIndicationsDeleteInput = {
  geoIndicationIds: Array<Scalars['Int']['input']>;
};

export type GeoIndicationsDeletePayload = {
  __typename?: 'GeoIndicationsDeletePayload';
  query?: Maybe<Query>;
  /** ID геоиндикаций, которые были удалены */
  recordIds: Array<Scalars['Int']['output']>;
};

export type GeoJsoncrsProperties = GeoJsonLinkedCrsProperties | GeoJsonNamedCrsProperties;

export type GeoJsonCoordinateReferenceSystem = {
  __typename?: 'GeoJSONCoordinateReferenceSystem';
  properties: GeoJsoncrsProperties;
  type: Scalars['String']['output'];
};

export enum GeoJsonCoordinateSystemType {
  Link = 'link',
  Name = 'name'
}

export type GeoJsonFeature = GeoJsonInterface & {
  __typename?: 'GeoJSONFeature';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  geometry?: Maybe<GeoJsonGeometryInterface>;
  id?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSONObject']['output']>;
  type: GeoJsonType;
};

export type GeoJsonFeatureCollection = GeoJsonInterface & {
  __typename?: 'GeoJSONFeatureCollection';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  features: Array<GeoJsonFeature>;
  type: GeoJsonType;
};

export type GeoJsonGeometryCollection = GeoJsonInterface & {
  __typename?: 'GeoJSONGeometryCollection';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  geometries: Array<GeoJsonGeometryInterface>;
  type: GeoJsonType;
};

export type GeoJsonGeometryInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonLineString = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONLineString';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonLinkedCrsProperties = {
  __typename?: 'GeoJSONLinkedCRSProperties';
  href: Scalars['String']['output'];
  type?: Maybe<GeoJsonCoordinateSystemType>;
};

export type GeoJsonMultiLineString = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONMultiLineString';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonMultiPoint = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONMultiPoint';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonMultiPolygon = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONMultiPolygon';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonNamedCrsProperties = {
  __typename?: 'GeoJSONNamedCRSProperties';
  name: Scalars['String']['output'];
};

export type GeoJsonPoint = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONPoint';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export type GeoJsonPolygon = GeoJsonGeometryInterface & GeoJsonInterface & {
  __typename?: 'GeoJSONPolygon';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>;
  crs?: Maybe<GeoJsonCoordinateReferenceSystem>;
  type: GeoJsonType;
};

export enum GeoJsonType {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}

/** Сорт винограда */
export type GrapeVariety = {
  __typename?: 'GrapeVariety';
  grapeVarietyColor: GrapeVarietyColor;
  /** Идентификатор сорта винограда */
  id: Scalars['Int']['output'];
  isPopular: Scalars['Boolean']['output'];
  /** Название сорта винограда */
  masterName: Scalars['String']['output'];
};

/** Цвет винограда */
export type GrapeVarietyColor = {
  __typename?: 'GrapeVarietyColor';
  /** Код цвета винограда */
  code?: Maybe<Scalars['String']['output']>;
  /** Идентификатор цвета винограда */
  id: Scalars['Int']['output'];
  /** Перевод названия цвета винограда */
  localizedName: Scalars['String']['output'];
  /** Название цвета винограда */
  masterName: Scalars['String']['output'];
};


/** Цвет винограда */
export type GrapeVarietyColorLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Употребимое название сорта винограда */
export type GrapeVarietySynonym = {
  __typename?: 'GrapeVarietySynonym';
  /** Сорт винограда */
  grapeVariety: GrapeVariety;
  /** Идентификатор синонима сорта винограда */
  id: Scalars['Int']['output'];
  /** Название сорта винограда */
  name: Scalars['String']['output'];
};

export type GravityInput = {
  position?: InputMaybe<PositionType>;
  xOffset?: InputMaybe<Scalars['Int']['input']>;
  yOffset?: InputMaybe<Scalars['Int']['input']>;
};

export type HiddenCompany = {
  __typename?: 'HiddenCompany';
  /** Категория компании */
  companyCategory: CompanyCategory;
  /** Страна компании */
  country: Country;
  id: Scalars['ID']['output'];
  /** Пользователь представитель данной компании */
  representativeUser?: Maybe<User>;
};

export type Image = {
  __typename?: 'Image';
  /** Описание изображения */
  altText?: Maybe<Scalars['String']['output']>;
  /** Ссылка на изображение с преобразованиями */
  imageUrl: Scalars['URL']['output'];
  /**
   * Ссылка на метаданные об изображении
   * @deprecated Not used
   */
  metadataUrl: Scalars['URL']['output'];
  /**
   * Ссылка на оригинал изображения
   * @deprecated Not used
   */
  originUrl: Scalars['URL']['output'];
};


export type ImageImageUrlArgs = {
  proxy?: Scalars['Boolean']['input'];
  transformations?: InputMaybe<TransformationsInput>;
};

/** Изменение изображения */
export type ImageDiff = {
  __typename?: 'ImageDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<Image>;
  rhs?: Maybe<Image>;
};

export type ImageInput = {
  imageUrl: Scalars['URL']['input'];
};

/** Инкотермс (способ доставки) */
export type Incoterms = {
  __typename?: 'Incoterms';
  /** Код инкотермс */
  code: Scalars['String']['output'];
  /** Идентификатор инкотермс */
  id: Scalars['Int']['output'];
  /** Описание инкотермс */
  localizedLongDescription: Scalars['String']['output'];
  /** Сокращенное описание инкотермс */
  localizedShortDescription: Scalars['String']['output'];
};


/** Инкотермс (способ доставки) */
export type IncotermsLocalizedLongDescriptionArgs = {
  localeId: Scalars['Int']['input'];
};


/** Инкотермс (способ доставки) */
export type IncotermsLocalizedShortDescriptionArgs = {
  localeId: Scalars['Int']['input'];
};

/** Ингредиент */
export type Ingredient = {
  __typename?: 'Ingredient';
  /** Категория ингредиента */
  category: IngredientCategory;
  /** Код ингредиента согласно стандарту */
  code?: Maybe<Scalars['String']['output']>;
  /** Идентификатор ингредиента */
  id: Scalars['ID']['output'];
  /** Является ли ингредиент аллергеном */
  isAllergen: Scalars['Boolean']['output'];
  /** Перевод названия ингредиента */
  localizedName: Scalars['String']['output'];
  /** Название ингредиента на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Ингредиент */
export type IngredientLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Категория ингредиента */
export type IngredientCategory = {
  __typename?: 'IngredientCategory';
  /** Идентификатор категории ингредиента */
  id: Scalars['ID']['output'];
  /** Список ингредиентов в категории */
  ingredients: Array<Ingredient>;
  /** Выводится ли название категории на этикетках */
  isPrintable: Scalars['Boolean']['output'];
  /** Перевод названия аллергена */
  localizedName: Scalars['String']['output'];
};


/** Категория ингредиента */
export type IngredientCategoryLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type IntDiff = {
  __typename?: 'IntDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<Scalars['Int']['output']>;
  rhs?: Maybe<Scalars['Int']['output']>;
};

export type IntForecast = {
  __typename?: 'IntForecast';
  isForecast: Scalars['Boolean']['output'];
  value: Scalars['Float']['output'];
};

export type ItemToOffer = Product | Wine;

export type LegalEntitiesAndBranchesPayload = {
  __typename?: 'LegalEntitiesAndBranchesPayload';
  /** Найденные записи */
  records: Array<Maybe<LegalEntity>>;
};

/** Юрдическое лицо (юрлицо) */
export type LegalEntity = {
  __typename?: 'LegalEntity';
  /** Код деятельности юрлица */
  activityCode?: Maybe<Scalars['String']['output']>;
  /** Регистрационный номер филиала (если это филиал) */
  branchNumber?: Maybe<Scalars['String']['output']>;
  /** Может ли пользователь редактировать запись */
  canEdit: Scalars['Boolean']['output'];
  /** Может ли пользователь редактировать запись */
  canOverrideFacts: Scalars['Boolean']['output'];
  /** Дата ликвидации юрлица */
  closingDate?: Maybe<Scalars['Date']['output']>;
  /** Категория компании соответствующая коду активности */
  companyCategory?: Maybe<CompanyCategory>;
  /** Страна регистрации юрлица */
  country: Country;
  /** Идентификатор юрлица */
  id: Scalars['ID']['output'];
  /** Регистрационный номер основного юрлица */
  identityNumber?: Maybe<Scalars['String']['output']>;
  /** Является ли юридическое лицо действующим */
  isActive: Scalars['Boolean']['output'];
  /** Является ли юридическое лицо головным офисом */
  isHeadOffice?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Not used */
  isOfficialRegistry?: Maybe<Scalars['Boolean']['output']>;
  /** Адрес юридического лица */
  legalAddress?: Maybe<Scalars['String']['output']>;
  /** Форма юридического лица */
  legalEntityForm?: Maybe<LegalEntityForm>;
  /** Название юридического лица без формы */
  legalName?: Maybe<Scalars['String']['output']>;
  /** Официальные торговые наименования юрлица */
  officialTradeNames: Array<TradeName>;
  /** Номер налогоплательщика */
  vat?: Maybe<Scalars['String']['output']>;
};

export type LegalEntityDiff = {
  __typename?: 'LegalEntityDiff';
  activityCode?: Maybe<StringDiff>;
  branchNumber?: Maybe<StringDiff>;
  identityNumber?: Maybe<StringDiff>;
  legalAddress?: Maybe<StringDiff>;
  legalEntityForm?: Maybe<LegalEntityFormDiff>;
  legalName?: Maybe<StringDiff>;
};

export enum LegalEntityDiscriminator {
  /** Регистрационный номер филиала */
  BranchNumber = 'BRANCH_NUMBER',
  /** Регистрационный номер */
  IdentityNumber = 'IDENTITY_NUMBER',
  /** Номер налогоплательщика */
  Vat = 'VAT'
}

/** Форма юридического лица */
export type LegalEntityForm = {
  __typename?: 'LegalEntityForm';
  /** Страна применения формы */
  country?: Maybe<Country>;
  /** Идентификатор юридического лица */
  id: Scalars['Int']['output'];
  /** Используется ли форма */
  isActive: Scalars['Boolean']['output'];
  isFrequentlyUsed: Scalars['Boolean']['output'];
  /** Название формы на языке оригинала */
  originName: Scalars['String']['output'];
  /** Сокращенное название юридической формы */
  shortName?: Maybe<Scalars['String']['output']>;
};

export type LegalEntityFormDiff = {
  __typename?: 'LegalEntityFormDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<LegalEntityForm>;
  rhs?: Maybe<LegalEntityForm>;
};

/** Минимальная информация о юрлице получаемая из официального источника */
export type LegalEntityOfficial = {
  __typename?: 'LegalEntityOfficial';
  legalAddress?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type LegalEntityPagination = {
  __typename?: 'LegalEntityPagination';
  items: Array<LegalEntity>;
  pageInfo: PageInfo;
};

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};


export type LocaleLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Упоминание о вине в интернете */
export type Mention = {
  __typename?: 'Mention';
  authorUser?: Maybe<User>;
  certs: Array<Certification>;
  createdAt: Scalars['DateTime']['output'];
  geoIndicationCondition?: Maybe<GeoIndicationCondition>;
  grapeVarieties: Array<MentionGrapeVariety>;
  id: Scalars['ID']['output'];
  /** Изображения связанные с упоминанием (исходные) */
  images: Array<Image>;
  isNotWine: Scalars['Boolean']['output'];
  modelConfidence?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use mentionWine */
  modelProduct?: Maybe<Product>;
  modelVersion?: Maybe<Scalars['String']['output']>;
  modelWine?: Maybe<MentionWine>;
  product?: Maybe<Product>;
  productImages: Array<ProductImage>;
  productState?: Maybe<MentionProductState>;
  source?: Maybe<Source>;
  sourceInfo: MentionSourceInfo;
  text: Scalars['String']['output'];
  tradeName?: Maybe<TradeName>;
  tradeNameState?: Maybe<MentionTradeNameState>;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['URL']['output']>;
  userWine?: Maybe<MentionWine>;
  verification: MentionVerification;
};


/** Упоминание о вине в интернете */
export type MentionImagesArgs = {
  includeScreenshot?: Scalars['Boolean']['input'];
};

export type MentionGrapeVariety = {
  __typename?: 'MentionGrapeVariety';
  grapeVarietySynonym: GrapeVarietySynonym;
  /** Процентное содержание сорта винограда в вине */
  value?: Maybe<Scalars['Int']['output']>;
};

export type MentionPagination = {
  __typename?: 'MentionPagination';
  items: Array<Mention>;
  pageInfo: PageInfo;
};

export type MentionProduct = {
  __typename?: 'MentionProduct';
  bottleSize: BottleSize;
  packageType: WinePackageType;
};

export enum MentionProductState {
  NotEnoughInfo = 'NOT_ENOUGH_INFO',
  SourceError = 'SOURCE_ERROR',
  UseGoogle = 'USE_GOOGLE'
}

export type MentionSourceInfo = {
  __typename?: 'MentionSourceInfo';
  addressTradeName?: Maybe<Scalars['String']['output']>;
  alcoholOnLabelMax?: Maybe<Scalars['Float']['output']>;
  alcoholOnLabelMin?: Maybe<Scalars['Float']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  communeTradeName?: Maybe<Scalars['String']['output']>;
  elaborateTradeName?: Maybe<Scalars['String']['output']>;
  emailTradeName?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  numberTradeName?: Maybe<Scalars['String']['output']>;
  parTradeName?: Maybe<Scalars['String']['output']>;
  postcodeTradeName?: Maybe<Scalars['String']['output']>;
  pourTradeName?: Maybe<Scalars['String']['output']>;
  proprietaiereTradeName?: Maybe<Scalars['String']['output']>;
  vigneronTradeName?: Maybe<Scalars['String']['output']>;
  websiteTradeName?: Maybe<Scalars['String']['output']>;
  withoutTradeName?: Maybe<Scalars['String']['output']>;
};

export enum MentionTradeNameState {
  NotEnoughInfo = 'NOT_ENOUGH_INFO'
}

export type MentionVerification = {
  __typename?: 'MentionVerification';
  generalInfo: MentionVerificationStep;
  imageManagement: MentionVerificationStep;
  productIdentification: MentionVerificationStep;
  sourceInfo: MentionVerificationStep;
  tradeNameIdentification: MentionVerificationStep;
};

export type MentionVerificationStep = {
  __typename?: 'MentionVerificationStep';
  disabled: Scalars['Boolean']['output'];
  state: MentionVerificationStepState;
};

export enum MentionVerificationStepState {
  Failed = 'FAILED',
  Passed = 'PASSED',
  Pending = 'PENDING'
}

/** Информация извлеченная из упоминания о вине */
export type MentionWine = {
  __typename?: 'MentionWine';
  brand?: Maybe<MentionWineBrand>;
  /** Страна производства */
  country?: Maybe<Country>;
  geoIndicationCondition?: Maybe<GeoIndicationCondition>;
  /** Гео-индикация вина */
  geoIndications: Array<GeoIndication>;
  id?: Maybe<Scalars['ID']['output']>;
  isVintageUnknown?: Maybe<Scalars['Boolean']['output']>;
  /** Ключевые слова при описании вина */
  keyWords?: Maybe<Scalars['String']['output']>;
  /** Национальный тип гео-индикации */
  nationalGIType?: Maybe<NationalGiType>;
  products: Array<MentionProduct>;
  /** Специальные классификации вина */
  specialClassifications: Array<SpecialClassification>;
  /** Уровень содержания сахара */
  sweetnessLevel?: Maybe<SweetnessLevel>;
  tradeName?: Maybe<MentionWineTradeName>;
  vintage?: Maybe<Scalars['PositiveInt']['output']>;
  /** Цвет вина */
  wineColor?: Maybe<WineColor>;
  /** Тип вина */
  wineType?: Maybe<WineType>;
};

export type MentionWineBrand = {
  __typename?: 'MentionWineBrand';
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
};

export type MentionWineTradeName = {
  __typename?: 'MentionWineTradeName';
  country: Country;
  id?: Maybe<Scalars['ID']['output']>;
  tradeName: Scalars['String']['output'];
};

export type Merchant = {
  __typename?: 'Merchant';
  country: Country;
  createdAt: Scalars['DateTime']['output'];
  deliveryTerms?: Maybe<Scalars['String']['output']>;
  deliveryTime?: Maybe<DeliveryTime>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  retailCoefficient: Scalars['Float']['output'];
  status: MerchantStatus;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
  website: Scalars['URL']['output'];
  winesAnalyzedCount: Scalars['Int']['output'];
  winesWithoutRRPCount: Scalars['Int']['output'];
};

export type MerchantGeoIndicationsFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Идентификатор магазина */
  merchantId: Scalars['ID']['input'];
};

export type MerchantPagination = {
  __typename?: 'MerchantPagination';
  items: Array<Merchant>;
  pageInfo: PageInfo;
};

export type MerchantRequestInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  website: Scalars['URL']['input'];
};

export type MerchantRequestPayload = {
  __typename?: 'MerchantRequestPayload';
  query?: Maybe<Query>;
};

export enum MerchantSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  RetailCoefAsc = 'RETAIL_COEF_ASC',
  RetailCoefDesc = 'RETAIL_COEF_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WinesAnalyzedCountAsc = 'WINES_ANALYZED_COUNT_ASC',
  WinesAnalyzedCountDesc = 'WINES_ANALYZED_COUNT_DESC',
  WinesWithoutRrpCountAsc = 'WINES_WITHOUT_RRP_COUNT_ASC',
  WinesWithoutRrpCountDesc = 'WINES_WITHOUT_RRP_COUNT_DESC'
}

export enum MerchantStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type MerchantsPaginationFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  /** Мутации для администратора. Требуют заголовок X-Admin-Key */
  admin: AdminMutations;
  /** Создание конкурса */
  awardCreate: AwardCreatePayload;
  /** Создание награды */
  awardRewardCreate: AwardRewardCreatePayload;
  /** Редактирование награды */
  awardRewardUpdate: AwardRewardUpdatePayload;
  /** Удаление наград */
  awardRewardsDelete: AwardRewardsDeletePayload;
  /** Обновление конкурса */
  awardUpdate: AwardUpdatePayload;
  /** Удаление конкурсов */
  awardsDelete: AwardsDeletePayload;
  /** Признание бренда допустимым */
  brandAccept: BrandAcceptPayload;
  /** Создание идентификатора бренда */
  brandCreate: BrandCreatePayload;
  /** Удаление факта о бренде */
  brandFactDelete: BrandFactDeletePayload;
  /** Перенос факта о бренде к другому бренду */
  brandFactMove: BrandFactMovePayload;
  /** Признание бренда недопустимым */
  brandRefuse: BrandRefusePayload;
  /** Обновление бренда */
  brandUpdate: BrandUpdatePayload;
  /** Изменение email с помощью токена полученного в письме */
  changeEmail: Scalars['Boolean']['output'];
  /** Измение локали текущего пользователя */
  changeLocale?: Maybe<User>;
  /** Изменение настроек уведомлений текущего пользователя и его компаний */
  changeNotificationSettings?: Maybe<User>;
  /** Смена пароля пользователем */
  changePassword: Token;
  /** Изменение системы оценки пользователя */
  changeScoreSystem?: Maybe<User>;
  /** Мутации для управления контентом. Требует заголовок X-CMS-Key */
  cms: CmsMutations;
  /**
   * Изменение валюты покупки
   * @deprecated Not used, use page settings
   */
  companyChangeBuyCurrency: CompanyChangeBuyCurrencyPayload;
  companyChangePageCurrency: CompanyChangePageCurrencyPayload;
  companyChangePageMerchant: CompanyChangePageMerchantPayload;
  /** Создание компании для аутентифицированного пользователя */
  companyCreate: CompanyCreatePayload;
  companyCreateFromExternal: CompanyCreateFromExternalPayload;
  /** Выдача разрешения на чтение данных компании */
  companyGrantInfo: CompanyGrantInfoPayload;
  /** Удаление компании аутентифицированного пользователя */
  companyRemove: CompanyRemovePayload;
  /** Удаление разрешения на чтение данных компании */
  companyRevokeInfo: CompanyRevokeInfoPayload;
  /** Отправка email для верификации компании */
  companySendVerificationEmail?: Maybe<Query>;
  /** Отправка email для верификации верифицированному представителю компании */
  companySendVerificationEmailToRepresenative?: Maybe<Query>;
  /** Успешное завершение тура пользователем */
  companyTourComplete: CompanyTourCompletePayload;
  /** Пропуск тура по системе */
  companyTourSkip: CompanyTourSkipPayload;
  /** Обновление существующей компании аутентифицированного пользователя */
  companyUpdate: CompanyUpdatePayload;
  /** Подтверждение верификации компании контент-менеджером */
  companyVerificationAccept?: Maybe<CompanyVerificationAcceptPayload>;
  /** Разрешение на верификацию компании от контент-менеджера */
  companyVerificationAllow?: Maybe<CompanyVerificationAllowPayload>;
  /** Запрет на верификацию компании контент-менеджером */
  companyVerificationProhibit?: Maybe<CompanyVerificationProhibitPayload>;
  /** Отказ в верификации компании контент-менеджером */
  companyVerificationReject?: Maybe<CompanyVerificationRejectPayload>;
  /** Отправка запроса для верификации контент-менеджеру */
  companyVerificationRequest?: Maybe<CompanyVerificationRequestPayload>;
  /** Верификация компании по токену из email */
  companyVerifyByEmail?: Maybe<Query>;
  /** Подтверждение почты пользователя */
  confirmMail: Token;
  /** Создание выставки */
  exhibitionCreate: ExhibitionCreatePayload;
  /** Обновление выставки */
  exhibitionUpdate: ExhibitionUpdatePayload;
  /** Удаление выставок */
  exhibitionsDelete: ExhibitionsDeletePayload;
  /** Удаление пресета фильтров */
  filterPresetDelete: FilterPresetDeletePayload;
  /** Отметка пресета фильтров как избранного */
  filterPresetFavorite: FilterPresetFavoritePayload;
  /** Сохранение пресета фильтров */
  filterPresetSave: FilterPresetSavePayload;
  /** Создание геоиндикации */
  geoIndicationCreate: GeoIndicationCreatePayload;
  /** Редактирование геоиндикации */
  geoIndicationUpdate: GeoIndicationUpdatePayload;
  /** Удаление геоиндикаций */
  geoIndicationsDelete: GeoIndicationsDeletePayload;
  merchantRequest: MerchantRequestPayload;
  /** Признание продукта допустимым */
  productAccept: ProductAcceptPayload;
  /** Создание идентификатора продукта */
  productCreate: ProductCreatePayload;
  /** Создание продукта из всех параметров продукта */
  productCreateExpanded: ProductCreateExpandedPayload;
  /** Создание электронной этикетки на основе продукта */
  productELabelCreate: ProductELabelCreatePayload;
  /** Активация оплаты подписки на электронные этикетки */
  productELabelSubscriptionActivate: ProductELabelSubscriptionActivatePayload;
  /** Получение ссылки на customer portal */
  productELabelSubscriptionPortalCreate: Scalars['URL']['output'];
  /** Редактирование электронной этикетки */
  productELabelUpdate: ProductELabelUpdatePayload;
  /** Удаление электоктронных этикеток */
  productELabelsDelete: ProductELabelsDeletePayload;
  /** Публикация электронных этикеток */
  productELabelsPublish: ProductELabelsPublishPayload;
  /** Отметка электронных этикеток как черновика */
  productELabelsUnpublish: ProductELabelsUnpublishPayload;
  /** Удаление факта о продукте */
  productFactDelete: ProductFactDeletePayload;
  /** Перенос факта о продукте */
  productFactMove: ProductFactMovePayload;
  /** Обновление изображений продукта */
  productImagesUpdate: ProductImagesUpdatePayload;
  /** Обновление информации о вине и продукте доступными значениями */
  productPatch: ProductPatchPayload;
  /** Мутация для завершения процесса поиска продукта по изображениям */
  productRecognitionComplete: ProductRecognitionCompletePayload;
  /** Мутация для ручного распознания продукта на изображении саппортом */
  productRecognitionResultSave: ProductRecognitionResultSavePayload;
  /** Признание продукта недопустимым */
  productRefuse: ProductRefusePayload;
  /** Обновление информации о продукте */
  productUpdate: ProductUpdatePayload;
  /** Отметка о посещении пользователем страницы с продуктом */
  productVisit?: Maybe<Query>;
  /** Добавление нескольких продуктов в шортлист */
  productsAddToShortlist: ProductsAddToShortlistPayload;
  /** Выгрузка продуктов торгового наименования */
  productsExport: ProductsExportPayload;
  /** Удаление продуктов из шортлиста */
  productsRemoveFromShortlist: ProductsRemoveFromShortlistPayload;
  /** Получение нового access токена пользователя */
  refreshToken: Token;
  /** Смена пароля с указанием токена из письма */
  resetPassword: Scalars['Boolean']['output'];
  /** Отправка письма для смены email */
  sendChangeEmailEmail: Scalars['Boolean']['output'];
  /**
   * Отправка письма с восстановлением пароля.
   * К указанной почте должен быть привязан пользователь, если такого пользователя в системе нет - будет возвращена ошибка.
   */
  sendConfirmEmailEmail: Scalars['Boolean']['output'];
  /**
   * Отправка письма с восстановлением пароля.
   * К указанной почте должен быть привязан пользователь, если такого пользователя в системе нет - будет возвращена ошибка.
   */
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  /** Вход пользователя по паролю */
  signIn: Token;
  /** Вход пользователя с помощью Apple */
  signInWithApple: Token;
  /** Вход пользователя с помощью Facebook */
  signInWithFacebook: Token;
  /** Вход пользователя с помощью Google */
  signInWithGoogle: Token;
  /** Самостоятельная регистрация пользователя по почте с последующим подтверждением почты */
  signUp?: Maybe<Token>;
  /** Запись на бесплатное добавление вин в базу */
  signUpFreeWineListing: Scalars['Boolean']['output'];
  /** Регистрация по приглашению */
  signUpInvitation?: Maybe<Token>;
  /** Удаление факта о техлисте */
  techSheetFactDelete: TechSheetFactDeletePayload;
  /** Перенос факта о техлисте */
  techSheetFactMove: TechSheetFactMovePayload;
  /** Сохранение техлиста */
  techSheetSave: TechSheetPayload;
  /** Признание торгового наименования допустимым */
  tradeNameAccept: TradeNameAcceptPayload;
  /** Удаление записи о допустимости */
  tradeNameAcceptabilityDelete: TradeNameAcceptabilityDeletePayload;
  /** Создание идентификатора торгового наименования */
  tradeNameCreate: TradeNameCreatePayload;
  /** Удаление факта о торговом наименовании */
  tradeNameFactDelete: TradeNameFactDeletePayload;
  /** Перенос факта о торговом наименовании */
  tradeNameFactMove: TradeNameFactMovePayload;
  /** Признание торгового наименования недопустимым */
  tradeNameRefuse: TradeNameRefusePayload;
  /** Обновление торгового наименования */
  tradeNameUpdate: TradeNameUpdatePayload;
  /** Добавление лота в список сравнения */
  tradeOfferLotCompareAdd: TradeOfferLotCompareAddPayload;
  /** Удаления лота из списка сравнения */
  tradeOfferLotCompareRemove: TradeOfferLotCompareRemovePayload;
  /** Запрос на добавление цены в базу данных */
  tradeOfferRequest: TradeOfferRequestPayload;
  /** Получение ссылки для загрузки файла */
  uploadUrl: Scalars['String']['output'];
  userChangePageCompanyCategory: UserChangePageCompanyCategoryPayload;
  userChangePageCurrency: UserChangePageCurrencyPayload;
  userChangePagePostCountry: UserChangePagePostCountryPayload;
  /** Удаление пользователя */
  userDelete: UserDeletePayload;
  /** Скрыть диалог для использования системы без выбора компании */
  userHideBecomeRepresentative: User;
  /** Приглашение пользователя */
  userInvite: UserInvitePayload;
  userTutorialComplete: UserTutorialCompletedPayload;
  /** Редактирование информации о пользователе */
  userUpdate: User;
  /** Проверка токена пользователя (для отладки) */
  validateToken: Scalars['Boolean']['output'];
  /** Добавление склада компании */
  warehouseCreate?: Maybe<WarehouseCreatePayload>;
  /** Обновление склада компании (полное обновление) */
  warehouseUpdate?: Maybe<WarehouseUpdatePayload>;
  /** Удаление нескольких складов компании */
  warehousesDelete?: Maybe<WarehousesDeletePayload>;
  /** Подтверждение идентификатора вина */
  wineAccept: WineAcceptPayload;
  /** Удание факта об участии в конкурсе */
  wineAwardFactDelete: WineAwardFactDeletePayload;
  /** Перенос факта об участии в конкурсе */
  wineAwardFactMove: WineAwardFactMovePayload;
  /** Обновление информации об участии вина в конкурсах */
  wineAwardsUpdate: WineAwardsUpdatePayload;
  /** Создание идентификатора вина в определенном кабинете компании */
  wineCreate: WineCreatePayload;
  /** Удаление факта о вине */
  wineFactDelete: WineFactDeletePayload;
  /** Перенос факта о вине */
  wineFactMove: WineFactMovePayload;
  /** Обновление ключевых слов вина */
  wineKeyWordsUpdate: WineKeyWordsUpdatePayload;
  wineOfferBuyerComment: WineOfferBuyerCommentPayload;
  /** Отправить условия покупателя продавцу. Доступно только для переговоров в статусе NEGOTIATIONS или SELLER_RESPONSE */
  wineOfferBuyerOfferConditions: WineOfferBuyerOfferConditionsPayload;
  /** Редактирование черновика коммерческого предложения для определенной компании */
  wineOfferCompanyDraftUpdate: WineOfferCompanyDraftUpdatePayload;
  /** Редактирование коммерческого предложения для определенной компании */
  wineOfferCompanyUpdate: WineOfferCompanyUpdatePayload;
  /** Создать завершившуюся сделку, которая была проведена за системой */
  wineOfferDealCreate: WineOfferDealCreatePayload;
  /** Редактирование черновика публичного коммерческого предложения */
  wineOfferPublicDraftUpdate: WineOfferPublicDraftUpdatePayload;
  /** Редактирование публичного коммерческого предложения */
  wineOfferPublicUpdate: WineOfferPublicUpdatePayload;
  /** Создание запроса на коммерческое предложение */
  wineOfferRequestCreate: WineOfferRequestCreatePayload;
  /** Удаление запроса на коммерческое предложение */
  wineOfferRequestDelete?: Maybe<WineOfferRequestDeletePayload>;
  /** Создание черновика запроса на коммерческое предложение */
  wineOfferRequestDraftCreate: WineOfferRequestDraftCreatePayload;
  /** Обновление запроса и снятие с него признака запроса */
  wineOfferRequestDraftPublish: WineOfferRequestDraftPublishPayload;
  /** Редактирование черновика запроса на коммерческое предложение */
  wineOfferRequestDraftUpdate: WineOfferRequestDraftUpdatePayload;
  /** Редактирование даты действия запроса */
  wineOfferRequestUpdatePeriodOfValidity?: Maybe<WineOfferRequestUpdatePeriodOfValidityPayload>;
  wineOfferRequestsComplete: WineOfferRequestsCompletePayload;
  wineOfferSellerComment: WineOfferSellerCommentPayload;
  /** Отправить условия продавца покупателю. Доступно только для переговоров в статусе BUYER_CONDITIONS */
  wineOfferSellerOfferConditions: WineOfferSellerOfferConditionsPayload;
  /** Обновить дату действия принятого коммерческого предложения */
  wineOfferSellerUpdatePeriodOfValidity: WineOfferSellerUpdatePeriodOfValidityPayload;
  /** Принятие последних условий коммерческого предложения противоположной стороны */
  wineOffersAccept: WineOffersAcceptPayload;
  /** Добавление нескольких коммерческих предложений в шортлист */
  wineOffersAddToShortlist: WineOffersAddToShortlistPayload;
  /** Отправить коммерческое предложение в архив */
  wineOffersArchive: WineOffersArchivePayload;
  /** Скрыть КП из архива */
  wineOffersArchiveHide: WineOffersArchiveHidePayload;
  /** Создание нескольких коммерческих предложений для компаний */
  wineOffersCompanyCreate: WineOffersCompanyCreatePayload;
  /** Создание черновиков коммерческих предложений для компаний */
  wineOffersCompanyDraftCreate: WineOffersCompanyDraftCreatePayload;
  /** Удалить черновик коммерческого предложения */
  wineOffersDelete: WineOffersDeletePayload;
  /** Отметить коммерческое предложение как прочитанное */
  wineOffersMarkAsRead: WineOffersMarkAsReadPayload;
  /** Отметить коммерческое предложение как непрочитанное */
  wineOffersMarkAsUnread: WineOffersMarkAsUnreadPayload;
  /** Создание нескольких публичных коммерческих предложений */
  wineOffersPublicCreate: WineOffersPublicCreatePayload;
  /** Создание черновиков публичных коммерческих предложений */
  wineOffersPublicDraftCreate: WineOffersPublicDraftCreatePayload;
  /** Опубликовать черновик коммерческого предложения (публичного или для определенной компании) */
  wineOffersPublish: WineOffersPublishPayload;
  /** Отклонение коммерческого предложения (покупателем или продавцом) */
  wineOffersReject: WineOffersRejectPayload;
  /** Удаление коммерческого предложения из шортлиста */
  wineOffersRemoveFromShortlist: WineOffersRemoveFromShortlistPayload;
  /** Запосить у покупателя контакты по данным КП */
  wineOffersRequestBuyerContacts: WineOffersRequestBuyerContactsPayload;
  /** Сделать коммерческое предложение черновиком */
  wineOffersUnpublish: WineOffersUnpublishPayload;
  /** Обновить дату действия публичных или офферов для компании */
  wineOffersUpdatePeriodOfValidity: WineOffersUpdatePeriodOfValidityPayload;
  wineOverwrite: WineOverwritePayload;
  /** Обновление информации сразу о нескольких продуктах */
  wineProductsUpdate: WineProductsUpdatePayload;
  /** Признание вина недопустимым */
  wineRefuse: WineRefusePayload;
  /** Создание отзыва на продукт */
  wineReviewCreate: WineReviewCreatePayload;
  /** Удаление отзыва на продукт */
  wineReviewDelete: WineReviewDeletePayload;
  /** Создание черновика отзыва */
  wineReviewDraftCreate: WineReviewDraftCreatePayload;
  /** Редактирование черновика отзыва */
  wineReviewDraftUpdate: WineReviewDraftUpdatePayload;
  /** Оставить заявку на написание отзыва */
  wineReviewRequest: WineReviewRequestPayload;
  /** Редактирование отзыва на продукт */
  wineReviewUpdate: WineReviewUpdatePayload;
  /** Опубликовать черновики отзывов */
  wineReviewsPublish: WineReviewsPublishPayload;
  /** Сделать отзывы приватными */
  wineReviewsSetPrivate: WineReviewsSetPrivatePayload;
  /** Сделать отзывы публичными */
  wineReviewsSetPublic: WineReviewsSetPublicPayload;
  /** Обновление информации о вине без возможности изменения идентифицирующих полей */
  wineUpdate: WineUpdatePayload;
};


export type MutationAwardCreateArgs = {
  input: AwardCreateInput;
};


export type MutationAwardRewardCreateArgs = {
  input: AwardRewardCreateInput;
};


export type MutationAwardRewardUpdateArgs = {
  input: AwardRewardUpdateInput;
};


export type MutationAwardRewardsDeleteArgs = {
  input: AwardRewardsDeleteInput;
};


export type MutationAwardUpdateArgs = {
  input: AwardUpdateInput;
};


export type MutationAwardsDeleteArgs = {
  input: AwardsDeleteInput;
};


export type MutationBrandAcceptArgs = {
  input: BrandAcceptInput;
};


export type MutationBrandCreateArgs = {
  input: BrandCreateInput;
};


export type MutationBrandFactDeleteArgs = {
  input: BrandFactDeleteInput;
};


export type MutationBrandFactMoveArgs = {
  input: BrandFactMoveInput;
};


export type MutationBrandRefuseArgs = {
  input: BrandRefuseInput;
};


export type MutationBrandUpdateArgs = {
  input: BrandUpdateInput;
};


export type MutationChangeEmailArgs = {
  changeEmailToken: Scalars['String']['input'];
};


export type MutationChangeLocaleArgs = {
  localeId: Scalars['Int']['input'];
};


export type MutationChangeNotificationSettingsArgs = {
  companySettings?: InputMaybe<Array<CompanyNotificationSettingsInput>>;
  userSettings?: InputMaybe<UserNotificationSettingsInput>;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationChangeScoreSystemArgs = {
  scoreSystemId: Scalars['Int']['input'];
};


export type MutationCompanyChangeBuyCurrencyArgs = {
  input: CompanyChangeBuyCurrencyInput;
};


export type MutationCompanyChangePageCurrencyArgs = {
  input: CompanyChangePageCurrencyInput;
};


export type MutationCompanyChangePageMerchantArgs = {
  input: CompanyChangePageMerchantInput;
};


export type MutationCompanyCreateArgs = {
  input: CompanyCreateInput;
};


export type MutationCompanyCreateFromExternalArgs = {
  input: CompanyCreateFromExternalInput;
};


export type MutationCompanyGrantInfoArgs = {
  input: CompanyGrantInfoInput;
};


export type MutationCompanyRemoveArgs = {
  input: CompanyRemoveInput;
};


export type MutationCompanyRevokeInfoArgs = {
  input: CompanyRevokeInfoInput;
};


export type MutationCompanySendVerificationEmailArgs = {
  input: CompanySendVerificationEmailInput;
};


export type MutationCompanySendVerificationEmailToRepresenativeArgs = {
  input: CompanySendVerificationEmailToRepresenativeInput;
};


export type MutationCompanyTourCompleteArgs = {
  input: CompanyTourCompleteInput;
};


export type MutationCompanyTourSkipArgs = {
  input: CompanyTourSkipInput;
};


export type MutationCompanyUpdateArgs = {
  input: CompanyUpdateInput;
};


export type MutationCompanyVerificationAcceptArgs = {
  input: CompanyVerificationAcceptInput;
};


export type MutationCompanyVerificationAllowArgs = {
  input: CompanyVerificationAllowInput;
};


export type MutationCompanyVerificationProhibitArgs = {
  input: CompanyVerificationProhibitInput;
};


export type MutationCompanyVerificationRejectArgs = {
  input: CompanyVerificationRejectInput;
};


export type MutationCompanyVerificationRequestArgs = {
  input: CompanyVerificationRequestInput;
};


export type MutationCompanyVerifyByEmailArgs = {
  localeId?: InputMaybe<Scalars['Int']['input']>;
  verifyToken: Scalars['String']['input'];
};


export type MutationConfirmMailArgs = {
  confirmToken: Scalars['String']['input'];
};


export type MutationExhibitionCreateArgs = {
  input: ExhibitionCreateInput;
};


export type MutationExhibitionUpdateArgs = {
  input: ExhibitionUpdateInput;
};


export type MutationExhibitionsDeleteArgs = {
  input: ExhibitionsDeleteInput;
};


export type MutationFilterPresetDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFilterPresetFavoriteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFilterPresetSaveArgs = {
  input: FilterPresetSaveInput;
};


export type MutationGeoIndicationCreateArgs = {
  input: GeoIndicationCreateInput;
};


export type MutationGeoIndicationUpdateArgs = {
  input: GeoIndicationUpdateInput;
};


export type MutationGeoIndicationsDeleteArgs = {
  input: GeoIndicationsDeleteInput;
};


export type MutationMerchantRequestArgs = {
  input: MerchantRequestInput;
};


export type MutationProductAcceptArgs = {
  input: ProductAcceptInput;
};


export type MutationProductCreateArgs = {
  input: ProductCreateInput;
};


export type MutationProductCreateExpandedArgs = {
  input: ProductCreateExpandedInput;
};


export type MutationProductELabelCreateArgs = {
  input: ProductELabelCreateInput;
};


export type MutationProductELabelSubscriptionActivateArgs = {
  input: ProductElabelSubscriptionActivateInput;
};


export type MutationProductELabelSubscriptionPortalCreateArgs = {
  input: ProductELabelSubscriptionPortalCreateInput;
};


export type MutationProductELabelUpdateArgs = {
  input: ProductELabelUpdateInput;
};


export type MutationProductELabelsDeleteArgs = {
  input: ProductELabelsDeleteInput;
};


export type MutationProductELabelsPublishArgs = {
  input: ProductELabelsPublishInput;
};


export type MutationProductELabelsUnpublishArgs = {
  input: ProductELabelsUnpublishInput;
};


export type MutationProductFactDeleteArgs = {
  input: ProductFactDeleteInput;
};


export type MutationProductFactMoveArgs = {
  input: ProductFactMoveInput;
};


export type MutationProductImagesUpdateArgs = {
  input: ProductImagesUpdateInput;
};


export type MutationProductPatchArgs = {
  input: ProductPatchInput;
};


export type MutationProductRecognitionCompleteArgs = {
  input: ProductRecognitionCompleteInput;
};


export type MutationProductRecognitionResultSaveArgs = {
  input: ProductRecognitionResultSaveInput;
};


export type MutationProductRefuseArgs = {
  input: ProductRefuseInput;
};


export type MutationProductUpdateArgs = {
  input: ProductUpdateInput;
};


export type MutationProductVisitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type MutationProductsAddToShortlistArgs = {
  input: ProductsAddToShortlistInput;
};


export type MutationProductsExportArgs = {
  input: ProductsExportInput;
};


export type MutationProductsRemoveFromShortlistArgs = {
  input: ProductsRemoveFromShortlistInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};


export type MutationSendChangeEmailEmailArgs = {
  email: Scalars['EmailAddress']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSendConfirmEmailEmailArgs = {
  email: Scalars['EmailAddress']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['EmailAddress']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSignInArgs = {
  email: Scalars['EmailAddress']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
};


export type MutationSignInWithAppleArgs = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  isMobile?: Scalars['Boolean']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  namePrefix?: InputMaybe<Scalars['String']['input']>;
  nameSuffix?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignInWithFacebookArgs = {
  accessToken: Scalars['String']['input'];
  isMobile?: Scalars['Boolean']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSignInWithGoogleArgs = {
  authCode?: InputMaybe<Scalars['String']['input']>;
  idToken?: InputMaybe<Scalars['String']['input']>;
  isMobile?: Scalars['Boolean']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSignUpArgs = {
  email: Scalars['EmailAddress']['input'];
  isMobile?: Scalars['Boolean']['input'];
  localeId?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};


export type MutationSignUpFreeWineListingArgs = {
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignUpInvitationArgs = {
  email: Scalars['EmailAddress']['input'];
  inviteToken: Scalars['String']['input'];
  localeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationTechSheetFactDeleteArgs = {
  input: TechSheetFactDeleteInput;
};


export type MutationTechSheetFactMoveArgs = {
  input: TechSheetFactMoveInput;
};


export type MutationTechSheetSaveArgs = {
  input: TechSheetSaveInput;
};


export type MutationTradeNameAcceptArgs = {
  input: TradeNameAcceptInput;
};


export type MutationTradeNameAcceptabilityDeleteArgs = {
  input: TradeNameAcceptabilityDeleteInput;
};


export type MutationTradeNameCreateArgs = {
  input: TradeNameCreateInput;
};


export type MutationTradeNameFactDeleteArgs = {
  input: TradeNameFactDeleteInput;
};


export type MutationTradeNameFactMoveArgs = {
  input: TradeNameFactMoveInput;
};


export type MutationTradeNameRefuseArgs = {
  input: TradeNameRefuseInput;
};


export type MutationTradeNameUpdateArgs = {
  input: TradeNameUpdateInput;
};


export type MutationTradeOfferLotCompareAddArgs = {
  input: TradeOfferLotCompareAddInput;
};


export type MutationTradeOfferLotCompareRemoveArgs = {
  input: TradeOfferLotCompareRemoveInput;
};


export type MutationTradeOfferRequestArgs = {
  input: TradeOfferRequestInput;
};


export type MutationUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


export type MutationUserChangePageCompanyCategoryArgs = {
  input: UserChangePageCompanyCategoryInput;
};


export type MutationUserChangePageCurrencyArgs = {
  input: UserChangePageCurrencyInput;
};


export type MutationUserChangePagePostCountryArgs = {
  input: UserChangePagePostCountryInput;
};


export type MutationUserInviteArgs = {
  input: UserInviteInput;
};


export type MutationUserTutorialCompleteArgs = {
  page: Scalars['String']['input'];
};


export type MutationUserUpdateArgs = {
  input: UserInput;
};


export type MutationValidateTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationWarehouseCreateArgs = {
  input: WarehouseCreateInput;
};


export type MutationWarehouseUpdateArgs = {
  input: WarehouseUpdateInput;
};


export type MutationWarehousesDeleteArgs = {
  input: WarehousesDeleteInput;
};


export type MutationWineAcceptArgs = {
  input: WineAcceptInput;
};


export type MutationWineAwardFactDeleteArgs = {
  input: WineAwardFactDeleteInput;
};


export type MutationWineAwardFactMoveArgs = {
  input: WineAwardFactMoveInput;
};


export type MutationWineAwardsUpdateArgs = {
  input: WineAwardsUpdateInput;
};


export type MutationWineCreateArgs = {
  input: WineCreateInput;
};


export type MutationWineFactDeleteArgs = {
  input: WineFactDeleteInput;
};


export type MutationWineFactMoveArgs = {
  input: WineFactMoveInput;
};


export type MutationWineKeyWordsUpdateArgs = {
  input: WineKeyWordsUpdateInput;
};


export type MutationWineOfferBuyerCommentArgs = {
  input: WineOfferBuyerCommentInput;
};


export type MutationWineOfferBuyerOfferConditionsArgs = {
  input: WineOfferBuyerOfferConditionsInput;
};


export type MutationWineOfferCompanyDraftUpdateArgs = {
  input: WineOfferCompanyDraftUpdateInput;
};


export type MutationWineOfferCompanyUpdateArgs = {
  input: WineOfferCompanyUpdateInput;
};


export type MutationWineOfferDealCreateArgs = {
  input: WineOfferDealCreateInput;
};


export type MutationWineOfferPublicDraftUpdateArgs = {
  input: WineOfferPublicDraftUpdateInput;
};


export type MutationWineOfferPublicUpdateArgs = {
  input: WineOfferPublicUpdateInput;
};


export type MutationWineOfferRequestCreateArgs = {
  input: WineOfferRequestCreateInput;
};


export type MutationWineOfferRequestDeleteArgs = {
  input: WineOfferRequestDeleteInput;
};


export type MutationWineOfferRequestDraftCreateArgs = {
  input: WineOfferRequestDraftCreateInput;
};


export type MutationWineOfferRequestDraftPublishArgs = {
  input: WineOfferRequestDraftPublishInput;
};


export type MutationWineOfferRequestDraftUpdateArgs = {
  input: WineOfferRequestDraftUpdateInput;
};


export type MutationWineOfferRequestUpdatePeriodOfValidityArgs = {
  input: WineOfferRequestUpdatePeriodOfValidityInput;
};


export type MutationWineOfferRequestsCompleteArgs = {
  input: WineOfferRequestsCompleteInput;
};


export type MutationWineOfferSellerCommentArgs = {
  input: WineOfferSellerCommentInput;
};


export type MutationWineOfferSellerOfferConditionsArgs = {
  input: WineOfferSellerOfferConditionsInput;
};


export type MutationWineOfferSellerUpdatePeriodOfValidityArgs = {
  input: WineOfferSellerUpdatePeriodOfValidityInput;
};


export type MutationWineOffersAcceptArgs = {
  input: WineOffersAcceptInput;
};


export type MutationWineOffersAddToShortlistArgs = {
  input: WineOffersAddToShortlistInput;
};


export type MutationWineOffersArchiveArgs = {
  input: WineOffersArchiveInput;
};


export type MutationWineOffersArchiveHideArgs = {
  input: WineOffersArchiveHideInput;
};


export type MutationWineOffersCompanyCreateArgs = {
  input: WineOffersCompanyCreateInput;
};


export type MutationWineOffersCompanyDraftCreateArgs = {
  input: WineOffersCompanyDraftCreateInput;
};


export type MutationWineOffersDeleteArgs = {
  input: WineOffersDeleteInput;
};


export type MutationWineOffersMarkAsReadArgs = {
  input: WineOffersMarkAsReadInput;
};


export type MutationWineOffersMarkAsUnreadArgs = {
  input: WineOffersMarkAsUnreadInput;
};


export type MutationWineOffersPublicCreateArgs = {
  input: WineOffersPublicCreateInput;
};


export type MutationWineOffersPublicDraftCreateArgs = {
  input: WineOffersPublicDraftCreateInput;
};


export type MutationWineOffersPublishArgs = {
  input: WineOffersPublishInput;
};


export type MutationWineOffersRejectArgs = {
  input: WineOffersRejectInput;
};


export type MutationWineOffersRemoveFromShortlistArgs = {
  input: WineOffersRemoveFromShortlistInput;
};


export type MutationWineOffersRequestBuyerContactsArgs = {
  input: WineOffersRequestBuyerContactsInput;
};


export type MutationWineOffersUnpublishArgs = {
  input: WineOffersUnpublishInput;
};


export type MutationWineOffersUpdatePeriodOfValidityArgs = {
  input: WineOffersUpdatePeriodOfValidityInput;
};


export type MutationWineOverwriteArgs = {
  input: WineOverwriteInput;
};


export type MutationWineProductsUpdateArgs = {
  input: WineProductsUpdateInput;
};


export type MutationWineRefuseArgs = {
  input: WineRefuseInput;
};


export type MutationWineReviewCreateArgs = {
  input: WineReviewCreateInput;
};


export type MutationWineReviewDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationWineReviewDraftCreateArgs = {
  input: WineReviewDraftCreateInput;
};


export type MutationWineReviewDraftUpdateArgs = {
  input: WineReviewDraftUpdateInput;
};


export type MutationWineReviewRequestArgs = {
  input: WineReviewRequestInput;
};


export type MutationWineReviewUpdateArgs = {
  input: WineReviewUpdateInput;
};


export type MutationWineReviewsPublishArgs = {
  input: WineReviewsPublishInput;
};


export type MutationWineReviewsSetPrivateArgs = {
  input: WineReviewsSetPrivateInput;
};


export type MutationWineReviewsSetPublicArgs = {
  input: WineReviewsSetPublicInput;
};


export type MutationWineUpdateArgs = {
  input: WineUpdateInput;
};

/** Тип национальной геоиндикации */
export type NationalGiType = {
  __typename?: 'NationalGIType';
  /** Страна в которой используется тип национальной геоиндикации */
  country: Country;
  /** Европейский тип геоиндикации */
  euGIType: EugiType;
  /** Название типа */
  fullName: Scalars['String']['output'];
  /** Геоиндикации */
  geoIndications: Array<GeoIndication>;
  /** Идентификатор типа национальной геоиндикации */
  id: Scalars['Int']['output'];
  /** Перевод названия типа геоиндикации */
  localizedFullName: Scalars['String']['output'];
  /** @deprecated Use localizedShortName */
  localizedName: Scalars['String']['output'];
  /** Перевод сокращенного названия типа геоиндикации */
  localizedShortName: Scalars['String']['output'];
  /** Сокращенное название типа */
  shortName: Scalars['String']['output'];
};


/** Тип национальной геоиндикации */
export type NationalGiTypeGeoIndicationsArgs = {
  localeId?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Тип национальной геоиндикации */
export type NationalGiTypeLocalizedFullNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Тип национальной геоиндикации */
export type NationalGiTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Тип национальной геоиндикации */
export type NationalGiTypeLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Направление сортировки */
export enum Order {
  /** По возрастанию значений */
  Asc = 'ASC',
  /** По убыванию значений */
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** Есть ли данные на следующей странице */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли данные на предыдущей странице */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер текущей страницы (начиная с 1) */
  page: Scalars['Int']['output'];
  /** Количество элементво на странице */
  perPage: Scalars['Int']['output'];
  /** Общее количество элементов */
  totalItems: Scalars['Int']['output'];
  /** Общее количество страниц */
  totalPages: Scalars['Int']['output'];
};

/** Изменение телефонного номера */
export type PhoneNumberDiff = {
  __typename?: 'PhoneNumberDiff';
  /** Совпадает ли новое значение с текущим */
  current: Scalars['Boolean']['output'];
  /** Старое значение номера телефона */
  lhs?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Новое значение номера телефона */
  rhs?: Maybe<Scalars['PhoneNumber']['output']>;
};

export enum PositionType {
  /** center */
  Ce = 'ce',
  /** east (right edge) */
  Ea = 'ea',
  /** north (top edge) */
  No = 'no',
  /** north-east (top-right corner) */
  Noea = 'noea',
  /** north-west (top-left corner) */
  Nowe = 'nowe',
  /** repeat */
  Re = 're',
  /** south (bottom edge) */
  So = 'so',
  /** south-east (bottom-right corner) */
  Soea = 'soea',
  /** south-west (bottom-left corner) */
  Sowe = 'sowe',
  /** west (left edge) */
  We = 'we'
}

/** Цена и валюта */
export type PriceCurrency = {
  __typename?: 'PriceCurrency';
  currency: Currency;
  price: Scalars['Float']['output'];
};


/** Цена и валюта */
export type PriceCurrencyPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};

export type PriceCurrencyDiff = {
  __typename?: 'PriceCurrencyDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<PriceCurrency>;
  rhs?: Maybe<PriceCurrency>;
};

export type PriceCurrencyForecast = {
  __typename?: 'PriceCurrencyForecast';
  currency: Currency;
  isForecast: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};


export type PriceCurrencyForecastPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};

export type PriceCurrencyRange = {
  __typename?: 'PriceCurrencyRange';
  max?: Maybe<PriceCurrency>;
  min?: Maybe<PriceCurrency>;
};

export enum PriceRecurringInterval {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type PriceSource = {
  __typename?: 'PriceSource';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Статистика по последней загрузке */
  lastUpload: PriceSourceUploadStat;
  merchant: Merchant;
  refreshRate?: Maybe<PriceSourceRefreshRate>;
  sourceURL: Scalars['URL']['output'];
  status: PriceSourceStatus;
  type: PriceSourceType;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PriceSourcePagination = {
  __typename?: 'PriceSourcePagination';
  items: Array<PriceSource>;
  pageInfo: PageInfo;
};

export type PriceSourceRefreshRate = {
  __typename?: 'PriceSourceRefreshRate';
  id: Scalars['ID']['output'];
  localizedName: Scalars['String']['output'];
};


export type PriceSourceRefreshRateLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export enum PriceSourceSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MerchantNameAsc = 'MERCHANT_NAME_ASC',
  MerchantNameDesc = 'MERCHANT_NAME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum PriceSourceStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Error = 'ERROR',
  Paused = 'PAUSED',
  Processing = 'PROCESSING'
}

export enum PriceSourceType {
  FeedWs = 'FEED_WS',
  FeedWwl = 'FEED_WWL',
  File = 'FILE'
}

export type PriceSourceUploadStat = {
  __typename?: 'PriceSourceUploadStat';
  /** Количество ошибочных записей в загрузке */
  invalidRowCount: Scalars['Int']['output'];
  /** Количество успешно загруженных записей */
  validRowCount: Scalars['Int']['output'];
};

/** Тип приоритета для многобазности */
export type PriorityType = {
  __typename?: 'PriorityType';
  /** Код типа приоритета */
  code: Scalars['String']['output'];
  /** Идентификатор типа приоритета */
  id: Scalars['Int']['output'];
  /** Перевод названия типа приоритета */
  localizedName: Scalars['String']['output'];
  /** Название типа приоритета на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** @deprecated Not used by clients */
  order: Scalars['Int']['output'];
};


/** Тип приоритета для многобазности */
export type PriorityTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Продукт (разлитая бутылка с вином) */
export type Product = ShortUniformResourceLocatable & {
  __typename?: 'Product';
  /** Признак допустимости вина */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Процентное содержание алкоголя
   * @deprecated Use wine chemical analysis
   */
  alcoholOnLabel?: Maybe<Scalars['Float']['output']>;
  alcoholOnLabelMax?: Maybe<Scalars['Float']['output']>;
  /** Процент алкоголя для этикетке (нижняя граница) */
  alcoholOnLabelMin?: Maybe<Scalars['Float']['output']>;
  /** Объем производства в год */
  annualProduction?: Maybe<ValueWithUnit>;
  /** Аппеласьон продукта в геоиндикации */
  appellationRating?: Maybe<ProductAppellationRating>;
  /** Аппеласьоны продукта */
  appellationRatings: Array<ProductAppellationRating>;
  /** Автор записи о продукте */
  authorUser?: Maybe<User>;
  /** Изображение контрэтикетки */
  backLabelImage?: Maybe<Image>;
  /**
   * Штрихкод продукта
   * @deprecated Use gtin
   */
  barcode?: Maybe<Scalars['String']['output']>;
  bestOfferPrice?: Maybe<PriceCurrencyForecast>;
  /** Изображение бутылки */
  bottleImage?: Maybe<Image>;
  /** Размер бутылки */
  bottleSize: BottleSize;
  /** Дата розлива вина */
  bottlingDate?: Maybe<Scalars['Date']['output']>;
  canOverrideAcceptability: Scalars['Boolean']['output'];
  canOverrideFacts: Scalars['Boolean']['output'];
  /** AKA CDP */
  cellarDoorPrice?: Maybe<Scalars['Float']['output']>;
  cellarDoorPriceLink?: Maybe<Scalars['URL']['output']>;
  /** Вид пробки */
  closureType?: Maybe<ClosureType>;
  coef?: Maybe<FloatForecast>;
  /** Дата создания продукта */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта цены на винодельне */
  currency?: Maybe<Currency>;
  /** Процент заполнения информации */
  fillPercent: Scalars['Int']['output'];
  /** Изображение этикетки */
  frontLabelImage?: Maybe<Image>;
  /** Global Trade Item Number */
  gtin?: Maybe<Scalars['GTIN']['output']>;
  /** Идентификатор продукта */
  id: Scalars['ID']['output'];
  images: Array<ProductImage>;
  /** Основной синоним продукта */
  mainSynonym?: Maybe<Product>;
  margin?: Maybe<IntForecast>;
  mentionCount: Scalars['Int']['output'];
  /** Количество мерчантов продающих данный продукт с лотом в 1 бутылку */
  merchantCount: Scalars['Int']['output'];
  minimalRecommendedRetailOffer?: Maybe<TradeOfferLot>;
  /** AKA MRPWC */
  minimalRecommendedRetailPrice?: Maybe<PriceCurrencyForecast>;
  /** Внешняя ссылка на товар с минимальной ценой */
  minimalRecommendedRetailPriceURL?: Maybe<Scalars['URL']['output']>;
  minimalRetailPrice?: Maybe<PriceCurrencyForecast>;
  /** Тип упаковки */
  packageType: WinePackageType;
  /**
   * Цена на винодельне
   * @deprecated Use cellar door price or recommended retail price
   */
  priceAtWinery?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use canOverrideAcceptability */
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use canOverrideFacts */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  /** Рекомендованнная розничная цена (RRP) */
  recommendedRetailPrice?: Maybe<Scalars['Float']['output']>;
  retailCoef?: Maybe<FloatForecast>;
  /** Список отзывов на продукт */
  reviewsPagination: ReviewsPaginationPayload;
  shortResourcePath: Scalars['String']['output'];
  shortUrl: Scalars['URL']['output'];
  /** Признак добавления продукта в шортлист компанией */
  shortlisted: Scalars['Boolean']['output'];
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  source?: Maybe<Source>;
  verified: Scalars['Boolean']['output'];
  /** Вино */
  wine: Wine;
  /** Список коммерческих предложений с данным продуктом */
  wineOffers: ProductWineOffers;
  /** Политика записи для текущего пользователя */
  writePolicy: ProductWritePolicy;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductAppellationRatingArgs = {
  geoIndicationId: Scalars['Int']['input'];
};


/** Продукт (разлитая бутылка с вином) */
export type ProductBestOfferPriceArgs = {
  companyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductCellarDoorPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductCoefArgs = {
  companyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductImagesArgs = {
  filter?: InputMaybe<ProductImageFilter>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductMarginArgs = {
  companyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductMinimalRecommendedRetailPriceArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductMinimalRetailPriceArgs = {
  companyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductPriceAtWineryArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductRecommendedRetailPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Продукт (разлитая бутылка с вином) */
export type ProductRetailCoefArgs = {
  companyId: Scalars['ID']['input'];
};


/** Продукт (разлитая бутылка с вином) */
export type ProductReviewsPaginationArgs = {
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  withPrivate?: Scalars['Boolean']['input'];
};


/** Продукт (разлитая бутылка с вином) */
export type ProductShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};


/** Продукт (разлитая бутылка с вином) */
export type ProductWineOffersArgs = {
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<ProductWineOfferFilter>;
  isBuyer: Scalars['Boolean']['input'];
};

export type ProductAcceptInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type ProductAcceptPayload = {
  __typename?: 'ProductAcceptPayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductAcceptabilityDiff = {
  __typename?: 'ProductAcceptabilityDiff';
  current: Scalars['Boolean']['output'];
  /** Старый признак допустимости (если он был) */
  lhs?: Maybe<Scalars['Boolean']['output']>;
  /** Метаданные изменения */
  metadata: EntityDiffMetadata;
  /** Продукт у которого изменился признак допустимости */
  product: Product;
  /** Новый признак допустимости */
  rhs: Scalars['Boolean']['output'];
};

export type ProductAppellationRating = {
  __typename?: 'ProductAppellationRating';
  count: Scalars['Int']['output'];
  filters: FacetFilterPayload;
  geoIndication: GeoIndication;
  /** Возврат страницы содержащей родительский продукт */
  page?: Maybe<ProductsPagination>;
  /** Список продуктов входящих в аппеласьон */
  pagination: ProductsPagination;
  /** Процентный ранг продукта */
  rank: Scalars['Float']['output'];
};


export type ProductAppellationRatingFiltersArgs = {
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<ProductAppellationRatingFilterFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ProductAppellationRatingPageArgs = {
  localeId: Scalars['Int']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: ProductSort;
};


export type ProductAppellationRatingPaginationArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ProductAppellationRatingFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};

export type ProductAppellationRatingFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Идентификаторы статусов коммерческих предложений для включения */
  wineOfferStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Идентификаторы типов коммерческих предложений для включения */
  wineOfferTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductAppellationRatingFilterFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Идентификаторы статусов коммерческих предложений для включения */
  wineOfferStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Идентификаторы типов коммерческих предложений для включения */
  wineOfferTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductCompositeIdInput = {
  bottleSizeId: Scalars['Int']['input'];
  packageTypeId?: InputMaybe<Scalars['Int']['input']>;
  wineId: Scalars['ID']['input'];
};

export type ProductCreateExpandedInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Составной идентификатор продукта */
  compositeId: ProductExpandedCompositeIdInput;
  /** Идентификатор отзыва со страницы которого создается продукт */
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductCreateExpandedPayload = {
  __typename?: 'ProductCreateExpandedPayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductCreateInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Составной идентификатор продукта */
  compositeId: ProductCompositeIdInput;
};

export type ProductCreatePayload = {
  __typename?: 'ProductCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductDiff = {
  __typename?: 'ProductDiff';
  /** @deprecated Use alcoholOnLabelMin & alcoholOnLabelMax */
  alcoholOnLabel?: Maybe<FloatDiff>;
  alcoholOnLabelMax?: Maybe<FloatDiff>;
  alcoholOnLabelMin?: Maybe<FloatDiff>;
  annualProduction?: Maybe<ValueWithUnitDiff>;
  backLabelImage?: Maybe<ImageDiff>;
  /** @deprecated Use gtin */
  barcode?: Maybe<StringDiff>;
  bottleImage?: Maybe<ImageDiff>;
  bottlingDate?: Maybe<DateDiff>;
  canDelete: Scalars['Boolean']['output'];
  canMove: Scalars['Boolean']['output'];
  cellarDoorPriceLink?: Maybe<UrlDiff>;
  closureType?: Maybe<ClosureTypeDiff>;
  frontLabelImage?: Maybe<ImageDiff>;
  gtin?: Maybe<StringDiff>;
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  metadata: EntityDiffMetadata;
  priceAtWinery?: Maybe<PriceCurrencyDiff>;
  product: Product;
};

export type ProductELabel = ShortUniformResourceLocatable & {
  __typename?: 'ProductELabel';
  bottleImage?: Maybe<Image>;
  bottleSize: BottleSize;
  /** Публикации этикеток отдельными компаниями */
  companyPublications: Array<ProductELabelCompanyPublication>;
  createdAt: Scalars['DateTime']['output'];
  gtin?: Maybe<Scalars['GTIN']['output']>;
  id: Scalars['ID']['output'];
  /** Общая публикация этикетки. Этикетка опубликована если хотя бы один из представителей производителя её опубликовал */
  isPublished: Scalars['Boolean']['output'];
  originProduct: Product;
  packageElements: Array<ProductELabelPackageElement>;
  packageType: WinePackageType;
  shortResourcePath: Scalars['String']['output'];
  shortUrl: Scalars['URL']['output'];
  slug: Scalars['String']['output'];
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
  wine: WineELabel;
};

export type ProductELabelCompanyPublication = {
  __typename?: 'ProductELabelCompanyPublication';
  /** Компания-представитель */
  company: Company;
  isPublished: Scalars['Boolean']['output'];
};

export type ProductELabelCoupon = {
  __typename?: 'ProductELabelCoupon';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProductELabelCreateInput = {
  /** Идентификатор текущей компании производителя */
  companyId: Scalars['ID']['input'];
  /** Опциональный gtin для переопределения */
  gtin?: InputMaybe<Scalars['GTIN']['input']>;
  productId: Scalars['ID']['input'];
};

export type ProductELabelCreatePayload = {
  __typename?: 'ProductELabelCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<ProductELabel>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductELabelCustomer = {
  __typename?: 'ProductELabelCustomer';
  id: Scalars['ID']['output'];
};

export enum ProductELabelIngredientDisplayVariant {
  Name = 'NAME',
  NameNumber = 'NAME_NUMBER',
  Number = 'NUMBER'
}

/** Тип намерения для отличия секретов: PaymentIntent или SetupIntent */
export enum ProductELabelIntentType {
  Payment = 'PAYMENT',
  Setup = 'SETUP'
}

export type ProductELabelPackageElement = {
  __typename?: 'ProductELabelPackageElement';
  element: WinePackageElement;
  esWasteContainer?: Maybe<EsWasteContainer>;
  euMaterials: Array<EuPackageMaterial>;
};

export type ProductELabelPrice = {
  __typename?: 'ProductELabelPrice';
  /** Валюта цены */
  currency: Currency;
  id: Scalars['String']['output'];
  /** Интервал оплаты */
  interval: PriceRecurringInterval;
  /** Цена плана в центах */
  price: Scalars['NonNegativeInt']['output'];
  /** Количество электронных этикеток доступных для публикации. Нет ограничения обозначается null */
  quantity?: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Есть ли триальная версия подписки */
  trial: Scalars['Boolean']['output'];
};

export enum ProductELabelSort {
  /** Сортировка по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка по возрастанию идентификатора */
  IdAsc = 'ID_ASC',
  /** Сортировка по убыванию идентификатора */
  IdDesc = 'ID_DESC',
  /** Сортировка по возрастанию даты обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванию даты обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка по возрастанию составного названия вина */
  WineNameAsc = 'WINE_NAME_ASC',
  /** Сортировка по убыванию составного названия вина */
  WineNameDesc = 'WINE_NAME_DESC'
}

export type ProductELabelSubscription = {
  __typename?: 'ProductELabelSubscription';
  /** Валюта цены */
  currency: Currency;
  /** Интервал оплаты */
  interval: PriceRecurringInterval;
  /** Дата и время окончания текущего периода подписки (UTC) */
  periodEnd: Scalars['DateTime']['output'];
  /** Дата и время начала текущего периода подписки (UTC) */
  periodStart: Scalars['DateTime']['output'];
  /** Цена плана в центах */
  price: Scalars['NonNegativeInt']['output'];
  /** Лимит по количеству опубликованных этикеток */
  quota?: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Статус подписки */
  status: ProductELabelSubscriptionStatus;
  /** Использование лимита */
  usedQuota: Scalars['NonNegativeInt']['output'];
};

export type ProductELabelSubscriptionActivatePayload = {
  __typename?: 'ProductELabelSubscriptionActivatePayload';
  clientSecret: Scalars['String']['output'];
  intentType: ProductELabelIntentType;
  trialing: Scalars['Boolean']['output'];
};

export type ProductELabelSubscriptionPortalCreateInput = {
  companyId: Scalars['ID']['input'];
  returnUrl: Scalars['URL']['input'];
};

export type ProductELabelSubscriptionPreview = {
  __typename?: 'ProductELabelSubscriptionPreview';
  /** Валюта цены */
  currency: Currency;
  /** Цена планы в центах без учета налогов */
  priceWithoutTax: Scalars['NonNegativeInt']['output'];
};

export type ProductELabelSubscriptionPreviewInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификатор купона (если имеется) */
  couponId?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор цены (плана подписки) */
  priceId: Scalars['String']['input'];
};

/** https://stripe.com/docs/api/subscriptions/object#subscription_object-status */
export enum ProductELabelSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type ProductELabelUpdateGrapeVarietyInput = {
  grapeVarietySynonymId: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductELabelUpdateInput = {
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  certificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  chemicalAnalysis?: InputMaybe<ProductElabelUpdateChemicalAnalysisInput>;
  companyId: Scalars['ID']['input'];
  grapeVarieties?: InputMaybe<Array<ProductELabelUpdateGrapeVarietyInput>>;
  gtin?: InputMaybe<Scalars['GTIN']['input']>;
  id: Scalars['ID']['input'];
  ingredientDisplayVariant?: ProductELabelIngredientDisplayVariant;
  ingredientId?: InputMaybe<Array<Scalars['Int']['input']>>;
  nutritionDeclaration?: InputMaybe<ProductELabelUpdateNutritionDeclarationInput>;
  packageElements?: InputMaybe<Array<ProductELabelUpdatePackageElementInput>>;
  responsibleConsumptionId?: InputMaybe<Array<Scalars['Int']['input']>>;
  wineTypeEUId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductELabelUpdateNutritionDeclarationInput = {
  carbohydrate?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  carbohydrateApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  energyKCal?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  energyKCalApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  fat?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  fatApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  protein?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  proteinApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  salt?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  saltApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  saturates?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  saturatesApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  sugars?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  sugarsApproximate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductELabelUpdatePackageElementInput = {
  elementId: Scalars['Int']['input'];
  esWasteContainerId?: InputMaybe<Scalars['Int']['input']>;
  euMaterialId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductELabelUpdatePayload = {
  __typename?: 'ProductELabelUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<ProductELabel>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductELabelsDeleteInput = {
  companyId: Scalars['ID']['input'];
  productELabelIds: Array<Scalars['ID']['input']>;
};

export type ProductELabelsDeletePayload = {
  __typename?: 'ProductELabelsDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type ProductELabelsFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Фильтр по общей публикации этикетки (true - только опубликованные, false - только черновики, null - все) */
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фильтр этикеток по компании которая опубликовала её */
  publishedByCompany?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ProductELabelsFilterFilter = {
  /** Значения фасетов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Фильтр по общей публикации этикетки (true - только опубликованные, false - только черновики, null - все) */
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фильтр этикеток по компании которая опубликовала её */
  publishedByCompany?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ProductELabelsPagination = {
  __typename?: 'ProductELabelsPagination';
  items: Array<ProductELabel>;
  pageInfo: PageInfo;
};

export type ProductELabelsPublishInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы электронных этикеток для публикации */
  productELabelIds: Array<Scalars['ID']['input']>;
};

export type ProductELabelsPublishPayload = {
  __typename?: 'ProductELabelsPublishPayload';
  query?: Maybe<Query>;
  records: Array<ProductELabel>;
};

export type ProductELabelsUnpublishInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы электронных этикеток для снятия публикации */
  productELabelIds: Array<Scalars['ID']['input']>;
};

export type ProductELabelsUnpublishPayload = {
  __typename?: 'ProductELabelsUnpublishPayload';
  query?: Maybe<Query>;
  records: Array<ProductELabel>;
};

export type ProductElabelSubscriptionActivateInput = {
  /** Текущая компания покупателя */
  companyId: Scalars['ID']['input'];
  /** Двухбуквеный код страны покупателя */
  countryCode: Scalars['String']['input'];
  /** Идентификатр купона из stripe */
  couponId?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['String']['input'];
  /** Сброс подписки (только для разработки) */
  reset?: InputMaybe<Scalars['Boolean']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type ProductElabelUpdateChemicalAnalysisInput = {
  alcoholVolume?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductExpandedCompositeIdInput = {
  bottleSizeId: Scalars['Int']['input'];
  brandId: Scalars['ID']['input'];
  countryId: Scalars['Int']['input'];
  geoIndication?: InputMaybe<WineGeoIndicationInput>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  nationalGIType?: InputMaybe<WineNationalGiTypeInput>;
  packageTypeId: Scalars['Int']['input'];
  specialClassificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  sweetnessLevelId: Scalars['Int']['input'];
  tradeNameId: Scalars['ID']['input'];
  vintage?: InputMaybe<Scalars['PositiveInt']['input']>;
  wineColorId: Scalars['Int']['input'];
  wineTypeId: Scalars['Int']['input'];
};

export enum ProductExportColumn {
  AppellationRank = 'APPELLATION_RANK',
  AuthorUsername = 'AUTHOR_USERNAME',
  BackLabelImage = 'BACK_LABEL_IMAGE',
  BottleImage = 'BOTTLE_IMAGE',
  BottleSize = 'BOTTLE_SIZE',
  Brand = 'BRAND',
  CompanyOfferCount = 'COMPANY_OFFER_COUNT',
  FillPercent = 'FILL_PERCENT',
  FrontLabelImage = 'FRONT_LABEL_IMAGE',
  GeoIndicationNationalGiType = 'GEO_INDICATION_NATIONAL_GI_TYPE',
  Keyword = 'KEYWORD',
  PackageType = 'PACKAGE_TYPE',
  PublicOfferCount = 'PUBLIC_OFFER_COUNT',
  Rating = 'RATING',
  RecommendedRetailPrice = 'RECOMMENDED_RETAIL_PRICE',
  SpecialClassification = 'SPECIAL_CLASSIFICATION',
  SweetnessLevel = 'SWEETNESS_LEVEL',
  TechSheetLanguages = 'TECH_SHEET_LANGUAGES',
  UpdatedAt = 'UPDATED_AT',
  Vintage = 'VINTAGE',
  WineColor = 'WINE_COLOR',
  WineCountry = 'WINE_COUNTRY',
  WineType = 'WINE_TYPE'
}

export type ProductFactDeleteInput = {
  id: Scalars['ID']['input'];
};

export type ProductFactDeletePayload = {
  __typename?: 'ProductFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
};

export type ProductFactMoveInput = {
  /** ID факта */
  factId: Scalars['ID']['input'];
  /** ID вина */
  wineId: Scalars['ID']['input'];
};

export type ProductFactMovePayload = {
  __typename?: 'ProductFactMovePayload';
  originRecord?: Maybe<Product>;
  query?: Maybe<Query>;
  record?: Maybe<Product>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  /** Пользователь, который добавил изображение */
  authorUser?: Maybe<User>;
  /** Дата создания экземпляра изображения */
  createdAt: Scalars['DateTime']['output'];
  /** Группа изображений (изображения относящиеся к одному неизвестному продукту) */
  group?: Maybe<ProductImageGroup>;
  /** Есть ли на изображении продукт */
  hasProduct: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  /** Выводится ли изображение на карточке вина */
  isPublic: Scalars['Boolean']['output'];
  isReference?: Maybe<Scalars['Boolean']['output']>;
  /** Есть ли проблема с определением винтажа */
  isVintageUnknown: Scalars['Boolean']['output'];
  linkAuthor?: Maybe<User>;
  linkStatus: ProductImageLinkStatus;
  /** Актуальное упоминание */
  mention?: Maybe<Mention>;
  /** Уверенность модели в определении типа изображения */
  modelConfidence?: Maybe<Scalars['Float']['output']>;
  modelType?: Maybe<ProductImageType>;
  product?: Maybe<Product>;
  search?: Maybe<ProductImageSearch>;
  source?: Maybe<ProductImageSource>;
  sourceType: ProductImageSourceType;
  type?: Maybe<ProductImageType>;
  updateUser?: Maybe<User>;
  /** Дата обновления экземпляра изображения */
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['URL']['output'];
};

export type ProductImageBulkImport = {
  __typename?: 'ProductImageBulkImport';
  _?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Source>;
  /**
   * Цвет вина который был указан в первоисточнике изображения при загрузке
   * @deprecated Use wineColors
   */
  wineColor?: Maybe<WineColor>;
  wineColors: Array<WineColor>;
  /**
   * Тип вина который был указан в первоисточнике изображения при загрузке
   * @deprecated Use wineTypes
   */
  wineType?: Maybe<WineType>;
  wineTypes: Array<WineType>;
};

export type ProductImageFilter = {
  excludeMentionId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductImageGroup = {
  __typename?: 'ProductImageGroup';
  id: Scalars['ID']['output'];
};

export enum ProductImageLinkStatus {
  Linked = 'LINKED',
  Processing = 'PROCESSING',
  SearchError = 'SEARCH_ERROR'
}

export type ProductImagePagination = {
  __typename?: 'ProductImagePagination';
  items: Array<ProductImage>;
  pageInfo: PageInfo;
};

export type ProductImageSearch = {
  __typename?: 'ProductImageSearch';
  googleResponse?: Maybe<ProductImageSearchGoogleResponse>;
  id: Scalars['ID']['output'];
  productImage?: Maybe<ProductImage>;
  tineyeResponse?: Maybe<ProductImageSearchTineyeResponse>;
};

export type ProductImageSearchGoogleResponse = {
  __typename?: 'ProductImageSearchGoogleResponse';
  product?: Maybe<Product>;
  referenceImage?: Maybe<Scalars['URL']['output']>;
  response?: Maybe<Scalars['JSONObject']['output']>;
  /** Score от 0 до 1 */
  score?: Maybe<Scalars['Float']['output']>;
};

export type ProductImageSearchTineyeResponse = {
  __typename?: 'ProductImageSearchTineyeResponse';
  product?: Maybe<Product>;
  referenceImage?: Maybe<Scalars['URL']['output']>;
  response?: Maybe<Scalars['JSONObject']['output']>;
  /** Score от 0 до 1 */
  score?: Maybe<Scalars['Float']['output']>;
};

export type ProductImageSource = Product | ProductImageBulkImport | ProductImageSearch | WineReview;

export enum ProductImageSourceType {
  BulkImport = 'BULK_IMPORT',
  Manually = 'MANUALLY',
  Product = 'PRODUCT',
  ProductSearch = 'PRODUCT_SEARCH',
  Review = 'REVIEW'
}

export enum ProductImageType {
  BackLabel = 'BACK_LABEL',
  Bottle = 'BOTTLE',
  FrontLabel = 'FRONT_LABEL'
}

export type ProductImagesUpdateInput = {
  /** Ссылка на изображение контрэтикетки */
  backLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Ссылка на изображение этикетки бутылки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type ProductImagesUpdatePayload = {
  __typename?: 'ProductImagesUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductOrWine = Product | Wine;

export enum ProductPatchField {
  AlcoholOnLabel = 'ALCOHOL_ON_LABEL',
  Certifications = 'CERTIFICATIONS',
  GrapeVarieties = 'GRAPE_VARIETIES'
}

export type ProductPatchInput = {
  alcoholOnLabelMax?: InputMaybe<Scalars['Float']['input']>;
  alcoholOnLabelMin?: InputMaybe<Scalars['Float']['input']>;
  certificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  grapeVarieties?: InputMaybe<Array<WineGrapeVarietyInput>>;
  productId: Scalars['ID']['input'];
};

export type ProductPatchPayload = {
  __typename?: 'ProductPatchPayload';
  errorFields: Array<ProductPatchField>;
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
  successFields: Array<ProductPatchField>;
};

export type ProductRecognised = {
  __typename?: 'ProductRecognised';
  backLabelImage?: Maybe<ProductImageSearch>;
  bottleImage?: Maybe<ProductImageSearch>;
  frontLabelImage?: Maybe<ProductImageSearch>;
  incorrectRecognition?: Maybe<ProductRecognizedIncorrect>;
};

export type ProductRecognitionCompleteInput = {
  /** Идентификатор поиска по изображению */
  backLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор поиска по изображению */
  bottleSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор поиска по изображению */
  frontLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор выбранного продукта пользователем */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Винтаж выбранного продукта (или новый) */
  vintage?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type ProductRecognitionCompletePayload = {
  __typename?: 'ProductRecognitionCompletePayload';
  product?: Maybe<Product>;
  query: Query;
};

export type ProductRecognitionError = {
  __typename?: 'ProductRecognitionError';
  reason: ProductRecognitionErrorReason;
};

export enum ProductRecognitionErrorReason {
  /** Low quality image */
  LowImageQuality = 'LOW_IMAGE_QUALITY',
  /** The product is not wine */
  NoProductOnImage = 'NO_PRODUCT_ON_IMAGE',
  /** Images do not meet the requirements (there is no label on the image) */
  RequirementsFail = 'REQUIREMENTS_FAIL'
}

export type ProductRecognitionNeedMoreInfo = {
  __typename?: 'ProductRecognitionNeedMoreInfo';
  /** @deprecated Need more info type not used */
  unknownAttributes: Array<ProductRecognitionUnknownAttribute>;
};

export type ProductRecognitionResult = ProductRecognised | ProductRecognitionError | ProductRecognitionNeedMoreInfo;

export type ProductRecognitionResultErrorInput = {
  reason: ProductRecognitionErrorReason;
};

export type ProductRecognitionResultNeedMoreInfoInput = {
  unknownAttributes: Array<ProductRecognitionUnknownAttribute>;
};

export type ProductRecognitionResultSaveInput = {
  error?: InputMaybe<ProductRecognitionResultErrorInput>;
  recognized?: InputMaybe<ProductRecognitionResultSuccessInput>;
  wineReviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductRecognitionResultSavePayload = {
  __typename?: 'ProductRecognitionResultSavePayload';
  query?: Maybe<Query>;
  wineReview: WineReview;
};

export type ProductRecognitionResultSuccessInput = {
  productId: Scalars['ID']['input'];
};

export enum ProductRecognitionUnknownAttribute {
  BottleSize = 'BOTTLE_SIZE',
  Brand = 'BRAND',
  Classification = 'CLASSIFICATION',
  GeoIndication = 'GEO_INDICATION',
  NationalGiType = 'NATIONAL_GI_TYPE',
  Producer = 'PRODUCER',
  ProducerCountry = 'PRODUCER_COUNTRY',
  SweetnessLevel = 'SWEETNESS_LEVEL',
  Vintage = 'VINTAGE',
  WineColor = 'WINE_COLOR',
  WineCountry = 'WINE_COUNTRY',
  WineType = 'WINE_TYPE'
}

export type ProductRecognizedIncorrect = {
  __typename?: 'ProductRecognizedIncorrect';
  comment: Scalars['String']['output'];
  product: Product;
};

export type ProductRefuseInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type ProductRefusePayload = {
  __typename?: 'ProductRefusePayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export enum ProductSort {
  /** Сортировка по возрастанию процентного содержания алкоголя в бутылке */
  AlcoholAsc = 'ALCOHOL_ASC',
  /** Сортировка по убыванию процентного содержания алкоголя в бутылке */
  AlcoholDesc = 'ALCOHOL_DESC',
  /** Сортировка по возрастанию рейтинга в аппелласьоне */
  AppellationRankAsc = 'APPELLATION_RANK_ASC',
  /** Сортировка по убыванию рейтинга в аппелласьоне */
  AppellationRankDesc = 'APPELLATION_RANK_DESC',
  /** Сортировка по возрастанию цены за 1 бутылку среди коммерческих предложений подходящих под покупателя */
  BestOfferPricePerBottleAsc = 'BEST_OFFER_PRICE_PER_BOTTLE_ASC',
  /** Сортировка по убыванию цены за 1 бутылку среди коммерческих предложений подходящих под покупателя */
  BestOfferPricePerBottleDesc = 'BEST_OFFER_PRICE_PER_BOTTLE_DESC',
  /** Сортировка по возврастанию отношения лучшего КП к розничной цене (только для покупателя) */
  CoefficientAsc = 'COEFFICIENT_ASC',
  /** Сортировка по убыванию отношения лучшего КП к розничной цене (только для покупателя) */
  CoefficientDesc = 'COEFFICIENT_DESC',
  /** Сортировка по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка по возрастанию названия сорта винограда */
  GrapeVarietyNameAsc = 'GRAPE_VARIETY_NAME_ASC',
  /** Сортировка по убыванию названия сорта винограда */
  GrapeVarietyNameDesc = 'GRAPE_VARIETY_NAME_DESC',
  /** Сортировка по возрастанию маржи (для покупателя) */
  MarginAsc = 'MARGIN_ASC',
  /** Сортировка по убыванию маржи (для покупателя) */
  MarginDesc = 'MARGIN_DESC',
  /** Сортировка по возрастанию минимальной розничной цены (только для покупателя) */
  MinRecommendedPriceAsc = 'MIN_RECOMMENDED_PRICE_ASC',
  /** Сортировка по убыванию минимальной розничной цены (только для покупателя) */
  MinRecommendedPriceDesc = 'MIN_RECOMMENDED_PRICE_DESC',
  /** Сортировка по возрастанию минимальной розничной цены в стране покупателя */
  MinRetailPriceAsc = 'MIN_RETAIL_PRICE_ASC',
  /** Сортировка по убыванию минимальной розничной цены в стране покупателя */
  MinRetailPriceDesc = 'MIN_RETAIL_PRICE_DESC',
  /** Сортировка по recommendedRetailPrice/priceAtWinery/cellarDoorPrice по возрастанию */
  PriceAsc = 'PRICE_ASC',
  /** Сортировка по recommendedRetailPrice/priceAtWinery/cellarDoorPrice по убывнию */
  PriceDesc = 'PRICE_DESC',
  /** Сортировка по возрастанию торгового наименования производителя */
  ProducerTradeNameAsc = 'PRODUCER_TRADE_NAME_ASC',
  /** Сортировка по убыванию торгового наименования производителя */
  ProducerTradeNameDesc = 'PRODUCER_TRADE_NAME_DESC',
  /** Сортировка по возрастанию рейтинга WWL */
  RatingAsc = 'RATING_ASC',
  /** Сортировка по убыванию рейтинга WWL */
  RatingDesc = 'RATING_DESC',
  /** Сортировка по возрастанию даты обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванию даты обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка по возрастанию винтажа */
  VintageAsc = 'VINTAGE_ASC',
  /** Сортировка по убыванию винтажа */
  VintageDesc = 'VINTAGE_DESC',
  /** Сортировка по возрастанию размера бутылки */
  VolumeAsc = 'VOLUME_ASC',
  /** Сортировка по убыванию размера бутылки */
  VolumeDesc = 'VOLUME_DESC',
  /** Сортировка по возрастанию составного названия вина */
  WineNameAsc = 'WINE_NAME_ASC',
  /** Сортировка по убыванию составного названия вина */
  WineNameDesc = 'WINE_NAME_DESC',
  /** Сортировка по возрастанию названия геоиндикации */
  WineRegionNameAsc = 'WINE_REGION_NAME_ASC',
  /** Сортировка по убыванию названия геоиндикации */
  WineRegionNameDesc = 'WINE_REGION_NAME_DESC'
}

export type ProductUpdateInput = {
  alcoholOnLabelMax?: InputMaybe<Scalars['Float']['input']>;
  alcoholOnLabelMin?: InputMaybe<Scalars['Float']['input']>;
  annualProduction?: InputMaybe<ValueWithUnitInput>;
  /** Ссылка на изображение контрэтикетки */
  backLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Дата розлива */
  bottlingDate?: InputMaybe<Scalars['Date']['input']>;
  cellarDoorPriceLink?: InputMaybe<Scalars['URL']['input']>;
  /** Вид пробки */
  closureTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор валюты для цены на винодельне */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Ссылка на изображение этикетки бутылки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Global Trade Item Number */
  gtin?: InputMaybe<Scalars['GTIN']['input']>;
  /** Цена продукта на винодельне */
  priceAtWinery?: InputMaybe<Scalars['Float']['input']>;
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type ProductUpdatePayload = {
  __typename?: 'ProductUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Product>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type ProductWineOfferFilter = {
  /** Фильтры для продуктов */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Идентификаторы статусов коммерческих предложений для включения */
  wineOfferStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Идентификаторы типов коммерческих предложений для включения */
  wineOfferTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductWineOffers = {
  __typename?: 'ProductWineOffers';
  /** Количество доступных/опубликованных предложений */
  count: Scalars['Int']['output'];
  /** Список рынков для опубликованных предложений */
  markets: Array<WineOfferCountryStats>;
  /** Список доступных предложений */
  pagination?: Maybe<WineOfferPagination>;
  /** Разброс коэффициентов для доступных/опубликованных предложений */
  priceCoefficientRange?: Maybe<FloatRange>;
  /** Разброс цен за бутылку для доступных/опубликованных предложений */
  pricePerBottleRange?: Maybe<PriceCurrencyRange>;
};


export type ProductWineOffersPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
};

export type ProductWritePolicy = {
  __typename?: 'ProductWritePolicy';
  alcoholOnLabel: WritePolicy;
  annualProduction: WritePolicy;
  backLabelImage: WritePolicy;
  bottleImage: WritePolicy;
  bottlingDate: WritePolicy;
  cellarDoorPrice: WritePolicy;
  cellarDoorPriceLink: WritePolicy;
  closureType: WritePolicy;
  frontLabelImage: WritePolicy;
  gtin: WritePolicy;
};

export type ProductsAddToShortlistInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  productIds: Array<Scalars['ID']['input']>;
};

export type ProductsAddToShortlistPayload = {
  __typename?: 'ProductsAddToShortlistPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Product>>;
};

export type ProductsExportFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type ProductsExportInput = {
  /** Столбцы для выгрузки (с учетом порядка столбцов) */
  columns: Array<ProductExportColumn>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Формат файла */
  fileFormat: TableFileFormat;
  filter?: InputMaybe<ProductsExportFilter>;
  localeId: Scalars['Int']['input'];
};

export type ProductsExportPayload = {
  __typename?: 'ProductsExportPayload';
  /** Ссылка на файл */
  link: Scalars['URL']['output'];
  query?: Maybe<Query>;
  /** Размер файла в байтах */
  sizeInBytes: Scalars['Int']['output'];
};

export type ProductsOrWinesFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Нужно ли возвращать только допустимые продукты */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type ProductsOrWinesFiltersFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Нужно ли возвращать только допустимые продукты */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type ProductsOrWinesPagination = {
  __typename?: 'ProductsOrWinesPagination';
  items: Array<ProductOrWine>;
  pageInfo: PageInfo;
};

export type ProductsOrWinesSearchPayload = {
  __typename?: 'ProductsOrWinesSearchPayload';
  items: Array<ProductOrWine>;
};

export type ProductsOrWinesToOfferPayload = {
  __typename?: 'ProductsOrWinesToOfferPayload';
  items: Array<ItemToOffer>;
};

export type ProductsPagination = {
  __typename?: 'ProductsPagination';
  items: Array<Product>;
  pageInfo: PageInfo;
};

export type ProductsPaginationFilter = {
  producerCompanyId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductsPaginationPayload = {
  __typename?: 'ProductsPaginationPayload';
  items: Array<Product>;
};

export type ProductsRemoveFromShortlistInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы продуктов */
  productIds: Array<Scalars['ID']['input']>;
};

export type ProductsRemoveFromShortlistPayload = {
  __typename?: 'ProductsRemoveFromShortlistPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Product>>;
};

export type ProductsShortlistFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Нужно ли возвращать только допустимые продукты */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type ProductsShortlistFiltersFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Нужно ли возвращать только допустимые продукты */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type ProductsToOfferPayload = {
  __typename?: 'ProductsToOfferPayload';
  items: Array<Product>;
};

/** Условие розничной покупки */
export type PurchaseCondition = {
  __typename?: 'PurchaseCondition';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  localizedName: Scalars['String']['output'];
};


/** Условие розничной покупки */
export type PurchaseConditionLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Запросы для подраздела Administration и внутренней админки.
   * Требует роль пользователя ADMIN, SUPERADMIN, CONTENT, MANAGER или заголовок X-Admin-Key
   */
  admin: AdminQueries;
  /** Поиск аллергена по идентификатору */
  allergen?: Maybe<Allergen>;
  /** Список всех аллергенов */
  allergens: Array<Allergen>;
  /** Поиск конкурса по идентификатору */
  award?: Maybe<Award>;
  /** Поиск конкурса по точному совпадению полного названия */
  awardByName?: Maybe<Award>;
  /** Поиск награды по id */
  awardReward?: Maybe<AwardReward>;
  awardRewardScoreLevels: Array<AwardRewardScoreLevel>;
  /** Список типов наград в конкурсе */
  awardRewardTypes: Array<AwardRewardType>;
  /** Список конкурсов */
  awards: Array<Award>;
  /** Список конкурсов с постраничным доступом */
  awardsDatabasePagination: AwardPagination;
  /** Список размеров бутылок */
  bottleSizes: Array<BottleSize>;
  /** Поиск бренда по идентификатору */
  brand?: Maybe<Brand>;
  /** Поиск бренда по составному идентификатору. Поиск идет без учета регистра и диакритических знаков */
  brandByCompositeId?: Maybe<Brand>;
  brandsContributedPagination?: Maybe<BrandPagination>;
  /** Поиск всех брендов в разделе Database */
  brandsDatabasePagination?: Maybe<BrandPagination>;
  /** Фильтры для брендов и их значения */
  brandsFilters: FacetFilterPayload;
  buyProductsFilters: FacetFilterPayload;
  buyProductsPagination: BuyProductsPaginationPayload;
  /** Поиск покупателей */
  buyersPagination: CompanyInfoPagination;
  /** Поиск сертификата по идентификатору */
  certification?: Maybe<Certification>;
  /** Поиск типа сертификата по идентификатору */
  certificationType?: Maybe<CertificationType>;
  /** Список типов сертификатов */
  certificationTypes: Array<CertificationType>;
  /** Список сертификатов */
  certifications: Array<Certification>;
  /** Поиск вида пробки по идентификатору */
  closureType?: Maybe<ClosureType>;
  /** Список видов пробок */
  closureTypes?: Maybe<Array<ClosureType>>;
  cms: CmsQueries;
  /**
   * Поиск компаний.
   * Page от 1
   * PerPage от 1 до 50
   */
  companiesPagination: CompanyPagination;
  /**
   * Поиск компании по ID
   * @deprecated Use admin.companies.company or companyInfo
   */
  company?: Maybe<Company>;
  /** Список категорий компаний */
  companyCategories: Array<CompanyCategory>;
  /** Поиск компании по ID */
  companyInfo?: Maybe<CompanyInfo>;
  /** Пиоск статуса верификации компании по ID */
  companyVerificationStatus?: Maybe<CompanyVerificationStatus>;
  /** Список статусов верификации компаний */
  companyVerificationStatuses: Array<CompanyVerificationStatus>;
  /** Список стран */
  countries: Array<Country>;
  /** Поиск страны по идентификатору */
  country?: Maybe<Country>;
  /** Список валют */
  currencies: Array<Currency>;
  /** Поиск валюты по идентификатору */
  currency?: Maybe<Currency>;
  /** Курс валют для конкретного кода валюты */
  currencyRate?: Maybe<CurrencyRate>;
  /** Курсы валют по отношению к USD */
  currencyRates: Array<CurrencyRate>;
  /** Список вариантов учета доставки в розничных ценах */
  deliveryCosts: Array<DeliveryCost>;
  /** Список времени доставки */
  deliveryTimes: Array<DeliveryTime>;
  esWasteContainers: Array<EsWasteContainer>;
  /** Поиск типа европеский геоиндикаций по идентификатору */
  euGIType?: Maybe<EugiType>;
  /** Список европейских типов геоиндикации */
  euGITypes: Array<EugiType>;
  /** Список европейских упаковочных материалов */
  euPackageMaterials: Array<EuPackageMaterial>;
  /** Поиск выставки по идентификатору */
  exhibition?: Maybe<Exhibition>;
  /** Поиск выставки по названию */
  exhibitionByName?: Maybe<Exhibition>;
  /** Список типов выставок */
  exhibitionTypes: Array<ExhibitionType>;
  /** Список выставок */
  exhibitions: Array<Exhibition>;
  /** Список выставок с постраничным доступом */
  exhibitionsDatabasePagination: ExhibitionPagination;
  /** Фильтры для выставок и их значения */
  exhibitionsFilters: FacetFilterPayload;
  /** Список квалификаций дегустаторов */
  expertQualifications: Array<ExpertQualification>;
  /** Список причин для отказа в верификации дегутстаторов */
  expertVerificationRejectReasons: Array<ExpertVerificationRejectReason>;
  /** Список статусов верификации дегутстаторов */
  expertVerificationStatuses: Array<ExpertVerificationStatus>;
  /** Поиск пресета фильтров по идентификатору */
  filterPreset?: Maybe<FilterPreset>;
  /** Список блюд, которые могут сочетаться с напитками */
  foodPairs: Array<FoodPair>;
  /** Поиск геоиндкации по идентификатору */
  geoIndication?: Maybe<GeoIndication>;
  geoIndicationConditions: Array<GeoIndicationCondition>;
  geoIndicationInstantSearch: Array<GeoIndication>;
  /** Список геоиндикаций */
  geoIndications: Array<GeoIndication>;
  /** Список геоиндикаций с постраничным доступом */
  geoIndicationsDatabasePagination: GeoIndicationPagination;
  /** Фильтры для геоиндикаций и их значения */
  geoIndicationsFilters: FacetFilterPayload;
  /** Список сортов винограда */
  grapeVarieties: Array<GrapeVariety>;
  /** Список цветов винограда */
  grapeVarietyColors: Array<GrapeVarietyColor>;
  /** Список синонимов сортов винограда */
  grapeVarietySynonyms: Array<GrapeVarietySynonym>;
  imageTransformed: Scalars['URL']['output'];
  /** Пиоск инкотермс по идентификатору */
  incoterm?: Maybe<Incoterms>;
  /** Список инкотермс */
  incoterms: Array<Incoterms>;
  /** Поиск ингредиента по идентификатору */
  ingredient?: Maybe<Ingredient>;
  /** Список категорий ингредиентов */
  ingredientCategories: Array<IngredientCategory>;
  /** Список ингредиентов */
  ingredients: Array<Ingredient>;
  /** Проверка, занята ли уникальная ссылка производителя */
  isCompanyLinkTaken: Scalars['Boolean']['output'];
  /** Поиск юрлиц по стране, регистрационному номеру основного юрлица или номеру налогоплательщика */
  legalEntitiesAndBranches: LegalEntitiesAndBranchesPayload;
  /** Поиск юрлица по идентификатору */
  legalEntity?: Maybe<LegalEntity>;
  /** Поиск юрлица по VAT */
  legalEntityByVAT?: Maybe<LegalEntityOfficial>;
  /** Поиск юридической формы по идентификатору */
  legalEntityForms: Array<LegalEntityForm>;
  locale?: Maybe<Locale>;
  locales?: Maybe<Array<Locale>>;
  /** Информация о текущем пользователе */
  me: User;
  merchantGeoIndicationsFilters: FacetFilterPayload;
  merchantGeoIndicationsPagination: GeoIndicationPagination;
  /** Поиск мерчанта по названию */
  merchantSearch: Array<Merchant>;
  merchantsFilters: FacetFilterPayload;
  merchantsPagination: MerchantPagination;
  /** Поиск типа национальной геоиндикации по идентификатору */
  nationalGIType?: Maybe<NationalGiType>;
  /** Список типов национальных геоиндикаций */
  nationalGITypes: Array<NationalGiType>;
  /** Поиск продукта по идентификатору */
  product?: Maybe<Product>;
  /** Поиск продукта по составному идентификатору */
  productByCompositeId?: Maybe<Product>;
  /** Поиск продукта по составному идентификатору включая поля вина */
  productByExpandedCompositeId?: Maybe<Product>;
  /** Поиск проодукта по slug */
  productBySlug?: Maybe<Product>;
  /** Поиск электронной этикетки по ID */
  productELabel?: Maybe<ProductELabel>;
  /** Поиск электронной этикетки по slug */
  productELabelBySlug?: Maybe<ProductELabel>;
  /** Получение информации о купоне по промо-коду */
  productELabelCouponByCode?: Maybe<ProductELabelCoupon>;
  /** Список цен на подписки на электронные этикетки */
  productELabelPrices: Array<ProductELabelPrice>;
  /** Получение предварительной информации о подписке */
  productELabelSubscriptionPreview: ProductELabelSubscriptionPreview;
  /** Преобразование параметров продукта в фильтры по розничным ценам */
  productToTradeOfferFilters: Array<FacetFilterSelection>;
  /** Полнотекстовый поиск по винам и продуктам */
  productsOrWinesSearch: ProductsOrWinesSearchPayload;
  /** Публичный поиск продуктов */
  productsPagination: ProductsPaginationPayload;
  /** Список условий розничной покупки */
  purchaseConditions: Array<PurchaseCondition>;
  responsibleConsumption?: Maybe<ResponsibleConsumption>;
  responsibleConsumptions: Array<ResponsibleConsumption>;
  /** Поиск системы оценки по ID */
  scoreSystem?: Maybe<ScoreSystem>;
  /** Список всех систем оценки */
  scoreSystems: Array<ScoreSystem>;
  /** Поиск продавцов */
  sellersPagination: CompanyPagination;
  sources: Array<Source>;
  /** Поиск специальной классификации по идентификатору */
  specialClassification?: Maybe<SpecialClassification>;
  /** Список специальных классификаций */
  specialClassifications: Array<SpecialClassification>;
  suspiciousWordSearch?: Maybe<SuspiciousWordSearchPayload>;
  /** Поиск техлиста по составному идентификатору */
  techSheetByCompositeId?: Maybe<TechSheet>;
  /** Список территорий */
  territories: Array<Territory>;
  /** Поиск территории по идентификатору */
  territory?: Maybe<Territory>;
  /** Поиск типа территории по идентификатору */
  territoryType?: Maybe<TerritoryType>;
  /** Список типов территорий */
  territoryTypes: Array<TerritoryType>;
  /** Поиск торговой марки по идентификатору */
  tradeMark?: Maybe<TradeMark>;
  /** Поиск торговых марок или брендов */
  tradeMarkBrandsSearch: TradeMarkBrandSearchPayload;
  /** Поиск статуса торговой марки по идентификатору */
  tradeMarkStatus?: Maybe<TradeMarkStatus>;
  /** Список статусов торговой марки */
  tradeMarkStatuses: Array<TradeMarkStatus>;
  /** Поиск торговых марок по названию */
  tradeMarksByName: Array<TradeMark>;
  /** Поиск торгового наименования по ID */
  tradeName?: Maybe<TradeName>;
  /** Запрос для формирования префикса названия вина */
  tradeNameBrand?: Maybe<Scalars['String']['output']>;
  /** Поиск торгового наименования по его составному идентификатору */
  tradeNameByCompositeId?: Maybe<TradeName>;
  /** Поиск торговых наименований компаний созданных текущим пользователем */
  tradeNameContributedPagination?: Maybe<TradeNamePagination>;
  /** Поиск торговых наименований компаний для раздела Database */
  tradeNameDatabasePagination?: Maybe<TradeNamePagination>;
  tradeNameInstantSearch: Array<TradeName>;
  /** Поиск торговых наименований компаний */
  tradeNamePagination?: Maybe<TradeNamePagination>;
  /** Фильтры для торговых наименований и их значения */
  tradeNamesFilters: FacetFilterPayload;
  tradeOffer?: Maybe<TradeOffer>;
  tradeOfferLot?: Maybe<TradeOfferLot>;
  tradeOfferLotsFilters: FacetFilterPayload;
  tradeOfferLotsPagination: TradeOfferLotPagination;
  /** Поиск единицы измерения по идентификатору */
  unit?: Maybe<Unit>;
  /** Поиск группы единиц измерений по идентификатору */
  unitGroup?: Maybe<UnitGroup>;
  /** Поиск группы единиц измерений по названию */
  unitGroupByName?: Maybe<UnitGroup>;
  /** Список групп единиц измерений */
  unitGroups: Array<UnitGroup>;
  /** Список единиц измерения */
  units: Array<Unit>;
  /** Поиск пользователя по идентификатору */
  user?: Maybe<User>;
  /** Поиск роли пользователя по идентификатору */
  userRole?: Maybe<UserRole>;
  /** Поиск роли пользователя по коду */
  userRoleByCode?: Maybe<UserRole>;
  /** Список ролей пользователя */
  userRoles: Array<UserRole>;
  /** Поиск статуса пользователя по идентификатору */
  userStatus?: Maybe<UserStatus>;
  /** Поиск статуса пользователя по коду */
  userStatusByCode?: Maybe<UserStatus>;
  /** Список статусов пользователей */
  userStatuses: Array<UserStatus>;
  /** Проверка, занят ли username */
  usernameTaken: Scalars['Boolean']['output'];
  /** Список способов применения НДС */
  vatBehaviors: Array<VatBehavior>;
  /** Проверка, имеет ли сайт и email совпадающий домен */
  websiteAndEmailHaveSameDomain: Scalars['Boolean']['output'];
  /** Запрос информации о вине */
  wine?: Maybe<Wine>;
  /** Поиск участия вина в конкурсе по идентификатору */
  wineAward?: Maybe<WineAward>;
  /** Поиск участия вина в конкурсе по составному идентификатору */
  wineAwardByCompositeId?: Maybe<WineAward>;
  /** Запрос информации о вине по его составному идентфикатору */
  wineByCompositeId?: Maybe<Wine>;
  /** Поиск цвета вина по идентификатору */
  wineColor?: Maybe<WineColor>;
  /** Список цветов вина */
  wineColors?: Maybe<Array<WineColor>>;
  /** Список лотов поставки вина для коммерческих предложений */
  wineLots: Array<WineLot>;
  /** Список лотов поставки вина для розничных цен */
  wineLotsForTradeOfferLot: Array<WineLot>;
  /** Поиск коммерческого предложения по идентификатору */
  wineOffer?: Maybe<WineOffer>;
  /** Поиск запроса по идентификатору */
  wineOfferRequest?: Maybe<WineOfferRequest>;
  /** Преобразование фильтров коммерческих предложений в параметры потенциального запроса */
  wineOfferRequestFiltersParse: WineOfferRequestParsePayload;
  wineOfferRequestParametersToProductFilters: Array<FacetFilterSelection>;
  wineOfferRequestParametersToWineOfferFilters: Array<FacetFilterSelection>;
  /** Преобразование фильтров продуктов в параметры потенциального запроса */
  wineOfferRequestProductFiltersParse: WineOfferRequestParsePayload;
  /**
   * Информация о продавцах попадающих под запрос
   * @deprecated Use wineOfferRequestStats
   */
  wineOfferRequestSellers: WineOfferRequestSellersPayload;
  /** Получение количественных показателей по параметрам запроса */
  wineOfferRequestStats: WineOfferRequestStatsPayload;
  /** Список статусов коммерческих предложений */
  wineOfferStatuses: Array<WineOfferStatus>;
  /** Поиск типа коммерческого предложения по идентификатору */
  wineOfferType?: Maybe<WineOfferType>;
  /** Список типов коммерческих предложений */
  wineOfferTypes: Array<WineOfferType>;
  /** Список элементов упаковки вина */
  winePackageElements: Array<WinePackageElement>;
  /** Список типов упаковки вина */
  winePackageTypes: Array<WinePackageType>;
  /** Поиск европейского винного региона по идентификатору */
  wineRegionEU?: Maybe<WineRegionEu>;
  /** Список европейских виннных регионов */
  wineRegionsEU: Array<WineRegionEu>;
  /** Поиск отзыва по идентификатору */
  wineReview?: Maybe<WineReview>;
  /** Список отзывов которые оставил текущий пользователь */
  wineReviewsContributedPagination: WineReviewPagination;
  /** Фильтры для отзывов и их значения */
  wineReviewsFilters: FacetFilterPayload;
  /** Поиск типа винной территории по идентификатору */
  wineTerritoryType?: Maybe<WineTerritoryType>;
  /** Список типов винных территорий */
  wineTerritoryTypes: Array<WineTerritoryType>;
  /** Поиск типа вина по идентификатору */
  wineType?: Maybe<WineType>;
  /** Поиск типа вина из ЕС по идентификатору */
  wineTypeEU?: Maybe<WineTypeEu>;
  /** Список типов вина */
  wineTypes?: Maybe<Array<WineType>>;
  /** Список типов вина TC */
  wineTypesEU?: Maybe<Array<WineTypeEu>>;
  /** Поиск всех вин в разделе Database */
  winesDatabasePagination?: Maybe<WinePagination>;
  /** Фильтры для вин и их значения */
  winesFilters: FacetFilterPayload;
};


export type QueryAllergenArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAwardArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAwardByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryAwardRewardArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAwardsDatabasePaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<AwardSort>;
};


export type QueryBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBrandByCompositeIdArgs = {
  name: Scalars['String']['input'];
  tradeNameId: Scalars['ID']['input'];
};


export type QueryBrandsContributedPaginationArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: BrandSort;
};


export type QueryBrandsDatabasePaginationArgs = {
  filter?: InputMaybe<BrandFilter>;
  localeId?: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: BrandSort;
};


export type QueryBrandsFiltersArgs = {
  filter?: InputMaybe<BrandFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBuyProductsFiltersArgs = {
  filter: BuyProductsPaginationFilter;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBuyProductsPaginationArgs = {
  filter: BuyProductsPaginationFilter;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};


export type QueryBuyersPaginationArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCertificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCertificationTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryClosureTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCompaniesPaginationArgs = {
  filter?: InputMaybe<CompanyFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyInfoArgs = {
  callerCompanyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type QueryCompanyVerificationStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCountryArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCurrencyArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCurrencyRateArgs = {
  currencyCode: Scalars['String']['input'];
};


export type QueryEuGiTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExhibitionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExhibitionByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryExhibitionsDatabasePaginationArgs = {
  filter?: InputMaybe<ExhibitionFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ExhibitionSort>;
};


export type QueryExhibitionsFiltersArgs = {
  filter?: InputMaybe<ExhibitionFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFilterPresetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGeoIndicationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGeoIndicationConditionsArgs = {
  geoIndicationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  nationalGITypeId?: InputMaybe<Scalars['Int']['input']>;
  sweetnessLevelId?: InputMaybe<Scalars['Int']['input']>;
  vintage?: InputMaybe<Scalars['Int']['input']>;
  wineColorId?: InputMaybe<Scalars['Int']['input']>;
  wineTypeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGeoIndicationInstantSearchArgs = {
  filter?: InputMaybe<GeoIndicationInstantSearchFilter>;
  limit?: Scalars['PositiveInt']['input'];
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGeoIndicationsDatabasePaginationArgs = {
  filter?: InputMaybe<GeoIndicationFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GeoIndicationSort>;
};


export type QueryGeoIndicationsFiltersArgs = {
  filter?: InputMaybe<GeoIndicationFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGrapeVarietySynonymsArgs = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryImageTransformedArgs = {
  transformations?: InputMaybe<TransformationsInput>;
  url: Scalars['URL']['input'];
};


export type QueryIncotermArgs = {
  id: Scalars['Int']['input'];
};


export type QueryIngredientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIsCompanyLinkTakenArgs = {
  companyLink: Scalars['String']['input'];
};


export type QueryLegalEntitiesAndBranchesArgs = {
  countryId: Scalars['Int']['input'];
  identityNumber?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLegalEntityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLegalEntityByVatArgs = {
  vat: Scalars['String']['input'];
};


export type QueryLegalEntityFormsArgs = {
  countryId: Scalars['Int']['input'];
};


export type QueryLocaleArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLocalesArgs = {
  onlyEUOfficial?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMerchantGeoIndicationsFiltersArgs = {
  filter: MerchantGeoIndicationsFilter;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMerchantGeoIndicationsPaginationArgs = {
  filter: MerchantGeoIndicationsFilter;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort: GeoIndicationSort;
};


export type QueryMerchantSearchArgs = {
  limit?: Scalars['PositiveInt']['input'];
  query: Scalars['String']['input'];
};


export type QueryMerchantsFiltersArgs = {
  filter?: InputMaybe<MerchantsPaginationFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMerchantsPaginationArgs = {
  filter?: InputMaybe<MerchantsPaginationFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: MerchantSort;
};


export type QueryNationalGiTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductByCompositeIdArgs = {
  compositeId: ProductCompositeIdInput;
};


export type QueryProductByExpandedCompositeIdArgs = {
  compositeId: ProductExpandedCompositeIdInput;
};


export type QueryProductBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryProductELabelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductELabelBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryProductELabelCouponByCodeArgs = {
  promoCode: Scalars['String']['input'];
};


export type QueryProductELabelSubscriptionPreviewArgs = {
  input: ProductELabelSubscriptionPreviewInput;
};


export type QueryProductToTradeOfferFiltersArgs = {
  localeId: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
};


export type QueryProductsOrWinesSearchArgs = {
  limit: Scalars['Int']['input'];
  localeId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};


export type QueryProductsPaginationArgs = {
  filter?: InputMaybe<ProductsPaginationFilter>;
  limit?: Scalars['Int']['input'];
  localeId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};


export type QueryResponsibleConsumptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScoreSystemArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySellersPaginationArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpecialClassificationArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySpecialClassificationsArgs = {
  filter?: InputMaybe<SpecialClassificationFilter>;
};


export type QuerySuspiciousWordSearchArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTechSheetByCompositeIdArgs = {
  compositeId: TechSheetCompositeIdInput;
};


export type QueryTerritoryArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTerritoryTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTradeMarkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTradeMarkBrandsSearchArgs = {
  perPage?: Scalars['PositiveInt']['input'];
  query: Scalars['String']['input'];
};


export type QueryTradeMarkStatusArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTradeMarksByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryTradeNameArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTradeNameBrandArgs = {
  brandId: Scalars['ID']['input'];
  tradeNameId: Scalars['ID']['input'];
};


export type QueryTradeNameByCompositeIdArgs = {
  compositeId: TradeNameCompositeIdInput;
};


export type QueryTradeNameContributedPaginationArgs = {
  localeId?: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeNameSort;
};


export type QueryTradeNameDatabasePaginationArgs = {
  filter?: InputMaybe<TradeNameFilter>;
  localeId?: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeNameSort;
};


export type QueryTradeNameInstantSearchArgs = {
  filter?: InputMaybe<TradeNameFilter>;
  limit?: Scalars['PositiveInt']['input'];
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTradeNamePaginationArgs = {
  filter?: InputMaybe<TradeNameFilter>;
  localeId?: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeNameSort;
};


export type QueryTradeNamesFiltersArgs = {
  filter?: InputMaybe<TradeNameFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTradeOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTradeOfferLotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTradeOfferLotsFiltersArgs = {
  filter: TradeOfferLotsPaginationFilter;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTradeOfferLotsPaginationArgs = {
  filter: TradeOfferLotsPaginationFilter;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: TradeOfferLotSort;
};


export type QueryUnitArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUnitGroupArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUnitGroupByNameArgs = {
  masterName: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserRoleArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUserRoleByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryUserStatusArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUserStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryUsernameTakenArgs = {
  username: Scalars['String']['input'];
};


export type QueryWebsiteAndEmailHaveSameDomainArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWineArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWineAwardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWineAwardByCompositeIdArgs = {
  compositeId: WineAwardCompositeIdInput;
};


export type QueryWineByCompositeIdArgs = {
  compositeId: WineCompositeIdInput;
};


export type QueryWineColorArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineOfferArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineOfferRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWineOfferRequestFiltersParseArgs = {
  input: WineOfferRequestFiltersParseInput;
};


export type QueryWineOfferRequestParametersToProductFiltersArgs = {
  input: WineOfferRequestParametersToProductFilters;
};


export type QueryWineOfferRequestParametersToWineOfferFiltersArgs = {
  input: WineOfferRequestParametersToWineOfferFilters;
};


export type QueryWineOfferRequestProductFiltersParseArgs = {
  input: WineOfferRequestProductFiltersParseInput;
};


export type QueryWineOfferRequestSellersArgs = {
  input: WineOfferRequestSellersInput;
};


export type QueryWineOfferRequestStatsArgs = {
  input: WineOfferRequestStatsInput;
};


export type QueryWineOfferTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineRegionEuArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWineReviewsContributedPaginationArgs = {
  filter?: InputMaybe<WineReviewFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineReviewSort;
};


export type QueryWineReviewsFiltersArgs = {
  filter?: InputMaybe<WineReviewFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWineTerritoryTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWineTypeEuArgs = {
  id: Scalars['Int']['input'];
};


export type QueryWinesDatabasePaginationArgs = {
  filter?: InputMaybe<WineDatabaseFilter>;
  fuzziness?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineSort;
};


export type QueryWinesFiltersArgs = {
  filter?: InputMaybe<WineFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum ResizeType {
  /** if both source and resulting dimensions have the same orientation (portrait or landscape), will use fill. Otherwise, it will use fit. */
  Auto = 'auto',
  /** resizes the image while keeping aspect ratio to fill a given size and crops projecting parts. */
  Fill = 'fill',
  /** resizes the image while keeping aspect ratio to fit a given size. */
  Fit = 'fit'
}

export type ResponsibleConsumption = {
  __typename?: 'ResponsibleConsumption';
  id: Scalars['ID']['output'];
  localizedName: Scalars['String']['output'];
  logo?: Maybe<Scalars['URL']['output']>;
  masterName: Scalars['String']['output'];
};


export type ResponsibleConsumptionLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type ReviewsPaginationPayload = {
  __typename?: 'ReviewsPaginationPayload';
  items: Array<WineReview>;
  pageInfo: PageInfo;
};

/** Система оценки */
export type ScoreSystem = {
  __typename?: 'ScoreSystem';
  id: Scalars['ID']['output'];
  localizedName: Scalars['String']['output'];
  /** Полное название */
  masterName: Scalars['String']['output'];
  /** Максимальная оценка */
  maxScore: Scalars['Int']['output'];
  /** Минимальная оценка */
  minScore: Scalars['Int']['output'];
};


/** Система оценки */
export type ScoreSystemLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type ShortUniformResourceLocatable = {
  shortResourcePath: Scalars['String']['output'];
  shortUrl: Scalars['URL']['output'];
};

/** Внешний источник */
export type Source = {
  __typename?: 'Source';
  /** Описание источника */
  description?: Maybe<Scalars['String']['output']>;
  /** Идентификатор внешнего источника */
  id: Scalars['Int']['output'];
  /** Название источника */
  name?: Maybe<Scalars['String']['output']>;
  /** Тип источника */
  sourceType?: Maybe<SourceType>;
};

/** Тип внешего источника */
export type SourceType = {
  __typename?: 'SourceType';
  /** Код типа внешнего источника */
  code: Scalars['String']['output'];
  /** Идентификатор внешнего источника */
  id: Scalars['Int']['output'];
};

/** Специальная классификация */
export type SpecialClassification = {
  __typename?: 'SpecialClassification';
  /** Идентификатор специальной классификации */
  id: Scalars['Int']['output'];
  /** Название специальной классификации */
  name: Scalars['String']['output'];
};

export type SpecialClassificationFilter = {
  /** Идентификаторы геоиндикации */
  geoIndicationId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Идентификатор типа национальной геоиндикации */
  nationalGITypeId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор сладости */
  sweetnessLevelId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор торгового наименования */
  tradeNameId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор цвета вина */
  wineColorId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор типа вина */
  wineTypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** Изменение строки */
export type StringDiff = {
  __typename?: 'StringDiff';
  /** Совпадает ли новое значение с текущим */
  current: Scalars['Boolean']['output'];
  /** Старое строковое значение */
  lhs?: Maybe<Scalars['String']['output']>;
  /** Новое строковое значение */
  rhs?: Maybe<Scalars['String']['output']>;
};

export type StringWrapper = {
  __typename?: 'StringWrapper';
  value: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']['output']>;
  /** Подписка на изменение тура компании */
  companyTour: CompanyTour;
  /** Подписка на количество неотвеченных запросов на КП */
  wineOfferRequestsUnreads: WineOfferRequestsUnreadsPayload;
  /** Подписка на количество коммерческих предложений по статусам */
  wineOffersUnreads: WineOffersUnreadsPayload;
  /** Подписка на количество неразобранных модератором отзывов */
  wineReviewsPending: WineReviewsPendingPayload;
};


export type SubscriptionCompanyTourArgs = {
  companyId: Scalars['ID']['input'];
};


export type SubscriptionWineOfferRequestsUnreadsArgs = {
  companyId: Scalars['ID']['input'];
};


export type SubscriptionWineOffersUnreadsArgs = {
  companyId: Scalars['ID']['input'];
};

export enum SuspiciousWordRightField {
  GeoIndication = 'GEO_INDICATION',
  Keywords = 'KEYWORDS',
  NationalGiType = 'NATIONAL_GI_TYPE',
  SpecialClassification = 'SPECIAL_CLASSIFICATION',
  SweetnessLevel = 'SWEETNESS_LEVEL',
  WineType = 'WINE_TYPE'
}

export type SuspiciousWordSearchPayload = {
  __typename?: 'SuspiciousWordSearchPayload';
  /** Оригинальный текст для поиска с выделенным словом в html */
  highlight: Scalars['String']['output'];
  /** Оригинальный текст для поиска */
  query: Scalars['String']['output'];
  /** Поле, которое может быть использовано для найденного слова */
  rightField?: Maybe<SuspiciousWordRightField>;
  /** Найденное подозрительное слово */
  word: Scalars['String']['output'];
};

/** Сладость вина */
export type SweetnessLevel = {
  __typename?: 'SweetnessLevel';
  /** Страна, где используется эта сладость */
  country?: Maybe<Country>;
  /** Идентификатор сладости */
  id: Scalars['Int']['output'];
  /** Является ли эта сладость по умолчанию */
  isDefault: Scalars['Boolean']['output'];
  /** Перевод названия сладости вина */
  localizedName: Scalars['String']['output'];
  /** Название сладости вина на языке по умолчанию */
  masterName: Scalars['String']['output'];
  /** Содержание сахара */
  residualSugar?: Maybe<Scalars['String']['output']>;
  /** Тип вина */
  wineType?: Maybe<WineType>;
};


/** Сладость вина */
export type SweetnessLevelLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export enum TableFileFormat {
  Csv = 'CSV',
  Xlsx = 'XLSX'
}

/** Технический лист c описанием вина(техлист) */
export type TechSheet = {
  __typename?: 'TechSheet';
  /** Признак допустимости вина */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /** Последний автор записи */
  authorUser?: Maybe<User>;
  /** Информация об отображении конкурсов на техлисте */
  awards: Array<TechSheetAward>;
  canOverrideAcceptability: Scalars['Boolean']['output'];
  canOverrideFacts: Scalars['Boolean']['output'];
  /** Email для связи */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Телефон для связи */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Торговое название для связи */
  contactProducerTradeName?: Maybe<Scalars['String']['output']>;
  /** Сайт для связи */
  contactWebsite?: Maybe<Scalars['URL']['output']>;
  /** Идентификатор техлиста */
  id: Scalars['ID']['output'];
  /** Используется ли WWL оценка на техлисте */
  isWwlScoreUsed: Scalars['Boolean']['output'];
  /** Локаль описания техлиста */
  locale: Locale;
  /** Маркетинговое описание вина */
  marketingNote?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use canOverrideAcceptability */
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use canOverrideFacts */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  source?: Maybe<Source>;
  /** Первый автор записи */
  sourceAuthorUser?: Maybe<User>;
  /** Список источников использованных для формирования записи */
  sources: Array<Source>;
  /** Дегустационная заметка от производителя */
  tastingNote?: Maybe<Scalars['String']['output']>;
  /** Дата и время последнего изменения */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verified: Scalars['Boolean']['output'];
  /** Информация о процессе винификации */
  vinification?: Maybe<Scalars['String']['output']>;
  /** Информация о годе урожая, детали винтажа */
  vintageDetails?: Maybe<Scalars['String']['output']>;
  /** Информация о выращивании */
  viticulture?: Maybe<Scalars['String']['output']>;
  /** Вино */
  wine: Wine;
  /** Информация о выдержке */
  wineAging?: Maybe<Scalars['String']['output']>;
};

export type TechSheetAcceptabilityDiff = {
  __typename?: 'TechSheetAcceptabilityDiff';
  current: Scalars['Boolean']['output'];
  /** Старый признак допустимости (если он был) */
  lhs?: Maybe<Scalars['Boolean']['output']>;
  /** Метаданные изменения */
  metadata: EntityDiffMetadata;
  /** Новый признак допустимости */
  rhs: Scalars['Boolean']['output'];
  /** Техлист у которой изменился признак допустимости */
  techSheet: TechSheet;
};

export type TechSheetAward = {
  __typename?: 'TechSheetAward';
  /** Выводится ли участие в конкурсе */
  isUsed: Scalars['Boolean']['output'];
  /** Участие в конкурсе */
  wineAward: WineAward;
};

export type TechSheetAwardInput = {
  isUsed: Scalars['Boolean']['input'];
  wineAwardId: Scalars['ID']['input'];
};

export type TechSheetCompositeIdInput = {
  /** Идентификатор локали */
  localeId: Scalars['Int']['input'];
  /** Идентификатор вина */
  wineId: Scalars['ID']['input'];
};

export type TechSheetDiff = {
  __typename?: 'TechSheetDiff';
  canDelete: Scalars['Boolean']['output'];
  canMove: Scalars['Boolean']['output'];
  contactEmail?: Maybe<EmailAddressDiff>;
  contactPhone?: Maybe<PhoneNumberDiff>;
  contactProducerTradeName?: Maybe<StringDiff>;
  contactWebsite?: Maybe<UrlDiff>;
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  marketingNote?: Maybe<StringDiff>;
  metadata: EntityDiffMetadata;
  tastingNote?: Maybe<StringDiff>;
  techSheet: TechSheet;
  vinification?: Maybe<StringDiff>;
  vintageDetails?: Maybe<StringDiff>;
  viticulture?: Maybe<StringDiff>;
  wineAging?: Maybe<StringDiff>;
};

export type TechSheetFactDeleteInput = {
  id: Scalars['ID']['input'];
};

export type TechSheetFactDeletePayload = {
  __typename?: 'TechSheetFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<TechSheet>;
};

export type TechSheetFactMoveInput = {
  /** ID факта техлиста */
  factId: Scalars['ID']['input'];
  /** ID вина */
  wineId: Scalars['ID']['input'];
};

export type TechSheetFactMovePayload = {
  __typename?: 'TechSheetFactMovePayload';
  originRecord?: Maybe<TechSheet>;
  query?: Maybe<Query>;
  record?: Maybe<TechSheet>;
  wine?: Maybe<Wine>;
};

export type TechSheetPayload = {
  __typename?: 'TechSheetPayload';
  query?: Maybe<Query>;
  record: TechSheet;
  recordId: Scalars['ID']['output'];
};

export type TechSheetSaveInput = {
  awards?: InputMaybe<Array<TechSheetAwardInput>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  contactProducerTradeName?: InputMaybe<Scalars['String']['input']>;
  contactWebsite?: InputMaybe<Scalars['URL']['input']>;
  /** Используется ли WWL оценка на техлисте */
  isWwlScoreUsed?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['Int']['input'];
  marketingNote?: InputMaybe<Scalars['String']['input']>;
  tastingNote?: InputMaybe<Scalars['String']['input']>;
  vinification?: InputMaybe<Scalars['String']['input']>;
  vintageDetails?: InputMaybe<Scalars['String']['input']>;
  viticulture?: InputMaybe<Scalars['String']['input']>;
  wineAging?: InputMaybe<Scalars['String']['input']>;
  wineId: Scalars['ID']['input'];
};

/** Территория */
export type Territory = {
  __typename?: 'Territory';
  /** Дочерние территории */
  children: Array<Territory>;
  /** Код территории */
  code: Scalars['String']['output'];
  /** Идентификатор территории */
  id: Scalars['Int']['output'];
  /** Название территории */
  masterName: Scalars['String']['output'];
  /** Родительская территории */
  parent?: Maybe<Territory>;
  /** Тип территории */
  territoryType: TerritoryType;
};

export type TerritoryPayload = {
  __typename?: 'TerritoryPayload';
  records: Array<Territory>;
};

/** Тип территории */
export type TerritoryType = {
  __typename?: 'TerritoryType';
  /** Страна территории */
  country: Country;
  /** Идентификатор типа территории */
  id: Scalars['Int']['output'];
  /** Уровень территории */
  level: Scalars['Int']['output'];
  /** Перевод названия типа территории */
  localizedName: Scalars['String']['output'];
  /** Название типа территории */
  masterName: Scalars['String']['output'];
  territories: Array<Territory>;
};


/** Тип территории */
export type TerritoryTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type Token = {
  __typename?: 'Token';
  locale?: Maybe<Locale>;
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Торговая марка */
export type TradeMark = {
  __typename?: 'TradeMark';
  /** Регистрационный номер торговой марки */
  TIN?: Maybe<Scalars['String']['output']>;
  /** Страна торговой марки */
  country: Country;
  /** Имя держателя торговой марки */
  holderName: Scalars['String']['output'];
  /** Идентификатор торговой марки */
  id: Scalars['ID']['output'];
  /** Название торговой марки */
  name: Scalars['String']['output'];
  /** Статус торговой марки */
  status: TradeMarkStatus;
};

export type TradeMarkBrandSearchPayload = {
  __typename?: 'TradeMarkBrandSearchPayload';
  items: Array<Scalars['String']['output']>;
};

/** Статус торговой марки */
export type TradeMarkStatus = {
  __typename?: 'TradeMarkStatus';
  /** Идентификатор статуса торговой марки */
  id: Scalars['Int']['output'];
  /** Перевод названия статуса торговой марки */
  localizedName: Scalars['String']['output'];
  /** Название статуса торговой марки на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Статус торговой марки */
export type TradeMarkStatusLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Торговое наименование (он же производитель) */
export type TradeName = {
  __typename?: 'TradeName';
  /** Является ли текущая запись допустимой */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /** Последний автор записи */
  authorUser?: Maybe<User>;
  brandInstantSearch: Array<Brand>;
  /** Бренды торгового наименования */
  brands: Array<Brand>;
  /** У пользователя приоритет равен или выше приоритета записи о допустимости сущности */
  canOverrideAcceptability: Scalars['Boolean']['output'];
  /**
   * У пользователя приоритет равен или выше приоритета записи о информации сущности
   * @deprecated Use writePolicy
   */
  canOverrideFacts: Scalars['Boolean']['output'];
  changelog: Array<TradeNameChangelogItem>;
  /** Страна торгового наименования */
  country: Country;
  /** Дата создания торгового наименования */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Информация о группе дубликатов торгового наименования */
  duplicateGroup?: Maybe<TradeNameDuplicateGroup>;
  /** Основной email торгового наименования */
  email?: Maybe<Scalars['EmailAddress']['output']>;
  /** Идентификатор торгового наименования */
  id: Scalars['ID']['output'];
  lastNewsletter?: Maybe<WineOfferRequestTradeNameNewsletter>;
  /** Широта адреса торгового наименования */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Юрлицо привязанное к торговому наименованию */
  legalEntity?: Maybe<LegalEntity>;
  /** Перевод для конкретной локали */
  localizedTradeName: Scalars['String']['output'];
  /** Логотип торгового наименования */
  logo?: Maybe<Image>;
  /** Долгота адреса торгового наименования */
  longitude?: Maybe<Scalars['Float']['output']>;
  mainSynonym?: Maybe<TradeName>;
  /** Основной телефон торгового наименования */
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Адрес для почтовых отправлений */
  postAddress?: Maybe<Scalars['String']['output']>;
  /** Почтовый индекс */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Страна для почтовых отправлений */
  postCountry?: Maybe<Country>;
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use writePolicy */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  /**
   * Фильтры для электронных этикеток
   * @deprecated Use company.productELabelsFilters
   */
  productELabelsFilters: FacetFilterPayload;
  /**
   * Поиск электронных этикеток производителя
   * @deprecated Use company.productELabelsPagination
   */
  productELabelsPagination: ProductELabelsPagination;
  /** Значения для фильтров */
  productsOrWinesFilters: FacetFilterPayload;
  /** Список продуктов и вин без продуктов выпускаемых торговым наименованием */
  productsOrWinesPagination: ProductsOrWinesPagination;
  /** Поиск продуктов производителя (включая всех представителей) */
  productsPagination: ProductsPagination;
  /** У пользователя приоритет выше приоритета записи о допустимости сущнности */
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  /** Текущий источник записи */
  source?: Maybe<Source>;
  /** Первый автор записи */
  sourceAuthorUser?: Maybe<User>;
  /** Список источников использованных для формирования записи */
  sources: Array<Source>;
  /** Количество неосновных синонимов */
  synonymCount: Scalars['NonNegativeInt']['output'];
  /** Название торгового наименования */
  tradeName: Scalars['String']['output'];
  /** Список переводов */
  translations: Array<TradeNameTranslation>;
  /** Дата и время последнего изменения */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Является ли запись верифицированной (приоритет больше системного (производителя)) */
  verified: Scalars['Boolean']['output'];
  /** Основной сайт торгового наименования */
  website?: Maybe<Scalars['URL']['output']>;
  /** Список вин торгового наименования */
  winesPagination?: Maybe<WinePagination>;
  /** Политика записи для текущего пользователя */
  writePolicy: TradeNameWritePolicy;
};


/** Торговое наименование (он же производитель) */
export type TradeNameBrandInstantSearchArgs = {
  includeSynonyms?: Scalars['Boolean']['input'];
  limit?: Scalars['PositiveInt']['input'];
  localeId: Scalars['Int']['input'];
  onlyAccepted?: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameBrandsArgs = {
  includeSynonyms?: Scalars['Boolean']['input'];
  onlyAccepted?: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameLocalizedTradeNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Торговое наименование (он же производитель) */
export type TradeNameProductELabelsFiltersArgs = {
  filter?: InputMaybe<ProductELabelsFilterFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameProductELabelsPaginationArgs = {
  filter?: InputMaybe<ProductELabelsFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductELabelSort;
};


/** Торговое наименование (он же производитель) */
export type TradeNameProductsOrWinesFiltersArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ProductsOrWinesFiltersFilter>;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameProductsOrWinesPaginationArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ProductsOrWinesFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameProductsPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};


/** Торговое наименование (он же производитель) */
export type TradeNameTranslationsArgs = {
  sort?: InputMaybe<TradeNameTranslationSort>;
};


/** Торговое наименование (он же производитель) */
export type TradeNameWinesPaginationArgs = {
  filter?: InputMaybe<WinesPaginationFilter>;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineSort;
};

export type TradeNameAcceptInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор торгового наименования */
  tradeNameId: Scalars['ID']['input'];
};

export type TradeNameAcceptPayload = {
  __typename?: 'TradeNameAcceptPayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type TradeNameAcceptabilityDeleteInput = {
  id: Scalars['ID']['input'];
};

export type TradeNameAcceptabilityDeletePayload = {
  __typename?: 'TradeNameAcceptabilityDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
};

export type TradeNameChangelogItem = AcceptabilityDiff | TradeNameDiff;

export type TradeNameCompositeIdInput = {
  /** Идентификатор страны, в которой зарегистрирована торговое наименование */
  countryId: Scalars['Int']['input'];
  /** Название торгового наименования */
  tradeName: Scalars['String']['input'];
};

export type TradeNameContributedSeriesPayloadItem = {
  __typename?: 'TradeNameContributedSeriesPayloadItem';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type TradeNameContributionCreateInput = {
  TIN?: InputMaybe<Scalars['String']['input']>;
  activityCode?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  countryId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  legalEntityFormId?: InputMaybe<Scalars['Int']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  postAddress?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  tradeName: Scalars['String']['input'];
  vat?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type TradeNameContributionCreatePayload = {
  __typename?: 'TradeNameContributionCreatePayload';
  query?: Maybe<Query>;
  recordId: Scalars['ID']['output'];
};

export type TradeNameCreateInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Составной идентификатор торгового наименования */
  compositeId: TradeNameCompositeIdInput;
};

export type TradeNameCreatePayload = {
  __typename?: 'TradeNameCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type TradeNameDiff = {
  __typename?: 'TradeNameDiff';
  canDelete: Scalars['Boolean']['output'];
  canMove: Scalars['Boolean']['output'];
  email?: Maybe<EmailAddressDiff>;
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  legalEntity?: Maybe<LegalEntityDiff>;
  logo?: Maybe<ImageDiff>;
  metadata: EntityDiffMetadata;
  phone?: Maybe<PhoneNumberDiff>;
  postAddress?: Maybe<StringDiff>;
  postCode?: Maybe<StringDiff>;
  /** Изменения переводов для каждой из локали */
  translations?: Maybe<Array<TradeNameTranslationDiff>>;
  website?: Maybe<UrlDiff>;
};

/** Группа дубликатов торгового наименования */
export type TradeNameDuplicateGroup = {
  __typename?: 'TradeNameDuplicateGroup';
  /** Список регистрационных номеров филиалов в группе */
  branchNumbers?: Maybe<Array<TradeNameDuplicateGroupBranchNumber>>;
  /** Количество дубликатов в группе дубликатов */
  count: Scalars['Int']['output'];
  /** Список доменов из сайтов и почт */
  domains?: Maybe<Array<Scalars['String']['output']>>;
  /** Список регистрационных номеров в группе */
  identityNumbers?: Maybe<Array<TradeNameDuplicateGroupIdentityNumber>>;
  /** Список торговых наименований в группе дубликатов торгового наименования */
  pagination?: Maybe<TradeNamePagination>;
  /** Список телефонов */
  phones?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use pagination */
  records: Array<TradeName>;
};


/** Группа дубликатов торгового наименования */
export type TradeNameDuplicateGroupPaginationArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: TradeNameSort;
};

export type TradeNameDuplicateGroupBranchNumber = {
  __typename?: 'TradeNameDuplicateGroupBranchNumber';
  branchNumber: Scalars['String']['output'];
  country: Country;
};

export type TradeNameDuplicateGroupIdentityNumber = {
  __typename?: 'TradeNameDuplicateGroupIdentityNumber';
  country: Country;
  identityNumber: Scalars['String']['output'];
};

export type TradeNameELabel = {
  __typename?: 'TradeNameELabel';
  /** Логотип торгового наименования */
  logo?: Maybe<Image>;
  tradeName: Scalars['String']['output'];
  /** Основной сайт торгового наименования */
  website?: Maybe<Scalars['URL']['output']>;
};

export enum TradeNameExportColumn {
  Address = 'ADDRESS',
  AllEmails = 'ALL_EMAILS',
  AllPhones = 'ALL_PHONES',
  AllWebsites = 'ALL_WEBSITES',
  AuthorUsername = 'AUTHOR_USERNAME',
  Country = 'COUNTRY',
  Email = 'EMAIL',
  Id = 'ID',
  LegalEntityDiscriminator = 'LEGAL_ENTITY_DISCRIMINATOR',
  LegalEntityName = 'LEGAL_ENTITY_NAME',
  Phone = 'PHONE',
  TradeName = 'TRADE_NAME',
  UpdatedAt = 'UPDATED_AT',
  Website = 'WEBSITE'
}

export type TradeNameFactDeleteInput = {
  id: Scalars['ID']['input'];
};

export type TradeNameFactDeletePayload = {
  __typename?: 'TradeNameFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
};

export type TradeNameFactMoveInput = {
  /** ID факта */
  factId: Scalars['ID']['input'];
  /** ID торгового наименования */
  tradeNameId: Scalars['ID']['input'];
};

export type TradeNameFactMovePayload = {
  __typename?: 'TradeNameFactMovePayload';
  /** Запись-источник */
  originRecord?: Maybe<TradeName>;
  query?: Maybe<Query>;
  /** Принимающая запись */
  record?: Maybe<TradeName>;
};

export type TradeNameFilter = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  onlyAccepted?: Scalars['Boolean']['input'];
  onlyWithDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TradeNameFiltersFilter = {
  /** Значения из фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Нужно ли возвращать только допустимые торговые наименования */
  onlyAccepted?: Scalars['Boolean']['input'];
  /** Нужно ли возвращать только торговые наименования с дубликатами */
  onlyWithDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TradeNamePagination = {
  __typename?: 'TradeNamePagination';
  items: Array<TradeName>;
  pageInfo: PageInfo;
};

export type TradeNameRefuseInput = {
  /** идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор торгового наименования */
  tradeNameId: Scalars['ID']['input'];
};

export type TradeNameRefusePayload = {
  __typename?: 'TradeNameRefusePayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export enum TradeNameRequestMatchSort {
  /** Сортировка по дате последней рассылки по возрастанию */
  LastNewsletterDateAsc = 'LAST_NEWSLETTER_DATE_ASC',
  /** Сортировка по дате последней рассылки по убыванию */
  LastNewsletterDateDesc = 'LAST_NEWSLETTER_DATE_DESC',
  ProductCountAsc = 'PRODUCT_COUNT_ASC',
  ProductCountDesc = 'PRODUCT_COUNT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum TradeNameSort {
  /** Сортировка по возрастанию названия страны */
  CountryNameAsc = 'COUNTRY_NAME_ASC',
  /** Сортировка по убыванию названия страны */
  CountryNameDesc = 'COUNTRY_NAME_DESC',
  /** Сортировка по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка по возрастанию количества дубликатов */
  DuplicateGroupSizeAsc = 'DUPLICATE_GROUP_SIZE_ASC',
  /** Сортировка по убыванию количества дубликатов */
  DuplicateGroupSizeDesc = 'DUPLICATE_GROUP_SIZE_DESC',
  /** Сортировка по возрастанию полного юридического названия */
  LegalFullNameAsc = 'LEGAL_FULL_NAME_ASC',
  /** Сортировка по убыванию полного юридического названия */
  LegalFullNameDesc = 'LEGAL_FULL_NAME_DESC',
  /** Сортировка по релевантности поиска */
  Score = 'SCORE',
  /** Сортировка по возрастанию названия */
  TradeNameAsc = 'TRADE_NAME_ASC',
  /** Сортировка по убыванию названия */
  TradeNameDesc = 'TRADE_NAME_DESC',
  /** Сортировка по возрастанию последнего обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка по убыванию последнего обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type TradeNameTranslation = {
  __typename?: 'TradeNameTranslation';
  locale: Locale;
  tradeName: Scalars['String']['output'];
};

export type TradeNameTranslationDiff = {
  __typename?: 'TradeNameTranslationDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<TradeNameTranslation>;
  rhs?: Maybe<TradeNameTranslation>;
};

export type TradeNameTranslationInput = {
  /** Идентификатор локали */
  localeId: Scalars['Int']['input'];
  /** Перевод торгового наименования */
  tradeName: Scalars['String']['input'];
};

export enum TradeNameTranslationSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type TradeNameUpdateInput = {
  /** Регистрационный номер юрлица торгового наименования */
  TIN?: InputMaybe<Scalars['String']['input']>;
  /** Код деятельности юрлица */
  activityCode?: InputMaybe<Scalars['String']['input']>;
  /** Регистрационный номер филиала юрлица торгового наименования */
  branchNumber?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Основной email торгового наименования */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Широта адреса торгового наименования */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** Адрес юрлица */
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор формы юрлица */
  legalEntityFormId?: InputMaybe<Scalars['Int']['input']>;
  /** Юридическое название торгового наименования */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на логотип торгового наименования */
  logo?: InputMaybe<Scalars['URL']['input']>;
  /** Долгота адреса торгового наименования */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** Основной телефон торгового наименования */
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Адрес для почтовых отправлений */
  postAddress?: InputMaybe<Scalars['String']['input']>;
  /** Почтовый индекс */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор страны для почтовых отправлений */
  postCountryId?: InputMaybe<Scalars['Int']['input']>;
  /** Новое написание торгового наименования */
  tradeName?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор торгового наименования */
  tradeNameId: Scalars['ID']['input'];
  /** Переводы торгового наименования */
  translations?: Array<TradeNameTranslationInput>;
  /** Номер налогоплательщика юрлица */
  vat?: InputMaybe<Scalars['String']['input']>;
  /** Основной сайт торгового наименования */
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type TradeNameUpdatePayload = {
  __typename?: 'TradeNameUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<TradeName>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type TradeNameWritePolicy = {
  __typename?: 'TradeNameWritePolicy';
  email: WritePolicy;
  latitude: WritePolicy;
  legalEntity: WritePolicy;
  logo: WritePolicy;
  longitude: WritePolicy;
  phone: WritePolicy;
  postAddress: WritePolicy;
  postCode: WritePolicy;
  postCountry: WritePolicy;
  translations: WritePolicy;
  website: WritePolicy;
};

export type TradeOffer = {
  __typename?: 'TradeOffer';
  additionalId?: Maybe<Scalars['ID']['output']>;
  archivedReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isProductURLUnique: Scalars['Boolean']['output'];
  isVintageUnknown: Scalars['Boolean']['output'];
  lots: Array<TradeOfferLot>;
  lotsPagination: TradeOfferLotPagination;
  merchant?: Maybe<Merchant>;
  priceHistory: TradeOfferPriceHistoryPagination;
  priceSource?: Maybe<PriceSource>;
  product?: Maybe<Product>;
  productURL?: Maybe<Scalars['URL']['output']>;
  reference: TradeOfferReference;
  status: TradeOfferStatus;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
  virtualStatus: TradeOfferVirtualStatus;
};


export type TradeOfferLotsPaginationArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: InputMaybe<TradeOfferLotSort>;
  uniqueWineLot?: Scalars['Boolean']['input'];
};


export type TradeOfferPriceHistoryArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: TradeOfferLotHistorySort;
};

export type TradeOfferLot = {
  __typename?: 'TradeOfferLot';
  authorUser?: Maybe<User>;
  /** Признак добавления в список для сравнения */
  compared: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Currency>;
  deliveryCost: DeliveryCost;
  deliveryTime?: Maybe<DeliveryTime>;
  id: Scalars['ID']['output'];
  lot: WineLot;
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  priceHistory: TradeOfferPriceHistoryPagination;
  purchaseCondition?: Maybe<PurchaseCondition>;
  retailCoef?: Maybe<Scalars['Float']['output']>;
  status: TradeOfferLotStatus;
  tradeOffer: TradeOffer;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
  vatBehavior: VatBehavior;
};


export type TradeOfferLotComparedArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type TradeOfferLotPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  wineLotId?: InputMaybe<Scalars['Int']['input']>;
};


export type TradeOfferLotPriceHistoryArgs = {
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
};

export type TradeOfferLotCompareAddInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы торговых лотов */
  tradeOfferLotId: Array<Scalars['ID']['input']>;
};

export type TradeOfferLotCompareAddPayload = {
  __typename?: 'TradeOfferLotCompareAddPayload';
  query?: Maybe<Query>;
  records: Array<TradeOfferLot>;
};

export type TradeOfferLotCompareRemoveInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы торговых лотов */
  tradeOfferLotId: Array<Scalars['ID']['input']>;
};

export type TradeOfferLotCompareRemovePayload = {
  __typename?: 'TradeOfferLotCompareRemovePayload';
  query?: Maybe<Query>;
  records: Array<TradeOfferLot>;
};

export enum TradeOfferLotExportColumn {
  BottleSize = 'BOTTLE_SIZE',
  Brand = 'BRAND',
  DeliveryCost = 'DELIVERY_COST',
  DeliveryTimeName = 'DELIVERY_TIME_NAME',
  ExternalProductUrl = 'EXTERNAL_PRODUCT_URL',
  GeoIndication = 'GEO_INDICATION',
  Keywords = 'KEYWORDS',
  MerchantCountry = 'MERCHANT_COUNTRY',
  MerchantName = 'MERCHANT_NAME',
  MerchantWebsite = 'MERCHANT_WEBSITE',
  NationalGiType = 'NATIONAL_GI_TYPE',
  PackageType = 'PACKAGE_TYPE',
  PricePerLot = 'PRICE_PER_LOT',
  PricePerLotCurrency = 'PRICE_PER_LOT_CURRENCY',
  ProductSlug = 'PRODUCT_SLUG',
  PurchaseCondition = 'PURCHASE_CONDITION',
  SpecialClassification = 'SPECIAL_CLASSIFICATION',
  Status = 'STATUS',
  SweetnessLevel = 'SWEETNESS_LEVEL',
  TradeName = 'TRADE_NAME',
  TradeNameCountry = 'TRADE_NAME_COUNTRY',
  TradeNameEmail = 'TRADE_NAME_EMAIL',
  TradeNamePhone = 'TRADE_NAME_PHONE',
  TradeNameWebsite = 'TRADE_NAME_WEBSITE',
  TradeOfferId = 'TRADE_OFFER_ID',
  VatBehavior = 'VAT_BEHAVIOR',
  Vintage = 'VINTAGE',
  WineColor = 'WINE_COLOR',
  WineCountry = 'WINE_COUNTRY',
  WineLotBottleQuantity = 'WINE_LOT_BOTTLE_QUANTITY',
  WineType = 'WINE_TYPE'
}

export enum TradeOfferLotHistorySort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type TradeOfferLotPagination = {
  __typename?: 'TradeOfferLotPagination';
  items: Array<TradeOfferLot>;
  pageInfo: PageInfo;
};

export enum TradeOfferLotSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка по возрастанию названия магазина */
  MerchantNameAsc = 'MERCHANT_NAME_ASC',
  /** Сортировка по убыванию названия магазина */
  MerchantNameDesc = 'MERCHANT_NAME_DESC',
  /** Сортировка по возрастанию минимальной розничной цены */
  MinRecommendedPriceAsc = 'MIN_RECOMMENDED_PRICE_ASC',
  /** Сортировка по убыванию минимальной розничной цены (только для покупателя) */
  MinRecommendedPriceDesc = 'MIN_RECOMMENDED_PRICE_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  /** Сортировка по возрастанию рейтинга WWL */
  RatingAsc = 'RATING_ASC',
  /** Сортировка по убыванию рейтинга WWL */
  RatingDesc = 'RATING_DESC',
  RetailCoefAsc = 'RETAIL_COEF_ASC',
  RetailCoefDesc = 'RETAIL_COEF_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка по возрастанию составного названия вина */
  WineNameAsc = 'WINE_NAME_ASC',
  /** Сортировка по убыванию составного названия вина */
  WineNameDesc = 'WINE_NAME_DESC'
}

export enum TradeOfferLotStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Hidden = 'HIDDEN'
}

export type TradeOfferLotsPaginationFilter = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  onlyCompare?: Scalars['Boolean']['input'];
  onlyMyRange?: Scalars['Boolean']['input'];
};

export type TradeOfferOrLot = TradeOffer | TradeOfferLot;

export type TradeOfferOrLotPagination = {
  __typename?: 'TradeOfferOrLotPagination';
  items: Array<TradeOfferOrLot>;
  pageInfo: PageInfo;
};

export type TradeOfferPagination = {
  __typename?: 'TradeOfferPagination';
  items: Array<TradeOffer>;
  pageInfo: PageInfo;
};

export type TradeOfferPriceHistory = {
  __typename?: 'TradeOfferPriceHistory';
  authorUser?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  currency: Currency;
  deliveryTime?: Maybe<DeliveryTime>;
  id: Scalars['ID']['output'];
  lot: WineLot;
  price: Scalars['NonNegativeFloat']['output'];
};

export type TradeOfferPriceHistoryPagination = {
  __typename?: 'TradeOfferPriceHistoryPagination';
  items: Array<TradeOfferPriceHistory>;
  pageInfo: PageInfo;
};

export type TradeOfferReference = {
  __typename?: 'TradeOfferReference';
  bottleSize?: Maybe<BottleSize>;
  packageType?: Maybe<WinePackageType>;
  wineDescription?: Maybe<Scalars['String']['output']>;
  wineName?: Maybe<Scalars['String']['output']>;
};

export type TradeOfferRequestInput = {
  note: Scalars['String']['input'];
  productURL: Scalars['URL']['input'];
};

export type TradeOfferRequestPayload = {
  __typename?: 'TradeOfferRequestPayload';
  query?: Maybe<Query>;
};

export enum TradeOfferSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WineNameAsc = 'WINE_NAME_ASC',
  WineNameDesc = 'WINE_NAME_DESC'
}

export enum TradeOfferStatus {
  /** Active offer */
  Active = 'ACTIVE',
  /** Archived offer */
  Deleted = 'DELETED',
  /** Out of stock */
  Hidden = 'HIDDEN',
  /** Page unavailable */
  Unavailable = 'UNAVAILABLE'
}

export enum TradeOfferVirtualStatus {
  /** Active offer */
  Active = 'ACTIVE',
  /** Archived offer */
  Deleted = 'DELETED',
  /** Out of stock */
  Hidden = 'HIDDEN',
  /** Page unavailable */
  Unavailable = 'UNAVAILABLE',
  /** Product undefined */
  Undefined = 'UNDEFINED'
}

export type TransformationsInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  blur?: InputMaybe<Scalars['Float']['input']>;
  crop?: InputMaybe<CropInput>;
  dpr?: InputMaybe<Scalars['Float']['input']>;
  enlarge?: InputMaybe<Scalars['Int']['input']>;
  extend?: InputMaybe<Scalars['Int']['input']>;
  extension?: InputMaybe<FormatType>;
  format?: InputMaybe<FormatType>;
  gravity?: InputMaybe<GravityInput>;
  height?: InputMaybe<Scalars['Int']['input']>;
  preset?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quality?: InputMaybe<Scalars['String']['input']>;
  resize?: InputMaybe<ResizeType>;
  sharpen?: InputMaybe<Scalars['Float']['input']>;
  watermark?: InputMaybe<WatermarkInput>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Изменение адреса */
export type UrlDiff = {
  __typename?: 'URLDiff';
  /** Совпадает ли новое значение с текущим */
  current: Scalars['Boolean']['output'];
  /** Старое значение адреса */
  lhs?: Maybe<Scalars['URL']['output']>;
  /** Новое значение адреса */
  rhs?: Maybe<Scalars['URL']['output']>;
};

/** Единица измерения */
export type Unit = {
  __typename?: 'Unit';
  /** Идентификатор единицы измерения */
  id: Scalars['Int']['output'];
  /** Перевод названия единицы измерения */
  localizedName: Scalars['String']['output'];
  /** Название единицы измерения */
  masterName: Scalars['String']['output'];
  /** Группы единиц измерений */
  unitGroups: Array<UnitGroup>;
};


/** Единица измерения */
export type UnitLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Группа единиц измерений (например, размеры бутылок) */
export type UnitGroup = {
  __typename?: 'UnitGroup';
  id: Scalars['Int']['output'];
  /** Название группы единиц измерений */
  masterName: Scalars['String']['output'];
  /** Список единиц измерений в группе */
  units: Array<Unit>;
};

/** Пользователь системы */
export type User = {
  __typename?: 'User';
  /** Аватар пользователя */
  avatar?: Maybe<Image>;
  /** Биография пользователя */
  bio?: Maybe<Scalars['String']['output']>;
  /** Дата блокировки */
  blockedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Причина блокировки пользователя */
  blockedReason?: Maybe<Scalars['String']['output']>;
  /** Количество изменений брендов по дням начиная с сегодня */
  brandsContributedSeries: Array<BrandContributedSeriesPayloadItem>;
  /** Список компаний пользователя */
  companies: Array<Company>;
  /** Поиск компании пользователя по идентификатору */
  company?: Maybe<Company>;
  /** Страна пользователя */
  country?: Maybe<Country>;
  /** Дата регистрации */
  createdAt: Scalars['DateTime']['output'];
  /** Ник пользователя или ФИО */
  displayName: Scalars['String']['output'];
  /** Email пользователя */
  email: Scalars['String']['output'];
  /**
   * Квалификация дегустатора
   * @deprecated Use expertQualifications
   */
  expertQualification?: Maybe<ExpertQualification>;
  /** Ссылки на документы, подтверждающие квалификацию пользователя */
  expertQualificationDocs: Array<Scalars['URL']['output']>;
  /** Квалификации дегустатора */
  expertQualifications: Array<UserExpert>;
  /**
   * Информация о верификации дегустатора
   * @deprecated Use expertQualifications.verification
   */
  expertVerification?: Maybe<UserExpertVerification>;
  externalCompanies: Array<ExternalCompany>;
  facebook?: Maybe<Scalars['String']['output']>;
  /** Сохраненные пресеты фильтров */
  filterPresets: Array<FilterPreset>;
  /** Скрыт ли диалог выбора компании при первом входе */
  hideBecomeRepresentative?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор пользователя */
  id: Scalars['ID']['output'];
  /** Список приглашенных пользователей */
  invitedUsers: Array<User>;
  /** Установлен ли у пользователя пароль */
  isPasswordSet: Scalars['Boolean']['output'];
  /** Зарегистрирован ли пользователь с помощью внешнего сервиса */
  isSignupWithThirdParty: Scalars['Boolean']['output'];
  /** Подтвержденная ли почта у пользователя */
  isVerifiedEmail: Scalars['Boolean']['output'];
  /**
   * Является ли пользователь верифированным дегустатором
   * @deprecated Use expertQualifications.isVerified
   */
  isVerifiedExpert: Scalars['Boolean']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  /** Текущая локаль пользователя */
  locale?: Maybe<Locale>;
  /** Имя пользователя */
  name: Scalars['String']['output'];
  /** Настройки уведомлений пользователя */
  notificationSettings: UserNotificationSettings;
  pageSettings: UserPageSettings;
  permissions: UserPermissions;
  /** Список последних посещений пользователем продуктов */
  productVisits: Array<Product>;
  /** Роль пользователя */
  role?: Maybe<UserRole>;
  /** Предпочитаемая система оценки пользователя */
  scoreSystem: ScoreSystem;
  /** Статус пользователя */
  status: UserStatus;
  /** Комментарий к изменению статуса */
  statusComment?: Maybe<Scalars['String']['output']>;
  /** Пользователь, который обновил статус */
  statusUpdateUser?: Maybe<User>;
  /** Количество изменений торговых наименований по дням начиная с сегодня */
  tradeNamesContributedSeries: Array<TradeNameContributedSeriesPayloadItem>;
  tutorialCompleted: Scalars['Boolean']['output'];
  twitter?: Maybe<Scalars['String']['output']>;
  /** Ник пользователя */
  username: Scalars['String']['output'];
  website?: Maybe<Scalars['URL']['output']>;
  /** @deprecated Not used */
  wineAwardContributedPagination?: Maybe<WineAwardPagination>;
  wineReviewsAggregation: WineReviewsAggregation;
  /** Количество изменений вин по дням начиная с сегодня */
  winesContributedSeries: Array<WineContributedSeriesPayloadItem>;
};


/** Пользователь системы */
export type UserBrandsContributedSeriesArgs = {
  limit?: Scalars['Int']['input'];
};


/** Пользователь системы */
export type UserCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** Пользователь системы */
export type UserFilterPresetsArgs = {
  page?: InputMaybe<Scalars['String']['input']>;
};


/** Пользователь системы */
export type UserPageSettingsArgs = {
  page: Scalars['String']['input'];
};


/** Пользователь системы */
export type UserProductVisitsArgs = {
  limit: Scalars['Int']['input'];
};


/** Пользователь системы */
export type UserTradeNamesContributedSeriesArgs = {
  limit?: Scalars['Int']['input'];
};


/** Пользователь системы */
export type UserTutorialCompletedArgs = {
  page: Scalars['String']['input'];
};


/** Пользователь системы */
export type UserWineAwardContributedPaginationArgs = {
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineAwardSort;
};


/** Пользователь системы */
export type UserWinesContributedSeriesArgs = {
  limit?: Scalars['Int']['input'];
};

export type UserActivatePayload = {
  __typename?: 'UserActivatePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<User>>>;
};

export type UserArchivePayload = {
  __typename?: 'UserArchivePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<User>>>;
};

export type UserBanPayload = {
  __typename?: 'UserBanPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<User>>>;
};

export type UserChangePageCompanyCategoryInput = {
  companyCategoryId: Scalars['Int']['input'];
  page: Scalars['String']['input'];
};

export type UserChangePageCompanyCategoryPayload = {
  __typename?: 'UserChangePageCompanyCategoryPayload';
  query: Query;
  user: User;
};

export type UserChangePageCurrencyInput = {
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['String']['input'];
};

export type UserChangePageCurrencyPayload = {
  __typename?: 'UserChangePageCurrencyPayload';
  query: Query;
  user: User;
};

export type UserChangePagePostCountryInput = {
  page: Scalars['String']['input'];
  postCountryId: Scalars['Int']['input'];
};

export type UserChangePagePostCountryPayload = {
  __typename?: 'UserChangePagePostCountryPayload';
  query: Query;
  user: User;
};

export type UserChangePermissionsPayload = {
  __typename?: 'UserChangePermissionsPayload';
  query?: Maybe<Query>;
  record?: Maybe<User>;
};

export type UserChangeRolePayload = {
  __typename?: 'UserChangeRolePayload';
  query?: Maybe<Query>;
  user?: Maybe<User>;
};

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  query?: Maybe<Query>;
};

export type UserExpert = {
  __typename?: 'UserExpert';
  isVerified: Scalars['Boolean']['output'];
  qualification: ExpertQualification;
  verification: UserExpertVerification;
};

export type UserExpertVerification = {
  __typename?: 'UserExpertVerification';
  comment?: Maybe<Scalars['String']['output']>;
  rejectReason?: Maybe<ExpertVerificationRejectReason>;
  status: ExpertVerificationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserFilter = {
  /** Выбранные значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Необходимо ли возвращать только удаленных пользователей */
  onlyDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификаторы статусов пользователей */
  userStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  expertQualificationDocs?: InputMaybe<Array<Scalars['URL']['input']>>;
  expertQualificationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  twitter?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type UserInviteInput = {
  localeId?: InputMaybe<Scalars['Int']['input']>;
  userEmail: Scalars['EmailAddress']['input'];
  userName: Scalars['String']['input'];
};

export type UserInvitePayload = {
  __typename?: 'UserInvitePayload';
  user: User;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  /** Получение новостей WWL */
  news: Scalars['Boolean']['output'];
};

export type UserNotificationSettingsInput = {
  news?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPageSettings = {
  __typename?: 'UserPageSettings';
  companyCategory: CompanyCategory;
  currency?: Maybe<Currency>;
  postCountry: Country;
};

export type UserPagination = {
  __typename?: 'UserPagination';
  items: Array<User>;
  pageInfo: PageInfo;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  reviewsConsidered: Scalars['Boolean']['output'];
  reviewsDisplayed: Scalars['Boolean']['output'];
};

export type UserRecoverPayload = {
  __typename?: 'UserRecoverPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<User>>>;
};

export type UserRemoveBanPayload = {
  __typename?: 'UserRemoveBanPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<Maybe<User>>>;
};

/** Роль пользователя */
export type UserRole = {
  __typename?: 'UserRole';
  /** Код роли пользователя */
  code: Scalars['String']['output'];
  /** Идентификатор роли пользователя */
  id: Scalars['Int']['output'];
  /** Перевод названия роли пользователя */
  localizedName: Scalars['String']['output'];
  /** Название роли пользователя на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Роль пользователя */
export type UserRoleLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export enum UserSort {
  /** Сортировка пользователей по возрастанию даты регистрации */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка пользователей по убыванию даты регистрации */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка пользователей по возрастанию почты */
  EmailAsc = 'EMAIL_ASC',
  /** Сортировка пользователей по убыванию почты */
  EmailDesc = 'EMAIL_DESC',
  /** Сортировка пользователей по возрастанию фамилии */
  LastNameAsc = 'LAST_NAME_ASC',
  /** Сортировка пользователей по убыванию фамилии */
  LastNameDesc = 'LAST_NAME_DESC',
  /** Сортировка пользователей по возрастанию имени */
  NameAsc = 'NAME_ASC',
  /** Сортировка пользователей по убыванию имени */
  NameDesc = 'NAME_DESC',
  /** Сортировка пользователей по возрастанию названия роли */
  RoleNameAsc = 'ROLE_NAME_ASC',
  /** Сортировка пользователей по убыванию названия роли */
  RoleNameDesc = 'ROLE_NAME_DESC',
  /** Сортировка пользователей по возрастанию названия статуса */
  StatusNameAsc = 'STATUS_NAME_ASC',
  /** Сортировка пользователей по убыванию названия статуса */
  StatusNameDesc = 'STATUS_NAME_DESC'
}

/** Статус пользователя */
export type UserStatus = {
  __typename?: 'UserStatus';
  /** Код статуса пользователя */
  code: Scalars['String']['output'];
  /** Идентификатор статуса пользователя */
  id: Scalars['Int']['output'];
  /** Перевод названия статуса пользователя */
  localizedName: Scalars['String']['output'];
  /** Название статуса пользователя на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Статус пользователя */
export type UserStatusLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type UserTutorialCompletedPayload = {
  __typename?: 'UserTutorialCompletedPayload';
  query: Query;
  user: User;
};

export type UserVerifyExpertPayload = {
  __typename?: 'UserVerifyExpertPayload';
  query?: Maybe<Query>;
  record?: Maybe<User>;
};

export type ValueWithUnit = {
  __typename?: 'ValueWithUnit';
  unit: Unit;
  value: Scalars['Float']['output'];
};

export type ValueWithUnitDiff = {
  __typename?: 'ValueWithUnitDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<ValueWithUnit>;
  rhs?: Maybe<ValueWithUnit>;
};

export type ValueWithUnitInput = {
  unitId: Scalars['Int']['input'];
  /** Значение полученное после измерения, включает в себя целые и вещественные числа (для простоты) */
  value: Scalars['Float']['input'];
};

/** Способ применения НДС */
export type VatBehavior = {
  __typename?: 'VatBehavior';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  localizedName: Scalars['String']['output'];
};


/** Способ применения НДС */
export type VatBehaviorLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Склад */
export type Warehouse = {
  __typename?: 'Warehouse';
  /** Адрес склада */
  address: Scalars['String']['output'];
  /** Страна склада */
  country: Country;
  /** Идентификатор склада */
  id: Scalars['ID']['output'];
  /** Используется ли склад в коммерческих предложениях */
  isUsedInWineOffers: Scalars['Boolean']['output'];
  /** Широта адреса склада */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Долгота адреса склада */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Название склада */
  name: Scalars['String']['output'];
  /** Территория склада */
  territory?: Maybe<Territory>;
};

/** Параметры создания склада */
export type WarehouseCreateInput = {
  /** Адрес склада */
  address: Scalars['String']['input'];
  /** ID компании под которой работает пользователь */
  companyId: Scalars['ID']['input'];
  /** ID страны, где расположен склад */
  countryId: Scalars['ID']['input'];
  /** Широта адреса расположения склада */
  latitude: Scalars['Float']['input'];
  /** Долгота адреса расположения склада */
  longitude: Scalars['Float']['input'];
  /** Название склада */
  name: Scalars['String']['input'];
};

export type WarehouseCreatePayload = {
  __typename?: 'WarehouseCreatePayload';
  query?: Maybe<Query>;
  warehouse: Warehouse;
};

export enum WarehouseSort {
  /** Сортировка складов по убыванию адреса */
  AddressAsc = 'ADDRESS_ASC',
  /** Сортировка складов по возрастанию адреса */
  AddressDesc = 'ADDRESS_DESC',
  /** Сортировка складов по убыванию названия страны */
  CountryNameAsc = 'COUNTRY_NAME_ASC',
  /** Сортировка складов по возрастанию названия страны */
  CountryNameDesc = 'COUNTRY_NAME_DESC',
  /** Сортировка складов по убыванию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка складов по возрастанию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

/** Параметры обновления компании */
export type WarehouseUpdateInput = {
  /** Адрес склада */
  address: Scalars['String']['input'];
  /** ID компании под которой работает пользователь */
  companyId: Scalars['ID']['input'];
  /** ID страны, где расположен склад */
  countryId: Scalars['ID']['input'];
  /** Широта адреса расположения склада */
  latitude: Scalars['Float']['input'];
  /** Долгота адреса расположения склада */
  longitude: Scalars['Float']['input'];
  /** Название склада */
  name: Scalars['String']['input'];
  /** ID редактируемого склада */
  warehouseId: Scalars['ID']['input'];
};

export type WarehouseUpdatePayload = {
  __typename?: 'WarehouseUpdatePayload';
  query?: Maybe<Query>;
  warehouse: Warehouse;
};

/** Параметры удаления складов */
export type WarehousesDeleteInput = {
  /** ID компании под которой работает пользователь */
  companyId: Scalars['ID']['input'];
  /** Массив ID складов, которые нужно удалить */
  warehouseIds: Array<Scalars['ID']['input']>;
};

export type WarehousesDeletePayload = {
  __typename?: 'WarehousesDeletePayload';
  query?: Maybe<Query>;
  /** ID складов, которые были удалены */
  recordIds: Array<Scalars['ID']['output']>;
};

export type WarehousesPagination = {
  __typename?: 'WarehousesPagination';
  items: Array<Warehouse>;
  pageInfo: PageInfo;
};

export type WatermarkInput = {
  opacity?: InputMaybe<Scalars['Float']['input']>;
  position?: InputMaybe<PositionType>;
  scale?: InputMaybe<Scalars['Float']['input']>;
  xOffset?: InputMaybe<Scalars['Int']['input']>;
  yOffset?: InputMaybe<Scalars['Int']['input']>;
};

/** Вино */
export type Wine = {
  __typename?: 'Wine';
  /** Признак допустимости вина */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /** Последний автор записи */
  authorUser?: Maybe<User>;
  /**
   * Средний возраст виноградника в годах
   * @deprecated Not used
   */
  averageAgeOfVines?: Maybe<Scalars['Int']['output']>;
  /** Список конкурсов */
  awards: Array<WineAward>;
  /** Бренд вина */
  brand?: Maybe<Brand>;
  canOverrideAcceptability: Scalars['Boolean']['output'];
  /** @deprecated Use write policy */
  canOverrideFacts: Scalars['Boolean']['output'];
  /** Сертификаты вина (как сущность) */
  certifications: Array<WineCertification>;
  /** Сертификаты вина */
  certs: Array<Certification>;
  changelog: Array<WineChangelogItem>;
  /** Химический анализ вина */
  chemicalAnalysis?: Maybe<WineChemicalAnalysis>;
  /** @deprecated Not used */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** @deprecated Not used */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** @deprecated Not used */
  contactProducerTradeName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Not used */
  contactWebsite?: Maybe<Scalars['URL']['output']>;
  /** Страна производства */
  country?: Maybe<Country>;
  /**
   * Описание вина
   * @deprecated Not used
   */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Урожайность
   * @deprecated Not used
   */
  field?: Maybe<ValueWithUnit>;
  /** Процент заполнения информации */
  fillPercent: Scalars['Int']['output'];
  /** Информация о сочетаниях вина */
  foodPairs: Array<FoodPair>;
  /**
   * Гео-индикация вина без привязки
   * @deprecated Not used
   */
  geoIndicationText?: Maybe<Scalars['String']['output']>;
  /** Гео-индикация вина */
  geoIndications: Array<GeoIndication>;
  /** Сортовой состав вина */
  grapeVarieties: Array<WineGrapeVariety>;
  /** Идентификатор вина */
  id: Scalars['ID']['output'];
  /** Ингредиенты вина */
  ingredients: Array<Ingredient>;
  /**
   * Признак является ли сборка урожая ручной
   * @deprecated Not used
   */
  isHandHarvest?: Maybe<Scalars['Boolean']['output']>;
  /** Ключевые слова при описании вина */
  keyWords?: Maybe<Scalars['String']['output']>;
  /** Основной синоним вина */
  mainSynonym?: Maybe<Wine>;
  /**
   * Описание процесса созревания
   * @deprecated Not used
   */
  maturation?: Maybe<Scalars['String']['output']>;
  /** Национальный тип гео-индикации */
  nationalGIType?: Maybe<NationalGiType>;
  /**
   * Национальный тип гео-индикации без привязки
   * @deprecated Not used
   */
  nationalGITypeText?: Maybe<Scalars['String']['output']>;
  nutritionDeclaration?: Maybe<WineNutritionDeclaration>;
  /**
   * Плотность посадки
   * @deprecated Not used
   */
  plantingDensity?: Maybe<ValueWithUnit>;
  /** @deprecated Use canOverrideAcceptability */
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use canOverrideFacts */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  /**
   * Площадь посадки
   * @deprecated Not used
   */
  productionArea?: Maybe<ValueWithUnit>;
  /** Продукты связанные с вином */
  products: Array<Product>;
  /**
   * Метод обрезки
   * @deprecated Not used
   */
  pruningMethod?: Maybe<Scalars['String']['output']>;
  /** Рейтинг вина в WWL */
  rating?: Maybe<Scalars['Int']['output']>;
  responsibleConsumptions: Array<ResponsibleConsumption>;
  /** Максимальная температура подачи */
  servingTemperatureMax?: Maybe<Scalars['Int']['output']>;
  /** Минимальная температура подачи */
  servingTemperatureMin?: Maybe<Scalars['Int']['output']>;
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  /**
   * Описание почвы
   * @deprecated Not used
   */
  soil?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Source>;
  /** Первый автор записи */
  sourceAuthorUser?: Maybe<User>;
  /** Список источников использованных для формирования записи */
  sources: Array<Source>;
  /** Специальные классификации вина */
  specialClassifications: Array<SpecialClassification>;
  /** Уровень содержания сахара */
  sweetnessLevel?: Maybe<SweetnessLevel>;
  /**
   * Дегустационная заметка от производителя
   * @deprecated Moved to reviews
   */
  tastingNote?: Maybe<Scalars['String']['output']>;
  /** Техлист для языка определенного языка (если не указан, то будет использован язык пользователя) */
  techSheet?: Maybe<TechSheet>;
  /** Список техлистов на разных языках */
  techSheets: Array<TechSheet>;
  tradeName?: Maybe<TradeName>;
  /**
   * Название торгового наименования и название бренда.
   * Если бренд совпадает с торговым наименованием, то только торговое наименование
   */
  tradeNameBrand: Scalars['String']['output'];
  /** Дата и время последнего изменения */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verified: Scalars['Boolean']['output'];
  /**
   * Информация о процессе винификации
   * @deprecated Moved to techsheet
   */
  vinification?: Maybe<Scalars['String']['output']>;
  vintage?: Maybe<Scalars['PositiveInt']['output']>;
  /**
   * Информация о годе урожая, детали винтажа
   * @deprecated Moved to techsheet
   */
  vintageDetails?: Maybe<Scalars['String']['output']>;
  /** Линейка таких же вин с разными винтажами */
  vintageList: Array<Wine>;
  /**
   * Информация о выращивании
   * @deprecated Moved to techsheet
   */
  viticulture?: Maybe<Scalars['String']['output']>;
  /**
   * Информация о выдержке
   * @deprecated Moved to techsheet
   */
  wineAging?: Maybe<Scalars['String']['output']>;
  /** Цвет вина */
  wineColor?: Maybe<WineColor>;
  /** Тип вина */
  wineType?: Maybe<WineType>;
  /** Тип вина из справочника ЕС */
  wineTypeEU?: Maybe<WineTypeEu>;
  /**
   * Описание технологии производства вина
   * @deprecated Not used
   */
  winemaking?: Maybe<Scalars['String']['output']>;
  /** Политика записи для текущего пользователя */
  writePolicy: WineWritePolicy;
};


/** Вино */
export type WineAwardsArgs = {
  onlyAcceptable?: Scalars['Boolean']['input'];
};


/** Вино */
export type WineCertsArgs = {
  onlyAcceptable?: Scalars['Boolean']['input'];
};


/** Вино */
export type WineProductsArgs = {
  onlyAcceptable?: Scalars['Boolean']['input'];
};


/** Вино */
export type WineTechSheetArgs = {
  localeId?: InputMaybe<Scalars['Int']['input']>;
};


/** Вино */
export type WineVintageListArgs = {
  onlyAcceptable?: Scalars['Boolean']['input'];
};

export type WineAcceptInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  wineId: Scalars['ID']['input'];
};

export type WineAcceptPayload = {
  __typename?: 'WineAcceptPayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineAllergen = {
  __typename?: 'WineAllergen';
  allergen: Allergen;
  isUsed: Scalars['Boolean']['output'];
};

/** Участие вина в конкурсе */
export type WineAward = {
  __typename?: 'WineAward';
  /** Является ли текущая запись допустимой */
  acceptability?: Maybe<Scalars['Boolean']['output']>;
  /** Конкурс */
  award: Award;
  /** У пользователя приоритет равен или выше приоритета записи о допустимости сущности */
  canOverrideAcceptability: Scalars['Boolean']['output'];
  /** У пользователя приоритет равен или выше приоритета записи о информации сущности */
  canOverrideFacts: Scalars['Boolean']['output'];
  /** Дата создания участия */
  createdAt: Scalars['DateTime']['output'];
  /** Идентификатор участия вина в конкурсе */
  id: Scalars['ID']['output'];
  /** Максимальный балл */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Минимальный балл */
  minScore?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use canOverrideAcceptability */
  priorityAcceptability?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use canOverrideFacts */
  priorityFacts?: Maybe<Scalars['Int']['output']>;
  /** Список полученных наград */
  rewards: Array<AwardReward>;
  /** У пользователя приоритет выше приоритета записи о допустимости сущнности */
  shouldOverrideAcceptability: Scalars['Boolean']['output'];
  /** Является ли запись верифицированной (приоритет больше системного (производителя)) */
  verified: Scalars['Boolean']['output'];
  /** Вино */
  wine: Wine;
  /** Год участия */
  year: Scalars['PositiveInt']['output'];
};

export type WineAwardAcceptabilityDiff = {
  __typename?: 'WineAwardAcceptabilityDiff';
  current: Scalars['Boolean']['output'];
  /** Старый признак допустимости (если он был) */
  lhs?: Maybe<Scalars['Boolean']['output']>;
  /** Метаданные изменения */
  metadata: EntityDiffMetadata;
  /** Новый признак допустимости */
  rhs: Scalars['Boolean']['output'];
  /** Награда у которой изменился признак допустимости */
  wineAward: WineAward;
};

export type WineAwardCompositeIdInput = {
  /** Идентификатор конкурса */
  awardId: Scalars['ID']['input'];
  /** Идентификатор вина */
  wineId: Scalars['ID']['input'];
  /** Год получения награды */
  year: Scalars['PositiveInt']['input'];
};

export type WineAwardDiff = {
  __typename?: 'WineAwardDiff';
  canDelete: Scalars['Boolean']['output'];
  canMove: Scalars['Boolean']['output'];
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  metadata: EntityDiffMetadata;
  rewards?: Maybe<Array<AwardRewardDiff>>;
  score?: Maybe<FloatRangeDiff>;
  wineAward: WineAward;
};

export type WineAwardFactDeleteInput = {
  id: Scalars['ID']['input'];
};

export type WineAwardFactDeletePayload = {
  __typename?: 'WineAwardFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineAward>;
};

export type WineAwardFactMoveInput = {
  /** ID факта */
  factId: Scalars['ID']['input'];
  /** ID вина */
  wineId: Scalars['ID']['input'];
};

export type WineAwardFactMovePayload = {
  __typename?: 'WineAwardFactMovePayload';
  originRecord?: Maybe<WineAward>;
  query?: Maybe<Query>;
  record?: Maybe<WineAward>;
  wine?: Maybe<Wine>;
};

export type WineAwardPagination = {
  __typename?: 'WineAwardPagination';
  items: Array<WineAward>;
  pageInfo: PageInfo;
};

export enum WineAwardSort {
  /** Сортировка участия в конкурсе по возрастанию названия конкурса */
  AwardNameAsc = 'AWARD_NAME_ASC',
  /** Сортировка участия в конкурсе по убыванию названия конкурса */
  AwardNameDesc = 'AWARD_NAME_DESC',
  /** Сортировка участия в конкурсе по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка участия в конкурсе по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка участия в конкурсе по возрастанию названия производителя */
  ProducerTradeNameAsc = 'PRODUCER_TRADE_NAME_ASC',
  /** Сортировка участия в конкурсе по убыванию названия производителя */
  ProducerTradeNameDesc = 'PRODUCER_TRADE_NAME_DESC',
  /** Сортировка участия в конкурсе по возрастанию оценки WWL */
  RatingAsc = 'RATING_ASC',
  /** Сортировка участия в конкурсе по убыванию оценки WWL */
  RatingDesc = 'RATING_DESC',
  /** Сортировка участия в конкурсе по возрастанию балла */
  ScoreAsc = 'SCORE_ASC',
  /** Сортировка участия в конкурсе по убыванию балла */
  ScoreDesc = 'SCORE_DESC',
  /** Сортировка участия в конкурсе по возрастанию названия геоиндикации */
  WineRegionNameAsc = 'WINE_REGION_NAME_ASC',
  /** Сортировка участия в конкурсе по убыванию названия геоиндикации */
  WineRegionNameDesc = 'WINE_REGION_NAME_DESC'
}

export type WineAwardsUpdateInput = {
  /** Информация об участии в конкурсах */
  awards?: InputMaybe<Array<WineUpdateAwardInput>>;
  /** Текущая компания пользователя */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы участий в конкурсах для признания недопустимыми */
  refuseWineAwardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Идентификатор вина */
  wineId: Scalars['ID']['input'];
};

export type WineAwardsUpdatePayload = {
  __typename?: 'WineAwardsUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineCertification = {
  __typename?: 'WineCertification';
  acceptability: Scalars['Boolean']['output'];
  canOverrideAcceptability: Scalars['Boolean']['output'];
  certification: Certification;
};

export type WineChangelogItem = AcceptabilityDiff | ProductAcceptabilityDiff | ProductDiff | TechSheetAcceptabilityDiff | TechSheetDiff | WineAwardAcceptabilityDiff | WineAwardDiff | WineDiff;

/** Запись о химическом анализе вина */
export type WineChemicalAnalysis = {
  __typename?: 'WineChemicalAnalysis';
  /** AKA Tartaric acid */
  acidityTotal?: Maybe<ValueWithUnit>;
  /** @deprecated Not used in UI */
  acidityVolatile?: Maybe<ValueWithUnit>;
  alcoholVolume?: Maybe<Scalars['Float']['output']>;
  /** Дата анализа */
  date?: Maybe<Scalars['Date']['output']>;
  /** Идентификатор химического анализа */
  id: Scalars['Int']['output'];
  pH?: Maybe<Scalars['Float']['output']>;
  residualSugar?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Not used */
  sulphurDioxideFree?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Not used */
  sulphurDioxideTotal?: Maybe<Scalars['Int']['output']>;
};

/** Цвет вина */
export type WineColor = {
  __typename?: 'WineColor';
  /** Идентификатор цвета вина */
  id: Scalars['Int']['output'];
  /** Перевод названия цвета вина */
  localizedName: Scalars['String']['output'];
  /** Название цвета вина на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Цвет вина */
export type WineColorLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type WineCompositeIdInput = {
  brandId: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
  geoIndication?: InputMaybe<WineGeoIndicationInput>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  nationalGIType?: InputMaybe<WineNationalGiTypeInput>;
  specialClassificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  sweetnessLevelId: Scalars['Int']['input'];
  tradeNameId: Scalars['Int']['input'];
  vintage?: InputMaybe<Scalars['PositiveInt']['input']>;
  wineColorId: Scalars['Int']['input'];
  wineTypeId: Scalars['Int']['input'];
};

export type WineContributedSeriesPayloadItem = {
  __typename?: 'WineContributedSeriesPayloadItem';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type WineCreateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  compositeId: WineCompositeIdInput;
};

export type WineCreatePayload = {
  __typename?: 'WineCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineDatabaseFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Возвращать ли только допустимые вина */
  onlyAccepted?: Scalars['Boolean']['input'];
};

export type WineDiff = {
  __typename?: 'WineDiff';
  acidityTotal?: Maybe<ValueWithUnitDiff>;
  canDelete: Scalars['Boolean']['output'];
  canMove: Scalars['Boolean']['output'];
  foodPairs?: Maybe<Array<FoodPairsDiff>>;
  grapeVarieties?: Maybe<Array<WineGrapeVarietyDiff>>;
  /** ID факта о изменении */
  id: Scalars['ID']['output'];
  ingredients?: Maybe<Array<WineIngredientDiff>>;
  metadata: EntityDiffMetadata;
  pH?: Maybe<FloatDiff>;
  residualSugar?: Maybe<FloatDiff>;
  servingTemperatureMax?: Maybe<IntDiff>;
  servingTemperatureMin?: Maybe<IntDiff>;
};

export type WineELabel = {
  __typename?: 'WineELabel';
  /** Бренд вина */
  brand?: Maybe<BrandELabel>;
  /** Сертификаты вина */
  certs: Array<Certification>;
  chemicalAnalysis?: Maybe<WineChemicalAnalysis>;
  /** Страна производства */
  country?: Maybe<Country>;
  /** Гео-индикация вина */
  geoIndications: Array<GeoIndication>;
  /** Сортовой состав вина */
  grapeVarieties: Array<WineGrapeVarietyELabel>;
  ingredientDisplayVariant: ProductELabelIngredientDisplayVariant;
  ingredients: Array<Ingredient>;
  ingredientsFilled: Scalars['Boolean']['output'];
  /** Ключевые слова при описании вина */
  keyWords?: Maybe<Scalars['String']['output']>;
  /** Национальный тип гео-индикации */
  nationalGIType?: Maybe<NationalGiType>;
  nutritionDeclaration?: Maybe<WineNutritionDeclaration>;
  nutritionDeclarationFilled: Scalars['Boolean']['output'];
  responsibleConsumptions: Array<ResponsibleConsumption>;
  /** Специальные классификации вина */
  specialClassifications: Array<SpecialClassification>;
  /** Уровень содержания сахара */
  sweetnessLevel?: Maybe<SweetnessLevel>;
  tradeName: TradeNameELabel;
  tradeNameBrand: Scalars['String']['output'];
  vintage?: Maybe<Scalars['PositiveInt']['output']>;
  /** Цвет вина */
  wineColor?: Maybe<WineColor>;
  /** Тип вина */
  wineType?: Maybe<WineType>;
  wineTypeEU?: Maybe<WineTypeEu>;
};

export type WineFactDeleteInput = {
  id: Scalars['ID']['input'];
};

export type WineFactDeletePayload = {
  __typename?: 'WineFactDeletePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
};

export type WineFactMoveInput = {
  /** ID факта */
  factId: Scalars['ID']['input'];
  /** ID вина */
  wineId: Scalars['ID']['input'];
};

export type WineFactMovePayload = {
  __typename?: 'WineFactMovePayload';
  originRecord?: Maybe<Wine>;
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
};

export type WineFilter = {
  showHidden?: Scalars['Boolean']['input'];
};

export type WineFiltersFilter = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineGeoIndicationInput = {
  geoIndication?: InputMaybe<Scalars['String']['input']>;
  geoIndicationId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WineGrapeVariety = {
  __typename?: 'WineGrapeVariety';
  grapeVarietySynonym: GrapeVarietySynonym;
  /** Процентное содержание сорта винограда в вине */
  value?: Maybe<Scalars['Int']['output']>;
};

export type WineGrapeVarietyDiff = {
  __typename?: 'WineGrapeVarietyDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<WineGrapeVariety>;
  rhs?: Maybe<WineGrapeVariety>;
};

export type WineGrapeVarietyELabel = {
  __typename?: 'WineGrapeVarietyELabel';
  grapeVarietySynonym: GrapeVarietySynonym;
  /** Процентное содержание сорта винограда в вине */
  value?: Maybe<Scalars['Int']['output']>;
};

export type WineGrapeVarietyInput = {
  grapeVarietySynonymId: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type WineIngredientDiff = {
  __typename?: 'WineIngredientDiff';
  current: Scalars['Boolean']['output'];
  lhs?: Maybe<Ingredient>;
  rhs?: Maybe<Ingredient>;
};

export type WineKeyWordsUpdateInput = {
  keywords: Scalars['String']['input'];
  wineId: Scalars['ID']['input'];
};

export type WineKeyWordsUpdatePayload = {
  __typename?: 'WineKeyWordsUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

/** Лот поставки вина (размер поставки) */
export type WineLot = {
  __typename?: 'WineLot';
  /** Количество бутылок в лоте */
  bottleQuantity: Scalars['Int']['output'];
  /** Идентификатор лота поставки */
  id: Scalars['Int']['output'];
  /** Перевод названия лота поставки */
  localizedName: Scalars['String']['output'];
  /** Сокращенное название лота поставки */
  localizedShortName: Scalars['String']['output'];
  /** Название лота поставки на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Лот поставки вина (размер поставки) */
export type WineLotLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};


/** Лот поставки вина (размер поставки) */
export type WineLotLocalizedShortNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type WineNationalGiTypeInput = {
  nationalGIType?: InputMaybe<Scalars['String']['input']>;
  nationalGITypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** Запись о энергетической ценности вина */
export type WineNutritionDeclaration = {
  __typename?: 'WineNutritionDeclaration';
  carbohydrate?: Maybe<Scalars['NonNegativeFloat']['output']>;
  carbohydrateApproximate: Scalars['Boolean']['output'];
  energyKCal?: Maybe<Scalars['NonNegativeFloat']['output']>;
  energyKCalApproximate: Scalars['Boolean']['output'];
  energyKJ?: Maybe<Scalars['NonNegativeFloat']['output']>;
  fat?: Maybe<Scalars['NonNegativeFloat']['output']>;
  fatApproximate: Scalars['Boolean']['output'];
  protein?: Maybe<Scalars['NonNegativeFloat']['output']>;
  proteinApproximate: Scalars['Boolean']['output'];
  salt?: Maybe<Scalars['NonNegativeFloat']['output']>;
  saltApproximate: Scalars['Boolean']['output'];
  saturates?: Maybe<Scalars['NonNegativeFloat']['output']>;
  saturatesApproximate: Scalars['Boolean']['output'];
  sugars?: Maybe<Scalars['NonNegativeFloat']['output']>;
  sugarsApproximate: Scalars['Boolean']['output'];
};

export type WineOffer = WineOfferCompany | WineOfferCompanyDraft | WineOfferDeal | WineOfferPersonal | WineOfferPublic | WineOfferPublicDraft;

/** Общая информация о коммерческом предложении */
export type WineOfferBase = {
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency?: Maybe<Currency>;
  /** Список выставок где представлен продукт */
  exhibitions: Array<WineOfferExhibition>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms?: Maybe<Incoterms>;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot?: Maybe<WineLot>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle?: Maybe<Scalars['Float']['output']>;
  /** Продукт предложения */
  product?: Maybe<Product>;
  /** Компания продавца */
  sellerCompany?: Maybe<Company>;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Общая информация о коммерческом предложении */
export type WineOfferBaseHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Общая информация о коммерческом предложении */
export type WineOfferBasePriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Общая информация о коммерческом предложении */
export type WineOfferBasePricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Общая информация о коммерческом предложении */
export type WineOfferBaseShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

export type WineOfferBuyerCommentInput = {
  /** Комментарий к предложению */
  comment: Scalars['String']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferBuyerCommentPayload = {
  __typename?: 'WineOfferBuyerCommentPayload';
  oldRecord: WineOfferBase;
  query?: Maybe<Query>;
  record: WineOfferPersonal;
};

export type WineOfferBuyerOfferConditionsInput = {
  /** Комментарий к предложению */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Размер поставки */
  deliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Новая цена за лот */
  price?: InputMaybe<Scalars['PositiveFloat']['input']>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferBuyerOfferConditionsPayload = {
  __typename?: 'WineOfferBuyerOfferConditionsPayload';
  oldRecord: WineOfferBase;
  query?: Maybe<Query>;
  record: WineOfferPersonal;
};

/** Коммерческое предложение для определенной компании */
export type WineOfferCompany = WineOfferBase & {
  __typename?: 'WineOfferCompany';
  /** Компания для которой создавалось коммерческое предложение */
  buyer: CompanyInfo;
  /** Запросил ли продавец контакты покупателя */
  buyerContactsRequested: Scalars['Boolean']['output'];
  /** Может ли покупатель выдать разрешение на чтение контактов */
  canBuyerGrantCompanyInfo: Scalars['Boolean']['output'];
  /** Может ли покупатель отозвать разерешение на чтение контактов */
  canBuyerRevokeCompanyInfo: Scalars['Boolean']['output'];
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Контактная почта продавца */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Контактный номер телефона продавца */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency: Currency;
  /** Список выставок где представлен продукт */
  exhibitions: Array<WineOfferExhibition>;
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms: Incoterms;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot: WineLot;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price: Scalars['NonNegativeFloat']['output'];
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle: Scalars['Float']['output'];
  /** Продукт предложения */
  product: Product;
  /** Компания продавца */
  sellerCompany: Company;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Коммерческое предложение для определенной компании */
export type WineOfferCompanyBuyerArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Коммерческое предложение для определенной компании */
export type WineOfferCompanyHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Коммерческое предложение для определенной компании */
export type WineOfferCompanyPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Коммерческое предложение для определенной компании */
export type WineOfferCompanyPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Коммерческое предложение для определенной компании */
export type WineOfferCompanyShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraft = WineOfferBase & {
  __typename?: 'WineOfferCompanyDraft';
  /** Компания для которой создавалось коммерческое предложение */
  buyer?: Maybe<CompanyInfo>;
  /** Запросил ли продавец контакты покупателя */
  buyerContactsRequested: Scalars['Boolean']['output'];
  /** Может ли покупатель выдать разрешение на чтение контактов */
  canBuyerGrantCompanyInfo: Scalars['Boolean']['output'];
  /** Может ли покупатель отозвать разерешение на чтение контактов */
  canBuyerRevokeCompanyInfo: Scalars['Boolean']['output'];
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Контактная почта продавца */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Контактный номер телефона продавца */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency?: Maybe<Currency>;
  exhibitions: Array<WineOfferExhibition>;
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms?: Maybe<Incoterms>;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot?: Maybe<WineLot>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle?: Maybe<Scalars['Float']['output']>;
  /** Продукт предложения */
  product?: Maybe<Product>;
  /** Компания продавца */
  sellerCompany: Company;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraftBuyerArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraftHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraftPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraftPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Черновик коммерческого предложения для определенной компании */
export type WineOfferCompanyDraftShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

export type WineOfferCompanyDraftUpdateInput = {
  /** Идентификатор компании для которой создается предложение */
  buyerCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Цена за лот */
  price?: InputMaybe<Scalars['PositiveFloat']['input']>;
  /** Идентификатор продукта для продажи */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения для обновления */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferCompanyDraftUpdatePayload = {
  __typename?: 'WineOfferCompanyDraftUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferCompanyDraft>;
};

export type WineOfferCompanyUpdateInput = {
  /** Иденти��икатор компании для которой создается предложение */
  buyerCompanyId: Scalars['ID']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId: Scalars['Int']['input'];
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['input'];
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  /** Минимальный размер поставки */
  minimumDeliverySize: Scalars['PositiveInt']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Цена за лот */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта для продажи */
  productId: Scalars['ID']['input'];
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения для обновления */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferCompanyUpdatePayload = {
  __typename?: 'WineOfferCompanyUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferCompany>;
};

export type WineOfferConditions = {
  __typename?: 'WineOfferConditions';
  currency?: Maybe<Currency>;
  deliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  incoterms?: Maybe<Incoterms>;
  lot?: Maybe<WineLot>;
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  warehouse?: Maybe<Warehouse>;
};

export type WineOfferCountPerCategory = {
  __typename?: 'WineOfferCountPerCategory';
  restaurant: Scalars['Int']['output'];
  shop: Scalars['Int']['output'];
  wholesaler: Scalars['Int']['output'];
};

export type WineOfferCountryStats = {
  __typename?: 'WineOfferCountryStats';
  /** Количество КП для компаний */
  companyOffersCount: WineOfferCountPerCategory;
  /** Null в случае Global */
  country?: Maybe<Country>;
  /** Количество публичных КП */
  publicOffersCount: WineOfferCountPerCategory;
};

/** Сделка проведенная за системой */
export type WineOfferDeal = WineOfferBase & {
  __typename?: 'WineOfferDeal';
  /** Компания покупателя */
  buyerCompany: Company;
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency?: Maybe<Currency>;
  /** Список выставок где представлен продукт */
  exhibitions: Array<WineOfferExhibition>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms?: Maybe<Incoterms>;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot?: Maybe<WineLot>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle?: Maybe<Scalars['Float']['output']>;
  /** Продукт предложения */
  product?: Maybe<Product>;
  /** Компания продавца */
  sellerCompany?: Maybe<Company>;
  /** Категория продавца */
  sellerCompanyCategory?: Maybe<CompanyCategory>;
  /** Наименование продавца (при наличии) */
  sellerTradeName?: Maybe<Scalars['String']['output']>;
  /** Страна отправления */
  shipmentCountry?: Maybe<Country>;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Сделка проведенная за системой */
export type WineOfferDealHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Сделка проведенная за системой */
export type WineOfferDealPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Сделка проведенная за системой */
export type WineOfferDealPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Сделка проведенная за системой */
export type WineOfferDealShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

export type WineOfferDealCreateInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Валюта сделки */
  currencyId: Scalars['Int']['input'];
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Лот сделки */
  lotId: Scalars['Int']['input'];
  /** Количество лотов в минимальной поставке */
  minimumDeliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Цена сделки */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
  /** Категория компании продавца */
  sellerCompanyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Название продавца */
  sellerTradeName?: InputMaybe<Scalars['String']['input']>;
  /** Страна отправки */
  shipmentCountryId?: InputMaybe<Scalars['Int']['input']>;
};

export type WineOfferDealCreatePayload = {
  __typename?: 'WineOfferDealCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferDeal>;
};

export type WineOfferDistLimit = {
  __typename?: 'WineOfferDistLimit';
  /** Адрес ограничения поставки. Адрес обязан вклюлчать название страны */
  address: Scalars['String']['output'];
  /** Страна ограчения поставки */
  country: Country;
  id: Scalars['ID']['output'];
  /** Включается ли страна или территория в место поставки */
  isIncluded: Scalars['Boolean']['output'];
  /** Выбранные области для ограчения поставки (круги) */
  territory?: Maybe<WineOfferDistLimitTerritory>;
};

export type WineOfferDistLimitInput = {
  /** Адрес ограчения поставки */
  address: Scalars['String']['input'];
  /** Идентификатор страны ограничения поставки */
  countryId: Scalars['Int']['input'];
  /** Включается ли страна или территория в место поставки */
  isIncluded: Scalars['Boolean']['input'];
  /** Область для ограничения поставки (круги) */
  territory?: InputMaybe<WineOfferDistLimitTerritoryInput>;
};

export type WineOfferDistLimitTerritory = {
  __typename?: 'WineOfferDistLimitTerritory';
  /** Широта центра ограничения */
  latitude: Scalars['Float']['output'];
  /** Долгота центра ограничения */
  longitude: Scalars['Float']['output'];
  /** Радиус области ограчения */
  radius: Scalars['PositiveInt']['output'];
  /** Единица измерения радуса для области ограничения действия коммерческого предложения */
  radiusUnit: Unit;
};

export type WineOfferDistLimitTerritoryInput = {
  /** Широта центра ограничения */
  latitude: Scalars['Float']['input'];
  /** Долгота центра ограничения */
  longitude: Scalars['Float']['input'];
  /** Радиус области ограчения */
  radius: Scalars['PositiveInt']['input'];
  /** Идентификатор единицы измерения радуса для области ограничения действия коммерческого предложения */
  radiusUnitId: Scalars['Int']['input'];
};

export type WineOfferExhibition = {
  __typename?: 'WineOfferExhibition';
  exhibition: Exhibition;
  exhibitionStand?: Maybe<Scalars['String']['output']>;
};

export type WineOfferExhibitionInput = {
  exhibitionId: Scalars['Int']['input'];
  exhibitionStand?: InputMaybe<Scalars['String']['input']>;
};

export type WineOfferFilter = {
  /** Значения для фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Включать только коммерческие предложения из шортлиста текущего пользователя */
  onlyFromShortlist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор вина для поиска коммерческих предложений */
  wineId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы статусов коммерческих предложений для включения */
  wineOfferStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Идентификаторы типов коммерческих предложений для включения */
  wineOfferTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WineOfferFiltersFilter = {
  /** Значения для фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  /** Включать только коммерческие предложения из шортлиста текущего пользователя */
  onlyFromShortlist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор вина для поиска коммерческих предложений */
  wineId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы статусов коммерческих предложений для включения */
  wineOfferStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Идентификаторы типов коммерческих предложений для включения */
  wineOfferTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WineOfferPagination = {
  __typename?: 'WineOfferPagination';
  items: Array<WineOffer>;
  pageInfo: PageInfo;
};

/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonal = WineOfferBase & {
  __typename?: 'WineOfferPersonal';
  /** Первоначальные условия оффера */
  baseWineOffer: WineOffer;
  /** Компания покупателя */
  buyer: CompanyInfo;
  /** Запросил ли продавец контакты покупателя */
  buyerContactsRequested: Scalars['Boolean']['output'];
  buyerCurrency?: Maybe<Currency>;
  /** Объем поставки, запрошеный покупателем */
  buyerDeliverySize?: Maybe<Scalars['Int']['output']>;
  /** Условия поставки, запрошенные покупателем */
  buyerIncoterms?: Maybe<Incoterms>;
  /** Срок действия условий, запрошенный покупателем */
  buyerPeriodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена за лот, запрошенная покупателем */
  buyerPrice?: Maybe<Scalars['PositiveFloat']['output']>;
  /** Цена за бутылку, запрошенная покупателем */
  buyerPricePerBottle?: Maybe<Scalars['PositiveFloat']['output']>;
  /** Может ли покупатель выдать разрешение на чтение контактов */
  canBuyerGrantCompanyInfo: Scalars['Boolean']['output'];
  /** Может ли покупатель отозвать разрешение на чтение контактов */
  canBuyerRevokeCompanyInfo: Scalars['Boolean']['output'];
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Комментарий к предложению */
  comment?: Maybe<Scalars['String']['output']>;
  /** Контактная почта продавца */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Контактный номер телефона продавца */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency: Currency;
  /** Список выставок где представлен продукт */
  exhibitions: Array<WineOfferExhibition>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** История переговоров по коммерческому предложению. От старых к более новым */
  history: Array<WineOfferPersonal>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms: Incoterms;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  /** Было ли предложение завершено по причине продавца */
  isSellerFault?: Maybe<Scalars['Boolean']['output']>;
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot: WineLot;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Идентификатор переговоров (связанных КП) */
  negotiationId?: Maybe<Scalars['ID']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price: Scalars['NonNegativeFloat']['output'];
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle: Scalars['Float']['output'];
  /** Продукт предложения */
  product: Product;
  /** Компания продавца */
  sellerCompany: Company;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalBuyerArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalBuyerPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalBuyerPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Персональное коммерческое предложение (переговоры) */
export type WineOfferPersonalShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

/** Публичное коммерческое предложение */
export type WineOfferPublic = WineOfferBase & {
  __typename?: 'WineOfferPublic';
  /** Категория компании покупателя */
  buyerCompanyCategory: CompanyCategory;
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Контактная почта продавца */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Контактный номер телефона продавца */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency: Currency;
  exhibitions: Array<WineOfferExhibition>;
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms: Incoterms;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot: WineLot;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price: Scalars['NonNegativeFloat']['output'];
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle: Scalars['Float']['output'];
  /** Продукт предложения */
  product: Product;
  /** Компания продавца */
  sellerCompany: Company;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Публичное коммерческое предложение */
export type WineOfferPublicHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Публичное коммерческое предложение */
export type WineOfferPublicPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Публичное коммерческое предложение */
export type WineOfferPublicPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Публичное коммерческое предложение */
export type WineOfferPublicShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

/** Черновик публичного коммерческого предложения */
export type WineOfferPublicDraft = WineOfferBase & {
  __typename?: 'WineOfferPublicDraft';
  /** Категория компании покупателя */
  buyerCompanyCategory?: Maybe<CompanyCategory>;
  /** Дочерние коммерческие предложения */
  childOffers: Array<WineOffer>;
  /** Контактная почта продавца */
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  /** Контактный номер телефона продавца */
  contactPhone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** Дата создания предложения */
  createdAt: Scalars['DateTime']['output'];
  /** Валюта выставленной цены за лот */
  currency?: Maybe<Currency>;
  exhibitions: Array<WineOfferExhibition>;
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Признак прочтения КП компанией */
  hasRead?: Maybe<Scalars['Boolean']['output']>;
  /** Идентификатор коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Инкотермс предложения */
  incoterms?: Maybe<Incoterms>;
  /** Являтся ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['output'];
  lastSellerConditions: WineOfferConditions;
  /** Лот предложения */
  lot?: Maybe<WineLot>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: Maybe<Scalars['PositiveInt']['output']>;
  /** Текстовый комментарий продавца */
  note?: Maybe<Scalars['String']['output']>;
  /** Дата действия предложения */
  periodOfValidity?: Maybe<Scalars['Date']['output']>;
  /** Цена предложения за лот */
  price?: Maybe<Scalars['NonNegativeFloat']['output']>;
  /** Коэффициент цены (отношение цены за бутылку к цене на винодельне) */
  priceCoefficient?: Maybe<Scalars['Float']['output']>;
  /** Цена за бутылку */
  pricePerBottle?: Maybe<Scalars['Float']['output']>;
  /** Продукт предложения */
  product?: Maybe<Product>;
  /** Компания продавца */
  sellerCompany: Company;
  /** Признак добавления КП в шортлист компанией */
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  /** Количество добавлений в шортлист */
  shortlistedCount: Scalars['Int']['output'];
  /** Дата последнего обновления предложения */
  updatedAt: Scalars['DateTime']['output'];
  /** Склад отгрузки */
  warehouse?: Maybe<Warehouse>;
  /** Ограничения поставки */
  wineOfferDistLimits: Array<WineOfferDistLimit>;
  /** Статус коммерческого предложения */
  wineOfferStatus: WineOfferStatus;
  /** Тип коммерческого предложения */
  wineOfferType: WineOfferType;
};


/** Черновик публичного коммерческого предложения */
export type WineOfferPublicDraftHasReadArgs = {
  companyId: Scalars['ID']['input'];
};


/** Черновик публичного коммерческого предложения */
export type WineOfferPublicDraftPriceArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Черновик публичного коммерческого предложения */
export type WineOfferPublicDraftPricePerBottleArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Черновик публичного коммерческого предложения */
export type WineOfferPublicDraftShortlistedArgs = {
  companyId: Scalars['ID']['input'];
};

export type WineOfferPublicDraftUpdateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Цена за лот */
  price?: InputMaybe<Scalars['PositiveFloat']['input']>;
  /** Идентификатор продукта для продажи */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferPublicDraftUpdatePayload = {
  __typename?: 'WineOfferPublicDraftUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferPublicDraft>;
};

export type WineOfferPublicUpdateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId: Scalars['Int']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  /** Идентификатор валюты для цены за лот */
  currencyId: Scalars['Int']['input'];
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight: Scalars['Boolean']['input'];
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  /** Минимальный размер поставки */
  minimumDeliverySize: Scalars['PositiveInt']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Цена за лот */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта для продажи */
  productId: Scalars['ID']['input'];
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferPublicUpdatePayload = {
  __typename?: 'WineOfferPublicUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineOfferPublic>;
};

/** Пользовательский запрос на новые предложения */
export type WineOfferRequest = {
  __typename?: 'WineOfferRequest';
  /** Пользователь который создал запрос */
  authorUser?: Maybe<User>;
  /** Компания покупателя */
  buyer: CompanyInfo;
  /** Комментарий к запросу */
  comment?: Maybe<Scalars['String']['output']>;
  /** Дата создания запроса */
  createdAt: Scalars['DateTime']['output'];
  /** Выбранные фильтры в запросе */
  filters?: Maybe<Array<FacetFilterSelection>>;
  /** Уникальный идентификатор запроса (UUID) */
  id: Scalars['ID']['output'];
  /** Совпадения по запросу */
  matches: WineOfferRequestMatches;
  /** Комментарий менеджера */
  note?: Maybe<Scalars['String']['output']>;
  /** Порядковый номер запроса */
  number: Scalars['Int']['output'];
  /** Дата до которой запрос валиден */
  periodOfValidity: Scalars['Date']['output'];
  /** @deprecated Запросы на конкретный продукт не используются */
  product?: Maybe<Product>;
  /** @deprecated Неструктурированный запрос не используется */
  query?: Maybe<Scalars['String']['output']>;
  /** Статус обработки запроса менеджером */
  stage: WineOfferRequestStage;
  /** Статус действительности запроса */
  status: WineOfferRequestStatus;
};


/** Пользовательский запрос на новые предложения */
export type WineOfferRequestBuyerArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Пользовательский запрос на новые предложения */
export type WineOfferRequestFiltersArgs = {
  onlyVisible?: Scalars['Boolean']['input'];
};

export type WineOfferRequestCompaniesMatchFilter = {
  companyStatus?: InputMaybe<Array<WineOfferRequestCompanyStatus>>;
};

export type WineOfferRequestCompanyMatch = {
  __typename?: 'WineOfferRequestCompanyMatch';
  /** Компания для которой есть совпадения с запросом */
  company: Company;
  /** Статус компани попадающей под запрос */
  companyStatus: WineOfferRequestCompanyStatus;
  /** Количество коммерческих предложений компании попадающих под запрос */
  offerCount: Scalars['Int']['output'];
  /** Количество продуктов компании попадающих под запрос */
  productCount: Scalars['Int']['output'];
};

export type WineOfferRequestCompanyMatchesPagination = {
  __typename?: 'WineOfferRequestCompanyMatchesPagination';
  items: Array<WineOfferRequestCompanyMatch>;
  pageInfo: PageInfo;
};

export enum WineOfferRequestCompanyStatus {
  HasOffer = 'HAS_OFFER',
  Registered = 'REGISTERED'
}

export type WineOfferRequestCreateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор компании покупателя */
  companyId: Scalars['ID']['input'];
  /** Идентификатор локали пользователя */
  localeId: Scalars['Int']['input'];
  parameters?: InputMaybe<WineOfferRequestParametersInput>;
  /** Дата окончания действия запроса */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
};

export type WineOfferRequestCreatePayload = {
  __typename?: 'WineOfferRequestCreatePayload';
  query?: Maybe<Query>;
  request: WineOfferRequest;
};

export type WineOfferRequestDeleteInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы запросов для удаления */
  id: Array<Scalars['ID']['input']>;
};

export type WineOfferRequestDeletePayload = {
  __typename?: 'WineOfferRequestDeletePayload';
  query?: Maybe<Query>;
};

export type WineOfferRequestDraftCreateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор компании покупателя */
  companyId: Scalars['ID']['input'];
  /** Идентификатор локали пользователя */
  localeId: Scalars['Int']['input'];
  parameters?: InputMaybe<WineOfferRequestParametersInput>;
  /** Дата окончания действия запроса */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
};

export type WineOfferRequestDraftCreatePayload = {
  __typename?: 'WineOfferRequestDraftCreatePayload';
  query?: Maybe<Query>;
  request: WineOfferRequest;
};

export type WineOfferRequestDraftPublishInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор компании покупателя */
  companyId: Scalars['ID']['input'];
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
  /** Идентификатор локали пользователя */
  localeId: Scalars['Int']['input'];
  parameters?: InputMaybe<WineOfferRequestParametersInput>;
  /** Дата окончания действия запроса */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
};

export type WineOfferRequestDraftPublishPayload = {
  __typename?: 'WineOfferRequestDraftPublishPayload';
  query?: Maybe<Query>;
  request: WineOfferRequest;
};

export type WineOfferRequestDraftUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор компании покупателя */
  companyId: Scalars['ID']['input'];
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
  /** Идентификатор локали пользователя */
  localeId: Scalars['Int']['input'];
  parameters?: InputMaybe<WineOfferRequestParametersInput>;
  /** Дата окончания действия запроса */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
};

export type WineOfferRequestDraftUpdatePayload = {
  __typename?: 'WineOfferRequestDraftUpdatePayload';
  query?: Maybe<Query>;
  request: WineOfferRequest;
};

export enum WineOfferRequestExportPotentialProducerColumn {
  ActivityCode = 'ACTIVITY_CODE',
  Address = 'ADDRESS',
  AllEmails = 'ALL_EMAILS',
  AllPhones = 'ALL_PHONES',
  AllWebsites = 'ALL_WEBSITES',
  Country = 'COUNTRY',
  Email = 'EMAIL',
  LastUpdater = 'LAST_UPDATER',
  LastUpdateTime = 'LAST_UPDATE_TIME',
  LegalId = 'LEGAL_ID',
  LegalName = 'LEGAL_NAME',
  Phone = 'PHONE',
  Producer = 'PRODUCER',
  Website = 'WEBSITE'
}

export type WineOfferRequestFiltersParseInput = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  localeId: Scalars['Int']['input'];
};

export type WineOfferRequestMatchPriceInCountry = {
  __typename?: 'WineOfferRequestMatchPriceInCountry';
  bottleSize: BottleSize;
  country?: Maybe<Country>;
  price?: Maybe<PriceCurrency>;
};

export type WineOfferRequestMatches = {
  __typename?: 'WineOfferRequestMatches';
  /** Список компаний попадающих под запрос */
  companiesPagination: WineOfferRequestCompanyMatchesPagination;
  companyCount: Scalars['Int']['output'];
  /** Количество производителей попадающих под запрос с коммерческими предложениями */
  companyHasOfferCount: Scalars['Int']['output'];
  /** Количество производителей попадающих под запрос без коммерческих предложений но с регистрацией */
  companyRegisteredCount: Scalars['Int']['output'];
  /** Количество коммерческих предложений, попадающих под запрос */
  count: Scalars['Int']['output'];
  /** Количество продуктов компании, попадающих под запрос */
  productCount: Scalars['Int']['output'];
  /** Список фильтров для продуктов, попадающих под запрос */
  productsFilters: FacetFilterPayload;
  /** Список продуктов продавца, попадающих под запрос */
  productsPagination: ProductsPagination;
  /** Количество производителей попадающих под запрос без регистрации в системе */
  tradeNamePotentialCount: Scalars['Int']['output'];
  /** Количество производителей без регистрации по которым была инициирована рассылка */
  tradeNamePotentialNewsletterSendedCount: Scalars['Int']['output'];
  /** Список производителей попадающих под запрос без регистрации */
  tradeNamesPagination: WineOfferRequestTradeNameMatchesPagination;
  /** Количество коммерческих предложений для компании, попадающих под запрос */
  wineOfferCompanyCount: Scalars['Int']['output'];
  wineOfferCompanyCountByTradeName: Array<WineOfferRequestWineOfferCountPerCompany>;
  /** Количество публичных коммерческих предложений, попадающих под запрос */
  wineOfferPublicCount: Scalars['Int']['output'];
  /** Список фильтров для коммечерских предложений, попадающих под запрос */
  wineOffersFilters: FacetFilterPayload;
  /** Список коммерческих предложений попадающих под запрос */
  wineOffersPagination: WineOfferPagination;
};


export type WineOfferRequestMatchesCompaniesPaginationArgs = {
  filter?: InputMaybe<WineOfferRequestCompaniesMatchFilter>;
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
};


export type WineOfferRequestMatchesProductCountArgs = {
  sellerCompanyId: Scalars['Int']['input'];
};


export type WineOfferRequestMatchesProductsFiltersArgs = {
  filter: WineOfferRequestProductFiltersFilter;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type WineOfferRequestMatchesProductsPaginationArgs = {
  filter: WineOfferRequestProductFilter;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: ProductSort;
};


export type WineOfferRequestMatchesTradeNamesPaginationArgs = {
  filter?: InputMaybe<WineOfferRequestTradeNamesMatchFilter>;
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  sort?: TradeNameRequestMatchSort;
};


export type WineOfferRequestMatchesWineOfferCompanyCountArgs = {
  sellerCompanyId: Scalars['Int']['input'];
};


export type WineOfferRequestMatchesWineOfferCompanyCountByTradeNameArgs = {
  tradeNameId: Scalars['ID']['input'];
};


export type WineOfferRequestMatchesWineOfferPublicCountArgs = {
  sellerCompanyId: Scalars['Int']['input'];
};


export type WineOfferRequestMatchesWineOffersFiltersArgs = {
  filter: WineOfferRequestWineOfferFiltersFilter;
  localeId: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type WineOfferRequestMatchesWineOffersPaginationArgs = {
  filter: WineOfferRequestWineOfferFilter;
  localeId: Scalars['Int']['input'];
  page?: Scalars['PositiveInt']['input'];
  perPage?: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: WineOfferSort;
};

export type WineOfferRequestPagination = {
  __typename?: 'WineOfferRequestPagination';
  items: Array<WineOfferRequest>;
  pageInfo: PageInfo;
};

export type WineOfferRequestParametersInput = {
  bottleSizeId?: InputMaybe<Array<Scalars['Int']['input']>>;
  brandId?: InputMaybe<Array<Scalars['ID']['input']>>;
  certificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryOfShipmentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  exhibitionId?: InputMaybe<Array<Scalars['Int']['input']>>;
  geoIndicationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  grapeVarietySynonymId?: InputMaybe<Array<Scalars['Int']['input']>>;
  incotermsId?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxAlcohol?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minAlcohol?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  minimumDeliverySize?: InputMaybe<Scalars['Int']['input']>;
  monocepage?: InputMaybe<Scalars['Boolean']['input']>;
  nationalGITypeId?: InputMaybe<Array<Scalars['Int']['input']>>;
  onlyForMe?: InputMaybe<Scalars['Boolean']['input']>;
  onlyFromProducer?: InputMaybe<Scalars['Boolean']['input']>;
  packageTypeId?: InputMaybe<Array<Scalars['Int']['input']>>;
  priceCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  specialClassificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  sweetnessLevelId?: InputMaybe<Array<Scalars['Int']['input']>>;
  tradeNameCountryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  tradeNameId?: InputMaybe<Array<Scalars['ID']['input']>>;
  vintage?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  wineColorId?: InputMaybe<Array<Scalars['Int']['input']>>;
  wineCountryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  wineRegionEUId?: InputMaybe<Array<Scalars['Int']['input']>>;
  wineTypeId?: InputMaybe<Array<Scalars['Int']['input']>>;
  wwlScore?: InputMaybe<Scalars['Int']['input']>;
};

export type WineOfferRequestParametersToProductFilters = {
  /** Параметры запроса */
  parameters: WineOfferRequestParametersInput;
};

export type WineOfferRequestParametersToWineOfferFilters = {
  /** Параметры запроса */
  parameters: WineOfferRequestParametersInput;
};

export type WineOfferRequestParsePayload = {
  __typename?: 'WineOfferRequestParsePayload';
  bottleSize?: Maybe<Array<BottleSize>>;
  brand?: Maybe<Array<Brand>>;
  certification?: Maybe<Array<Certification>>;
  countryOfShipment?: Maybe<Array<Country>>;
  exclusive?: Maybe<Scalars['Boolean']['output']>;
  exhibition?: Maybe<Array<Exhibition>>;
  geoIndication?: Maybe<Array<GeoIndication>>;
  grapeVarietySynonym?: Maybe<Array<GrapeVarietySynonym>>;
  incoterms?: Maybe<Array<Incoterms>>;
  maxAlcohol?: Maybe<Scalars['Float']['output']>;
  maxPrice?: Maybe<Scalars['Float']['output']>;
  minAlcohol?: Maybe<Scalars['Float']['output']>;
  minPrice?: Maybe<Scalars['Float']['output']>;
  minimumDeliverySize?: Maybe<Scalars['Int']['output']>;
  monocepage?: Maybe<Scalars['Boolean']['output']>;
  nationalGIType?: Maybe<Array<NationalGiType>>;
  onlyForMe?: Maybe<Scalars['Boolean']['output']>;
  onlyFromProducer?: Maybe<Scalars['Boolean']['output']>;
  packageType?: Maybe<Array<WinePackageType>>;
  priceCurrency?: Maybe<Currency>;
  specialClassification?: Maybe<Array<SpecialClassification>>;
  sweetnessLevel?: Maybe<Array<SweetnessLevel>>;
  tradeName?: Maybe<Array<TradeName>>;
  tradeNameCountry?: Maybe<Array<Country>>;
  vintage?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  wineColor?: Maybe<Array<WineColor>>;
  wineCountry?: Maybe<Array<Country>>;
  wineRegionEU?: Maybe<Array<WineRegionEu>>;
  wineType?: Maybe<Array<WineType>>;
  wwlScore?: Maybe<Scalars['Int']['output']>;
};

export type WineOfferRequestProductFilter = {
  companyId: Scalars['Int']['input'];
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineOfferRequestProductFiltersFilter = {
  companyId: Scalars['Int']['input'];
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineOfferRequestProductFiltersParseInput = {
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  localeId: Scalars['Int']['input'];
};

export type WineOfferRequestSellersInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Значения фильтров соответствующих запросу */
  filters?: InputMaybe<Array<FacetInput>>;
  /** Идентификатор локали пользователя */
  localeId: Scalars['Int']['input'];
};

export type WineOfferRequestSellersPayload = {
  __typename?: 'WineOfferRequestSellersPayload';
  /** Количество продавцов попадающих под запрос (производящих или продающих вино) */
  count: Scalars['Int']['output'];
};

export enum WineOfferRequestSort {
  /** Сортировка запросов по возрастанию торгового наименования покупателя (создателя запроса) */
  BuyerTradeNameAsc = 'BUYER_TRADE_NAME_ASC',
  /** Сортировка запросов по убыванию торгового наименования покупателя (создателя запроса) */
  BuyerTradeNameDesc = 'BUYER_TRADE_NAME_DESC',
  /** Сортировка запросов по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка запросов по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка запросов по возрастанию номера */
  IdAsc = 'ID_ASC',
  /** Сортировка запросов по убыванию номера */
  IdDesc = 'ID_DESC',
  /** Сортировка запросов по возрастанию даты окончания действия запроса */
  PeriodOfValidityAsc = 'PERIOD_OF_VALIDITY_ASC',
  /** Сортировка запросов по убыыванию даты окончания действия запроса */
  PeriodOfValidityDesc = 'PERIOD_OF_VALIDITY_DESC'
}

export enum WineOfferRequestStage {
  Calling = 'CALLING',
  DbReady = 'DB_READY',
  DbSearching = 'DB_SEARCHING',
  Done = 'DONE',
  EmailsSent = 'EMAILS_SENT',
  New = 'NEW'
}

export type WineOfferRequestStatsInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Параметры запроса */
  parameters: WineOfferRequestParametersInput;
};

export type WineOfferRequestStatsPayload = {
  __typename?: 'WineOfferRequestStatsPayload';
  /** Минимальная цена на оффер среди доступных покупателю и попадающих под запрос */
  bestOfferPrice?: Maybe<PriceCurrency>;
  /** Максимальный рейтинг среди подходящих вин */
  bestWwlScore?: Maybe<Scalars['Int']['output']>;
  /** Количество верифицированных компаний с винами попадающими под запрос */
  companyActiveUserCount: Scalars['Int']['output'];
  /** Количество верифицированных компаний с офферами попадающими под запрос */
  companyWithOfferCount: Scalars['Int']['output'];
  /** Слишком много продавцов под запрос, нужно уточнить */
  isBroad: Scalars['Boolean']['output'];
  /** Минимальная розничная цена в стране вина */
  minimalRecommendedRetailPrice: WineOfferRequestMatchPriceInCountry;
  /** Минимальная розничная цена в стране покупателя */
  minimalRetailPrice: WineOfferRequestMatchPriceInCountry;
  /** Цена потенциального оффера */
  potentialBestOfferPrice?: Maybe<PriceCurrencyForecast>;
  /** Количество потенциальных офферов */
  potentialNumberOfOffers?: Maybe<IntForecast>;
  /** Количество продавцов попадающих под запрос */
  sellerCount: Scalars['Int']['output'];
  /** Количество производителей попадающих под запрос с email */
  sellerWithEmailCount: Scalars['Int']['output'];
};

export enum WineOfferRequestStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS'
}

export enum WineOfferRequestTradeNameEmailStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Bounced = 'BOUNCED',
  Invalid = 'INVALID',
  Unknown = 'UNKNOWN',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type WineOfferRequestTradeNameMatch = {
  __typename?: 'WineOfferRequestTradeNameMatch';
  emailStatus?: Maybe<WineOfferRequestTradeNameEmailStatus>;
  productCount: Scalars['Int']['output'];
  tradeName: TradeName;
};

export type WineOfferRequestTradeNameMatchesPagination = {
  __typename?: 'WineOfferRequestTradeNameMatchesPagination';
  items: Array<WineOfferRequestTradeNameMatch>;
  pageInfo: PageInfo;
};

export type WineOfferRequestTradeNameNewsletter = {
  __typename?: 'WineOfferRequestTradeNameNewsletter';
  createdAt: Scalars['DateTime']['output'];
  senderUser: User;
  wineOfferRequest: WineOfferRequest;
};

export type WineOfferRequestTradeNamesMatchFilter = {
  countryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysSinceLastNewsletter?: InputMaybe<Scalars['Int']['input']>;
  emailStatuses?: InputMaybe<Array<WineOfferRequestTradeNameEmailStatus>>;
  onlyWithEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WineOfferRequestUpdatePeriodOfValidityInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы запросов (в случае установления одной и той же даты окончания действия запроса) */
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Данные для установки разных дат действий запросов для разных запросов */
  items?: InputMaybe<Array<WineOfferRequestUpdatePeriodOfValidityInputItem>>;
  /** Дата окончания действия запроса (для нескольких запросов) */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
};

export type WineOfferRequestUpdatePeriodOfValidityInputItem = {
  /** Идентификатор запроса */
  id: Scalars['ID']['input'];
  /** Дата окончания действия запроса */
  periodOfValidity: Scalars['Date']['input'];
};

export type WineOfferRequestUpdatePeriodOfValidityPayload = {
  __typename?: 'WineOfferRequestUpdatePeriodOfValidityPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferRequest>>;
};

export type WineOfferRequestWineOfferCountPerCompany = {
  __typename?: 'WineOfferRequestWineOfferCountPerCompany';
  company: Company;
  count: Scalars['Int']['output'];
};

export type WineOfferRequestWineOfferFilter = {
  companyId: Scalars['Int']['input'];
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineOfferRequestWineOfferFiltersFilter = {
  companyId: Scalars['Int']['input'];
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineOfferRequestsCompleteInput = {
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификаторы запросов */
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WineOfferRequestsCompletePayload = {
  __typename?: 'WineOfferRequestsCompletePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferRequest>>;
};

export type WineOfferRequestsPeriodOfValiditySeriesPayloadItem = {
  __typename?: 'WineOfferRequestsPeriodOfValiditySeriesPayloadItem';
  /** Количество истекающих запросов */
  count: Scalars['Int']['output'];
  /** Дата окончания действия запроса */
  periodOfValidity: Scalars['Date']['output'];
};

export type WineOfferRequestsUnreadsPayload = {
  __typename?: 'WineOfferRequestsUnreadsPayload';
  seller?: Maybe<WineOffersUnreadsSeller>;
};

export type WineOfferSellerCommentInput = {
  /** Комментарий к предложению */
  comment: Scalars['String']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferSellerCommentPayload = {
  __typename?: 'WineOfferSellerCommentPayload';
  oldRecord: WineOfferPersonal;
  query?: Maybe<Query>;
  record: WineOfferPersonal;
};

export type WineOfferSellerOfferConditionsInput = {
  /** Комментарий к предложению */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Валюта новой цены за лот */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Размер поставки */
  deliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор лота */
  lotId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Новая цена за лот */
  price?: InputMaybe<Scalars['PositiveFloat']['input']>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferSellerOfferConditionsPayload = {
  __typename?: 'WineOfferSellerOfferConditionsPayload';
  oldRecord: WineOfferPersonal;
  query?: Maybe<Query>;
  record: WineOfferPersonal;
};

export type WineOfferSellerUpdatePeriodOfValidityInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор коммерческого предложения */
  wineOfferId: Scalars['Int']['input'];
};

export type WineOfferSellerUpdatePeriodOfValidityPayload = {
  __typename?: 'WineOfferSellerUpdatePeriodOfValidityPayload';
  oldRecord: WineOfferPersonal;
  query?: Maybe<Query>;
  record: WineOfferPersonal;
};

export enum WineOfferSort {
  /** Сортировка КП по возрастанию популярности бренда */
  BrandPopularityAsc = 'BRAND_POPULARITY_ASC',
  /** Сортировка КП по убыванию популярности бренда */
  BrandPopularityDesc = 'BRAND_POPULARITY_DESC',
  /** Сортировка КП по возрастанию торгового наименования покупателя */
  BuyerTradeNameAsc = 'BUYER_TRADE_NAME_ASC',
  /** Сортировка КП по убыванию торгового наименования покупателя */
  BuyerTradeNameDesc = 'BUYER_TRADE_NAME_DESC',
  /** Сортировка КП по возрастанию названий сортов винограда вина */
  GrapeVarietyNameAsc = 'GRAPE_VARIETY_NAME_ASC',
  /** Сортировка КП по убыванию названий сортов винограда вина */
  GrapeVarietyNameDesc = 'GRAPE_VARIETY_NAME_DESC',
  /** Сортировка КП по возрастанию номера */
  IdAsc = 'ID_ASC',
  /** Сортировка КП по убыванию номера */
  IdDesc = 'ID_DESC',
  /** Сортировка КП по возрастанию цены за лот */
  PriceAsc = 'PRICE_ASC',
  /** Сортировка КП по возрастанию коэффициента цены */
  PriceCoefficientAsc = 'PRICE_COEFFICIENT_ASC',
  /** Сортировка КП по убыванию коэффициента цены */
  PriceCoefficientDesc = 'PRICE_COEFFICIENT_DESC',
  /** Сортировка КП по убыванию цены за лот */
  PriceDesc = 'PRICE_DESC',
  /** Сортировка КП по возрастанию цены за бутылку */
  PricePerBottleAsc = 'PRICE_PER_BOTTLE_ASC',
  /** Сортировка КП по убыванию цены за бутылку */
  PricePerBottleDesc = 'PRICE_PER_BOTTLE_DESC',
  /** Сортировка КП по убыванию названия производителя */
  ProducerTradeNameAsc = 'PRODUCER_TRADE_NAME_ASC',
  /** Сортировка КП по возрастанию названия производителя */
  ProducerTradeNameDesc = 'PRODUCER_TRADE_NAME_DESC',
  /** Сортировка КП по возрастанию ранга продукта в аппеласьоне */
  ProductAppellationRankAsc = 'PRODUCT_APPELLATION_RANK_ASC',
  /** Сортировка КП по убыванию ранга продукта в аппеласьоне */
  ProductAppellationRankDesc = 'PRODUCT_APPELLATION_RANK_DESC',
  /** Сортировка КП по возрастанию цены за продукт (цена на винодельне или рекомендованная цена для продажи) */
  ProductPriceAsc = 'PRODUCT_PRICE_ASC',
  /** Сортировка КП по убыванию цены за продукт (цена на винодельне или рекомендованная цена для продажи) */
  ProductPriceDesc = 'PRODUCT_PRICE_DESC',
  /** Сортировка КП по возрастанию рейтинга WWL */
  RatingAsc = 'RATING_ASC',
  /** Сортировка КП по убыванию рейтинга WWL */
  RatingDesc = 'RATING_DESC',
  /** Сортировка КП по возрастанию даты последнего обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка КП по убыванию даты последнего обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка КП по возрастанию названия страны склада */
  WarehouseCountryNameAsc = 'WAREHOUSE_COUNTRY_NAME_ASC',
  /** Сортировка КП по убыванию названия страны склада */
  WarehouseCountryNameDesc = 'WAREHOUSE_COUNTRY_NAME_DESC',
  /** Сортировка КП по возрастанию полного названия вина */
  WineFullnameAsc = 'WINE_FULLNAME_ASC',
  /** Сортировка КП по убыванию полного названия вина */
  WineFullnameDesc = 'WINE_FULLNAME_DESC',
  /** Сортировка КП по возрастанию названия гео. индикации */
  WineRegionNameAsc = 'WINE_REGION_NAME_ASC',
  /** Сортировка КП по убыванию названия гео. индикации */
  WineRegionNameDesc = 'WINE_REGION_NAME_DESC'
}

/** Статус коммерческого предложения */
export type WineOfferStatus = {
  __typename?: 'WineOfferStatus';
  /** Код статуса коммерческого предложения */
  code: Scalars['String']['output'];
  /** Идентификатор статуса коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Перевод названия статуса коммерческого предложения */
  localizedName: Scalars['String']['output'];
  /** Название статуса коммерческого предложения на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Статус коммерческого предложения */
export type WineOfferStatusLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Тип коммерческого предложения */
export type WineOfferType = {
  __typename?: 'WineOfferType';
  /** Код типа коммерческого предложения */
  code: Scalars['String']['output'];
  /** Идентификатор типа коммерческого предложения */
  id: Scalars['Int']['output'];
  /** Перевод названия типа коммерческого предложения */
  localizedName: Scalars['String']['output'];
  /** Название типа коммерческого предложения на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Тип коммерческого предложения */
export type WineOfferTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type WineOffersAcceptInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersAcceptPayload = {
  __typename?: 'WineOffersAcceptPayload';
  oldRecords: Array<WineOfferBase>;
  query?: Maybe<Query>;
  records: Array<WineOfferPersonal>;
};

export type WineOffersAddToShortlistInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersAddToShortlistPayload = {
  __typename?: 'WineOffersAddToShortlistPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferBase>>;
};

export type WineOffersArchiveHideInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений для скрытия */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersArchiveHidePayload = {
  __typename?: 'WineOffersArchiveHidePayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersArchiveInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Предложение было помещено в архив из-за продавца. Это поле только для покупателя */
  isSellerFault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификаторы коммерческих предложений для архивирования */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersArchivePayload = {
  __typename?: 'WineOffersArchivePayload';
  oldRecords: Array<WineOfferBase>;
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersCompanyCreateInput = {
  /** Идентификатор компании для которой создается предложение */
  buyerCompanyId: Scalars['ID']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type WineOffersCompanyCreatePayload = {
  __typename?: 'WineOffersCompanyCreatePayload';
  query?: Maybe<Query>;
  records: Array<WineOfferCompany>;
};

export type WineOffersCompanyDraftCreateInput = {
  /** Идентификатор компании для которой создается предложение */
  buyerCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductDraftInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type WineOffersCompanyDraftCreatePayload = {
  __typename?: 'WineOffersCompanyDraftCreatePayload';
  query?: Maybe<Query>;
  records: Array<WineOfferCompanyDraft>;
};

export type WineOffersCreateProductDraftInput = {
  /** Идентификатор валюты для цены */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный размер поставки */
  minimumDeliverySize?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Цена за бутылку */
  price?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type WineOffersCreateProductInput = {
  /** Идентификатор валюты для цены */
  currencyId: Scalars['Int']['input'];
  /** Минимальный размер поставки */
  minimumDeliverySize: Scalars['PositiveInt']['input'];
  /** Цена за лот */
  price: Scalars['PositiveFloat']['input'];
  /** Идентификатор продукта */
  productId: Scalars['ID']['input'];
};

export type WineOffersDeleteInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений для удаления */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersDeletePayload = {
  __typename?: 'WineOffersDeletePayload';
  query?: Maybe<Query>;
  recordIds: Array<Scalars['ID']['output']>;
};

export type WineOffersMarkAsReadInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersMarkAsReadPayload = {
  __typename?: 'WineOffersMarkAsReadPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersMarkAsUnreadInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersMarkAsUnreadPayload = {
  __typename?: 'WineOffersMarkAsUnreadPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersPublicCreateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId: Scalars['Int']['input'];
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId: Scalars['Int']['input'];
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity: Scalars['Date']['input'];
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type WineOffersPublicCreatePayload = {
  __typename?: 'WineOffersPublicCreatePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferPublic>>;
};

export type WineOffersPublicDraftCreateInput = {
  /** Идентификатор категории компании на которую направлено предложение */
  buyerCompanyCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  exhibitions?: InputMaybe<Array<WineOfferExhibitionInput>>;
  /** Идентификатор инкотермс */
  incotermsId?: InputMaybe<Scalars['Int']['input']>;
  /** Является ли предложение эксклюзивным */
  isExclusiveRight?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор лота */
  lotId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Список продуктов в коммерческих предложениях */
  products: Array<WineOffersCreateProductDraftInput>;
  /** Идентификатор склада */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
  /** Ограчения по доставке */
  wineOfferDistLimits?: InputMaybe<Array<WineOfferDistLimitInput>>;
};

export type WineOffersPublicDraftCreatePayload = {
  __typename?: 'WineOffersPublicDraftCreatePayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferPublicDraft>>;
};

export type WineOffersPublishInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений для публикации */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersPublishPayload = {
  __typename?: 'WineOffersPublishPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersRejectInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersRejectPayload = {
  __typename?: 'WineOffersRejectPayload';
  oldRecords: Array<WineOfferPersonal>;
  query?: Maybe<Query>;
  records: Array<WineOfferPersonal>;
};

export type WineOffersRemoveFromShortlistInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersRemoveFromShortlistPayload = {
  __typename?: 'WineOffersRemoveFromShortlistPayload';
  query?: Maybe<Query>;
  records?: Maybe<Array<WineOfferBase>>;
};

export type WineOffersRequestBuyerContactsInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersRequestBuyerContactsPayload = {
  __typename?: 'WineOffersRequestBuyerContactsPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersUnpublishInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersUnpublishPayload = {
  __typename?: 'WineOffersUnpublishPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOffersUnreadsCompany = {
  __typename?: 'WineOffersUnreadsCompany';
  /** Разбивка количества непрочитанных коммерческих предложений по статусам */
  items?: Maybe<Array<WineOffersUnreadsCompanyByStatus>>;
  /** Общее количество непрочитанных коммерческих предложений */
  total: Scalars['Int']['output'];
};

export type WineOffersUnreadsCompanyByStatus = {
  __typename?: 'WineOffersUnreadsCompanyByStatus';
  count: Scalars['Int']['output'];
  wineOfferStatus: WineOfferStatus;
};

export type WineOffersUnreadsPayload = {
  __typename?: 'WineOffersUnreadsPayload';
  /** Информация о непрочитанных коммерческих предложениях в роли покупателя */
  buyer?: Maybe<WineOffersUnreadsCompany>;
  /** Информация о непрочитанных коммерческих предложениях в роли продавца */
  seller?: Maybe<WineOffersUnreadsCompany>;
};

export type WineOffersUnreadsSeller = {
  __typename?: 'WineOffersUnreadsSeller';
  notCompletedCount: Scalars['Int']['output'];
};

export type WineOffersUpdatePeriodOfValidityInput = {
  /** Идентификатор текущей компании */
  companyId: Scalars['ID']['input'];
  /** Дата действия предложения */
  periodOfValidity?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификаторы коммерческих предложений */
  wineOfferIds: Array<Scalars['Int']['input']>;
};

export type WineOffersUpdatePeriodOfValidityPayload = {
  __typename?: 'WineOffersUpdatePeriodOfValidityPayload';
  query?: Maybe<Query>;
  records: Array<WineOfferBase>;
};

export type WineOverwriteInput = {
  fromWineId: Scalars['ID']['input'];
  toWineId: Scalars['ID']['input'];
};

export type WineOverwritePayload = {
  __typename?: 'WineOverwritePayload';
  destination: Wine;
  query?: Maybe<Query>;
};

/** Элемент упаковки вина */
export type WinePackageElement = {
  __typename?: 'WinePackageElement';
  /** Идентификатор элемента упаковки вина */
  id: Scalars['Int']['output'];
  /** Название элемента упаковки */
  localizedName: Scalars['String']['output'];
};


/** Элемент упаковки вина */
export type WinePackageElementLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Тип упаковки вина */
export type WinePackageType = {
  __typename?: 'WinePackageType';
  /** Идентификатор тип упаковки */
  id: Scalars['Int']['output'];
  /** Является ли этот тип типом по умолчанию */
  isDefault: Scalars['Boolean']['output'];
  /** Перевод названия лота поставки */
  localizedName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};


/** Тип упаковки вина */
export type WinePackageTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type WinePagination = {
  __typename?: 'WinePagination';
  items: Array<Wine>;
  pageInfo: PageInfo;
};

export type WineProductsUpdateInput = {
  /** Идентификаторы продуктов для признания допустимыми */
  accept?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Идентификатор текущей компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Составные идентификаторы продуктов для создания */
  create?: InputMaybe<Array<ProductCompositeIdInput>>;
  /** Идентификаторы продуктов для признания недопустимыми */
  refuse?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WineProductsUpdatePayload = {
  __typename?: 'WineProductsUpdatePayload';
  accepted?: Maybe<Array<ProductAcceptPayload>>;
  created?: Maybe<Array<ProductCreatePayload>>;
  query?: Maybe<Query>;
  refused?: Maybe<Array<ProductRefusePayload>>;
};

export type WineRefuseInput = {
  /** Идентификатор текущей компании пользователя */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор вина */
  wineId: Scalars['ID']['input'];
};

export type WineRefusePayload = {
  __typename?: 'WineRefusePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

/** Европейский винный регион */
export type WineRegionEu = {
  __typename?: 'WineRegionEU';
  /** Страна содержащая регион */
  country: Country;
  /** Геоиндикации соответствующие региону */
  geoIndications: Array<GeoIndication>;
  /** Идентификатор европейского винного региона */
  id: Scalars['Int']['output'];
  /** Перевод названия региона */
  localizedName: Scalars['String']['output'];
  /** Название региона на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Европейский винный регион */
export type WineRegionEuLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Отзыв на продукт */
export type WineReview = {
  __typename?: 'WineReview';
  /** Адрес, где была совершена покупка */
  address?: Maybe<Scalars['String']['output']>;
  /** Автор отзыва */
  authorUser: User;
  /** Файл с контрэтикеткой вина */
  backLabelImage?: Maybe<Image>;
  /** Код слепой дегустации */
  blindCode?: Maybe<Scalars['String']['output']>;
  /** Дата блокировки отзыва */
  blockedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Файл с изображением бутылки */
  bottleImage?: Maybe<Image>;
  /** Дата создания отзыва */
  createdAt: Scalars['DateTime']['output'];
  /** Валюлта цены по которой была куплена бутылка */
  currency?: Maybe<Currency>;
  /** Дата дегустации */
  date?: Maybe<Scalars['Date']['output']>;
  /** Год начала диапазона годов, когда рекомендуется пить это вино */
  drinkingWindowFrom?: Maybe<Scalars['PositiveInt']['output']>;
  /** Год окончания диапазона годов, когда рекомендуется пить это вино */
  drinkingWindowTo?: Maybe<Scalars['PositiveInt']['output']>;
  exhibition?: Maybe<Exhibition>;
  /** Рекомендуемые сочетания с вином */
  foodPairs: Array<FoodPair>;
  /** Файл с этикеткой вина */
  frontLabelImage?: Maybe<Image>;
  /** Идентификатор отзыва */
  id: Scalars['ID']['output'];
  /** Другие изображения не связанные с продуктом */
  images: Array<Image>;
  /** Признак, что бутылка была дефектная */
  isBottleDefected: Scalars['Boolean']['output'];
  /** Является ли отзыв черновиком */
  isDraft: Scalars['Boolean']['output'];
  /** Является ли отзыв скрытым от других пользователей */
  isPrivate: Scalars['Boolean']['output'];
  /** Можно ли опубликовать отзыв */
  isPublishable: Scalars['Boolean']['output'];
  /** Широта координаты адреса, где была совершена покупка вина */
  latitude?: Maybe<Scalars['Latitude']['output']>;
  /** Долгота координаты адреса, где была совершена покупка вина */
  longitude?: Maybe<Scalars['Longitude']['output']>;
  owner?: Maybe<WineReviewOwner>;
  /** Место дегустации */
  place?: Maybe<Scalars['String']['output']>;
  /** Тип места дегустации */
  placeType: WineReviewPlaceType;
  /** Цена по которой была куплена бутылка вина */
  price?: Maybe<Scalars['Float']['output']>;
  /** Продукт на который оставлен отзыв */
  product?: Maybe<Product>;
  /** Результат распознавания вина в отзыве */
  recognitionResult?: Maybe<ProductRecognitionResult>;
  /** Отзыв пользователя */
  review?: Maybe<Scalars['String']['output']>;
  /** Оценка пользователя */
  score?: Maybe<Scalars['Int']['output']>;
  /** Система оценки */
  scoreSystem?: Maybe<ScoreSystem>;
  status: WineReviewStatus;
  /** Тип отзыва */
  type: WineReviewType;
  /** Дата последнего изменения отзыва */
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['URL']['output']>;
};


/** Отзыв на продукт */
export type WineReviewScoreArgs = {
  scoreSystemMax?: InputMaybe<Scalars['Int']['input']>;
};

export enum WineReviewAggregationSort {
  AvgScoreDesc = 'AVG_SCORE_DESC',
  CountDesc = 'COUNT_DESC'
}

export type WineReviewCreateInput = {
  /** Адрес, где была проведена дегустация */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение контрэтикетки */
  backLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  backLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Код слепой дегустации */
  blindCode?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  bottleSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор валюты для цены за бутылку */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата проведения дегустации */
  date: Scalars['Date']['input'];
  /** Рекомендуемый год с которого стоит дегустировать вино */
  drinkingWindowFrom?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Рекомендуемый последний год для дегустации вина */
  drinkingWindowTo?: InputMaybe<Scalars['PositiveInt']['input']>;
  exhibitionId?: InputMaybe<Scalars['Int']['input']>;
  /** Рекомендации по блюдам с которыми может сочетаться вино */
  foodPairId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Ссылка на изображение этикетки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  frontLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Другие изображения не связанные с продуктом */
  images?: InputMaybe<Array<Scalars['URL']['input']>>;
  /** Была ли бутылка испорчена */
  isBottleDefected: Scalars['Boolean']['input'];
  /** Широта адреса, где была проведена дегустация */
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  /** Долгота адреса, где была проведена дегустация */
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  /** Место дегустации. Максимальная длина 50 */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Тип места дегустации */
  placeType?: WineReviewPlaceType;
  /** Цена за бутылку по которой был приобретен продукт */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Идентификатор продукта на который создается отзыв */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Текст отзыва */
  review?: InputMaybe<Scalars['String']['input']>;
  /** Оценка продукта */
  score?: InputMaybe<Scalars['Int']['input']>;
  /** Тип отзыва */
  type: WineReviewType;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type WineReviewCreatePayload = {
  __typename?: 'WineReviewCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineReview>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineReviewDeletePayload = {
  __typename?: 'WineReviewDeletePayload';
  query?: Maybe<Query>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineReviewDraftCreateInput = {
  /** Адрес, где была проведена дегустация */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор поиска по изображению */
  backLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Код слепой дегустации */
  blindCode?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  bottleSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор валюты для цены за бутылку */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата проведения дегустации */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Рекомендуемый год с которого стоит дегустировать вино */
  drinkingWindowFrom?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Рекомендуемый последний год для дегустации вина */
  drinkingWindowTo?: InputMaybe<Scalars['PositiveInt']['input']>;
  exhibitionId?: InputMaybe<Scalars['Int']['input']>;
  /** Рекомендации по блюдам с которыми может сочетаться вино */
  foodPairId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Ссылка на изображение этикетки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  frontLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Другие изображения не связанные с продуктом */
  images?: InputMaybe<Array<Scalars['URL']['input']>>;
  /** Была ли бутылка испорчена */
  isBottleDefected?: Scalars['Boolean']['input'];
  /** Широта адреса, где была проведена дегустация */
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  /** Долгота адреса, где была проведена дегустация */
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  /** Место дегустации. Максимальная длина 50 */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Тип места дегустации */
  placeType?: WineReviewPlaceType;
  /** Цена за бутылку по которой был приобретен продукт */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Идентификатор продукта на который создается отзыв */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Текст отзыва */
  review?: InputMaybe<Scalars['String']['input']>;
  /** Оценка продукта */
  score?: InputMaybe<Scalars['Int']['input']>;
  /** Тип отзыва */
  type: WineReviewType;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type WineReviewDraftCreatePayload = {
  __typename?: 'WineReviewDraftCreatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineReview>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineReviewDraftUpdateInput = {
  /** Адрес, где была проведена дегустация */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение контрэтикетки */
  backLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  backLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Код слепой дегустации */
  blindCode?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  bottleSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор валюты для цены за бутылку */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата проведения дегустации */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Рекомендуемый год с которого стоит дегустировать вино */
  drinkingWindowFrom?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Рекомендуемый последний год для дегустации вина */
  drinkingWindowTo?: InputMaybe<Scalars['PositiveInt']['input']>;
  exhibitionId?: InputMaybe<Scalars['Int']['input']>;
  /** Рекомендации по блюдам с которыми может сочетаться вино */
  foodPairId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Ссылка на изображение этикетки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  frontLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор отзыва для редактирования */
  id: Scalars['ID']['input'];
  /** Другие изображения не связанные с продуктом */
  images?: InputMaybe<Array<Scalars['URL']['input']>>;
  /** Информация о неправильном распознавании */
  incorrectRecognition?: InputMaybe<WineReviewIncorrectRecognitionInput>;
  /** Была ли бутылка испорчена */
  isBottleDefected?: Scalars['Boolean']['input'];
  /** Широта адреса, где была проведена дегустация */
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  /** Долгота адреса, где была проведена дегустация */
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  /** Место дегустации. Максимальная длина 50 */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Тип места дегустации */
  placeType?: WineReviewPlaceType;
  /** Цена за бутылку по которой был приобретен продукт */
  price?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Текст отзыва */
  review?: InputMaybe<Scalars['String']['input']>;
  /** Оценка продукта */
  score?: InputMaybe<Scalars['Int']['input']>;
  /** Тип отзыва */
  type: WineReviewType;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type WineReviewDraftUpdatePayload = {
  __typename?: 'WineReviewDraftUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineReview>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineReviewFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<WineReviewStatus>;
};

export type WineReviewFiltersFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
  status?: InputMaybe<WineReviewStatus>;
};

export type WineReviewIncorrectRecognitionInput = {
  /** Комментарий к неправильному распознаванию */
  comment: Scalars['String']['input'];
  /** Идентификатор продукта, который был неправильно распознан */
  productId: Scalars['ID']['input'];
};

export type WineReviewOwner = Company | User;

export type WineReviewPagination = {
  __typename?: 'WineReviewPagination';
  items: Array<WineReview>;
  pageInfo: PageInfo;
};

export enum WineReviewPlaceType {
  Exhibition = 'EXHIBITION',
  Home = 'HOME',
  Other = 'OTHER',
  Restaurant = 'RESTAURANT',
  Winery = 'WINERY'
}

export type WineReviewRequestInput = {
  /** Список квалификаций экспертов */
  expertQualificationIds: Array<Scalars['Int']['input']>;
  /** Список продуктов, на которые нужно написать отзыв */
  productIds: Array<Scalars['ID']['input']>;
};

export type WineReviewRequestPayload = {
  __typename?: 'WineReviewRequestPayload';
  query?: Maybe<Query>;
};

export enum WineReviewSort {
  /** Сортировка по возрастанию username автора */
  AuthorUsernameAsc = 'AUTHOR_USERNAME_ASC',
  /** Сортировка по убыванию username автора */
  AuthorUsernameDesc = 'AUTHOR_USERNAME_DESC',
  /** Сортировка отзывов по возрастанию даты создания */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Сортировка отзывов по убыванию даты создания */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Сортировка отзывов по возрастанию оценки WWL вина */
  RatingAsc = 'RATING_ASC',
  /** Сортировка отзывов по убыванию оценки WWL вина */
  RatingDesc = 'RATING_DESC',
  /** Сортировка отзывов по возрастанию даты проведения дегустации */
  ReviewDateAsc = 'REVIEW_DATE_ASC',
  /** Сортировка отзывов по убыванию даты проведения дегустации */
  ReviewDateDesc = 'REVIEW_DATE_DESC',
  /** Сортировка отзывов по возрастанию даты обновления */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка отзывов по убыванию даты обновления */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка отзывов по возрастанию полного названия вина */
  WineFullnameAsc = 'WINE_FULLNAME_ASC',
  /** Сортировка отзывов по убыванию полного названия вина */
  WineFullnameDesc = 'WINE_FULLNAME_DESC'
}

export enum WineReviewStatus {
  /** Отзыв заблокирован модератором */
  Blocked = 'BLOCKED',
  /** В отзыве не указан продукт и он нуждается в обработке модератором */
  Checking = 'CHECKING',
  /** Автор пометил отзыв как черновик */
  Draft = 'DRAFT',
  /** Отзыв выставлен на продажу */
  ForSale = 'FOR_SALE',
  /** Отзыв является скрытым от остальных пользователей */
  Private = 'PRIVATE',
  /** Отзыв опубликован и виден всем */
  Published = 'PUBLISHED',
  /** Отзыв продан */
  Sold = 'SOLD'
}

/** Тип отзыва */
export enum WineReviewType {
  /** Для продажи */
  ForSale = 'FOR_SALE',
  /** Приватный. Виден только автору */
  Private = 'PRIVATE',
  /** Публичный. Виден всем, если есть разрешение */
  Public = 'PUBLIC'
}

export type WineReviewUpdateInput = {
  /** Адрес, где была проведена дегустация */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение контрэтикетки */
  backLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  backLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Код слепой дегустации */
  blindCode?: InputMaybe<Scalars['String']['input']>;
  /** Ссылка на изображение бутылки */
  bottleImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  bottleSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор валюты для цены за бутылку */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** Дата проведения дегустации */
  date: Scalars['Date']['input'];
  /** Рекомендуемый год с которого стоит дегустировать вино */
  drinkingWindowFrom?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Рекомендуемый последний год для дегустации вина */
  drinkingWindowTo?: InputMaybe<Scalars['PositiveInt']['input']>;
  exhibitionId?: InputMaybe<Scalars['Int']['input']>;
  /** Рекомендации по блюдам с которыми может сочетаться вино */
  foodPairId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Ссылка на изображение этикетки */
  frontLabelImage?: InputMaybe<Scalars['URL']['input']>;
  /** Идентификатор поиска по изображению */
  frontLabelSearchId?: InputMaybe<Scalars['ID']['input']>;
  /** Идентификатор отзыва для редактирования */
  id: Scalars['ID']['input'];
  /** Другие изображения не связанные с продуктом */
  images?: InputMaybe<Array<Scalars['URL']['input']>>;
  /** Информация о неправильном распознавании */
  incorrectRecognition?: InputMaybe<WineReviewIncorrectRecognitionInput>;
  /** Была ли бутылка испорчена */
  isBottleDefected: Scalars['Boolean']['input'];
  /** Широта адреса, где была проведена дегустация */
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  /** Долгота адреса, где была проведена дегустация */
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  /** Место дегустации. Максимальная длина 50 */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Тип места дегустации */
  placeType?: WineReviewPlaceType;
  /** Цена за бутылку по которой был приобретен продукт */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Идентификатор продукта */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Текст отзыва */
  review?: InputMaybe<Scalars['String']['input']>;
  /** Оценка продукта */
  score?: InputMaybe<Scalars['Int']['input']>;
  /** Тип отзыва */
  type: WineReviewType;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export type WineReviewUpdatePayload = {
  __typename?: 'WineReviewUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<WineReview>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineReviewsAggregateByCountryGeoIndicationItem = {
  __typename?: 'WineReviewsAggregateByCountryGeoIndicationItem';
  count: Scalars['Int']['output'];
  country: Country;
  coverage: Scalars['Int']['output'];
  geoIndicationCount: Scalars['Int']['output'];
};

export type WineReviewsAggregateByCountryItem = {
  __typename?: 'WineReviewsAggregateByCountryItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  country: Country;
  coverage: Scalars['Int']['output'];
};

export type WineReviewsAggregateByGeoIndicationItem = {
  __typename?: 'WineReviewsAggregateByGeoIndicationItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  geoIndication: GeoIndication;
};

export type WineReviewsAggregateByGeoIndicationTradeNameItem = {
  __typename?: 'WineReviewsAggregateByGeoIndicationTradeNameItem';
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  geoIndication: GeoIndication;
  tradeNameCount: Scalars['Int']['output'];
};

export type WineReviewsAggregateByGrapeVarietyItem = {
  __typename?: 'WineReviewsAggregateByGrapeVarietyItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  grapeVariety: GrapeVariety;
};

export type WineReviewsAggregateByPriceRangeItem = {
  __typename?: 'WineReviewsAggregateByPriceRangeItem';
  count: Scalars['Int']['output'];
  lower?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  upper?: Maybe<Scalars['Float']['output']>;
};

export type WineReviewsAggregateBySweetnessLevelItem = {
  __typename?: 'WineReviewsAggregateBySweetnessLevelItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  sweetnessLevel: SweetnessLevel;
};

export type WineReviewsAggregateByTradeNameItem = {
  __typename?: 'WineReviewsAggregateByTradeNameItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  tradeName: TradeName;
};

export type WineReviewsAggregateByVintageItem = {
  __typename?: 'WineReviewsAggregateByVintageItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  vintage: Scalars['Int']['output'];
};

export type WineReviewsAggregateByVintageScoreItem = {
  __typename?: 'WineReviewsAggregateByVintageScoreItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  maxScore?: Maybe<Scalars['Float']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  vintage: Scalars['Int']['output'];
};

export type WineReviewsAggregateByWineColorItem = {
  __typename?: 'WineReviewsAggregateByWineColorItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  wineColor: WineColor;
};

export type WineReviewsAggregateByWineTypeItem = {
  __typename?: 'WineReviewsAggregateByWineTypeItem';
  avgScore?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  wineType: WineType;
};

export type WineReviewsAggregateFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineReviewsAggregateFiltersFilter = {
  /** Значения фильтров */
  facetsInputs?: InputMaybe<Array<FacetInput>>;
};

export type WineReviewsAggregateGrapeVarietyCoverage = {
  __typename?: 'WineReviewsAggregateGrapeVarietyCoverage';
  covered: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WineReviewsAggregation = {
  __typename?: 'WineReviewsAggregation';
  avgScore?: Maybe<Scalars['Float']['output']>;
  byCountry?: Maybe<Array<WineReviewsAggregateByCountryItem>>;
  byCountryGeoIndication?: Maybe<Array<WineReviewsAggregateByCountryGeoIndicationItem>>;
  byGeoIndication?: Maybe<Array<WineReviewsAggregateByGeoIndicationItem>>;
  byGeoIndicationTradeName?: Maybe<Array<WineReviewsAggregateByGeoIndicationTradeNameItem>>;
  byGrapeVariety?: Maybe<Array<WineReviewsAggregateByGrapeVarietyItem>>;
  byPriceRange?: Maybe<Array<WineReviewsAggregateByPriceRangeItem>>;
  byRecommendedPriceRange?: Maybe<Array<WineReviewsAggregateByPriceRangeItem>>;
  bySweetnessLevel?: Maybe<Array<WineReviewsAggregateBySweetnessLevelItem>>;
  byTradeName?: Maybe<Array<WineReviewsAggregateByTradeNameItem>>;
  byVintage?: Maybe<Array<WineReviewsAggregateByVintageItem>>;
  byVintageScore?: Maybe<Array<WineReviewsAggregateByVintageScoreItem>>;
  byWineColor?: Maybe<Array<WineReviewsAggregateByWineColorItem>>;
  byWineType?: Maybe<Array<WineReviewsAggregateByWineTypeItem>>;
  count: Scalars['Int']['output'];
  filters: FacetFilterPayload;
  grapeVarietyMonocepageCoverage: WineReviewsAggregateGrapeVarietyCoverage;
  mostScored?: Maybe<WineReview>;
};


export type WineReviewsAggregationAvgScoreArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};


export type WineReviewsAggregationByCountryArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByCountryGeoIndicationArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByGeoIndicationArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
  sort?: InputMaybe<WineReviewAggregationSort>;
};


export type WineReviewsAggregationByGeoIndicationTradeNameArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByGrapeVarietyArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByPriceRangeArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByRecommendedPriceRangeArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationBySweetnessLevelArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByTradeNameArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByVintageArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByVintageScoreArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByWineColorArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationByWineTypeArgs = {
  filter?: InputMaybe<WineReviewsAggregateFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationCountArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};


export type WineReviewsAggregationFiltersArgs = {
  filter?: InputMaybe<WineReviewsAggregateFiltersFilter>;
  localeId: Scalars['Int']['input'];
};


export type WineReviewsAggregationMostScoredArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WineReviewsPendingPayload = {
  __typename?: 'WineReviewsPendingPayload';
  count: Scalars['Int']['output'];
};

export type WineReviewsPublishInput = {
  /** Идентификаторы отзывов */
  reviewIds: Array<Scalars['ID']['input']>;
};

export type WineReviewsPublishPayload = {
  __typename?: 'WineReviewsPublishPayload';
  query?: Maybe<Query>;
  records: Array<WineReview>;
};

export type WineReviewsSetPrivateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  reviewIds: Array<Scalars['ID']['input']>;
};

export type WineReviewsSetPrivatePayload = {
  __typename?: 'WineReviewsSetPrivatePayload';
  query?: Maybe<Query>;
  records: Array<WineReview>;
};

export type WineReviewsSetPublicInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  reviewIds: Array<Scalars['ID']['input']>;
};

export type WineReviewsSetPublicPayload = {
  __typename?: 'WineReviewsSetPublicPayload';
  query?: Maybe<Query>;
  records: Array<WineReview>;
};

export enum WineSort {
  /** Сортировка вин по возрастанию названия бренда */
  BrandNameAsc = 'BRAND_NAME_ASC',
  /** Сортировка вин по убыванию названия бренда */
  BrandNameDesc = 'BRAND_NAME_DESC',
  /** Сортировка вин по возрастанию ключевых слов */
  KeywordsAsc = 'KEYWORDS_ASC',
  /** Сортировка вин по убыванию ключевых слов */
  KeywordsDesc = 'KEYWORDS_DESC',
  /** Сортировка вин по релевантности полнотекстового поиска */
  Score = 'SCORE',
  /** Сортировка вин по возрастанию названия специальной классификации */
  SpecialClassificationNameAsc = 'SPECIAL_CLASSIFICATION_NAME_ASC',
  /** Сортировка вин по убыванию названия специальной классификации */
  SpecialClassificationNameDesc = 'SPECIAL_CLASSIFICATION_NAME_DESC',
  /** Сортировка вин по возрастанию названия сладости вина */
  SweetnessLevelNameAsc = 'SWEETNESS_LEVEL_NAME_ASC',
  /** Сортировка вин по убыванию названия сладости вина */
  SweetnessLevelNameDesc = 'SWEETNESS_LEVEL_NAME_DESC',
  /** Сортировка вин по возрастанию даты последнего изменения */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Сортировка вин по убыванию даты последнего изменения */
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  /** Сортировка вин по возрастанию винтажа */
  VintageAsc = 'VINTAGE_ASC',
  /** Сортировка вин по убыванию винтажа */
  VintageDesc = 'VINTAGE_DESC',
  /** Сортировка вин по возрастанию названия цвета вина */
  WineColorNameAsc = 'WINE_COLOR_NAME_ASC',
  /** Сортировка вин по убыванию названия цвета вина */
  WineColorNameDesc = 'WINE_COLOR_NAME_DESC',
  /** Сортировка вин по возрастанию составного названия */
  WineNameAsc = 'WINE_NAME_ASC',
  /** Сортировка вин по убыванию составного названия */
  WineNameDesc = 'WINE_NAME_DESC',
  /** Сортировка вин по возрастанию названия геоиндикации */
  WineRegionNameAsc = 'WINE_REGION_NAME_ASC',
  /** Сортировка вин по возрастанию названия геоиндикации */
  WineRegionNameDesc = 'WINE_REGION_NAME_DESC',
  /** Сортировка вин по возрастанию названия типа вина */
  WineTypeNameAsc = 'WINE_TYPE_NAME_ASC',
  /** Сортировка вин по убыванию названия типа вина */
  WineTypeNameDesc = 'WINE_TYPE_NAME_DESC'
}

export enum WineStatus {
  /** Приоритет записи о допустимости текущего вина выше или равен приоритету текущего пользователя */
  Approved = 'APPROVED',
  /** Приоритет записи о допустимости текущего вина ниже приоритета текущего пользователя */
  Unapproved = 'UNAPPROVED'
}

/** Тип винной территории */
export type WineTerritoryType = {
  __typename?: 'WineTerritoryType';
  /** Страна в которой используется тип винной территории */
  country: Country;
  /** Геоиндикации соответствующие типу */
  geoIndications: Array<GeoIndication>;
  /** Идентификатора типа винной территории */
  id: Scalars['Int']['output'];
  /** Уровень типа винной территории */
  level: Scalars['Int']['output'];
  /** Перевод типа винной территории */
  localizedName: Scalars['String']['output'];
  /** Название типа винной территории на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Тип винной территории */
export type WineTerritoryTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Тип вина */
export type WineType = {
  __typename?: 'WineType';
  /** Идентификатор типа вина */
  id: Scalars['Int']['output'];
  /** Является ли тип вина типом по умолчанию (не отображается в полном названии) */
  isDefault: Scalars['Boolean']['output'];
  /** Перевод названия типа вина */
  localizedName: Scalars['String']['output'];
  /** Название типа вина на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Тип вина */
export type WineTypeLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

/** Тип вина ЕС */
export type WineTypeEu = {
  __typename?: 'WineTypeEU';
  /** Идентификатор типа вина ЕС */
  id: Scalars['Int']['output'];
  /** Является ли тип вина типом по умолчанию (не отображается в полном названии) */
  isDefault: Scalars['Boolean']['output'];
  /** Перевод названия типа вина */
  localizedName: Scalars['String']['output'];
  /** Название типа вина на языке по умолчанию */
  masterName: Scalars['String']['output'];
};


/** Тип вина ЕС */
export type WineTypeEuLocalizedNameArgs = {
  localeId: Scalars['Int']['input'];
};

export type WineUpdateAwardInput = {
  awardId: Scalars['Int']['input'];
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  rewardIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  year: Scalars['Int']['input'];
};

export type WineUpdateChemicalAnalysisInput = {
  acidityTotal?: InputMaybe<ValueWithUnitInput>;
  alcoholVolume?: InputMaybe<Scalars['Float']['input']>;
  pH?: InputMaybe<Scalars['Float']['input']>;
  residualSugar?: InputMaybe<Scalars['Float']['input']>;
};

export type WineUpdateInput = {
  awards?: InputMaybe<Array<WineUpdateAwardInput>>;
  certificationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  chemicalAnalysis?: InputMaybe<WineUpdateChemicalAnalysisInput>;
  /** Идентификатор текущей компании пользователя */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  foodPairId?: InputMaybe<Array<Scalars['Int']['input']>>;
  grapeVarieties?: InputMaybe<Array<WineGrapeVarietyInput>>;
  ingredientId?: InputMaybe<Array<Scalars['Int']['input']>>;
  nutritionDeclaration?: InputMaybe<WineUpdateNutritionDeclarationInput>;
  refuseWineAwardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  responsibleConsumptionId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Значение от 1 до 99 */
  servingTemperatureMax?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Значение от 1 до 99 */
  servingTemperatureMin?: InputMaybe<Scalars['PositiveInt']['input']>;
  /** Идентификатор вина */
  wineId: Scalars['ID']['input'];
  wineTypeEUId?: InputMaybe<Scalars['Int']['input']>;
};

export type WineUpdateNutritionDeclarationInput = {
  carbohydrate?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  carbohydrateApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  energyKCal?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  energyKCalApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  fat?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  fatApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  protein?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  proteinApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  salt?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  saltApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  saturates?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  saturatesApproximate?: InputMaybe<Scalars['Boolean']['input']>;
  sugars?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  sugarsApproximate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WineUpdatePayload = {
  __typename?: 'WineUpdatePayload';
  query?: Maybe<Query>;
  record?: Maybe<Wine>;
  recordId?: Maybe<Scalars['ID']['output']>;
};

export type WineWritePolicy = {
  __typename?: 'WineWritePolicy';
  chemicalAnalysis: WritePolicy;
  grapeVarieties: WritePolicy;
  ingredients: WritePolicy;
  nutritionDeclaration: WritePolicy;
  recommendation: WritePolicy;
  responsibleConsumptions: WritePolicy;
  wineTypeEU: WritePolicy;
};

export type WinesPaginationFilter = {
  status?: InputMaybe<Array<WineStatus>>;
};

export type WinesToOfferPayload = {
  __typename?: 'WinesToOfferPayload';
  items: Array<Wine>;
};

export enum WritePolicy {
  LowPriority = 'LOW_PRIORITY',
  OfficialRegistry = 'OFFICIAL_REGISTRY',
  Ok = 'OK'
}
