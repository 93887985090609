import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { WineEditStore } from './types'
import { objectValueByPath } from './selectors'
import { ProductDataAutofillWineFragment } from '../../api/operations'

const initialState: WineEditStore = {
  preloaded: {},
  values: {},
  errors: {},
  editKeywords: null,
  otherProducts: [],
  previewBottleIndex: 0,
  loading: false,
  isDirty: false,
  overwriteProduct: null,
}

const wineEditSlice = createSlice({
  name: 'wineEdit',
  initialState,
  reducers: {
    setEditKeywords: (
      state,
      { payload }: PayloadAction<{ name: string; value: any }>
    ) => {
      state.editKeywords = payload
    },
    setOtherProducts: (state, { payload }: PayloadAction<any[]>) => {
      state.otherProducts = payload
    },
    setPreviewBottleIndex: (
      state,
      { payload }: PayloadAction<number | string>
    ) => {
      state.previewBottleIndex = payload
    },
    setValue: (
      state,
      {
        payload,
      }: PayloadAction<{ name: string; value: any; setDirty?: boolean }>
    ) => {
      state.values = {
        ...state.values,
        [payload.name]: payload.value,
      }

      if (payload.setDirty !== false) state.isDirty = true
    },
    setValues: (state, { payload }: PayloadAction<any>) => {
      state.values = {
        ...state.values,
        ...payload,
      }
      state.isDirty = true
    },
    setError: (
      state,
      { payload }: PayloadAction<{ name: string; value: any }>
    ) => {
      state.errors = {
        ...state.errors,
        [payload.name]: payload.value,
      }
    },
    setArrayItemValue: (
      state,
      { payload }: PayloadAction<{ name: string; index: number; value: any }>
    ) => {
      const newValues = [...objectValueByPath(state.values, payload.name, [])]
      newValues[payload.index] = payload.value

      state.values = {
        ...state.values,
        [payload.name]: newValues,
      }
      state.isDirty = true
    },
    setArrayItemError: (
      state,
      { payload }: PayloadAction<{ name: string; index: number; value: any }>
    ) => {
      const newValues = [
        ...(objectValueByPath(state.errors, payload.name, []) ?? []),
      ]
      newValues[payload.index] = payload.value

      state.errors = {
        ...state.errors,
        [payload.name]: newValues,
      }
    },
    deleteArrayItem: (
      state,
      { payload }: PayloadAction<{ name: string; index: number }>
    ) => {
      state.values = {
        ...state.values,
        [payload.name]: objectValueByPath(
          state.values,
          payload.name,
          []
        ).filter((_: any, index: number) => index !== payload.index),
      }
    },
    setFormLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setPreloadedData: (state, { payload }: PayloadAction<any>) => {
      state.preloaded = payload
    },
    setErrors: (state, { payload }) => {
      state.errors = payload
    },
    setDirty: (state, { payload }: PayloadAction<boolean>) => {
      state.isDirty = payload
    },
    clear: (state, { payload }) => {
      const notClear = (payload ?? []).reduce((acc, item) => {
        acc[item] = state[item]
        return acc
      }, {})

      return {
        ...initialState,
        ...notClear,
      }
    },
    reset: (state, { payload }: PayloadAction<Record<string, any>>) => {
      state.values = {
        ...initialState.values,
        ...payload,
      }
      state.isDirty = false
      state.errors = null
    },
    setOverwriteProduct: (
      state,
      { payload }: PayloadAction<ProductDataAutofillWineFragment | null>
    ) => {
      state.overwriteProduct = payload
    },
  },
})

export const { actions, reducer: wineEdit } = wineEditSlice
export const {
  setValue,
  setValues,
  setError,
  setArrayItemValue,
  setArrayItemError,
  deleteArrayItem,
  setPreloadedData,
  setDirty,
  setOverwriteProduct,
} = actions
