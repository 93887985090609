import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ls } from 'utils/my-localstorage'
import type { RetailWinesStore, Sorting, Filters, PageInfo } from './types'
import type { Currency } from 'api/schemas'
import { ManagementRoles } from 'utils/consts/userRoles'
import { RetailWinesCurrencyLocalStorage } from 'components/Pages/Retail/Wines/Content/_methods'

const getInitialCurrency = () => {
  const role = ls.getItem('wwl-role_code')
  const isAdmin = [
    ManagementRoles.SUPERADMIN,
    ManagementRoles.CONTENT,
    ManagementRoles.MANAGER,
  ].includes(role)

  const currencyFromLS = ls.getObject(RetailWinesCurrencyLocalStorage)

  return isAdmin
    ? currencyFromLS && currencyFromLS.id !== null
      ? currencyFromLS
      : null
    : null
}
const getInitialState: () => RetailWinesStore = () => ({
  items: [],
  pageInfo: null,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
  selectedItems: [],
  currency: getInitialCurrency(),
})
export interface CurrencyRetailWines extends Omit<Currency, 'id'> {
  id: number | null
}
const retailWinesSlice = createSlice({
  name: 'retailWines',
  initialState: getInitialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<any[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    clear: () => {
      return getInitialState()
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    setCurrency: (
      state,
      { payload }: PayloadAction<CurrencyRetailWines | null>
    ) => {
      state.currency = payload
    },

    reset: () => {
      return getInitialState()
    },
  },
})

export const { actions, reducer: retailWines } = retailWinesSlice
export const {
  setItems,
  setPageInfo,
  setSortingValue: setSort,
  clear,
  setLoading,
  setQuery,
  setFilters,
  setError,
  reset,
  setSelectedItems,
  setCurrency,
} = actions
