import { useMemo } from 'react'
import { ManagementRoles, Roles, PERSON } from 'utils/consts/userRoles'
import { useUserDataForRoutes } from 'utils/hooks/useSelectedCompany'
import {
  REVIEWS_DASHBOARD,
  REVIEWS_COMPANY_REVIEWS,
  REVIEWS_PATH,
  ADMINISTRATION_OFFERS,
  ADMINISTRATION_TRADE_OFFERS,
  ADMINISTRATION_RETAIL_PUBLIC_PAGES,
  ADMINISTRATION_MERCHANTS,
} from 'utils/consts/path'

const defaultRedirects = [
  { from: '/acc/buy-wine', to: '/acc/buy-wine/offers/all' },
  { from: '/acc/buy-wine/offers', to: '/acc/buy-wine/offers/all' },
  { from: '/acc/sell-wine', to: '/acc/sell-wine/my-offers/all' },
  { from: '/acc/sell-wine/my-offers', to: '/acc/sell-wine/my-offers/all' },
  { from: '/acc/retail/wines', to: '/acc/retail/wines/all' },
  {
    from: '/acc/buy-wine/negotiations',
    to: '/acc/buy-wine/negotiations/active',
  },
  { from: '/acc/my-wines', to: '/acc/my-wines/wines/all' },
  { from: '/acc/my-wines/e-labels', to: '/acc/my-wines/e-labels/all' },
  { from: '/acc/database', to: '/acc/database/wines' },
  { from: '/acc/offer/create', to: '/acc/offer/create/wine' },
  { from: '/acc/reviews/my-reviews', to: '/acc/reviews/my-reviews/all' },
  {
    from: '/acc/administration/users',
    to: '/acc/administration/users/current',
  },
  {
    from: ADMINISTRATION_MERCHANTS,
    to: ADMINISTRATION_MERCHANTS + '/all',
  },
]

const adminRedirects = [
  { from: '/acc', to: '/acc/administration' },
  { from: '/acc/administration', to: '/acc/administration/users/current' },
  {
    from: '/acc/administration/users',
    to: '/acc/administration/users/current',
  },
]
const superAdminRedirects = [
  { from: '/acc/administration', to: '/acc/administration/users/current' },
  {
    from: '/acc/administration/users',
    to: '/acc/administration/users/current',
  },
  { from: '/acc/database/producers', to: '/acc/database/producers/all' },
  {
    from: ADMINISTRATION_TRADE_OFFERS,
    to: `${ADMINISTRATION_TRADE_OFFERS}/all`,
  },
  {
    from: ADMINISTRATION_RETAIL_PUBLIC_PAGES,
    to: `${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/all`,
  },
]
const contentRedirects = [
  { from: '/acc', to: '/acc/database/wines' },
  { from: '/acc/administration', to: '/acc/administration/mentions' },
  { from: '/acc/database/producers', to: '/acc/database/producers/all' },
  {
    from: ADMINISTRATION_TRADE_OFFERS,
    to: `${ADMINISTRATION_TRADE_OFFERS}/all`,
  },
  {
    from: ADMINISTRATION_RETAIL_PUBLIC_PAGES,
    to: `${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/all`,
  },
]
const managerRedirects = [
  { from: '/acc', to: '/acc/database/wines' },
  { from: '/acc/administration', to: ADMINISTRATION_OFFERS },

  {
    from: ADMINISTRATION_RETAIL_PUBLIC_PAGES,
    to: `${ADMINISTRATION_RETAIL_PUBLIC_PAGES}/all`,
  },
]

const managementRedirects = {
  [Roles.SUPERADMIN]: superAdminRedirects,
  [Roles.ADMIN]: adminRedirects,
  [Roles.CONTENT]: contentRedirects,
  [Roles.MANAGER]: managerRedirects,
}

const getPersonRedirects = category => [
  { from: '/acc/company/create', to: '/acc/company/create/step-1' },
  {
    from: REVIEWS_PATH,
    to: category === PERSON ? REVIEWS_DASHBOARD : REVIEWS_COMPANY_REVIEWS,
  },
]

export function useRedirects() {
  const { role, category } = useUserDataForRoutes()

  return useMemo(
    () => [
      ...defaultRedirects,
      ...(Object.values(ManagementRoles).includes(role)
        ? managementRedirects[role] ?? []
        : getPersonRedirects(category)),
    ],
    [role, category]
  )
}
