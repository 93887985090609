import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { ProducerState } from './types'

const initialState: ProducerState = {
  id: null,
  tradeNameId: null,
  acceptability: null,
  country: null,
  tradeName: null,
  category: null,
  TIN: null,
  branchNumber: null,
  legalEntityForm: null,
  legalName: null,
  legalEntity: null,
  registrationNumber: null,
  logoLoading: false,
  translations: [],
  legalAddress: null,
  website: '',
  noWebSite: false,
  postCode: null,
  writePolicy: null,
  postAddress: null,
  sameAsLegalAddress: false,
  email: null,
  phone: null,
  phoneCode: null,
  activityCode: null,
  vat: null,
  companiesByTin: null,
  coincidesWithLegalName: false,
  countriesList: [],
  legalEntityFormList: [],
  logo: null,
  tradeNameEdit: null,
  errors: {},
  touched: {},
  initialData: null,
  isDirty: false,
}

const producerSlice = createSlice({
  name: 'producer',
  initialState,
  reducers: {
    setField: (state, { payload }) => {
      state[payload.field] = payload.value
      state.touched = {
        ...state.touched,
        [payload.field]: true,
      }
      state.isDirty = true
    },
    setFieldNoTouch: (state, { payload }) => {
      state[payload.field] = payload.value
    },
    setFieldError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.value,
      }
    },
    reset: () => {
      return {
        ...initialState,
        isDirty: false,
      }
    },
    setData: (state, { payload }) => {
      return {
        ...initialState,
        ...payload,
        legalEntityFormList: state.legalEntityFormList,
        isDirty: false,
      }
    },
    setDataFromCompany: (state, { payload }) => {
      return {
        ...state,
        isDirty: true,
        ...payload,
        touched: {
          ...state.touched,
          ...Object.keys(payload).reduce((acc, field) => {
            acc[field] = true
            return acc
          }, {}),
        },
      }
    },
    setTradenameEdit: (state, { payload }) => {
      state.tradeNameEdit = payload
      state.isDirty = true
    },
    setInitialData: (state, { payload }) => {
      state.initialData = payload
    },
    setIsDirty: (state, { payload }: PayloadAction<boolean>) => {
      state.isDirty = payload
    },
  },
})

export const { actions, reducer: producer } = producerSlice
export const {
  setField,
  setFieldNoTouch,
  setFieldError,
  reset,
  setData,
  setDataFromCompany,
  setTradenameEdit,
  setInitialData,
  setIsDirty,
} = actions
