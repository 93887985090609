import { createSlice } from '@reduxjs/toolkit'
import type { RewardState } from './types'

const initialState: RewardState = {
  values: {},
  errors: {},
}

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    setValue: (state, { payload }) => {
      state.values = {
        ...state.values,
        [payload.field]: payload.value,
      }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.value,
      }
    },
    init: (state, { payload }) => {
      state.values = {
        ...initialState.values,
        ...payload,
        initialName: payload?.fullName,
      }
      state.errors = { ...initialState.errors }
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: reward } = rewardSlice
export const { setValue, setError, init, reset } = actions
