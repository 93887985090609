import { createSlice } from '@reduxjs/toolkit'
import type { MyWinesState } from './types'

const initialState: MyWinesState = {
  pageInfo: {
    page: 1,
    totalItems: 0,
  },
  items: [],
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  query: '',
  filters: {},
  isProducerWithOnlyRepresentative: false,
  subscription: 'loading',
  updatingItems: [],
  selectedItems: [],
}

const myWinesSlice = createSlice({
  name: 'myWines',
  initialState,
  reducers: {
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setItem: (state, { payload }) => {
      state.items = state.items.map(item => {
        return item.__typename === payload.__typename && item.id === payload.id
          ? { ...item, ...payload }
          : item
      })
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload
    },
    setUpdatingItems: (state, { payload }) => {
      state.updatingItems = payload
    },
    setQuery: (state, { payload }) => {
      state.query = payload
      state.pageInfo = { ...state.pageInfo, page: 1 }
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    reset: () => {
      return initialState
    },
    setProducerWithOnlyRepresentative: (state, { payload }) => {
      state.isProducerWithOnlyRepresentative = payload
    },
    setSubscription: (state, { payload }) => {
      state.subscription = payload
    },
  },
})

export const { actions, reducer: myWines } = myWinesSlice
export const {
  setPageInfo,
  setItems,
  setItem,
  setUpdatingItems,
  reset,
  setProducerWithOnlyRepresentative,
  setSubscription,
} = actions
