import { createSlice } from '@reduxjs/toolkit'
import type { verifyState } from './types'

const initialState: verifyState = {
  method: null,
  email: '',
  emailError: 'Not a valid email address',
  user: '',
  userError: 'Choose a user',
  touched: false,
}

const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {
    setMethod: (state, { payload }) => {
      state.method = payload
    },
    setEmailValue: (state, { payload }) => {
      state.email = payload
    },
    setEmailError: (state, { payload }) => {
      state.emailError = payload
    },
    setUserValue: (state, { payload }) => {
      state.user = payload
    },
    setUserError: (state, { payload }) => {
      state.userError = payload
    },
    setTouched: (state, { payload }) => {
      state.touched = payload
    },
    resetForm: () => {
      return initialState
    },
  },
})

export const { actions, reducer: verify } = verifySlice
export const {
  setMethod,
  setEmailValue,
  setEmailError,
  setUserValue,
  setUserError,
  setTouched,
  resetForm,
} = actions
