import {
  Children,
  cloneElement,
  FC,
  FormEventHandler,
  isValidElement,
  ReactNode,
  useId,
} from 'react'
import styled, { css } from 'styled-components'

export const Label = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  font: var(--typography-12-16-semibold);
  text-align: center;
  color: var(--color-night);
  border: 1px solid transparent;
  user-select: none;
  gap: 4px;
  padding: 6px;
  height: 100%;

  input:checked + & {
    background: #f3f3f5;
    border-color: #e7e7ea;
  }

  input:disabled:checked + & {
    color: var(--color-sw-20);
  }

  input:disabled:not(:checked) + & {
    color: var(--color-sns-20);
  }
`

const Switcher = styled.div<{
  $minHeight?: number
  $size?: 'M' | 'L'
  $disabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  padding: 4px;
  background: #fff;
  border: 1px solid #e7e7ea;
  border-radius: 8px;
  margin: 0;

  min-height: ${props => {
    if (props.$size === 'M') return '40px'
    if (props.$size === 'L') return '48px'
    return undefined
  }};

  input {
    opacity: 0;
    position: absolute;
  }

  ${props =>
    props.$minHeight &&
    css`
      ${Label} {
        min-height: 40px;
        padding: 0 8px;
      }
    `}
  ${props =>
    props.$size === 'L' &&
    css`
      ${Label} {
        font: var(--typography-14-16-semibold);
      }
    `}
  &, * {
    box-sizing: border-box;
  }

  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
      ${Label} {
        color: #d0d0d6;
        pointer-events: none;
      }
    `}
`

const SwitcherOption = styled.label`
  flex: 1;
  position: relative;
  cursor: pointer;

  &:has(:focus-visible) {
    outline: -webkit-focus-ring-color auto 1px;
  }
`

interface SegmentedControlPropsGeneric<T> {
  value: T
  children: ReactNode
  onChange: (value: T) => void
  itemMinHeight?: number
  size?: 'M' | 'L'
  disabled?: boolean
}

type SegmentedControlProps<T = string> = SegmentedControlPropsGeneric<T>

/**
 * SegmentedControl
 *
 * @param props
 * @param {string} props.value
 * @param {(string) => void} props.onChange
 *
 * @return JSX.Element
 *
 * @example
 * import {SegmentedControl, SegmentedControlItem} from 'components/Common/MasterComponents/SegmentedControl'
 * const Component = () => {
 *   const [value, setValue] = useState()
 *
 *   return (
 *     <SegmentedControl value={value} onChange={setValue}>
 *       <SegmentedControlItem value={'val1'}>{label1}</SegmentedControlItem>
 *       <SegmentedControlItem value={'val2'}>{label2}</SegmentedControlItem>
 *     </SegmentedControl>
 *   )
 * }
 *
 */
const SegmentedControl = <T = string,>(props: SegmentedControlProps<T>) => {
  const {
    value,
    children,
    onChange,
    itemMinHeight,
    size = 'M',
    disabled = false,
  } = props

  const name = useId()

  const handleChange: FormEventHandler = e => {
    // @ts-ignore
    onChange(e.target.value)
  }

  return (
    <Switcher
      onChange={handleChange}
      $minHeight={itemMinHeight}
      $size={size}
      $disabled={disabled}
    >
      {Children.map(children, child =>
        isValidElement(child)
          ? cloneElement(child, {
              // @ts-ignore
              checked: value === child.props.value,
              name,
            })
          : child
      )}
    </Switcher>
  )
}

interface SegmentedControlItemProps {
  value: string | null
  children: ReactNode
  checked?: boolean
  name?: string
  disabled?: boolean
}

const emptyOnChangeHandler = () => {}
const SegmentedControlItem: FC<SegmentedControlItemProps> = props => {
  const { value, checked, children, name, disabled = false } = props

  return (
    <SwitcherOption>
      <input
        type='radio'
        name={name}
        value={value || ''}
        checked={checked}
        onChange={emptyOnChangeHandler}
        disabled={disabled}
      />
      <Label>{children}</Label>
    </SwitcherOption>
  )
}

export { SegmentedControl, SegmentedControlItem }
