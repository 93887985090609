import { createSlice } from '@reduxjs/toolkit'
import type { OfferState } from './types'

const initialState: OfferState = {
  data: {},
  companyType: null,
}

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOffer: (state, { payload }) => {
      state.data = payload
    },
    setCompanyType: (state, { payload }) => {
      state.companyType = payload
    },

    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: offer } = offerSlice
export const { setOffer, setCompanyType, reset } = actions
