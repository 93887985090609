export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const normalizeDayName = day => day[0] + day[1].toLowerCase()

/**
 * Удаляет точку в конце строки
 * @param {string|number} value
 * @returns {string}
 */
export const removeTrailingDot = value => {
  return String(value ?? '').replace(/\.$/, '')
}

export const commaToDot = value => {
  return typeof value === 'string' ? value.replace(/,/g, '.') : value
}
