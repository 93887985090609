import { createSlice } from '@reduxjs/toolkit'
import type { MentionEdit } from './types'

const initialState: MentionEdit = {
  tnFromMention: null,
  verificationState: null,
  imageManagementState: {
    productImages: [],
    mentionImages: [],
  },
}

const wineEditSlice = createSlice({
  name: 'mentionEdit',
  initialState,
  reducers: {
    setTnFromMention: (state, { payload }) => {
      state.tnFromMention = payload
    },
    setVerificationState: (state, { payload }) => {
      state.verificationState = payload
    },
    setImageManagementState: (state, { payload }) => {
      state.imageManagementState = payload
    },
    reset() {
      return initialState
    },
  },
})

export const { actions, reducer: mentionEdit } = wineEditSlice
export const {
  setTnFromMention,
  setVerificationState,
  setImageManagementState,
  reset,
} = actions
