import { createSlice } from '@reduxjs/toolkit'
import type { DatabaseState } from './types'

const initialState: DatabaseState = {
  pageInfo: {
    page: 1,
  },
  items: [],
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  query: '',
  filters: {},
  tradeName: '',
  isLoading: true,
  isSorting: false,
  data: null,
}

const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
      state.isLoading = state?.pageInfo?.page !== payload?.page
      state.isSorting = false
    },
    setItems: (state, { payload }) => {
      state.items = payload
      state.isLoading = false
      state.isSorting = false
    },
    setTradeName: (state, { payload }) => {
      state.tradeName = payload
    },
    setItem: (state, { payload }) => {
      state.items = { ...state.items, [payload.id]: payload }
    },
    updateItem: (state, { payload }) => {
      state.items = (state.items ?? []).map(item => {
        if (item.id === payload.id) return { ...item, ...payload }
        return item
      })
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload
      state.isLoading = false
      state.isSorting = state.sorting !== payload
    },
    setQuery: (state, { payload }) => {
      state.query = payload
      state.pageInfo = { ...state.pageInfo, page: 1 }
      state.isLoading = true
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
      state.pageInfo = { ...state.pageInfo, page: 1 }
      state.isLoading = true
    },
    setData: (state, { payload }) => {
      state.data = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: database } = databaseSlice
export const {
  setPageInfo,
  setItems,
  updateItem,
  setSorting,
  setTradeName,
  setQuery,
  setFilters,
  setData,
  reset,
} = actions
