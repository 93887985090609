import { createSlice } from '@reduxjs/toolkit'
import type { CompanyWinesState } from './types'

const initialState: CompanyWinesState = {
  items: [],
  selectedItems: [],
  loading: true,
  updating: false,
  pageInfo: {},
  allCheckboxChecked: false,
  page: 1,
  sorting: {
    name: null,
    order: null,
  },
  query: undefined,
}

const companyWinesSlice = createSlice({
  name: 'companyWines',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    setPageInfo: (state, { payload }) => {
      state.selectedItems = payload
    },
    setAllCheckboxChecked: (state, { payload }) => {
      state.allCheckboxChecked = payload
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload
      state.page = 1
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setQuery: (state, { payload }) => {
      state.query = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setUpdating: (state, { payload }) => {
      state.updating = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: companyWines } = companyWinesSlice
